import { ReplaySubject, of, forkJoin, zip } from 'rxjs';
import { map, flatMap, publishReplay, refCount, tap } from 'rxjs/operators';
import { groupBy, index, normalizeAsArray } from 'yti-common-ui/utils/array';
import { GraphMeta, MetaModel } from 'app/entities/meta';
import { asLocalizable } from 'yti-common-ui/utils/localization';
import { Node } from 'app/entities/node';
import { getOrCreate } from 'yti-common-ui/utils/map';
import { apiUrl } from 'app/config';
import { HttpClient } from '@angular/common/http';
import { requireDefined } from 'yti-common-ui/utils/object';
var MetaModelService = /** @class */ (function () {
    function MetaModelService(http) {
        var _this = this;
        this.http = http;
        this.metaCache = new Cache(function (graphMeta) { return graphMeta.graphId; });
        this.templates = new ReplaySubject();
        var graphMetas$ = this.createAllGraphMetas().pipe(publishReplay(1), refCount());
        this.metaCache.init(graphMetas$);
        graphMetas$.subscribe(function (graphMetas) {
            _this.templates.next(graphMetas.filter(function (graphMeta) { return graphMeta.template; }));
            _this.templates.complete();
        });
    }
    MetaModelService.prototype.getMeta = function (graphId) {
        var _this = this;
        return this.getGraphMeta(graphId).pipe(flatMap(function (graphMeta) { return _this.createMetaModel(graphMeta); }));
    };
    MetaModelService.prototype.getGraphMeta = function (graphId) {
        var _this = this;
        return this.metaCache.getOrCreate(graphId, function () {
            return _this.getGraph(graphId).pipe(flatMap(function (graph) { return _this.createGraphMeta(graph); }));
        });
    };
    MetaModelService.prototype.createAllGraphMetas = function () {
        return zip(this.getGraphs(), this.getAllMetaNodesByGraph())
            .pipe(map(function (_a) {
            var graphs = _a[0], metaNodesByGraph = _a[1];
            return graphs.map(function (graph) { return MetaModelService.createGraphMetaFromNodeMetas(graph, metaNodesByGraph.get(graph.id) || []); });
        }));
    };
    MetaModelService.prototype.createGraphMeta = function (graph) {
        return this.getMetaNodes(graph.id).pipe(map(function (nodeMetasInGraph) {
            return MetaModelService.createGraphMetaFromNodeMetas(graph, nodeMetasInGraph);
        }));
    };
    MetaModelService.createGraphMetaFromNodeMetas = function (graph, nodeMetasInGraph) {
        var template = graph.properties.type ? graph.properties.type[0].value === 'Metamodel' : false;
        var label = asLocalizable(graph.properties['prefLabel']);
        return new GraphMeta(graph.id, label, nodeMetasInGraph, template);
    };
    MetaModelService.prototype.createMetaModel = function (graphMeta) {
        var _this = this;
        var externalGraphs = new Set();
        for (var _i = 0, _a = graphMeta.getNodeMetas(); _i < _a.length; _i++) {
            var nodeMeta = _a[_i];
            for (var _b = 0, _c = nodeMeta.references; _b < _c.length; _b++) {
                var reference = _c[_b];
                externalGraphs.add(reference.graphId);
            }
        }
        externalGraphs.delete(graphMeta.graphId);
        // necessary optimization since forkJoin doesn't ever complete with empty observables array
        if (externalGraphs.size === 0) {
            return of(new MetaModel([graphMeta]));
        }
        return forkJoin(Array.from(externalGraphs).map(function (graph) { return _this.getGraphMeta(graph); }))
            .pipe(map(function (graphMetas) { return new MetaModel([graphMeta].concat(graphMetas)); }));
    };
    MetaModelService.prototype.getReferrersByMeta = function (referrer) {
        var _this = this;
        var referenceId = referrer.referenceId;
        function groupByMeta(nodes) {
            var references = new Map();
            for (var _i = 0, nodes_1 = nodes; _i < nodes_1.length; _i++) {
                var node = nodes_1[_i];
                var referenceMeta = node.meta.getReference(referenceId);
                getOrCreate(references, referenceMeta, function () { return []; }).push(node);
            }
            return Array.from(references.entries()).map((function (entry) { return ({ meta: entry[0], nodes: entry[1] }); }));
        }
        var referrerNodes = forkJoin(referrer.values.map(function (nodeExternal) {
            return _this.getMeta(nodeExternal.type.graph.id).pipe(map(function (meta) {
                return Node.create(nodeExternal, meta, true);
            }));
        }));
        return referrerNodes.pipe(map(function (nodes) { return groupByMeta(nodes); }));
    };
    MetaModelService.prototype.getMetaTemplates = function () {
        return this.templates.asObservable();
    };
    MetaModelService.prototype.getGraph = function (graphId) {
        return this.http.get(apiUrl + "/graphs/" + graphId);
    };
    MetaModelService.prototype.getGraphs = function () {
        return this.http.get(apiUrl + "/graphs")
            .pipe(map(normalizeAsArray));
    };
    MetaModelService.prototype.getMetaNodes = function (graphId) {
        var params = {
            graphId: graphId
        };
        return this.http.get(apiUrl + "/types", { params: params })
            .pipe(map(normalizeAsArray));
    };
    MetaModelService.prototype.getAllMetaNodesByGraph = function () {
        return this.http.get(apiUrl + "/types")
            .pipe(map(normalizeAsArray), map(function (allNodes) { return groupBy(allNodes, function (node) { return node.graph.id; }); }));
    };
    return MetaModelService;
}());
export { MetaModelService };
var Cache = /** @class */ (function () {
    function Cache(keyExtractor) {
        this.keyExtractor = keyExtractor;
        this.cache = new ReplaySubject();
    }
    Cache.prototype.init = function (values$) {
        var _this = this;
        values$.subscribe(function (values) {
            _this.cache.next(index(values, _this.keyExtractor));
            _this.cache.complete();
        });
    };
    Cache.prototype.update = function (value$) {
        var _this = this;
        return zip(this.cache, value$)
            .pipe(tap(function (_a) {
            var cache = _a[0], value = _a[1];
            return cache.set(_this.keyExtractor(value), value);
        }), map(function (_a) {
            var cache = _a[0], value = _a[1];
            return value;
        }));
    };
    Cache.prototype.remove = function (key$) {
        return zip(this.cache, key$)
            .pipe(tap(function (_a) {
            var cache = _a[0], key = _a[1];
            return cache.delete(key);
        }), map(function (_a) {
            var cache = _a[0], key = _a[1];
            return key;
        }));
    };
    Cache.prototype.getOrCreate = function (key, create) {
        return this.cache.pipe(flatMap(function (cache) {
            if (cache.has(key)) {
                return of(requireDefined(cache.get(key)));
            }
            else {
                return create().pipe(tap(function (value) { return cache.set(key, value); }));
            }
        }));
    };
    return Cache;
}());
