import { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { EditableService } from 'app/services/editable.service';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { requireDefined } from 'yti-common-ui/utils/object';
import { DeleteConfirmationModalService } from 'app/components/common/delete-confirmation-modal.component';
import { LanguageService } from 'app/services/language.service';
import { vocabularyIdPrefix } from 'app/utils/id-prefix';
import { ConfirmCancelEditGuard } from '../common/edit.guard';
var VocabularyComponent = /** @class */ (function () {
    function VocabularyComponent(editableService, conceptViewModel, deleteConfirmationModal, languageService, editGuard) {
        var _this = this;
        this.editableService = editableService;
        this.conceptViewModel = conceptViewModel;
        this.languageService = languageService;
        this.editGuard = editGuard;
        this.idPrefix = vocabularyIdPrefix;
        editableService.onSave = function () { return conceptViewModel.saveVocabulary(); };
        editableService.onCanceled = function () { return conceptViewModel.resetVocabulary(); };
        editableService.onRemove = function () {
            return deleteConfirmationModal.open(requireDefined(_this.vocabulary))
                .then(function () { return conceptViewModel.removeVocabulary(); });
        };
    }
    Object.defineProperty(VocabularyComponent.prototype, "formNode", {
        get: function () {
            return this.conceptViewModel.vocabularyForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyComponent.prototype, "vocabulary", {
        get: function () {
            return this.conceptViewModel.vocabulary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyComponent.prototype, "filterLanguage", {
        get: function () {
            return this.languageService.filterLanguage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyComponent.prototype, "namespace", {
        get: function () {
            return this.conceptViewModel.prefixAndNamespace ? this.conceptViewModel.prefixAndNamespace.namespace : null;
        },
        enumerable: true,
        configurable: true
    });
    VocabularyComponent.prototype.ngOnInit = function () {
        this.editGuard.activeTabbedComponent = this;
    };
    VocabularyComponent.prototype.ngOnDestroy = function () {
        this.editGuard.activeTabbedComponent = undefined;
    };
    VocabularyComponent.prototype.isEditing = function () {
        return this.editableService.editing;
    };
    VocabularyComponent.prototype.cancelEditing = function () {
        this.editableService.cancel();
    };
    return VocabularyComponent;
}());
export { VocabularyComponent };
