import { EditableService } from 'app/services/editable.service';
import { FormPropertyLiteral } from 'app/services/form-state';
var LiteralInputComponent = /** @class */ (function () {
    function LiteralInputComponent(editableService) {
        this.editableService = editableService;
    }
    LiteralInputComponent.prototype.valueInError = function () {
        return !this.property.control.valid;
    };
    Object.defineProperty(LiteralInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    return LiteralInputComponent;
}());
export { LiteralInputComponent };
