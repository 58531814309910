import { ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'app/services/language.service';
import { TermedService } from 'app/services/termed.service';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { DataSet, Network as VisNetwork } from 'vis';
import { collectProperties } from 'yti-common-ui/utils/array';
import { assertNever, requireDefined } from 'yti-common-ui/utils/object';
import { TranslateService } from '@ngx-translate/core';
import { MetaModelService } from 'app/services/meta-model.service';
import { asLocalizable } from 'yti-common-ui/utils/localization';
import { ConfigurationService } from '../../services/configuration.service';
// Distinguish between single click and double click
var DELAY = 600;
var options = {
    nodes: {
        shape: 'box',
        fixed: false,
        font: {
            color: '#000000',
            face: 'Open Sans'
        },
        color: {
            background: '#ffffff',
            border: '#000000',
            highlight: {
                background: '#d3d3d3',
                border: '#000000'
            },
            hover: {
                background: '#d3d3d3',
                border: '#000000'
            }
        }
    },
    groups: {
        rootConceptGroup: {},
        rootCollectionGroup: {
            margin: 20,
            borderWidth: 0,
        },
        relatedGroup: {},
        broaderGroup: {},
        isPartOfGroup: {},
        memberGroup: {}
    },
    layout: {
        hierarchical: {
            enabled: false,
            direction: 'DU',
            sortMethod: 'hubsize',
            parentCentralization: true,
            nodeSpacing: 250,
            levelSeparation: 100,
        },
        improvedLayout: true
    },
    edges: {
        smooth: {
            enabled: true,
            type: 'cubicBezier',
            roundness: 0.25
        },
        length: 250,
        hoverWidth: 3,
        color: {
            color: 'black'
        }
    },
    interaction: {
        hideEdgesOnDrag: false,
        hideNodesOnDrag: false,
        navigationButtons: true,
        hover: true,
        selectable: true,
        hoverConnectedEdges: true,
        selectConnectedEdges: false
    },
    physics: {
        enabled: true,
        solver: 'forceAtlas2Based',
        forceAtlas2Based: {
            gravitationalConstant: -2000,
            centralGravity: 0.01,
            springConstant: 3,
            springLength: 100,
            damping: 1.5,
            avoidOverlap: 0
        },
        stabilization: {
            enabled: false,
            fit: true,
            iterations: 1
        },
        timestep: 0.4
    }
};
var ConceptNetworkComponent = /** @class */ (function () {
    function ConceptNetworkComponent(zone, translateService, languageService, termedService, metaModelService, router, renderer, conceptViewModel, configurationService) {
        var _this = this;
        this.zone = zone;
        this.translateService = translateService;
        this.languageService = languageService;
        this.termedService = termedService;
        this.metaModelService = metaModelService;
        this.router = router;
        this.renderer = renderer;
        this.conceptViewModel = conceptViewModel;
        this.configurationService = configurationService;
        this.rootNode = null;
        this.skipNextSelection = false;
        this.clicks = 0;
        this.networkData = {
            nodes: new DataSet(),
            edges: new DataSet()
        };
        this._maximized = false;
        var updateNetworkData = function () {
            var newNodes = _this.networkData.nodes.map(function (node) { return node.update(); });
            _this.networkData.nodes.update(newNodes);
            var newEdges = _this.networkData.edges.map(function (edge) { return edge.update(); });
            _this.networkData.edges.update(newEdges);
        };
        this.translateLanguageSubscription = this.languageService.translateLanguage$.subscribe(updateNetworkData);
    }
    Object.defineProperty(ConceptNetworkComponent.prototype, "maximized", {
        get: function () {
            return this._maximized;
        },
        set: function (maximized) {
            this._maximized = maximized;
            if (maximized) {
                this.renderer.addClass(document.body, 'visualization-maximized');
            }
            else {
                this.renderer.removeClass(document.body, 'visualization-maximized');
            }
        },
        enumerable: true,
        configurable: true
    });
    ConceptNetworkComponent.drawText = function (ctx, to, text) {
        ctx.font = '600 12px Open Sans, Helvetica Neue, Helvetica, Arial';
        ctx.textAlign = 'center';
        ctx.fillStyle = '#000000';
        ctx.fillText(text, to.x, to.y);
    };
    ConceptNetworkComponent.drawLine = function (ctx, from, to, lineWidth) {
        if (lineWidth === void 0) { lineWidth = 2; }
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = lineWidth;
        ctx.beginPath();
        ctx.moveTo(from.x, from.y);
        ctx.lineTo(to.x, to.y);
        ctx.closePath();
        ctx.stroke();
    };
    ConceptNetworkComponent.drawInheritanceArrow = function (ctx, data, lineWidth) {
        if (lineWidth === void 0) { lineWidth = 3; }
        var angle = data.angle, point = data.point;
        var x = point.x, y = point.y;
        var length = 15;
        ctx.fillStyle = '#ffffff';
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = lineWidth;
        var top = { x: x - length * Math.cos(angle), y: y - length * Math.sin(angle) };
        var left = { x: top.x + length / 2 * Math.cos(angle + 0.5 * Math.PI), y: top.y + length / 2 * Math.sin(angle + 0.5 * Math.PI) };
        var right = { x: top.x + length / 2 * Math.cos(angle - 0.5 * Math.PI), y: top.y + length / 2 * Math.sin(angle - 0.5 * Math.PI) };
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(left.x, left.y);
        ctx.lineTo(right.x, right.y);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
    };
    ConceptNetworkComponent.drawCompositionArrow = function (ctx, data, lineWidth) {
        if (lineWidth === void 0) { lineWidth = 3; }
        var point = data.point, angle = data.angle;
        var x = point.x, y = point.y;
        var length = 15;
        ctx.fillStyle = '#ffffff';
        ctx.strokeStyle = '#000000';
        ctx.lineWidth = lineWidth;
        var top = { x: x - length * Math.cos(angle), y: y - length * Math.sin(angle) };
        var bottom = { x: x - length * 2 * Math.cos(angle), y: y - length * 2 * Math.sin(angle) };
        var left = { x: top.x + length / 2 * Math.cos(angle + 0.5 * Math.PI), y: top.y + length / 2 * Math.sin(angle + 0.5 * Math.PI) };
        var right = { x: top.x + length / 2 * Math.cos(angle - 0.5 * Math.PI), y: top.y + length / 2 * Math.sin(angle - 0.5 * Math.PI) };
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(left.x, left.y);
        ctx.lineTo(bottom.x, bottom.y);
        ctx.lineTo(right.x, right.y);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
    };
    ConceptNetworkComponent.drawEdgeArrows = function (ctx, edgeType, arrowData) {
        switch (edgeType) {
            case 'relation':
                // no arrow
                break;
            case 'inheritance':
                ConceptNetworkComponent.drawInheritanceArrow(ctx, arrowData.to);
                break;
            case 'composition':
                ConceptNetworkComponent.drawCompositionArrow(ctx, arrowData.to);
                break;
            default:
                assertNever(edgeType, 'Unsupported edge type: ' + edgeType);
        }
    };
    ConceptNetworkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.drawLegend();
        this.languageSubscription = this.languageService.language$.subscribe(function () {
            _this.drawLegend();
        });
        this.zone.runOutsideAngular(function () {
            _this.network = new VisNetwork(_this.networkCanvasRef.nativeElement, _this.networkData, options);
            _this.network.on('dragStart', _this.onDragStart.bind(_this));
            _this.network.on('click', _this.onClick.bind(_this));
        });
        this.resourceActionSubscription = this.conceptViewModel.resourceAction$.subscribe(function (action) {
            switch (action.type) {
                case 'select':
                    if (!_this.skipNextSelection) {
                        _this.resetRootNode(action.item);
                        _this.network.once('afterDrawing', function () { return _this.network.fit(); });
                    }
                    _this.skipNextSelection = false;
                    break;
                case 'edit':
                    _this.updateNode(action.item);
                    if (_this.rootNode && _this.rootNode.id === action.item.id) {
                        _this.rootNode = action.item;
                    }
                    break;
                case 'remove':
                    if (_this.rootNode && _this.rootNode.id === action.item.id) {
                        _this.resetRootNode(null);
                    }
                    else {
                        _this.removeNode(action.item.id);
                    }
                    break;
                case 'noselect':
                    if (_this.rootNode && !_this.rootNode.persistent) {
                        _this.resetRootNode(null);
                    }
                    break;
                default:
                    assertNever(action, 'Unsupported action: ' + action);
            }
        });
    };
    ConceptNetworkComponent.prototype.ngOnDestroy = function () {
        this.maximized = false; // remove class from the body
        this.network.destroy();
        this.languageSubscription.unsubscribe();
        this.translateLanguageSubscription.unsubscribe();
        this.resourceActionSubscription.unsubscribe();
    };
    ConceptNetworkComponent.prototype.isEmpty = function () {
        return this.networkData.nodes.length === 0;
    };
    ConceptNetworkComponent.prototype.hidePopup = function () {
        var tooltip = this.networkCanvasRef.nativeElement.querySelector('.vis-tooltip');
        if (tooltip !== null) {
            tooltip.style.visibility = 'hidden';
        }
    };
    ConceptNetworkComponent.prototype.resetRootNode = function (node) {
        this.rootNode = node;
        this.networkData.nodes.clear();
        this.networkData.edges.clear();
        if (node) {
            this.createRootNode(node);
            this.updateEdgeNodes(node);
        }
    };
    ConceptNetworkComponent.prototype.createConceptNodeData = function (concept) {
        var _this = this;
        var createNode = function () {
            var node = {
                id: concept.id,
                label: _this.languageService.translate(concept.label),
                // FIXME: how to handle multiple definitions?
                title: _this.languageService.translate(asLocalizable(concept.getDefinitionWithoutSemantics(_this.configurationService.namespaceRoot), true))
            };
            return Object.assign(node, { update: createNode });
        };
        return createNode();
    };
    ConceptNetworkComponent.prototype.createRootNode = function (node) {
        if (node.type === 'Concept') {
            this.networkData.nodes.add(this.createRootConceptNode(node));
        }
        else {
            this.networkData.nodes.add(this.createCollectionNode(node));
        }
    };
    ConceptNetworkComponent.prototype.createRootConceptNode = function (concept) {
        return Object.assign(this.createConceptNodeData(concept), {
            group: 'rootConceptGroup',
            physics: false,
            fixed: false
        });
    };
    ConceptNetworkComponent.prototype.createCollectionNode = function (collection) {
        var _this = this;
        var createNode = function () {
            var node = {
                id: collection.id,
                label: _this.languageService.translate(collection.label),
                // FIXME: how to handle multiple definitions?
                title: _this.languageService.translate(asLocalizable(collection.getDefinitionWithoutSemantics(_this.configurationService.namespaceRoot), true)),
                group: 'rootCollectionGroup',
                physics: false,
                fixed: false
            };
            return Object.assign(node, { update: createNode });
        };
        return createNode();
    };
    ConceptNetworkComponent.prototype.createRelatedConceptNode = function (relatedConcept) {
        return Object.assign(this.createConceptNodeData(relatedConcept), { group: 'relatedGroup' });
    };
    ConceptNetworkComponent.prototype.createBroaderConceptNode = function (broaderConcept) {
        return Object.assign(this.createConceptNodeData(broaderConcept), { group: 'broaderGroup' });
    };
    ConceptNetworkComponent.prototype.createIsPartOfConceptNode = function (isPartOfConcept) {
        return Object.assign(this.createConceptNodeData(isPartOfConcept), { group: 'isPartOfGroup' });
    };
    ConceptNetworkComponent.prototype.createMemberConceptNode = function (memberConcept) {
        return Object.assign(this.createConceptNodeData(memberConcept), { group: 'memberGroup' });
    };
    ConceptNetworkComponent.prototype.createEdgeData = function (from, to, meta, type) {
        var _this = this;
        var createTitle = function () { return _this.languageService.translate(meta.label, false) + ': ' +
            _this.languageService.translate(from.label) +
            ' &rarr; ' +
            _this.languageService.translate(to.label); };
        var createEdge = function () {
            var edge = {
                from: from.id,
                to: to.id,
                id: from.id + to.id,
                title: createTitle(),
                type: type
            };
            return Object.assign(edge, { update: createEdge });
        };
        return createEdge();
    };
    ConceptNetworkComponent.prototype.createRelatedConceptEdge = function (from, to, meta) {
        return Object.assign(this.createEdgeData(from, to, meta, 'relation'), {});
    };
    ConceptNetworkComponent.prototype.createBroaderConceptEdge = function (from, to, meta) {
        return Object.assign(this.createEdgeData(from, to, meta, 'inheritance'), {
            arrows: {
                to: true
            }
        });
    };
    ConceptNetworkComponent.prototype.createIsPartOfConceptEdge = function (from, to, meta) {
        return Object.assign(this.createEdgeData(from, to, meta, 'composition'), {
            arrows: {
                to: true
            }
        });
    };
    ConceptNetworkComponent.prototype.createMemberConceptEdge = function (from, to, meta) {
        return Object.assign(this.createEdgeData(from, to, meta, 'relation'), {});
    };
    ConceptNetworkComponent.prototype.addNodeIfDoesNotExist = function (node) {
        if (!this.networkData.nodes.get(node.id)) {
            this.networkData.nodes.add(node);
        }
    };
    ConceptNetworkComponent.prototype.addEdgeIfDoesNotExist = function (edge) {
        if (!this.networkData.edges.get(edge.id)) {
            this.networkData.edges.add(edge);
            var edgeInstance = this.network.edgesHandler.body.edges[requireDefined(edge.id)];
            edgeInstance.drawArrows = function (ctx, arrowData, o) {
                return ConceptNetworkComponent.drawEdgeArrows(ctx, edge.type, arrowData);
            };
        }
    };
    ConceptNetworkComponent.prototype.drawLegend = function () {
        var legendCanvas = this.legendCanvasRef.nativeElement;
        var ctx = legendCanvas.getContext('2d');
        var dpp = 4;
        var width = 247;
        var height = 55;
        legendCanvas.width = width * dpp;
        legendCanvas.height = height * dpp;
        legendCanvas.style.width = width + 'px';
        legendCanvas.style.height = height + 'px';
        ctx.clearRect(0, 0, legendCanvas.width, legendCanvas.height);
        ctx.scale(0.825 * dpp, 0.825 * dpp);
        this.translateService.get('Hierarchical').subscribe(function (text) {
            ConceptNetworkComponent.drawText(ctx, { x: 47.5, y: 50 }, text.toUpperCase());
        });
        this.translateService.get('Compositive').subscribe(function (text) {
            ConceptNetworkComponent.drawText(ctx, { x: 142.5, y: 50 }, text.toUpperCase());
        });
        this.translateService.get('Associative').subscribe(function (text) {
            ConceptNetworkComponent.drawText(ctx, { x: 247.5, y: 50 }, text.toUpperCase());
        });
        ConceptNetworkComponent.drawLine(ctx, { x: 20, y: 20 }, { x: 75, y: 20 });
        ConceptNetworkComponent.drawLine(ctx, { x: 115, y: 20 }, { x: 170, y: 20 });
        ConceptNetworkComponent.drawLine(ctx, { x: 220, y: 20 }, { x: 275, y: 20 });
        ConceptNetworkComponent.drawInheritanceArrow(ctx, {
            angle: 0,
            point: {
                x: 75,
                y: 20
            }
        });
        ConceptNetworkComponent.drawCompositionArrow(ctx, {
            angle: 0,
            point: {
                x: 170,
                y: 20
            }
        });
    };
    ConceptNetworkComponent.prototype.updateNode = function (node) {
        if (node.type === 'Concept') {
            this.networkData.nodes.update(this.createConceptNodeData(node));
        }
        else {
            this.networkData.nodes.update(this.createCollectionNode(node));
        }
        this.updateEdgeNodes(node);
    };
    ConceptNetworkComponent.prototype.updateEdgeNodes = function (node) {
        if (node.type === 'Concept') {
            this.updateEdgeNodesForConcept(node);
        }
        else {
            this.updateEdgeNodesForCollection(node);
        }
    };
    ConceptNetworkComponent.prototype.updateEdgeNodesForConcept = function (concept) {
        this.addEdgeNodesForConcept(concept);
        this.removeEdgeNodesFromConcept(concept);
    };
    ConceptNetworkComponent.prototype.addEdgeNodesForConcept = function (concept) {
        var _this = this;
        if (concept.hasRelatedConcepts()) {
            for (var _i = 0, _a = concept.relatedConcepts.values; _i < _a.length; _i++) {
                var relatedConcept = _a[_i];
                this.addNodeIfDoesNotExist(this.createRelatedConceptNode(relatedConcept));
                this.addEdgeIfDoesNotExist(this.createRelatedConceptEdge(concept, relatedConcept, concept.relatedConcepts.meta));
            }
        }
        if (concept.hasBroaderConcepts()) {
            for (var _b = 0, _c = concept.broaderConcepts.values; _b < _c.length; _b++) {
                var broaderConcept = _c[_b];
                this.addNodeIfDoesNotExist(this.createBroaderConceptNode(broaderConcept));
                this.addEdgeIfDoesNotExist(this.createBroaderConceptEdge(concept, broaderConcept, concept.broaderConcepts.meta));
            }
        }
        this.metaModelService.getReferrersByMeta(concept.narrowerConcepts).subscribe(function (referrers) {
            for (var _i = 0, referrers_1 = referrers; _i < referrers_1.length; _i++) {
                var _a = referrers_1[_i], meta = _a.meta, nodes = _a.nodes;
                for (var _b = 0, nodes_1 = nodes; _b < nodes_1.length; _b++) {
                    var narrowerConcept = nodes_1[_b];
                    _this.addNodeIfDoesNotExist(_this.createBroaderConceptNode(narrowerConcept));
                    _this.addEdgeIfDoesNotExist(_this.createBroaderConceptEdge(narrowerConcept, concept, meta));
                }
            }
        });
        if (concept.hasIsPartOfConcepts()) {
            for (var _d = 0, _e = concept.isPartOfConcepts.values; _d < _e.length; _d++) {
                var isPartOfConcept = _e[_d];
                this.addNodeIfDoesNotExist(this.createIsPartOfConceptNode(isPartOfConcept));
                this.addEdgeIfDoesNotExist(this.createIsPartOfConceptEdge(concept, isPartOfConcept, concept.isPartOfConcepts.meta));
            }
        }
        this.metaModelService.getReferrersByMeta(concept.partOfThisConcepts).subscribe(function (referrers) {
            for (var _i = 0, referrers_2 = referrers; _i < referrers_2.length; _i++) {
                var _a = referrers_2[_i], meta = _a.meta, nodes = _a.nodes;
                for (var _b = 0, nodes_2 = nodes; _b < nodes_2.length; _b++) {
                    var partOfThisConcept = nodes_2[_b];
                    _this.addNodeIfDoesNotExist(_this.createIsPartOfConceptNode(partOfThisConcept));
                    _this.addEdgeIfDoesNotExist(_this.createIsPartOfConceptEdge(partOfThisConcept, concept, meta));
                }
            }
        });
    };
    ConceptNetworkComponent.prototype.removeEdgeNodesFromConcept = function (concept) {
        this.removeEdgeNodesFromNode(concept.id, collectProperties((concept.hasRelatedConcepts() ? concept.relatedConcepts.values : []).concat(concept.hasBroaderConcepts() ? concept.broaderConcepts.values : [], concept.narrowerConcepts.values, concept.hasIsPartOfConcepts() ? concept.isPartOfConcepts.values : [], concept.partOfThisConcepts.values), function (c) { return c.id; }));
    };
    ConceptNetworkComponent.prototype.updateEdgeNodesForCollection = function (collection) {
        this.addEdgeNodesForCollection(collection);
        this.removeEdgeNodesFromCollection(collection);
    };
    ConceptNetworkComponent.prototype.addEdgeNodesForCollection = function (collection) {
        for (var _i = 0, _a = collection.memberConcepts.values; _i < _a.length; _i++) {
            var memberConcept = _a[_i];
            this.addNodeIfDoesNotExist(this.createMemberConceptNode(memberConcept));
            this.addEdgeIfDoesNotExist(this.createMemberConceptEdge(collection, memberConcept, collection.memberConcepts.meta));
        }
        if (collection.hasBroaderConcepts()) {
            for (var _b = 0, _c = collection.broaderConcepts.values; _b < _c.length; _b++) {
                var broaderConcept = _c[_b];
                this.addNodeIfDoesNotExist(this.createBroaderConceptNode(broaderConcept));
                this.addEdgeIfDoesNotExist(this.createBroaderConceptEdge(collection, broaderConcept, collection.broaderConcepts.meta));
            }
        }
    };
    ConceptNetworkComponent.prototype.removeEdgeNodesFromCollection = function (collection) {
        this.removeEdgeNodesFromNode(collection.id, collectProperties(collection.memberConcepts.values.concat(collection.hasBroaderConcepts() ? collection.broaderConcepts.values : []), function (concept) { return concept.id; }));
    };
    ConceptNetworkComponent.prototype.removeNode = function (nodeId) {
        var edgesToRemove = [];
        for (var _i = 0, _a = this.networkData.edges.get(createConnectedEdgeFilter(nodeId)); _i < _a.length; _i++) {
            var edge = _a[_i];
            edgesToRemove.push(edge.id);
        }
        this.networkData.edges.remove(edgesToRemove);
        this.networkData.nodes.remove(nodeId);
    };
    ConceptNetworkComponent.prototype.removeEdgeNodesFromNode = function (nodeId, knownNeighbours) {
        var edgesToRemove = [];
        var nodesToRemove = [];
        for (var _i = 0, _a = this.networkData.edges.get(createConnectedEdgeFilter(nodeId)); _i < _a.length; _i++) {
            var connectedEdge = _a[_i];
            if (!knownNeighbours.has(connectedEdge.from) && !knownNeighbours.has(connectedEdge.to)) {
                edgesToRemove.push(connectedEdge.id);
            }
        }
        this.networkData.edges.remove(edgesToRemove);
        for (var _b = 0, _c = this.networkData.nodes.get(); _b < _c.length; _b++) {
            var node = _c[_b];
            var isNotRootNode = this.rootNode && this.rootNode.id !== node.id;
            var isDisconnectedNode = this.networkData.edges.get(createConnectedEdgeFilter(node.id)).length === 0;
            if (isNotRootNode && isDisconnectedNode) {
                nodesToRemove.push(node.id);
            }
        }
        this.networkData.nodes.remove(nodesToRemove);
    };
    ConceptNetworkComponent.prototype.onClick = function (eventData) {
        // If user starts dragging, reset click timer
        // NOTE: If user clicks the node and does not move cursor within 600ms, it will be interpreted as a click
        // leading to changing the concept
        var _this = this;
        var nodeId = eventData.nodes[0];
        var visNode = this.networkData.nodes.get(nodeId);
        var isConcept = visNode.group !== 'rootCollectionGroup';
        var onSingleClick = function () {
            _this.skipNextSelection = true;
            _this.zone.run(function () {
                var graphId = requireDefined(_this.conceptViewModel.vocabulary).graphId;
                if (isConcept) {
                    _this.router.navigate(['/concepts', graphId, 'concept', nodeId]);
                }
                else {
                    _this.router.navigate(['/concepts', graphId, 'collection', nodeId]);
                }
            });
        };
        var onDoubleClick = function () {
            if (isConcept) {
                var graphId = requireDefined(_this.conceptViewModel.vocabulary).graphId;
                var rootConcept$ = _this.termedService.getConcept(graphId, nodeId);
                rootConcept$.subscribe(function (concept) { return _this.addEdgeNodesForConcept(concept); });
            }
        };
        if (eventData.nodes.length > 0) {
            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(function () {
                    onSingleClick();
                    _this.clicks = 0;
                }, DELAY);
            }
            else {
                clearTimeout(this.timer);
                onDoubleClick();
                this.clicks = 0;
            }
        }
    };
    ;
    ConceptNetworkComponent.prototype.onDragStart = function () {
        clearTimeout(this.timer);
        this.clicks = 0;
    };
    ;
    return ConceptNetworkComponent;
}());
export { ConceptNetworkComponent };
function createConnectedEdgeFilter(id) {
    return {
        filter: function (edge) {
            return edge.from === id || edge.to === id;
        }
    };
}
