/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-hierarchy-node.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./concept-hierarchy-node.component";
import * as i3 from "../../services/concept.view.service";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "yti-common-ui/pipes/translate-value.pipe";
var styles_ConceptHierarchyNodeComponent = [i0.styles];
var RenderType_ConceptHierarchyNodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConceptHierarchyNodeComponent, data: {} });
export { RenderType_ConceptHierarchyNodeComponent as RenderType_ConceptHierarchyNodeComponent };
function View_ConceptHierarchyNodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-concept-hierarchy-node", [], null, null, null, View_ConceptHierarchyNodeComponent_0, RenderType_ConceptHierarchyNodeComponent)), i1.ɵdid(2, 49152, null, 0, i2.ConceptHierarchyNodeComponent, [i3.ConceptViewModelService, i4.Router], { concept: [0, "concept"], parentIds: [1, "parentIds"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.path; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_ConceptHierarchyNodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ConceptHierarchyNodeComponent_2)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.children)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ConceptHierarchyNodeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "far fa-plus-square"]], [[8, "hidden", 0], [8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.expand() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "far fa-minus-square"]], [[8, "hidden", 0], [8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.collapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "hierarchy-loop fa fa-retweet"]], [[8, "hidden", 0], [8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "text"]], [[8, "id", 0], [2, "selection", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 278528, null, 0, i5.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(5, { "hierarchy-loop": 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i6.TranslateValuePipe, [i6.LOCALIZER]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptHierarchyNodeComponent_1)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "text"; var currVal_9 = _ck(_v, 5, 0, _co.loop); _ck(_v, 4, 0, currVal_8, currVal_9); var currVal_11 = (_co.expanded && _co.children); _ck(_v, 10, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((!_co.hasChildren() || _co.expanded) || _co.loop); var currVal_1 = (_co.concept.idIdentifier + "_expand_concept_hierarchy_node"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = ((!_co.hasChildren() || _co.collapsed) || _co.loop); var currVal_3 = (_co.concept.idIdentifier + "_collapse_concept_hierarchy_node"); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = !_co.loop; var currVal_5 = (_co.concept.idIdentifier + "_concept_hierarchy_loop"); _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = (_co.concept.idIdentifier + "_concept_hierarchy_node"); var currVal_7 = _co.selected; _ck(_v, 3, 0, currVal_6, currVal_7); var currVal_10 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.concept.label)); _ck(_v, 7, 0, currVal_10); }); }
export function View_ConceptHierarchyNodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-hierarchy-node", [], null, null, null, View_ConceptHierarchyNodeComponent_0, RenderType_ConceptHierarchyNodeComponent)), i1.ɵdid(1, 49152, null, 0, i2.ConceptHierarchyNodeComponent, [i3.ConceptViewModelService, i4.Router], null, null)], null, null); }
var ConceptHierarchyNodeComponentNgFactory = i1.ɵccf("app-concept-hierarchy-node", i2.ConceptHierarchyNodeComponent, View_ConceptHierarchyNodeComponent_Host_0, { concept: "concept", parentIds: "parentIds" }, {}, []);
export { ConceptHierarchyNodeComponentNgFactory as ConceptHierarchyNodeComponentNgFactory };
