
    <div #popover role="tooltip" class="popover">

      <h3 class="popover-header">
        <span *ngIf="!concept" translate>Concept not in references</span>
        <span *ngIf="concept">{{concept.label | translateValue}}</span>
        <div class="actions">
          <span [id]="id + '_unlink_popover_button'" class="btn btn-sm btn-action" (click)="unlink.next()" translate>Unlink</span>
        </div>
      </h3>

      <div class="popover-body" *ngIf="concept" 
           app-semantic-text-plain 
           [value]="conceptDefinition | translateValue"
           [format]="concept.definitionSemanticTextFormat">
      </div>
    </div>
  