import { isDefined } from 'yti-common-ui/utils/object';
var selectionWidthKey = 'selectionWidth';
var SessionService = /** @class */ (function () {
    function SessionService() {
        if (!this.selectionWidth) {
            this.selectionWidth = 550;
        }
    }
    SessionService.get = function (key) {
        var value = window.sessionStorage.getItem(key);
        return isDefined(value) ? JSON.parse(value) : null;
    };
    SessionService.set = function (key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value));
    };
    Object.defineProperty(SessionService.prototype, "selectionWidth", {
        get: function () {
            return SessionService.get(selectionWidthKey);
        },
        set: function (value) {
            SessionService.set(selectionWidthKey, value);
        },
        enumerable: true,
        configurable: true
    });
    return SessionService;
}());
export { SessionService };
