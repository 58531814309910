import { BehaviorSubject } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { UserService } from 'yti-common-ui/services/user.service';
import { isModalClose } from 'yti-common-ui/utils/modal';
var EditableService = /** @class */ (function () {
    function EditableService(errorModalService, userService) {
        var _this = this;
        this.errorModalService = errorModalService;
        this.userService = userService;
        this.editing$ = new BehaviorSubject(false);
        this.saving$ = new BehaviorSubject(false);
        this.removing$ = new BehaviorSubject(false);
        this.loggedInSubscription = userService.loggedIn$.subscribe(function (loggedIn) {
            if (!loggedIn && _this.editing) {
                _this.cancel();
            }
        });
    }
    EditableService.prototype.ngOnDestroy = function () {
        this.loggedInSubscription.unsubscribe();
    };
    Object.defineProperty(EditableService.prototype, "editing", {
        get: function () {
            return this.editing$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableService.prototype, "saving", {
        get: function () {
            return this.saving$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableService.prototype, "removing", {
        get: function () {
            return this.removing$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    EditableService.prototype.edit = function () {
        this.editing$.next(true);
    };
    EditableService.prototype.cancel = function () {
        if (!this.onCanceled) {
            throw new Error('Cancel handler missing');
        }
        this.editing$.next(false);
        this.onCanceled();
    };
    EditableService.prototype.save = function () {
        var _this = this;
        if (!this.onSave) {
            throw new Error('Save handler missing');
        }
        this.saving$.next(true);
        Promise.resolve(this.onSave()).then(function () {
            _this.saving$.next(false);
            _this.editing$.next(false);
        }, function (err) {
            if (err !== 'cancel') {
                _this.errorModalService.openSubmitError(err);
            }
            _this.saving$.next(false);
        });
    };
    EditableService.prototype.remove = function () {
        var _this = this;
        if (!this.onRemove) {
            throw new Error('Remove handler missing');
        }
        this.removing$.next(true);
        Promise.resolve(this.onRemove()).then(function () {
            _this.removing$.next(false);
        }, function (err) {
            if (!isModalClose(err)) {
                _this.errorModalService.openSubmitError(err);
            }
            _this.removing$.next(false);
        });
    };
    return EditableService;
}());
export { EditableService };
