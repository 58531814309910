import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { anyMatching, contains, firstMatching, index, normalizeAsArray } from 'yti-common-ui/utils/array';
import { asLocalizable } from 'yti-common-ui/utils/localization';
import { Node } from './node';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { assertNever, requireDefined } from 'yti-common-ui/utils/object';
function createString(multiple, required, editor) {
    return { type: 'string', cardinality: (multiple ? 'multiple' : 'single'), required: required, editor: editor };
}
function createLocalizable(single, required, editor) {
    return { type: 'localizable', cardinality: (single ? 'single' : 'multiple'), required: required, editor: editor };
}
function createPropertyType(name, attributes) {
    function resolveStringOrLocalizableEditor() {
        if (attributes.has('area')) {
            if (attributes.has('markdown')) {
                return { type: 'semantic', format: 'markdown' };
            }
            else if (attributes.has('xml')) {
                return { type: 'semantic', format: 'xml' };
            }
            else {
                return { type: 'textarea' };
            }
        }
        else {
            return { type: 'input' };
        }
    }
    switch (name) {
        case 'string':
            return createString(attributes.has('multiple'), attributes.has('required'), resolveStringOrLocalizableEditor());
        case 'localizable':
            return createLocalizable(attributes.has('single'), attributes.has('required'), resolveStringOrLocalizableEditor());
        case 'status':
            return createString(false, true, { type: 'status' });
        case 'language':
            return createString(attributes.has('multiple'), attributes.has('required'), { type: 'language' });
        default:
            return assertNever(name, 'Unsupported type: ' + name);
    }
}
function parseTypeAndAttributes(textAttribute) {
    function findTypePropertyValue() {
        var property = textAttribute.properties.type;
        if (property && property.length > 0) {
            return property[0].value;
        }
        else {
            return '';
        }
    }
    var typePropertyValue = findTypePropertyValue();
    if (typePropertyValue.indexOf(':') !== -1) {
        var _a = typePropertyValue.split(':'), type = _a[0], attributesString = _a[1];
        return [type.trim(), attributesString.split(',').map(function (a) { return a.trim(); })];
    }
    else {
        return [typePropertyValue.trim(), []];
    }
}
var PropertyMeta = /** @class */ (function () {
    function PropertyMeta(textAttribute) {
        this.textAttribute = textAttribute;
        this.id = textAttribute.id;
        this.label = asLocalizable(textAttribute.properties.prefLabel);
        this.description = asLocalizable(textAttribute.properties.description);
        this.regex = textAttribute.regex;
        this.index = textAttribute.index;
        var _a = parseTypeAndAttributes(textAttribute), type = _a[0], attributes = _a[1];
        if (type) {
            this.type = createPropertyType(type, new Set(attributes));
        }
        else {
            this.type = createPropertyType('string', new Set('single'));
        }
    }
    Object.defineProperty(PropertyMeta.prototype, "multiColumn", {
        get: function () {
            if (contains(['semantic', 'textarea',], this.type.editor.type)) {
                return false;
            }
            switch (this.id) {
                case 'prefLabel':
                case 'altLabel':
                case 'hiddenLabel':
                    return false;
                default:
                    return true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyMeta.prototype, "semanticTextFormat", {
        get: function () {
            if (this.type.editor.type !== 'semantic') {
                throw new Error('Not a semantic text property, but is of type: ' + this.type.editor.type);
            }
            return this.type.editor.format;
        },
        enumerable: true,
        configurable: true
    });
    PropertyMeta.prototype.isLocalizable = function () {
        return this.type.type === 'localizable';
    };
    PropertyMeta.prototype.isStatus = function () {
        return this.type.editor.type === 'status';
    };
    PropertyMeta.prototype.isLabel = function () {
        return this.id === 'prefLabel' || this.id === 'altLabel';
    };
    return PropertyMeta;
}());
export { PropertyMeta };
var ReferenceMeta = /** @class */ (function () {
    function ReferenceMeta(referenceAttribute) {
        this.referenceAttribute = referenceAttribute;
        this.id = referenceAttribute.id;
        this.label = asLocalizable(referenceAttribute.properties.prefLabel);
        this.description = asLocalizable(referenceAttribute.properties.description);
        this.targetType = referenceAttribute.range.id;
        this.graphId = referenceAttribute.range.graph.id;
        this.index = referenceAttribute.index;
    }
    Object.defineProperty(ReferenceMeta.prototype, "referenceType", {
        get: function () {
            switch (this.targetType) {
                case 'Concept':
                    return 'Concept';
                case 'ConceptLink':
                    return 'ConceptLink';
                case 'Term':
                    return 'Term';
                case 'Organization':
                    return 'Organization';
                case 'Group':
                    return 'Group';
                default:
                    return 'Other';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceMeta.prototype, "required", {
        get: function () {
            return contains(['Organization', 'Group', 'PrimaryTerm'], this.referenceType);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceMeta.prototype, "term", {
        get: function () {
            return this.referenceType === 'Term';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceMeta.prototype, "concept", {
        get: function () {
            return this.referenceType === 'Concept';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceMeta.prototype, "conceptLink", {
        get: function () {
            return this.referenceType === 'ConceptLink';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceMeta.prototype, "cardinality", {
        get: function () {
            return this.id === 'prefLabelXl' ? 'single' : 'multiple';
        },
        enumerable: true,
        configurable: true
    });
    return ReferenceMeta;
}());
export { ReferenceMeta };
var MetaModel = /** @class */ (function () {
    function MetaModel(graphMeta) {
        this.graphMeta = graphMeta;
        this.meta = index(graphMeta, function (gm) { return gm.graphId; });
    }
    MetaModel.prototype.graphHas = function (graphId, nodeType) {
        return this.getGraphMeta(graphId).has(nodeType);
    };
    MetaModel.prototype.getGraphMeta = function (graphId) {
        return requireDefined(this.meta.get(graphId), 'Meta not found for graph: ' + graphId);
    };
    MetaModel.prototype.getNodeMeta = function (graphId, nodeType) {
        return this.getGraphMeta(graphId).getNodeMeta(nodeType);
    };
    MetaModel.prototype.createEmptyNode = function (graphId, nodeId, nodeType) {
        return Node.create(this.getNodeMeta(graphId, nodeType).createEmptyNode(nodeId), this, false);
    };
    MetaModel.prototype.createEmptyVocabulary = function (graphId, nodeId) {
        if (nodeId === void 0) { nodeId = uuid(); }
        var vocabularyType = this.graphHas(graphId, 'Vocabulary') ? 'Vocabulary' : 'TerminologicalVocabulary';
        var newVocabulary = this.createEmptyNode(graphId, nodeId, vocabularyType);
        if (newVocabulary.hasStatus()) {
            newVocabulary.status = 'DRAFT';
        }
        return newVocabulary;
    };
    MetaModel.prototype.createEmptyConcept = function (vocabulary, nodeId) {
        if (nodeId === void 0) { nodeId = uuid(); }
        var newConcept = this.createEmptyNode(vocabulary.graphId, nodeId, 'Concept');
        if (newConcept.hasVocabulary()) {
            newConcept.vocabulary = vocabulary.clone();
        }
        if (newConcept.hasStatus()) {
            newConcept.status = 'DRAFT';
        }
        return newConcept;
    };
    MetaModel.prototype.createEmptyTerm = function (graphId, language, nodeId) {
        if (nodeId === void 0) { nodeId = uuid(); }
        var newTerm = this.createEmptyNode(graphId, nodeId, 'Term');
        if (newTerm.hasStatus()) {
            newTerm.status = 'DRAFT';
        }
        newTerm.prefLabel = (_a = {}, _a[language] = '', _a);
        return newTerm;
        var _a;
    };
    MetaModel.prototype.createEmptyCollection = function (vocabulary, nodeId) {
        if (nodeId === void 0) { nodeId = uuid(); }
        var newCollection = this.createEmptyNode(vocabulary.graphId, nodeId, 'Collection');
        if (newCollection.hasStatus()) {
            newCollection.status = 'DRAFT';
        }
        return newCollection;
    };
    MetaModel.prototype.createConceptLink = function (toGraphId, fromVocabulary, concept) {
        var newConceptLink = this.createEmptyNode(toGraphId, uuid(), 'ConceptLink');
        newConceptLink.prefLabel = concept.prefLabel;
        newConceptLink.vocabularyLabel = fromVocabulary.label;
        newConceptLink.targetGraph = concept.graphId;
        newConceptLink.targetId = concept.id;
        return newConceptLink;
    };
    return MetaModel;
}());
export { MetaModel };
var NodeMeta = /** @class */ (function () {
    function NodeMeta(metaNode) {
        this.metaNode = metaNode;
        this.label = asLocalizable(metaNode.properties.prefLabel);
        this.type = metaNode.id;
        this.graphId = metaNode.graph.id;
        this.uri = metaNode.uri;
        this.properties = normalizeAsArray(metaNode.textAttributes)
            .sort(comparingPrimitive(function (x) { return x.index; }))
            .map(function (x) { return new PropertyMeta(x); });
        this.references = normalizeAsArray(metaNode.referenceAttributes)
            .sort(comparingPrimitive(function (x) { return x.index; }))
            .map(function (x) { return new ReferenceMeta(x); });
    }
    Object.defineProperty(NodeMeta.prototype, "term", {
        get: function () {
            return this.type === 'Term';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NodeMeta.prototype, "concept", {
        get: function () {
            return this.type === 'Concept';
        },
        enumerable: true,
        configurable: true
    });
    NodeMeta.prototype.createEmptyNode = function (id) {
        if (id === void 0) { id = uuid(); }
        var result = {
            id: id,
            type: {
                id: this.type,
                uri: this.uri,
                graph: {
                    id: this.graphId
                }
            },
            code: undefined,
            createdBy: '',
            createdDate: moment().toISOString(),
            lastModifiedBy: '',
            lastModifiedDate: moment().toISOString(),
            uri: undefined,
            properties: {},
            references: {},
            referrers: {}
        };
        for (var _i = 0, _a = this.properties; _i < _a.length; _i++) {
            var property = _a[_i];
            result.properties[property.id] = [];
        }
        for (var _b = 0, _c = this.references; _b < _c.length; _b++) {
            var reference = _c[_b];
            result.references[reference.id] = [];
        }
        return result;
    };
    NodeMeta.prototype.hasProperty = function (propertyId) {
        return anyMatching(this.properties, function (ref) { return ref.id === propertyId; });
    };
    NodeMeta.prototype.hasReference = function (referenceId) {
        return anyMatching(this.references, function (ref) { return ref.id === referenceId; });
    };
    NodeMeta.prototype.getReference = function (referenceId) {
        return requireDefined(firstMatching(this.references, function (ref) { return ref.id === referenceId; }));
    };
    NodeMeta.prototype.getProperty = function (propertyId) {
        return requireDefined(firstMatching(this.properties, function (p) { return p.id === propertyId; }));
    };
    return NodeMeta;
}());
export { NodeMeta };
var GraphMeta = /** @class */ (function () {
    function GraphMeta(graphId, label, nodeMetas, template) {
        this.graphId = graphId;
        this.label = label;
        this.nodeMetas = nodeMetas;
        this.template = template;
        this.meta = new Map();
        this.meta = index(nodeMetas.map(function (m) { return new NodeMeta(m); }), function (m) { return m.type; });
    }
    GraphMeta.prototype.has = function (nodeType) {
        return this.meta.has(nodeType);
    };
    GraphMeta.prototype.getNodeMeta = function (type) {
        return requireDefined(this.meta.get(type), "Meta not found for graph: " + this.graphId + " and node type: " + type);
    };
    GraphMeta.prototype.getNodeMetas = function () {
        return Array.from(this.meta.values());
    };
    return GraphMeta;
}());
export { GraphMeta };
