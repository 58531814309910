import { LanguageService } from './language.service';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { apiUrl } from 'app/config';
import { HttpClient } from '@angular/common/http';
var IndexedConcept = /** @class */ (function () {
    function IndexedConcept(hit) {
        this.id = hit.id;
        this.label = hit.label;
        this.status = hit.status;
        this.uri = hit.uri;
        this.hasNarrower = !!hit.narrower && hit.narrower.length > 0;
        if (hit.altLabel)
            this.altLabel = hit.altLabel;
        if (hit.definition)
            this.definition = hit.definition;
        if (hit.modified)
            this.modified = moment(hit.modified);
        this.vocabulary = {
            id: hit.terminology.id,
            label: hit.terminology.label
        };
    }
    Object.defineProperty(IndexedConcept.prototype, "idIdentifier", {
        get: function () {
            return this.uri;
        },
        enumerable: true,
        configurable: true
    });
    return IndexedConcept;
}());
export { IndexedConcept };
var ElasticSearchService = /** @class */ (function () {
    function ElasticSearchService(http, languageService) {
        this.http = http;
        this.languageService = languageService;
    }
    Object.defineProperty(ElasticSearchService.prototype, "language", {
        get: function () {
            return this.languageService.translateLanguage;
        },
        enumerable: true,
        configurable: true
    });
    ElasticSearchService.convert = function (response) {
        return response && response.concepts ? response.concepts.map(function (concept) { return new IndexedConcept(concept); }) : [];
    };
    ElasticSearchService.prototype.findSingleConceptForVocabulary = function (graphId, conceptId, filter, sortByModified, onlyStatus) {
        return this.conceptSearch({
            terminologyId: graphId ? [graphId] : undefined,
            conceptId: [conceptId],
            query: filter ? filter : undefined,
            sortBy: sortByModified ? "MODIFIED" : undefined,
            sortDirection: sortByModified ? "DESC" : "ASC",
            status: onlyStatus ? [onlyStatus] : undefined,
            highlight: true
        }).pipe(map(function (response) {
            if (response.concepts && response.concepts.length > 0) {
                return new IndexedConcept(response.concepts[0]);
            }
            return null;
        }));
    };
    ElasticSearchService.prototype.getAllConceptsForVocabulary = function (graphId, filter, sortByModified, onlyStatus, from, size) {
        return this.getAllConceptsInOrNotInVocabulary(graphId, undefined, filter, sortByModified, onlyStatus, from, size);
    };
    ElasticSearchService.prototype.getAllConceptsNotInVocabulary = function (notInGraphId, filter, sortByModified, onlyStatus, from, size) {
        return this.getAllConceptsInOrNotInVocabulary(undefined, notInGraphId, filter, sortByModified, onlyStatus, from, size);
    };
    ElasticSearchService.prototype.getTopConceptsForVocabulary = function (graphId, from, size) {
        return this.conceptSearch({
            terminologyId: graphId ? [graphId] : undefined,
            pageSize: size,
            pageFrom: from,
            sortLanguage: this.language,
            highlight: true
        }).pipe(map(ElasticSearchService.convert));
    };
    ElasticSearchService.prototype.getNarrowerConcepts = function (graphId, broaderConceptId) {
        return this.conceptSearch({
            terminologyId: graphId ? [graphId] : undefined,
            broaderConceptId: [broaderConceptId],
            pageSize: 10000,
            pageFrom: 0,
            sortLanguage: this.language,
            highlight: true
        }).pipe(map(ElasticSearchService.convert));
    };
    ElasticSearchService.prototype.terminologySearch = function (request) {
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(apiUrl + "/searchTerminology", JSON.stringify(request), { headers: headers });
    };
    ElasticSearchService.prototype.conceptSearch = function (request) {
        var headers = { 'Content-Type': 'application/json' };
        return this.http.post(apiUrl + "/searchConcept", JSON.stringify(request), { headers: headers });
    };
    ElasticSearchService.prototype.getAllConceptsInOrNotInVocabulary = function (graphId, notInGraphId, filter, sortByModified, onlyStatus, from, size) {
        return this.conceptSearch({
            terminologyId: graphId ? [graphId] : undefined,
            notInTerminologyId: notInGraphId ? [notInGraphId] : undefined,
            query: filter ? filter : undefined,
            sortBy: sortByModified ? "MODIFIED" : undefined,
            sortDirection: sortByModified ? "DESC" : "ASC",
            status: onlyStatus ? [onlyStatus] : undefined,
            pageSize: size,
            pageFrom: from,
            sortLanguage: this.language,
            highlight: true
        }).pipe(map(ElasticSearchService.convert));
    };
    return ElasticSearchService;
}());
export { ElasticSearchService };
