import { EditableService } from 'app/services/editable.service';
import { FormPropertyLocalizable } from 'app/services/form-state';
import { contains } from 'yti-common-ui/utils/array';
var LocalizedInputComponent = /** @class */ (function () {
    function LocalizedInputComponent(editingService) {
        this.editingService = editingService;
    }
    Object.defineProperty(LocalizedInputComponent.prototype, "languages", {
        get: function () {
            return this.property.languages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocalizedInputComponent.prototype, "addableLanguages", {
        get: function () {
            var _this = this;
            var allowMultiple = this.property.cardinality === 'multiple';
            var isNotAddedYet = function (lang) { return !contains(_this.property.addedLanguages, lang); };
            return this.languages.filter(function (lang) {
                return _this.isLanguageVisible(lang) && (allowMultiple || isNotAddedYet(lang));
            });
        },
        enumerable: true,
        configurable: true
    });
    LocalizedInputComponent.prototype.canAdd = function () {
        return this.editing && !this.property.fixed && this.addableLanguages.length > 0;
    };
    LocalizedInputComponent.prototype.canRemove = function () {
        return this.editing && !this.property.fixed;
    };
    LocalizedInputComponent.prototype.addNewLocalization = function (language) {
        this.property.append(language, '');
    };
    LocalizedInputComponent.prototype.removeValue = function (child) {
        this.property.remove(child);
    };
    Object.defineProperty(LocalizedInputComponent.prototype, "editing", {
        get: function () {
            return this.editingService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocalizedInputComponent.prototype, "visibleLocalizations", {
        get: function () {
            var _this = this;
            return this.property.children.filter(function (child) { return _this.isLanguageVisible(child.lang); });
        },
        enumerable: true,
        configurable: true
    });
    LocalizedInputComponent.prototype.isLanguageVisible = function (language) {
        return !this.filterLanguage || language === this.filterLanguage;
    };
    LocalizedInputComponent.prototype.canReorder = function () {
        return this.editing && !this.filterLanguage && this.visibleLocalizations.length > 1;
    };
    return LocalizedInputComponent;
}());
export { LocalizedInputComponent };
