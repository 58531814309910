var SemanticTextDocument = /** @class */ (function () {
    function SemanticTextDocument(children) {
        if (children === void 0) { children = []; }
        this.children = children;
        this.type = 'document';
    }
    Object.defineProperty(SemanticTextDocument.prototype, "text", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    SemanticTextDocument.prototype.removeMatchingLinks = function (predicate) {
        return new SemanticTextDocument(this.children.map(function (n) { return n.removeMatchingLinks(predicate); }));
    };
    return SemanticTextDocument;
}());
export { SemanticTextDocument };
var SemanticTextParagraph = /** @class */ (function () {
    function SemanticTextParagraph(children) {
        if (children === void 0) { children = []; }
        this.children = children;
        this.type = 'paragraph';
    }
    Object.defineProperty(SemanticTextParagraph.prototype, "text", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    SemanticTextParagraph.prototype.removeMatchingLinks = function (predicate) {
        return new SemanticTextParagraph(this.children.map(function (n) { return n.removeMatchingLinks(predicate); }));
    };
    return SemanticTextParagraph;
}());
export { SemanticTextParagraph };
var SemanticTextLink = /** @class */ (function () {
    function SemanticTextLink(text, destination, category) {
        if (category === void 0) { category = 'internal'; }
        this.text = text;
        this.destination = destination;
        this.category = category;
        this.type = 'link';
    }
    Object.defineProperty(SemanticTextLink.prototype, "children", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    SemanticTextLink.prototype.removeMatchingLinks = function (predicate) {
        if (predicate(this.destination)) {
            return new SemanticTextLiteral(this.text);
        }
        else {
            return this;
        }
    };
    return SemanticTextLink;
}());
export { SemanticTextLink };
var SemanticTextLiteral = /** @class */ (function () {
    function SemanticTextLiteral(text) {
        this.text = text;
        this.type = 'text';
    }
    Object.defineProperty(SemanticTextLiteral.prototype, "children", {
        get: function () {
            return [];
        },
        enumerable: true,
        configurable: true
    });
    SemanticTextLiteral.prototype.removeMatchingLinks = function (predicate) {
        return this;
    };
    return SemanticTextLiteral;
}());
export { SemanticTextLiteral };
