import { OnChanges } from '@angular/core';
import { EditableService } from 'app/services/editable.service';
import { FormReferenceTerm } from 'app/services/form-state';
import { MetaModelService } from 'app/services/meta-model.service';
import { contains, last } from 'yti-common-ui/utils/array';
var TermsComponent = /** @class */ (function () {
    function TermsComponent(editableService, metaModelModel) {
        this.editableService = editableService;
        this.metaModelModel = metaModelModel;
        this.openTerms = [];
    }
    TermsComponent.prototype.ngOnChanges = function () {
        this.openTerms = this.unsaved && this.editableService.editing && this.reference.value.length > 0 ? [this.reference.value[0].id] : [];
    };
    Object.defineProperty(TermsComponent.prototype, "languages", {
        get: function () {
            return this.reference.languagesProvider();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TermsComponent.prototype, "addableLanguages", {
        get: function () {
            var _this = this;
            var allowMultiple = this.reference.cardinality === 'multiple';
            var isNotAddedYet = function (lang) { return !contains(_this.reference.addedLanguages, lang); };
            return this.languages.filter(function (lang) {
                return _this.isLanguageVisible(lang) && (allowMultiple || isNotAddedYet(lang));
            });
        },
        enumerable: true,
        configurable: true
    });
    TermsComponent.prototype.canAdd = function () {
        return this.editing && this.addableLanguages.length > 0;
    };
    TermsComponent.prototype.canRemove = function () {
        return this.editing;
    };
    Object.defineProperty(TermsComponent.prototype, "children", {
        get: function () {
            if (this.showEmpty) {
                return this.reference.children;
            }
            else {
                return this.reference.children.filter(function (child) { return child.formNode.hasNonEmptyPrefLabel; });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TermsComponent.prototype, "showEmpty", {
        get: function () {
            return this.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TermsComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    TermsComponent.prototype.addTerm = function (language) {
        var _this = this;
        this.metaModelModel.getMeta(this.reference.graphId).subscribe(function (metaModel) {
            _this.reference.addTerm(metaModel, language);
            _this.openTerms.push(last(_this.children).id);
        });
    };
    TermsComponent.prototype.removeTerm = function (node) {
        this.reference.remove(node);
    };
    Object.defineProperty(TermsComponent.prototype, "visibleChildren", {
        get: function () {
            var _this = this;
            return this.children.filter(function (child) { return _this.isLanguageVisible(child.language); });
        },
        enumerable: true,
        configurable: true
    });
    TermsComponent.prototype.isLanguageVisible = function (language) {
        return !this.filterLanguage || language === this.filterLanguage;
    };
    TermsComponent.prototype.canReorder = function () {
        return this.editing && !this.filterLanguage && this.visibleChildren.length > 1;
    };
    return TermsComponent;
}());
export { TermsComponent };
