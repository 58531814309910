/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./group-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "yti-common-ui/pipes/translate-value.pipe";
import * as i3 from "@angular/common";
import * as i4 from "../../directives/drag-sortable.directive";
import * as i5 from "../form/error-messages.component.ngfactory";
import * as i6 from "../form/error-messages.component";
import * as i7 from "@ngx-translate/core";
import * as i8 from "./group-input.component";
import * as i9 from "../../services/editable.service";
import * as i10 from "./search-group-modal.component";
var styles_GroupInputComponent = [i0.styles];
var RenderType_GroupInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_GroupInputComponent, data: {} });
export { RenderType_GroupInputComponent as RenderType_GroupInputComponent };
function View_GroupInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslateValuePipe, [i2.LOCALIZER])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.label, true)); _ck(_v, 1, 0, currVal_0); }); }
function View_GroupInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupInputComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_GroupInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "removable-text"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i4.DragSortableItemDirective, [i4.DragSortableDirective, i1.NgZone, i1.ElementRef], { item: [0, "item"], index: [1, "index"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeReference(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i2.TranslateValuePipe, [i2.LOCALIZER])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_co.id + "_") + _v.context.$implicit.idIdentifier) + "_remove_domain_reference_link"); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.label, true)); _ck(_v, 5, 0, currVal_3); }); }
function View_GroupInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i4.DragSortableDirective, [i1.NgZone], { sortable: [0, "sortable"], dragDisabled: [1, "dragDisabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupInputComponent_4)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-error-messages", [], null, null, null, i5.View_ErrorMessagesComponent_0, i5.RenderType_ErrorMessagesComponent)), i1.ɵdid(5, 49152, null, 0, i6.ErrorMessagesComponent, [], { control: [0, "control"], id: [1, "id"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference; var currVal_1 = !_co.canReorder(); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.reference.value; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.reference.control; var currVal_4 = (_co.id + "_error_messages"); _ck(_v, 5, 0, currVal_3, currVal_4); }, null); }
function View_GroupInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-action mt-2"], ["translate", ""], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addReference() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add information domain"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "_add_domain_button"); _ck(_v, 0, 0, currVal_0); }); }
export function View_GroupInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupInputComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupInputComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GroupInputComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editing; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_GroupInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-group-input", [], null, null, null, View_GroupInputComponent_0, RenderType_GroupInputComponent)), i1.ɵdid(1, 49152, null, 0, i8.GroupInputComponent, [i9.EditableService, i10.SearchGroupModalService], null, null)], null, null); }
var GroupInputComponentNgFactory = i1.ɵccf("app-group-input", i8.GroupInputComponent, View_GroupInputComponent_Host_0, { id: "id", reference: "reference" }, {}, []);
export { GroupInputComponentNgFactory as GroupInputComponentNgFactory };
