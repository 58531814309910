import { SemanticTextDocument, SemanticTextLink, SemanticTextLiteral, SemanticTextParagraph } from 'app/entities/semantic';
import { assertNever, isDefined, requireDefined } from 'yti-common-ui/utils/object';
import { Parser as MarkdownParser } from 'commonmark';
import { allMatching, contains } from 'yti-common-ui/utils/array';
import { escapeHtml } from 'yti-common-ui/utils/string';
export function areNodesEqual(lhs, rhs) {
    if (lhs.type !== rhs.type || lhs.text !== rhs.text) {
        return false;
    }
    if (lhs.type === 'link' && rhs.type === 'link' && lhs.destination !== rhs.destination) {
        return false;
    }
    if (lhs.children.length !== rhs.children.length) {
        return false;
    }
    for (var i = 0; i < lhs.children.length; i++) {
        if (!areNodesEqual(lhs.children[i], rhs.children[i])) {
            return false;
        }
    }
    return true;
}
export function stripSemanticMarkup(text, format, namespaceRoot) {
    var result = '';
    var visit = function (n) {
        if (n.type === 'paragraph') {
            result += '\n\n';
        }
        result += n.text;
        for (var _i = 0, _a = n.children; _i < _a.length; _i++) {
            var child = _a[_i];
            visit(child);
        }
    };
    visit(resolveSerializer(format).deserialize(text, namespaceRoot).document);
    return result.trim();
}
export function removeMatchingLinks(value, format, predicate, namespaceRoot) {
    var serializer = resolveSerializer(format);
    return serializer.serialize(serializer.deserialize(value, namespaceRoot).document.removeMatchingLinks(predicate));
}
function ensureType(node) {
    var type = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        type[_i - 1] = arguments[_i];
    }
    if (!contains(type, node.type)) {
        throw new Error('Illegal child type');
    }
    return node;
}
var MarkdownSerializer = /** @class */ (function () {
    function MarkdownSerializer() {
    }
    MarkdownSerializer.prototype.serialize = function (document) {
        console.error("Deprecated MarkdownSerializer used!");
        function visit(node) {
            switch (node.type) {
                case 'document':
                    return node.children.map(function (c) { return visit(c); }).join('').trim();
                case 'paragraph':
                    return '\n\n' + node.children.map(function (c) { return visit(c); }).join('');
                case 'link':
                    return "[" + node.text + "](" + node.destination + ")";
                case 'text':
                    return node.text;
                default:
                    return assertNever(node);
            }
        }
        return visit(document);
    };
    MarkdownSerializer.prototype.deserialize = function (serialized, namespaceRoot) {
        console.error("Deprecated MarkdownSerializer used!");
        function getChildren(node) {
            var children = [];
            if (node.isContainer && node.firstChild) {
                for (var sibling = node.firstChild; isDefined(sibling); sibling = sibling.next) {
                    children.push(sibling);
                }
            }
            return children;
        }
        function getSingleTextChild(parent) {
            var children = getChildren(parent);
            if (children.length !== 1) {
                throw new Error('Exactly one child required, was: ' + children.length);
            }
            var child = children[0];
            if (child.type !== 'text') {
                throw new Error('Child must be literal, was: ' + child.type);
            }
            return child;
        }
        function visit(node) {
            switch (node.type) {
                case 'document':
                    return new SemanticTextDocument(getChildren(node)
                        .map(function (n) { return visit(n); })
                        .filter(function (n) { return n != null; })
                        .map(function (n) { return ensureType(requireDefined(n), 'paragraph'); }));
                case 'paragraph':
                    return new SemanticTextParagraph(getChildren(node)
                        .map(function (n) { return visit(n); })
                        .filter(function (n) { return n != null; })
                        .map(function (n) { return ensureType(requireDefined(n), 'text', 'link'); }));
                case 'link':
                    return new SemanticTextLink(getSingleTextChild(node).literal, node.destination);
                case 'text':
                    return new SemanticTextLiteral(node.literal);
                default:
                    console.log('Node type NOT SUPPORTED: ' + node.type);
                    return null;
            }
        }
        var result = visit(new MarkdownParser().parse(serialized));
        if (result == null || result.type !== 'document') {
            throw new Error('Cannot parse markdown: ' + serialized);
        }
        return { document: result, valid: true };
    };
    return MarkdownSerializer;
}());
var XmlSerializer = /** @class */ (function () {
    function XmlSerializer() {
    }
    XmlSerializer.prototype.serialize = function (document) {
        function visit(node, lastChild) {
            switch (node.type) {
                case 'document':
                    return node.children.map(function (c, i, arr) { return visit(c, arr.length - 1 === i); }).join('');
                case 'paragraph':
                    return node.children.map(function (c, i, arr) { return visit(c, arr.length - 1 === i); }).join('') + (lastChild ? '' : '<br />');
                case 'link':
                    return "<a href='" + node.destination + "' data-type='" + node.category + "'>" + escapeHtml(node.text) + "</a>";
                case 'text':
                    return escapeHtml(node.text);
                default:
                    return assertNever(node);
            }
        }
        return visit(document, true);
    };
    XmlSerializer.prototype.deserialize = function (serialized, namespaceRoot) {
        function getFallbackDocument(error) {
            console.error('Invalid searialized Semantic Text' + (error ? ' [' + error + ']' : '') + ': ' + serialized);
            return { document: new SemanticTextDocument([new SemanticTextParagraph([new SemanticTextLiteral(serialized)])]), valid: false };
        }
        function getChildren(node) {
            return Array.prototype.slice.call(node.childNodes);
        }
        function getSingleTextChild(parent) {
            if (parent.childNodes.length !== 1) {
                throw new Error('Exactly one child required, was: ' + parent.childNodes.length);
            }
            var child = parent.firstChild;
            if (child.nodeType !== Node.TEXT_NODE) {
                throw new Error('Child must be TEXT_NODE, was: ' + child.nodeType);
            }
            return child;
        }
        var documentString = "<document>" + serialized + "</document>";
        var document = new DOMParser().parseFromString(documentString, 'application/xml');
        if (document.getElementsByTagName('parsererror').length > 0) {
            return getFallbackDocument('Parse Error');
        }
        var documentNode = document.getElementsByTagName('document')[0];
        function isSupportedNode(node) {
            return node.nodeType === Node.TEXT_NODE || node.nodeName === 'a' || node.nodeName === 'br';
        }
        var children = getChildren(documentNode);
        if (!allMatching(children, function (child) { return isSupportedNode(child); })) {
            return getFallbackDocument('Unsupported Child');
        }
        // normalize if empty
        if (children.length === 0) {
            children.push(document.createTextNode(''));
        }
        var groupedNodes = [];
        groupedNodes.push([]);
        var currentIndex = 0;
        for (var _i = 0, children_1 = children; _i < children_1.length; _i++) {
            var node = children_1[_i];
            switch (node.nodeType) {
                case Node.TEXT_NODE:
                    groupedNodes[currentIndex].push(node);
                    break;
                case Node.ELEMENT_NODE:
                    switch (node.nodeName) {
                        case 'a':
                            groupedNodes[currentIndex].push(node);
                            break;
                        case 'br':
                            groupedNodes.push([]);
                            currentIndex++;
                            break;
                        default:
                            return getFallbackDocument('Unsupported Element: ' + node.nodeName);
                    }
                    break;
                default:
                    return getFallbackDocument('Unsupported Node: ' + node.nodeType);
            }
        }
        try {
            return {
                valid: true, document: new SemanticTextDocument(groupedNodes.map(function (group) {
                    return new SemanticTextParagraph(group.map(function (node) {
                        switch (node.nodeType) {
                            case Node.TEXT_NODE:
                                return new SemanticTextLiteral(node.nodeValue || '');
                            case Node.ELEMENT_NODE:
                                switch (node.nodeName) {
                                    case 'a':
                                        var text = getSingleTextChild(node).nodeValue || '';
                                        var destination = node.attributes.getNamedItem('href').value;
                                        var type_ = node.attributes.getNamedItem('data-type');
                                        if (type_ && type_.value) {
                                            var type = type_.value;
                                            switch (type) {
                                                case 'internal':
                                                    return new SemanticTextLink(text, destination, 'internal');
                                                case 'external':
                                                    return new SemanticTextLink(text, destination, 'external');
                                                default:
                                                    throw new Error('Unsupported Link: ' + type);
                                            }
                                        }
                                        else {
                                            return new SemanticTextLink(text, destination, (destination && destination.startsWith(namespaceRoot)) ? 'internal' : 'external');
                                        }
                                    default:
                                        throw new Error('Unsupported Element: ' + node.nodeName);
                                }
                            default:
                                throw new Error('Unsupported Node: ' + node.nodeType);
                        }
                    }));
                }))
            };
        }
        catch (error) {
            return getFallbackDocument(error);
        }
    };
    return XmlSerializer;
}());
export function resolveSerializer(type) {
    switch (type) {
        case 'markdown':
            console.error("Deprecated MarkdownSerializer resolved!");
            return new MarkdownSerializer();
        case 'xml':
            return new XmlSerializer();
        default:
            return assertNever(type);
    }
}
