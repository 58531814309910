import { forkJoin, of, zip } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { contains, flatten, normalizeAsArray } from 'yti-common-ui/utils/array';
import { MetaModelService } from './meta-model.service';
import { Node } from 'app/entities/node';
import * as moment from 'moment';
import { apiUrl } from 'app/config';
import { HttpClient } from '@angular/common/http';
var TermedService = /** @class */ (function () {
    function TermedService(http, metaModelService) {
        this.http = http;
        this.metaModelService = metaModelService;
    }
    TermedService.prototype.getVocabulary = function (graphId) {
        return zip(this.metaModelService.getMeta(graphId), this.getVocabularyNode(graphId))
            .pipe(map(function (_a) {
            var meta = _a[0], vocabulary = _a[1];
            return Node.create(vocabulary, meta, true);
        }));
    };
    TermedService.prototype.getVocabularyList = function () {
        var _this = this;
        return this.getVocabularyNodes()
            .pipe(flatMap(function (vocabularies) {
            // necessary optimization since forkJoin doesn't ever complete with empty observables array
            if (vocabularies.length === 0) {
                return of([]);
            }
            return forkJoin(vocabularies.map(function (vocabulary) {
                return _this.metaModelService.getMeta(vocabulary.type.graph.id)
                    .pipe(map(function (metaModel) { return Node.create(vocabulary, metaModel, true); }));
            }));
        }));
    };
    TermedService.prototype.getConcept = function (graphId, conceptId) {
        return zip(this.metaModelService.getMeta(graphId), this.getConceptDetailsNode(graphId, conceptId))
            .pipe(map(function (_a) {
            var meta = _a[0], concept = _a[1];
            return Node.create(concept, meta, true);
        }));
    };
    TermedService.prototype.findConcept = function (graphId, conceptId) {
        return zip(this.metaModelService.getMeta(graphId), this.findConceptDetailsNode(graphId, conceptId))
            .pipe(map(function (_a) {
            var meta = _a[0], concept = _a[1];
            return concept ? Node.create(concept, meta, true) : null;
        }));
    };
    TermedService.prototype.getCollection = function (graphId, collectionId) {
        return zip(this.metaModelService.getMeta(graphId), this.getCollectionDetailsNode(graphId, collectionId))
            .pipe(map(function (_a) {
            var meta = _a[0], collection = _a[1];
            return Node.create(collection, meta, true);
        }));
    };
    TermedService.prototype.findCollection = function (graphId, collectionId) {
        return zip(this.metaModelService.getMeta(graphId), this.findCollectionDetailsNode(graphId, collectionId))
            .pipe(map(function (_a) {
            var meta = _a[0], collection = _a[1];
            return collection ? Node.create(collection, meta, true) : null;
        }));
    };
    TermedService.prototype.getCollectionList = function (graphId) {
        return zip(this.metaModelService.getMeta(graphId), this.getCollectionListNodes(graphId))
            .pipe(map(function (_a) {
            var meta = _a[0], concepts = _a[1];
            return concepts.map(function (collection) { return Node.create(collection, meta, true); });
        }));
    };
    TermedService.prototype.getOrganizationList = function () {
        var _this = this;
        return this.getOrganizationListNodes()
            .pipe(flatMap(function (organizations) {
            return forkJoin(organizations.map(function (organization) {
                return _this.metaModelService.getMeta(organization.type.graph.id)
                    .pipe(map(function (metaModel) { return Node.create(organization, metaModel, true); }));
            }));
        }));
    };
    TermedService.prototype.getGroupList = function () {
        var _this = this;
        return this.getGroupListNodes()
            .pipe(flatMap(function (groups) {
            return forkJoin(groups.map(function (group) {
                return _this.metaModelService.getMeta(group.type.graph.id)
                    .pipe(map(function (metaModel) { return Node.create(group, metaModel, true); }));
            }));
        }));
    };
    TermedService.prototype.createVocabulary = function (templateGraphId, vocabulary, prefix) {
        var params = {
            'templateGraphId': templateGraphId,
            'prefix': prefix
        };
        return this.http.post(apiUrl + "/vocabulary", vocabulary.toInternalNode(), { params: params });
    };
    TermedService.prototype.removeVocabulary = function (graphId) {
        var params = {
            'graphId': graphId
        };
        return this.http.delete(apiUrl + "/vocabulary", { params: params });
    };
    TermedService.prototype.saveNodes = function (nodes) {
        var inlineNodes = flatten(flatten(nodes.map(function (node) { return node.getAllReferences(); }))
            .filter(function (ref) { return ref.inline; })
            .map(function (ref) { return ref.values.map(function (n) { return n.toInternalNode(); }); }));
        return this.updateAndDeleteInternalNodes(inlineNodes.concat(nodes.map(function (node) { return node.toInternalNode(); })), []);
    };
    TermedService.prototype.updateNode = function (updatedNode, previousNode) {
        updatedNode.lastModifiedDate = moment();
        function inlineNodes(node) {
            return flatten(node.getAllReferences()
                .filter(function (ref) { return ref.inline; })
                .map(function (ref) { return ref.values.map(function (n) { return n.toInternalNode(); }); }));
        }
        function inlineNodeIds(node) {
            return flatten(node.getAllReferences()
                .filter(function (ref) { return ref.inline; })
                .map(function (ref) { return ref.values.map(function (n) { return n.identifier; }); }));
        }
        var updatedInlineNodes = inlineNodes(updatedNode);
        var previousInlineNodeIds = previousNode ? inlineNodeIds(previousNode) : [];
        function nodeIdsAreEqual(left, right) {
            return left.id === right.id && left.type.id
                && left.type.id === right.type.id
                && left.type.graph.id === right.type.graph.id;
        }
        var deletedInlineNodeIds = previousInlineNodeIds.filter(function (id) { return !contains(updatedInlineNodes, id, nodeIdsAreEqual); });
        return this.updateAndDeleteInternalNodes(updatedInlineNodes.concat([updatedNode.toInternalNode()]), deletedInlineNodeIds);
    };
    TermedService.prototype.removeNode = function (node) {
        var inlineNodeIds = flatten(node.getAllReferences()
            .filter(function (ref) { return ref.inline; })
            .map(function (ref) { return ref.values.filter(function (term) { return term.persistent; }).map(function (term) { return term.identifier; }); }));
        return this.removeNodeIdentifiers(inlineNodeIds.concat([node.identifier]), true, true);
    };
    TermedService.prototype.isNamespaceInUse = function (prefix) {
        var params = {
            'prefix': prefix
        };
        return this.http.get(apiUrl + "/namespaceInUse", { params: params });
    };
    TermedService.prototype.getNamespaceRoot = function () {
        return this.http.get(apiUrl + "/namespaceRoot", {
            observe: 'body',
            responseType: 'text'
        });
    };
    TermedService.prototype.getGraphNamespace = function (graphId) {
        return this.http.get(apiUrl + "/graphs/" + graphId, { observe: 'body' })
            .pipe(map(function (graph) {
            return {
                prefix: graph.code,
                namespace: graph.uri
            };
        }));
    };
    TermedService.prototype.getUserRequests = function () {
        return this.http.get(apiUrl + "/requests");
    };
    TermedService.prototype.sendRequest = function (organizationId) {
        var params = {
            'organizationId': organizationId
        };
        return this.http.post(apiUrl + "/request", null, {
            params: params,
            observe: 'response'
        });
    };
    TermedService.prototype.getFakeableUsers = function () {
        return this.http.get(apiUrl + "/fakeableUsers");
    };
    TermedService.prototype.getGroupManagementUrl = function () {
        return this.http.get(apiUrl + "/groupManagementUrl", {
            observe: 'body',
            responseType: 'text'
        });
    };
    TermedService.prototype.removeNodeIdentifiers = function (nodeIds, sync, disconnect) {
        var params = {
            'sync': sync.toString(),
            'disconnect': disconnect.toString()
        };
        return this.http.request('DELETE', apiUrl + "/remove", {
            params: params,
            body: nodeIds
        });
    };
    TermedService.prototype.updateAndDeleteInternalNodes = function (toUpdate, toDelete) {
        var body = {
            'delete': toDelete,
            'save': toUpdate
        };
        return this.http.post(apiUrl + "/modify", body, { observe: 'response' });
    };
    TermedService.prototype.getVocabularyNode = function (graphId) {
        var params = {
            'graphId': graphId
        };
        return this.http.get(apiUrl + "/vocabulary", { params: params })
            .pipe(map(requireSingle));
    };
    TermedService.prototype.getVocabularyNodes = function () {
        return this.http.get(apiUrl + "/vocabularies")
            .pipe(map(normalizeAsArray));
    };
    TermedService.prototype.getConceptDetailsNode = function (graphId, conceptId) {
        var params = {
            'graphId': graphId,
            'conceptId': conceptId
        };
        return this.http.get(apiUrl + "/concept", { params: params })
            .pipe(map(requireSingle));
    };
    TermedService.prototype.findConceptDetailsNode = function (graphId, conceptId) {
        return this.getConceptDetailsNode(graphId, conceptId)
            .pipe(catchError(notFoundAsDefault(null)));
    };
    TermedService.prototype.getCollectionListNodes = function (graphId) {
        var params = {
            'graphId': graphId
        };
        return this.http.get(apiUrl + "/collections", { params: params })
            .pipe(map(normalizeAsArray), catchError(notFoundAsDefault([])));
    };
    TermedService.prototype.getCollectionDetailsNode = function (graphId, collectionId) {
        var params = {
            'graphId': graphId,
            'collectionId': collectionId
        };
        return this.http.get(apiUrl + "/collection", { params: params })
            .pipe(map(requireSingle));
    };
    TermedService.prototype.findCollectionDetailsNode = function (graphId, collectionId) {
        return this.getCollectionDetailsNode(graphId, collectionId)
            .pipe(catchError(notFoundAsDefault(null)));
    };
    TermedService.prototype.getGroupListNodes = function () {
        return this.http.get(apiUrl + "/groups")
            .pipe(map(normalizeAsArray), catchError(notFoundAsDefault([])));
    };
    TermedService.prototype.getOrganizationListNodes = function () {
        return this.http.get(apiUrl + "/organizations")
            .pipe(map(normalizeAsArray), catchError(notFoundAsDefault([])));
    };
    return TermedService;
}());
export { TermedService };
function requireSingle(json) {
    if (Array.isArray(json)) {
        if (json.length === 1) {
            return json[0];
        }
        else {
            throw new Error('Must be single length array, was: ' + json.length);
        }
    }
    else {
        return json;
    }
}
function notFoundAsDefault(defaultValue) {
    return responseCodeAsDefault(404, defaultValue);
}
function responseCodeAsDefault(responseCode, defaultValue) {
    return function (error, observable) {
        if (error.status === responseCode) {
            return of(defaultValue);
        }
        else {
            return observable;
        }
    };
}
