import { ConceptNode, VocabularyNode } from 'app/entities/node';
import { SearchConceptModalService } from './search-concept-modal.component';
import { SelectConceptReferenceModalService } from './select-concept-reference-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { anyMatching } from 'yti-common-ui/utils/array';
import { FormNode } from 'app/services/form-state';
import { EditableService } from 'app/services/editable.service';
import { requireDefined } from 'yti-common-ui/utils/object';
import { conceptIdPrefix } from 'app/utils/id-prefix';
import { ConfigurationService } from '../../services/configuration.service';
var ConceptFormComponent = /** @class */ (function () {
    function ConceptFormComponent(editableService, searchConceptModalService, selectConceptReferenceModalService, configurationService) {
        var _this = this;
        this.editableService = editableService;
        this.searchConceptModalService = searchConceptModalService;
        this.selectConceptReferenceModalService = selectConceptReferenceModalService;
        this.configurationService = configurationService;
        this.multiColumn = false;
        this.idPrefix = conceptIdPrefix;
        this.conceptSelector = function (name) { return _this.selectConcept(name); };
    }
    Object.defineProperty(ConceptFormComponent.prototype, "showEmpty", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptFormComponent.prototype, "fields", {
        get: function () {
            var _this = this;
            var hasContent = function (field) {
                return _this.filterLanguage ? field.hasContentForLanguage(_this.filterLanguage)
                    : !field.valueEmpty;
            };
            return this.form.fields.filter(function (f) { return _this.showEmpty || hasContent(f.value); });
        },
        enumerable: true,
        configurable: true
    });
    ConceptFormComponent.prototype.onConceptRemove = function (concept) {
        var lastReferenceRemoved = !anyMatching(this.form.referencedConcepts, function (ref) { return ref.id === concept.id; });
        if (lastReferenceRemoved) {
            this.form.removeSemanticReferencesTo(concept, this.configurationService.namespaceRoot);
        }
    };
    ConceptFormComponent.prototype.selectConcept = function (name) {
        var _this = this;
        var restricts = [{ graphId: this.concept.graphId, conceptId: this.concept.id, reason: 'self reference error' }];
        return this.searchConceptModalService.openForVocabulary(requireDefined(this.vocabulary), name, restricts)
            .then(function (concept) {
            if (!_this.form.hasConceptReference(concept.id)) {
                return _this.selectConceptReferenceModalService.open(_this.form)
                    .then(function (reference) {
                    reference.addReference(concept);
                    return concept;
                }, ignoreModalClose);
            }
            else {
                return concept;
            }
        }, ignoreModalClose);
    };
    ConceptFormComponent.prototype.isMultiColumn = function (field) {
        if (!this.multiColumn) {
            return false;
        }
        if (field.fieldType === 'property') {
            return field.multiColumn;
        }
        else if (field.fieldType === 'reference') {
            return !field.term;
        }
        return false;
    };
    return ConceptFormComponent;
}());
export { ConceptFormComponent };
