import { AfterViewInit, ElementRef, Renderer } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { debounceTime, map, skip, take, tap } from 'rxjs/operators';
import { TermedService } from 'app/services/termed.service';
import { LanguageService } from 'app/services/language.service';
import { contains } from 'yti-common-ui/utils/array';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
var SearchGroupModalComponent = /** @class */ (function () {
    function SearchGroupModalComponent(modal, termedService, languageService, renderer) {
        var _this = this;
        this.modal = modal;
        this.renderer = renderer;
        this.search$ = new BehaviorSubject('');
        this.loading = false;
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        this.searchResults$ = combineLatest(termedService.getGroupList(), concat(initialSearch, debouncedSearch))
            .pipe(tap(function () { return _this.loading = false; }), map(function (_a) {
            var informationDomains = _a[0], search = _a[1];
            return informationDomains.filter(function (domain) {
                var label = languageService.translate(domain.label, true);
                var searchMatches = !search || label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                var isNotRestricted = !contains(_this.restricts, domain.id);
                return searchMatches && isNotRestricted;
            })
                .sort(comparingLocalizable(languageService, function (node) { return node.label; }));
        }));
    }
    Object.defineProperty(SearchGroupModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchGroupModalComponent.prototype.select = function (informationDomain) {
        this.modal.close(informationDomain);
    };
    SearchGroupModalComponent.prototype.ngAfterViewInit = function () {
        this.renderer.invokeElementMethod(this.searchInput.nativeElement, 'focus');
    };
    SearchGroupModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    return SearchGroupModalComponent;
}());
export { SearchGroupModalComponent };
var SearchGroupModalService = /** @class */ (function () {
    function SearchGroupModalService(modalService) {
        this.modalService = modalService;
    }
    SearchGroupModalService.prototype.open = function (restrictDomainIds) {
        var modalRef = this.modalService.open(SearchGroupModalComponent, { size: 'sm' });
        var instance = modalRef.componentInstance;
        instance.restricts = restrictDomainIds;
        return modalRef.result;
    };
    return SearchGroupModalService;
}());
export { SearchGroupModalService };
