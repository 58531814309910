
    <div *ngIf="canEdit()">
          
      <button type="button"
              [id]="idPrefix + '_editable_cancel_editing_button'"
              class="btn btn-link float-right cancel" 
              (click)="cancelEditing()"
              [disabled]="operationPending"
              [hidden]="!editing">
        <span translate>Cancel</span>
      </button>
      
      <button type="button"
              [id]="idPrefix + '_editable_save_edited_button'"
              class="btn btn-action float-right save" 
              (click)="saveEdited()" 
              [hidden]="!editing" 
              [disabled]="!canSave() || operationPending">
        <span translate>Save</span>
      </button>
      
      <button type="button"
              [id]="idPrefix + '_editable_start_editing_button'"
              class="btn btn-action float-right edit" 
              (click)="startEditing()"
              [disabled]="operationPending"
              [hidden]="editing">
        <span translate>Edit</span>
      </button>

      <button type="button"
              [id]="idPrefix + '_editable_remove_button'"
              class="btn btn-link float-right remove"
              (click)="remove()"
              [disabled]="operationPending"
              [hidden]="editing || !canRemove">
        <i class="fa fa-trash"></i>
        <span translate>Remove</span>
      </button>

      <app-ajax-loading-indicator-small class="float-right" *ngIf="operationPending"></app-ajax-loading-indicator-small>
    </div>
  