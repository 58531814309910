
    <div class="content-box">
      <div *ngIf="vocabulary">
        <div class="header">
          <div class="row">
            <div class="nameButtonRow col-12">
              <h2 id="vocabulary_main_label"><span class="mr-4">{{vocabulary!.label | translateValue}}</span></h2>
              <div *ngIf="checkSubscription" class="subscription">
                <span class="subscription-icon icon-bell"
                      ngbTooltip="{{ 'You will receive an email notification when this resource changes.' | translate }}"></span>
              </div>
              <app-filter-language class="nameButtonRowButton" [(ngModel)]="filterLanguage"
                                   [languages]="filterLanguages"></app-filter-language>
              <div *ngIf="showMenu" ngbDropdown class="d-inline-block float-right ml-2" placement="bottom-right">
                <button class="btn btn-action" id="vocabularyDropdown" ngbDropdownToggle translate>Choose action</button>
                <div ngbDropdownMenu aria-labelledby="vocabularyDropdown">
                  <button id="add_subscription_button"
                          *ngIf="canAddSubscription"
                          type="button"
                          class="dropdown-item"
                          (click)="addSubscription()"
                          translate>Add email subscription</button>
                  <button id="delete_subscription_button"
                          *ngIf="canRemoveSubscription"
                          type="button"
                          class="dropdown-item"
                          (click)="removeSubscription()"
                          translate>Remove email subscription</button>
                  <button id="vocabulary_import_button"
                          *ngIf="canImport"
                          type="button"
                          class="dropdown-item"
                          (click)="selectFile()"
                          translate>Import concepts</button>
                  <a id="vocabulary_excel_export_button"
                     *ngIf="canExport"
                     class="dropdown-item"
                     href='/terminology-api/api/v1/export/{{graphId}}?format=xlsx'
                     translate>Export terminology Excel</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="informationRow col-12">
              <app-status class="status" [status]="vocabulary!.status"></app-status>
              <span class="inRowTitle"><span translate>Information domain</span>:</span>
              <span class="information-domains">
                <span class="badge badge-light" *ngFor="let domain of vocabulary!.groups">
                  {{domain.label | translateValue:true}}
                </span>
              </span>
              <span class="inRowTitle"><span translate>Organization</span>:</span>
              <ul class="organizations dot-separated-list">
                <li class="organization" *ngFor="let contributor of vocabulary!.contributors">
                  {{contributor.label | translateValue:true}}
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="descriptionRow col-12">
              <app-expandable-text [text]="vocabulary!.description | translateValue:true" [rows]="3"></app-expandable-text>
            </div>
          </div>
        </div>
        <ngb-tabset #tabs (tabChange)="onTabChange($event)">
          <ngb-tab id="conceptsTab" [title]="'Concepts' | translate">
            <ng-template ngbTabContent>
              <app-concepts #conceptsComponent></app-concepts>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="terminologyTab" [title]="'Terminology details' | translate">
            <ng-template ngbTabContent>
              <app-vocabulary #terminologyComponent></app-vocabulary>
              <div class="bottom-hack-border"></div>
              <div class="bottom-hack-padding"></div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  