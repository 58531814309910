
    <dl *ngIf="show">
      <dt>
        <label [for]="id">
          {{label | translateValue:true}}
          <span *ngIf="hasDescription()" 
                class="fa fa-info-circle info" 
                ngbTooltip="{{description | translateValue:true}}"></span>
        </label>
        <app-required-symbol *ngIf="editing && property.required"></app-required-symbol>
      </dt>
      <dd [ngSwitch]="property.type">
        
        <app-localized-input *ngSwitchCase="'localizable'"
                             [id]="id"
                             [property]="property"
                             [conceptSelector]="conceptSelector"
                             [relatedConcepts]="relatedConcepts"
                             [filterLanguage]="filterLanguage"></app-localized-input>
        
        <app-literal-input *ngSwitchCase="'literal'" 
                           [id]="id" 
                           [property]="property"></app-literal-input>
        
        <app-literal-list-input *ngSwitchCase="'literal-list'" 
                                [id]="id" 
                                [property]="property"></app-literal-list-input>
        
        <span *ngSwitchDefault>ERROR - unknown property type</span>
      </dd>
    </dl>
  