
    <div class="row">
      <div class="col-lg-12">

        <div class="selectable-actions">
          <button class="btn btn-action btn-add-new" id="concept_add_concept_button" (click)="addConcept()" *ngIf="canAddConcept()">
            <span translate>Add new concept</span>
          </button>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="selectable-concepts">
          <ul [ngClass]="{'has-button': canAddConcept()}"
              infinite-scroll
              [infiniteScrollDistance]="2.5"
              [scrollWindow]="false"
              (scrolled)="onScrollDown()">
            <li *ngFor="let concept of model.topConcepts; trackBy: conceptIdentity">
              <app-concept-hierarchy-node [concept]="concept" [parentIds]="[]"></app-concept-hierarchy-node>
            </li>
          </ul>
        </div>
      </div>
    </div>
  