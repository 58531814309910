import { LanguageService } from 'app/services/language.service';
import { UserService } from 'yti-common-ui/services/user.service';
import { LoginModalService } from 'yti-common-ui/components/login-modal.component';
import { TermedService } from '../../services/termed.service';
import { ConfigurationService } from '../../services/configuration.service';
import { Router } from '@angular/router';
var NavigationBarComponent = /** @class */ (function () {
    function NavigationBarComponent(languageService, userService, loginModal, termedService, configurationService, router) {
        var _this = this;
        this.languageService = languageService;
        this.userService = userService;
        this.loginModal = loginModal;
        this.termedService = termedService;
        this.configurationService = configurationService;
        this.router = router;
        this.availableLanguages = [
            { code: 'fi', name: 'Suomeksi (FI)' },
            { code: 'sv', name: 'På svenska (SV)' },
            { code: 'en', name: 'In English (EN)' }
        ];
        this.fakeableUsers = [];
        this.termedService.getFakeableUsers().subscribe(function (users) {
            _this.fakeableUsers = users;
        });
        this.environmentIdentifier = this.configurationService.getEnvironmentIdentifier('postfix');
        this.groupManagementUrl = this.configurationService.groupManagementUrl;
        this.codeListUrl = this.configurationService.codeListUrl;
        this.dataModelUrl = this.configurationService.dataModelUrl;
        this.commentsUrl = this.configurationService.commentsUrl;
    }
    Object.defineProperty(NavigationBarComponent.prototype, "noMenuItemsAvailable", {
        get: function () {
            return !this.userService.isLoggedIn();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "language", {
        get: function () {
            return this.languageService.language;
        },
        set: function (language) {
            this.languageService.language = language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NavigationBarComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    NavigationBarComponent.prototype.fakeUser = function (userEmail) {
        this.userService.updateLoggedInUser(userEmail);
    };
    NavigationBarComponent.prototype.logIn = function () {
        this.loginModal.open();
    };
    NavigationBarComponent.prototype.logOut = function () {
        this.userService.logout();
    };
    NavigationBarComponent.prototype.isLoggedIn = function () {
        return this.userService.isLoggedIn();
    };
    NavigationBarComponent.prototype.showGroupManagementUrl = function () {
        return this.user.superuser || this.user.isAdminInAnyOrganization();
    };
    NavigationBarComponent.prototype.goToFrontPage = function ($event) {
        var _this = this;
        $event.preventDefault();
        if (this.router.url === '/') {
            this.router.navigate(['re'], { skipLocationChange: true }).then(function () { return _this.router.navigate(['/'], { skipLocationChange: false }); });
        }
        else {
            this.router.navigate(['/'], { skipLocationChange: false });
        }
    };
    return NavigationBarComponent;
}());
export { NavigationBarComponent };
