import { VocabularyNode } from 'app/entities/node';
import { FormNode } from 'app/services/form-state';
import { EditableService } from 'app/services/editable.service';
import { vocabularyIdPrefix } from 'app/utils/id-prefix';
var VocabularyFormComponent = /** @class */ (function () {
    function VocabularyFormComponent(editableService) {
        this.editableService = editableService;
        this.idPrefix = vocabularyIdPrefix;
    }
    Object.defineProperty(VocabularyFormComponent.prototype, "showEmpty", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyFormComponent.prototype, "fields", {
        get: function () {
            var _this = this;
            var hasContent = function (field) {
                return _this.filterLanguage ? field.hasContentForLanguage(_this.filterLanguage)
                    : !field.valueEmpty;
            };
            return this.form.fields.filter(function (f) { return _this.showEmpty || hasContent(f.value); });
        },
        enumerable: true,
        configurable: true
    });
    return VocabularyFormComponent;
}());
export { VocabularyFormComponent };
