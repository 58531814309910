import { EditableService } from 'app/services/editable.service';
import { FormReferenceLiteral } from 'app/services/form-state';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { SearchGroupModalService } from './search-group-modal.component';
var GroupInputComponent = /** @class */ (function () {
    function GroupInputComponent(editableService, searchInformationDomainModal) {
        this.editableService = editableService;
        this.searchInformationDomainModal = searchInformationDomainModal;
    }
    Object.defineProperty(GroupInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    GroupInputComponent.prototype.removeReference = function (informationDomain) {
        this.reference.removeReference(informationDomain);
    };
    GroupInputComponent.prototype.addReference = function () {
        var _this = this;
        var restricts = this.reference.value.map(function (_a) {
            var id = _a.id;
            return id;
        });
        this.searchInformationDomainModal.open(restricts)
            .then(function (result) { return _this.reference.addReference(result); }, ignoreModalClose);
    };
    GroupInputComponent.prototype.canReorder = function () {
        return this.editing && this.reference.value.length > 1;
    };
    return GroupInputComponent;
}());
export { GroupInputComponent };
