/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-link-reference-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./concept-link-reference-input.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap/popover/popover";
import * as i4 from "@ng-bootstrap/ng-bootstrap/popover/popover-config";
import * as i5 from "yti-common-ui/pipes/translate-value.pipe";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../directives/drag-sortable.directive";
import * as i9 from "@ngx-translate/core";
import * as i10 from "../../services/editable.service";
import * as i11 from "../../services/termed.service";
import * as i12 from "../../services/meta-model.service";
import * as i13 from "./search-concept-modal.component";
import * as i14 from "../common/meta-information.component.ngfactory";
import * as i15 from "../common/meta-information.component";
var styles_ConceptLinkReferenceInputComponent = [i0.styles];
var RenderType_ConceptLinkReferenceInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConceptLinkReferenceInputComponent, data: {} });
export { RenderType_ConceptLinkReferenceInputComponent as RenderType_ConceptLinkReferenceInputComponent };
function View_ConceptLinkReferenceInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-link-reference-popover", [], null, null, null, View_ConceptLinkReferencePopoverComponent_0, RenderType_ConceptLinkReferencePopoverComponent)), i1.ɵdid(1, 49152, null, 0, i2.ConceptLinkReferencePopoverComponent, [], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ConceptLinkReferenceInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 6, "a", [], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 737280, null, 0, i3.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i4.NgbPopoverConfig, i1.NgZone], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], triggers: [2, "triggers"] }, null), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), i1.ɵdid(4, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i7.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 4), (_l()(), i1.ɵted(6, null, [" ", " "])), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), (_l()(), i1.ɵand(0, [["popContent", 2]], null, 0, null, View_ConceptLinkReferenceInputComponent_3))], function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 8); var currVal_4 = i1.ɵunv(_v, 2, 1, i1.ɵnov(_v, 3).transform(_v.context.$implicit.label)); var currVal_5 = "mouseenter:mouseleave"; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _ck(_v, 5, 0, "/concepts", _v.context.$implicit.targetGraph, "concept", _v.context.$implicit.targetId); _ck(_v, 4, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.idIdentifier + "_") + _co.id) + "_concept_link_reference_link"); var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_v.context.$implicit.label)); _ck(_v, 6, 0, currVal_7); }); }
function View_ConceptLinkReferenceInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptLinkReferenceInputComponent_2)), i1.ɵdid(2, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ConceptLinkReferenceInputComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-link-reference-popover", [], null, null, null, View_ConceptLinkReferencePopoverComponent_0, RenderType_ConceptLinkReferencePopoverComponent)), i1.ɵdid(1, 49152, null, 0, i2.ConceptLinkReferencePopoverComponent, [], { link: [0, "link"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ConceptLinkReferenceInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "removable-text"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i8.DragSortableItemDirective, [i8.DragSortableDirective, i1.NgZone, i1.ElementRef], { item: [0, "item"], index: [1, "index"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeReference(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 16777216, null, null, 4, "span", [], null, [[null, "mousedown"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 737280, [["p", 4]], 0, i3.NgbPopover, [i1.ElementRef, i1.Renderer2, i1.Injector, i1.ComponentFactoryResolver, i1.ViewContainerRef, i4.NgbPopoverConfig, i1.NgZone], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], triggers: [2, "triggers"] }, null), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), (_l()(), i1.ɵted(7, null, [" ", " "])), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), (_l()(), i1.ɵand(0, [["editingPopContent", 2]], null, 0, null, View_ConceptLinkReferenceInputComponent_6))], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 9); var currVal_4 = i1.ɵunv(_v, 5, 1, i1.ɵnov(_v, 6).transform(_v.context.$implicit.label)); var currVal_5 = "mouseenter:mouseleave"; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((((_v.context.$implicit.idIdentifier + "_") + _v.context.index) + "_") + _co.id) + "_concept_link_remove_reference"); _ck(_v, 3, 0, currVal_2); var currVal_6 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_v.context.$implicit.label)); _ck(_v, 7, 0, currVal_6); }); }
function View_ConceptLinkReferenceInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i8.DragSortableDirective, [i1.NgZone], { sortable: [0, "sortable"], dragDisabled: [1, "dragDisabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptLinkReferenceInputComponent_5)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference; var currVal_1 = !_co.canReorder(); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.reference.value; _ck(_v, 3, 0, currVal_2); }, null); }
function View_ConceptLinkReferenceInputComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-action"], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addReference() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "_concept_link_reference_add_reference_button"); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Add concept")); _ck(_v, 1, 0, currVal_1); }); }
export function View_ConceptLinkReferenceInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptLinkReferenceInputComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptLinkReferenceInputComponent_4)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptLinkReferenceInputComponent_7)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editing; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ConceptLinkReferenceInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-link-reference-input", [], null, null, null, View_ConceptLinkReferenceInputComponent_0, RenderType_ConceptLinkReferenceInputComponent)), i1.ɵdid(1, 114688, null, 0, i2.ConceptLinkReferenceInputComponent, [i10.EditableService, i11.TermedService, i12.MetaModelService, i13.SearchConceptModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConceptLinkReferenceInputComponentNgFactory = i1.ɵccf("app-concept-link-reference-input", i2.ConceptLinkReferenceInputComponent, View_ConceptLinkReferenceInputComponent_Host_0, { id: "id", vocabulary: "vocabulary", reference: "reference" }, {}, []);
export { ConceptLinkReferenceInputComponentNgFactory as ConceptLinkReferenceInputComponentNgFactory };
var styles_ConceptLinkReferencePopoverComponent = [];
var RenderType_ConceptLinkReferencePopoverComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ConceptLinkReferencePopoverComponent, data: {} });
export { RenderType_ConceptLinkReferencePopoverComponent as RenderType_ConceptLinkReferencePopoverComponent };
export function View_ConceptLinkReferencePopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵpid(131072, i5.TranslateValuePipe, [i5.LOCALIZER]), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-meta-information", [], [[8, "hidden", 0]], null, null, i14.View_MetaInformationComponent_0, i14.RenderType_MetaInformationComponent)), i1.ɵdid(8, 49152, null, 0, i15.MetaInformationComponent, [], { node: [0, "node"], showModified: [1, "showModified"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.link; var currVal_4 = false; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.link.vocabularyMetaLabel, true)); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.link.vocabularyLabel)); _ck(_v, 5, 0, currVal_1); var currVal_2 = !_co.link.persistent; _ck(_v, 7, 0, currVal_2); }); }
export function View_ConceptLinkReferencePopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-link-reference-popover", [], null, null, null, View_ConceptLinkReferencePopoverComponent_0, RenderType_ConceptLinkReferencePopoverComponent)), i1.ɵdid(1, 49152, null, 0, i2.ConceptLinkReferencePopoverComponent, [], null, null)], null, null); }
var ConceptLinkReferencePopoverComponentNgFactory = i1.ɵccf("app-concept-link-reference-popover", i2.ConceptLinkReferencePopoverComponent, View_ConceptLinkReferencePopoverComponent_Host_0, { link: "link" }, {}, []);
export { ConceptLinkReferencePopoverComponentNgFactory as ConceptLinkReferencePopoverComponentNgFactory };
