var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ResolveEnd, Route, Router, Routes, UrlSegment, UrlSegmentGroup } from '@angular/router';
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateLoader } from '@ngx-translate/core';
import { of } from 'rxjs';
import { ConceptComponent } from 'app/components/concept/concept.component';
import { NoSelectionComponent } from 'app/components/common/no-selection.component';
import { CollectionComponent } from 'app/components/collection/collection.component';
import { ConfirmCancelEditGuard } from 'app/components/common/edit.guard';
import { NewVocabularyComponent } from 'app/components/vocabulary/new-vocabulary.component';
import { FrontpageComponent } from 'app/components/frontpage.component';
import { RefreshComponent } from 'app/components/refresh.component';
import { UserDetailsComponent } from 'app/components/user-details/user-details.component';
import { InformationAboutServiceComponent } from 'app/components/information/information-about-service.component';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { apiUrl } from './config';
import { ConfigurationService } from './services/configuration.service';
import { VocabularyMainComponent } from './components/vocabulary/vocabulary-main.component';
function removeEmptyValues(obj) {
    var result = {};
    for (var _i = 0, _a = Object.entries(obj); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        if (!!value) {
            result[key] = value;
        }
    }
    return result;
}
var localizations = {
    fi: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/fi.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/fi.po")))),
    en: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/en.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/en.po")))),
    sv: __assign({}, removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!../../po/sv.po"))), removeEmptyValues(JSON.parse(require("raw-loader!po-loader?format=mf!yti-common-ui/po/sv.po"))))
};
export function resolveAuthenticatedUserEndpoint() {
    return apiUrl + "/authenticated-user";
}
export function createTranslateLoader() {
    return { getTranslation: function (lang) { return of(localizations[lang]); } };
}
export function createMissingTranslationHandler() {
    return {
        handle: function (params) {
            if (params.translateService.currentLang === 'en') {
                return params.key;
            }
            else {
                return '[MISSING]: ' + params.key;
            }
        }
    };
}
export function refreshRouteMatcher(segments, group, route) {
    if (segments.length >= 1 && segments[0].path === 're') {
        return {
            consumed: segments
        };
    }
    return {
        consumed: []
    };
}
var appRoutes = [
    { path: '', component: FrontpageComponent },
    { path: 'newVocabulary', component: NewVocabularyComponent },
    {
        path: 'concepts/:graphId', component: VocabularyMainComponent, children: [
            { path: '', component: NoSelectionComponent, canDeactivate: [ConfirmCancelEditGuard] },
            { path: 'concept/:conceptId', component: ConceptComponent, canDeactivate: [ConfirmCancelEditGuard] },
            { path: 'collection/:collectionId', component: CollectionComponent, canDeactivate: [ConfirmCancelEditGuard] }
        ]
    },
    { path: 'userDetails', component: UserDetailsComponent },
    { path: 'information', component: InformationAboutServiceComponent },
    // NOTE: If createRefreshRouteMatcher(['re']) starts to work after angular upgrade, then switch to that.
    { matcher: refreshRouteMatcher, component: RefreshComponent }
];
export function initApp(configurationService) {
    return function () { return configurationService.fetchConfiguration(); };
}
var AppModule = /** @class */ (function () {
    function AppModule(router, modalService) {
        router.events.subscribe(function (event) {
            if (event instanceof ResolveEnd) {
                modalService.closeAllModals();
            }
        });
    }
    return AppModule;
}());
export { AppModule };
