/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./property.component.ngfactory";
import * as i2 from "./property.component";
import * as i3 from "../../services/editable.service";
import * as i4 from "@angular/common";
import * as i5 from "./term.component";
var styles_TermComponent = [];
var RenderType_TermComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TermComponent, data: {} });
export { RenderType_TermComponent as RenderType_TermComponent };
function View_TermComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "col-md-12"]], [[2, "col-xl-6", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-property", [], null, null, null, i1.View_PropertyComponent_0, i1.RenderType_PropertyComponent)), i0.ɵdid(2, 49152, null, 0, i2.PropertyComponent, [i3.EditableService], { id: [0, "id"], property: [1, "property"], filterLanguage: [2, "filterLanguage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.id + "_") + _v.context.$implicit.name); var currVal_2 = _v.context.$implicit.value; var currVal_3 = _co.filterLanguage; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiColumn; _ck(_v, 0, 0, currVal_0); }); }
export function View_TermComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TermComponent_1)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.properties; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TermComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-term", [], null, null, null, View_TermComponent_0, RenderType_TermComponent)), i0.ɵdid(1, 49152, null, 0, i5.TermComponent, [i3.EditableService], null, null)], null, null); }
var TermComponentNgFactory = i0.ɵccf("app-term", i5.TermComponent, View_TermComponent_Host_0, { multiColumn: "multiColumn", term: "term", filterLanguage: "filterLanguage", id: "id" }, {}, []);
export { TermComponentNgFactory as TermComponentNgFactory };
