
    <div class="modal-header">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="cancel_search_domain_link" (click)="cancel()"></i></a>
        <span translate>Choose information domain</span>
      </h4>
    </div>
    <div class="modal-body full-height">

      <div class="row mb-2">
        <div class="col-12">

          <div class="input-group input-group-lg input-group-search">
            <input #searchInput type="text" id="search_domain_link" class="form-control"
                   placeholder="{{'Search information domain' | translate}}"
                   [(ngModel)]="search"/>
          </div>

        </div>
      </div>

      <div class="row full-height">
        <div class="col-12">
          <div class="content-box">
            <div class="search-results">
              <div class="search-result"
                   *ngFor="let domain of searchResults$ | async; let last = last"
                   [id]="domain.idIdentifier + '_domain_select'"
                   (click)="select(domain)">
                <div class="content" [class.last]="last">
                  <span class="title" [innerHTML]="domain.label | translateValue:true"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">

      <button type="button"
              id="cancel_search_domain_button"
              class="btn btn-link cancel"
              (click)="cancel()" translate>Cancel</button>
    </div>
  