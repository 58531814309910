/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../node_modules/yti-common-ui/components/filter-dropdown.component.ngfactory";
import * as i2 from "yti-common-ui/components/filter-dropdown.component";
import * as i3 from "./vocabulary-filter-dropdown.component";
import * as i4 from "../../services/language.service";
import * as i5 from "@ngx-translate/core";
var styles_VocabularyFilterDropdownComponent = [];
var RenderType_VocabularyFilterDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VocabularyFilterDropdownComponent, data: {} });
export { RenderType_VocabularyFilterDropdownComponent as RenderType_VocabularyFilterDropdownComponent };
export function View_VocabularyFilterDropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-filter-dropdown", [["class", "float-left"], ["id", "vocabulary_filter_dropdown"]], null, null, null, i1.View_FilterDropdownComponent_0, i1.RenderType_FilterDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i2.FilterDropdownComponent, [], { id: [0, "id"], options: [1, "options"], filterSubject: [2, "filterSubject"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "vocabulary_filter_dropdown"; var currVal_1 = _co.vocabularyOptions; var currVal_2 = _co.filterSubject; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_VocabularyFilterDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-vocabulary-filter-dropdown", [], null, null, null, View_VocabularyFilterDropdownComponent_0, RenderType_VocabularyFilterDropdownComponent)), i0.ɵdid(1, 114688, null, 0, i3.VocabularyFilterDropdownComponent, [i4.LanguageService, i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VocabularyFilterDropdownComponentNgFactory = i0.ɵccf("app-vocabulary-filter-dropdown", i3.VocabularyFilterDropdownComponent, View_VocabularyFilterDropdownComponent_Host_0, { filterSubject: "filterSubject", vocabularies: "vocabularies" }, {}, []);
export { VocabularyFilterDropdownComponentNgFactory as VocabularyFilterDropdownComponentNgFactory };
