
    <div class="row">
      <div class="col-12">

        <div class="panel-left" appFloat>
          <div>
            <ngb-tabset [justify]="'justified'" [activeId]="initialTabId">
              <ngb-tab id="concepts_alphabetic_tab">
                <ng-template ngbTabTitle>
                  <p>{{'Alphabetic' | translate}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <app-concept-list></app-concept-list>
                </ng-template>
              </ngb-tab>
              <ngb-tab id="concepts_hierarchical_tab">
                <ng-template ngbTabTitle>
                  <p>{{'Hierarchical' | translate}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <app-concept-hierarchy></app-concept-hierarchy>
                </ng-template>
              </ngb-tab>
              <ngb-tab id="concepts_collection_tab">
                <ng-template ngbTabTitle>
                  <p>{{'Collection' | translate}}</p>
                </ng-template>
                <ng-template ngbTabContent>
                  <app-collection-list></app-collection-list>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>
        </div>

        <div class="panel-right">

          <div class="selection-container" [style.width]="selectionWidth" [hidden]="!showSelection">
            <router-outlet (activate)="onOutletActivate($event)"></router-outlet>
          </div>

          <div class="visualization-container" [style.width]="visualizationWidth" [hidden]="!showVisualization">
            <div appFloat [setWidth]="false">
              <app-divider *ngIf="showDivider"></app-divider>
              <app-concept-network #network [class.without-divider]="!showDivider"></app-concept-network>
            </div>
          </div>

        </div>

      </div>
    </div>
  