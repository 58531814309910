/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-reference-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "yti-common-ui/pipes/translate-value.pipe";
import * as i5 from "../../directives/drag-sortable.directive";
import * as i6 from "@ngx-translate/core";
import * as i7 from "./concept-reference-input.component";
import * as i8 from "../../services/editable.service";
import * as i9 from "./search-concept-modal.component";
var styles_ConceptReferenceInputComponent = [i0.styles];
var RenderType_ConceptReferenceInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConceptReferenceInputComponent, data: {} });
export { RenderType_ConceptReferenceInputComponent as RenderType_ConceptReferenceInputComponent };
function View_ConceptReferenceInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 4), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i4.TranslateValuePipe, [i4.LOCALIZER])], function (_ck, _v) { var currVal_3 = _ck(_v, 3, 0, "/concepts", _v.context.$implicit.graphId, "concept", _v.context.$implicit.id); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit.idIdentifier + "_") + _co.id) + "_concept_reference_concept_link"); var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_v.context.$implicit.label)); _ck(_v, 4, 0, currVal_4); }); }
function View_ConceptReferenceInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptReferenceInputComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ConceptReferenceInputComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "removable-text"]], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i5.DragSortableItemDirective, [i5.DragSortableDirective, i1.NgZone, i1.ElementRef], { item: [0, "item"], index: [1, "index"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeReference(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), i1.ɵpid(131072, i4.TranslateValuePipe, [i4.LOCALIZER])], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.index; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (((_v.context.$implicit.idIdentifier + "_") + _co.id) + "_concept_reference_remove_reference_link"); _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.label)); _ck(_v, 5, 0, currVal_3); }); }
function View_ConceptReferenceInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(1, 540672, null, 0, i5.DragSortableDirective, [i1.NgZone], { sortable: [0, "sortable"], dragDisabled: [1, "dragDisabled"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptReferenceInputComponent_4)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.reference; var currVal_1 = !_co.canReorder(); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.reference.value; _ck(_v, 3, 0, currVal_2); }, null); }
function View_ConceptReferenceInputComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-sm btn-action"], ["translate", ""], ["type", "button"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addReference() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 8536064, null, 0, i6.TranslateDirective, [i6.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add concept"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "_concept_reference_add_reference_button"); _ck(_v, 0, 0, currVal_0); }); }
export function View_ConceptReferenceInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptReferenceInputComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptReferenceInputComponent_3)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptReferenceInputComponent_5)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.editing; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.editing; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.editing; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ConceptReferenceInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-reference-input", [], null, null, null, View_ConceptReferenceInputComponent_0, RenderType_ConceptReferenceInputComponent)), i1.ɵdid(1, 49152, null, 0, i7.ConceptReferenceInputComponent, [i8.EditableService, i9.SearchConceptModalService], null, null)], null, null); }
var ConceptReferenceInputComponentNgFactory = i1.ɵccf("app-concept-reference-input", i7.ConceptReferenceInputComponent, View_ConceptReferenceInputComponent_Host_0, { id: "id", vocabulary: "vocabulary", self: "self", reference: "reference" }, { conceptRemove: "conceptRemove" }, []);
export { ConceptReferenceInputComponentNgFactory as ConceptReferenceInputComponentNgFactory };
