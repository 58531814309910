/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/status-dropdown.component.ngfactory";
import * as i3 from "yti-common-ui/components/status-dropdown.component";
import * as i4 from "@angular/forms";
import * as i5 from "./status-input.component";
var styles_StatusInputComponent = [i0.styles];
var RenderType_StatusInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusInputComponent, data: {} });
export { RenderType_StatusInputComponent as RenderType_StatusInputComponent };
export function View_StatusInputComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "app-status-dropdown", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], null, null, i2.View_StatusDropdownComponent_0, i2.RenderType_StatusDropdownComponent)), i1.ɵdid(1, 49152, null, 0, i3.StatusDropdownComponent, [], { id: [0, "id"] }, null), i1.ɵprd(1024, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.StatusDropdownComponent]), i1.ɵdid(3, 540672, null, 0, i4.FormControlDirective, [[8, null], [8, null], [6, i4.NG_VALUE_ACCESSOR], [2, i4.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i4.NgControl, null, [i4.FormControlDirective]), i1.ɵdid(5, 16384, null, 0, i4.NgControlStatus, [[4, i4.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = (_co.id + "_input_dropdown"); _ck(_v, 1, 0, currVal_7); var currVal_8 = _co.select; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_StatusInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-input", [], null, null, null, View_StatusInputComponent_0, RenderType_StatusInputComponent)), i1.ɵdid(1, 49152, null, 0, i5.StatusInputComponent, [[8, null]], null, null)], null, null); }
var StatusInputComponentNgFactory = i1.ɵccf("app-status-input", i5.StatusInputComponent, View_StatusInputComponent_Host_0, { id: "id" }, {}, []);
export { StatusInputComponentNgFactory as StatusInputComponentNgFactory };
