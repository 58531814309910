
    <div class="row">
      
      <ng-container *ngFor="let field of fields" [ngSwitch]="field.value.fieldType">

        <app-property *ngSwitchCase="'property'"
                      class="col-md-6"
                      [property]="field.value"
                      [id]="idPrefix + '_' + field.name"
                      [filterLanguage]="filterLanguage"></app-property>

        <app-reference *ngSwitchCase="'reference'"
                       class="col-md-6"
                       [reference]="field.value"
                       [id]="idPrefix + '_' + field.name"
                       [unsaved]="!vocabulary.persistent"
                       [filterLanguage]="filterLanguage"
                       [vocabulary]="vocabulary"></app-reference>

      </ng-container>

      <div class="col-md-6 form-group">
        <label translate>Vocabulary type</label>
        <p class="form-control-static">{{vocabulary.meta.label | translateValue:true}}</p>
      </div>
      
      <div class="col-md-6 form-group" *ngIf="namespace">
        <label translate>Namespace</label>
        <p class="form-control-static">{{namespace}}</p>
      </div>
      
    </div>
  