import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VocabularyNode } from 'app/entities/node';
import { importApiUrl } from '../../config';
import { HttpClient, HttpEventType, HttpRequest, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Phase, Progress } from '../progress.component';
var ImportVocabularyXMLComponent = /** @class */ (function () {
    function ImportVocabularyXMLComponent(modal, http) {
        this.modal = modal;
        this.http = http;
        this.requestFullStatus = false;
        this.monitoringError = false;
        this.showMessages = false;
        this.progressPhases = [
            new Phase('File upload', this.upload.bind(this)),
            new Phase('Processing', this.process.bind(this), this.pollProgress.bind(this))
        ];
    }
    ImportVocabularyXMLComponent.prototype.upload = function (phase, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // TODO: Move upload code to a service!
            var data = new FormData();
            data.append('file', _this.importFile);
            _this.http.request(new HttpRequest('POST', importApiUrl + '/ntrf/' + _this.vocabulary.graphId, data, {
                reportProgress: true,
                responseType: 'json'
            })).subscribe(function (event) {
                if (event.type == HttpEventType.UploadProgress && event.total) {
                    var percentDone = Math.round(100 * event.loaded / event.total);
                    phase.progress.current = percentDone;
                }
                else if (event instanceof HttpResponseBase) {
                    _this.httpStatus = event.status;
                    _this.httpStatusText = event.statusText;
                    if (event instanceof HttpResponse) {
                        if (event.status === 200 && event.body) {
                            var anybody = event.body;
                            if (anybody.jobtoken) {
                                _this.jobToken = anybody.jobtoken;
                            }
                        }
                    }
                }
            }, function (err) {
                var error = (err && err.error && err.error.error) || (err && err.error);
                if (_this.httpStatus) {
                    _this.uploadError = _this.httpStatus + ' ' + _this.httpStatusText + (error ? ': ' + error : '');
                }
                else if (error) {
                    _this.uploadError = error;
                }
                else {
                    _this.uploadError = '?';
                }
                reject(_this.uploadError);
            }, function () {
                resolve(_this.jobToken);
            });
        });
    };
    ImportVocabularyXMLComponent.prototype.process = function (phase, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.processingResolve = resolve;
            _this.processingReject = reject;
        });
    };
    ImportVocabularyXMLComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    ImportVocabularyXMLComponent.prototype.confirm = function () {
        this.modal.close();
    };
    ImportVocabularyXMLComponent.prototype.pollProgress = function (phase, state) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(importApiUrl + '/status/' + _this.jobToken + (_this.requestFullStatus ? '?full=true' : ''), {
                responseType: 'json'
            }).subscribe(function (event) {
                var anybody = event;
                if (anybody.status) {
                    switch (anybody.status) {
                        case 'QUEUED':
                        case 'PREPROCESSING':
                            resolve(new Progress());
                            break;
                        case 'PROCESSING':
                            var progress = new Progress();
                            if (anybody.processingProgress >= 0 && anybody.processingTotal > 0 && anybody.processingProgress < anybody.processingTotal) {
                                // Reserve (quite arbitrary) 15% of total work for each of preprocessing and postprocessing.
                                progress.total = 1.3 * anybody.processingTotal;
                                progress.current = 0.15 * anybody.processingTotal + anybody.processingProgress;
                            }
                            resolve(progress);
                            break;
                        case 'POSTPROCESSING':
                            resolve(new Progress());
                            break;
                        case 'SUCCESS':
                        case 'SUCCESS_WITH_ERRORS':
                            resolve(new Progress());
                            if (_this.requestFullStatus) {
                                // TODO: Remove hack when results are reported correctly.
                                if (anybody.resultsCreated == null) {
                                    if (anybody.processingProgress && anybody.processingProgress === anybody.processingTotal) {
                                        anybody.resultsCreated = anybody.processingProgress - (anybody.resultsError ? anybody.resultsError : 0);
                                    }
                                }
                                _this.processingResolve(anybody);
                            }
                            else {
                                _this.requestFullStatus = true;
                            }
                            break;
                        case 'FAILURE':
                            resolve(new Progress());
                            _this.processingReject(anybody);
                            break;
                        case 'NOT_FOUND':
                        default:
                            reject();
                    }
                }
                else {
                    reject();
                }
            }, function (err) {
                reject(err);
            }, function () {
            });
        });
    };
    ImportVocabularyXMLComponent.prototype.onResult = function (result) {
        if (result.success) {
            this.finalResults = result.result;
        }
        else {
            this.finalError = result.result;
        }
    };
    ImportVocabularyXMLComponent.prototype.onPollingError = function (error) {
        this.monitoringError = error;
    };
    Object.defineProperty(ImportVocabularyXMLComponent.prototype, "canClose", {
        get: function () {
            return this.finalResults || this.finalError || this.monitoringError;
        },
        enumerable: true,
        configurable: true
    });
    ImportVocabularyXMLComponent.prototype.generateMessageJSON = function () {
        var _this = this;
        return function () {
            if (_this.finalResults && _this.finalResults.statusMessage) {
                return JSON.stringify(_this.finalResults.statusMessage, undefined, 2);
            }
            return '';
        };
    };
    ImportVocabularyXMLComponent.prototype.messageClass = function (level) {
        if (level === 'WARNING') {
            return 'message-warning';
        }
        else if (level === 'ERROR') {
            return 'message-error';
        }
        return 'message';
    };
    return ImportVocabularyXMLComponent;
}());
export { ImportVocabularyXMLComponent };
