/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./error-messages.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "yti-common-ui/pipes/keys.pipe";
import * as i5 from "./error-messages.component";
var styles_ErrorMessagesComponent = [i0.styles];
var RenderType_ErrorMessagesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ErrorMessagesComponent, data: {} });
export { RenderType_ErrorMessagesComponent as RenderType_ErrorMessagesComponent };
function View_ErrorMessagesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.index + "_") + _co.id); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit)); _ck(_v, 1, 0, currVal_1); }); }
function View_ErrorMessagesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["class", "errors"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ErrorMessagesComponent_2)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.control.errors)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ErrorMessagesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.KeysPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ErrorMessagesComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isVisible(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ErrorMessagesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-messages", [], null, null, null, View_ErrorMessagesComponent_0, RenderType_ErrorMessagesComponent)), i1.ɵdid(1, 49152, null, 0, i5.ErrorMessagesComponent, [], null, null)], null, null); }
var ErrorMessagesComponentNgFactory = i1.ɵccf("app-error-messages", i5.ErrorMessagesComponent, View_ErrorMessagesComponent_Host_0, { control: "control", id: "id" }, {}, []);
export { ErrorMessagesComponentNgFactory as ErrorMessagesComponentNgFactory };
