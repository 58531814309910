/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../form/property.component.ngfactory";
import * as i2 from "../form/property.component";
import * as i3 from "../../services/editable.service";
import * as i4 from "../form/reference.component.ngfactory";
import * as i5 from "../form/reference.component";
import * as i6 from "@angular/common";
import * as i7 from "@ngx-translate/core";
import * as i8 from "yti-common-ui/pipes/translate-value.pipe";
import * as i9 from "./vocabulary-form.component";
var styles_VocabularyFormComponent = [];
var RenderType_VocabularyFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VocabularyFormComponent, data: {} });
export { RenderType_VocabularyFormComponent as RenderType_VocabularyFormComponent };
function View_VocabularyFormComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-property", [["class", "col-md-6"]], null, null, null, i1.View_PropertyComponent_0, i1.RenderType_PropertyComponent)), i0.ɵdid(1, 49152, null, 0, i2.PropertyComponent, [i3.EditableService], { id: [0, "id"], property: [1, "property"], filterLanguage: [2, "filterLanguage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.idPrefix + "_") + _v.parent.context.$implicit.name); var currVal_1 = _v.parent.context.$implicit.value; var currVal_2 = _co.filterLanguage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_VocabularyFormComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-reference", [["class", "col-md-6"]], null, null, null, i4.View_ReferenceComponent_0, i4.RenderType_ReferenceComponent)), i0.ɵdid(1, 49152, null, 0, i5.ReferenceComponent, [i3.EditableService], { id: [0, "id"], reference: [1, "reference"], unsaved: [2, "unsaved"], vocabulary: [3, "vocabulary"], filterLanguage: [4, "filterLanguage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.idPrefix + "_") + _v.parent.context.$implicit.name); var currVal_1 = _v.parent.context.$implicit.value; var currVal_2 = !_co.vocabulary.persistent; var currVal_3 = _co.vocabulary; var currVal_4 = _co.filterLanguage; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_VocabularyFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VocabularyFormComponent_2)), i0.ɵdid(3, 278528, null, 0, i6.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VocabularyFormComponent_3)), i0.ɵdid(5, 278528, null, 0, i6.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value.fieldType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "property"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "reference"; _ck(_v, 5, 0, currVal_2); }, null); }
function View_VocabularyFormComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "col-md-6 form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "label", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(2, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Namespace"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.namespace; _ck(_v, 5, 0, currVal_1); }); }
export function View_VocabularyFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VocabularyFormComponent_1)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "col-md-6 form-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "label", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(5, 8536064, null, 0, i7.TranslateDirective, [i7.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Vocabulary type"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "p", [["class", "form-control-static"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵpid(131072, i8.TranslateValuePipe, [i8.LOCALIZER]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VocabularyFormComponent_4)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields; _ck(_v, 2, 0, currVal_0); var currVal_1 = ""; _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.namespace; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 8, 0, i0.ɵnov(_v, 9).transform(_co.vocabulary.meta.label, true)); _ck(_v, 8, 0, currVal_2); }); }
export function View_VocabularyFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-vocabulary-form", [], null, null, null, View_VocabularyFormComponent_0, RenderType_VocabularyFormComponent)), i0.ɵdid(1, 49152, null, 0, i9.VocabularyFormComponent, [i3.EditableService], null, null)], null, null); }
var VocabularyFormComponentNgFactory = i0.ɵccf("app-vocabulary-form", i9.VocabularyFormComponent, View_VocabularyFormComponent_Host_0, { vocabulary: "vocabulary", form: "form", filterLanguage: "filterLanguage", namespace: "namespace" }, {}, []);
export { VocabularyFormComponentNgFactory as VocabularyFormComponentNgFactory };
