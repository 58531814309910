import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthorizationManager } from 'app/services/authorization-manager.sevice';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { debounceTime, publishReplay, refCount, skip, take } from 'rxjs/operators';
import { TermedService } from 'app/services/termed.service';
import { anyMatching } from 'yti-common-ui/utils/array';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { LanguageService } from 'app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
import { getInformationDomainSvgIcon, getVocabularyTypeMaterialIcon } from 'yti-common-ui/utils/icons';
import { selectableStatuses } from 'yti-common-ui/entities/status';
import { ConfigurationService } from '../../services/configuration.service';
import { ElasticSearchService } from '../../services/elasticsearch.service';
import { TerminologySearchRequest } from '../../entities/search';
import { UserService } from 'yti-common-ui/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
var VocabulariesComponent = /** @class */ (function () {
    function VocabulariesComponent(authorizationManager, configurationService, languageService, translateService, termedService, elasticSearchService, userService, router, route) {
        this.authorizationManager = authorizationManager;
        this.configurationService = configurationService;
        this.languageService = languageService;
        this.translateService = translateService;
        this.termedService = termedService;
        this.elasticSearchService = elasticSearchService;
        this.userService = userService;
        this.router = router;
        this.route = route;
        // Active filtering criteria
        this.searchText$ = new BehaviorSubject('');
        this.searchConcepts$ = new BehaviorSubject(true);
        this.selectedInformationDomain$ = new BehaviorSubject(null);
        this.selectedOrganization$ = new BehaviorSubject(null);
        this.selectedStatus$ = new BehaviorSubject(null);
        this.statuses = selectableStatuses;
        // Search text filtered (and deep search augmented) terminology list
        // TODO: Using results here is not infinite scrolling ready, should use expanding list on service side with "fetch more".
        this.terminologyResults$ = new BehaviorSubject({
            totalHitCount: 0, resultStart: 0, terminologies: [], deepHits: {}
        });
        this.terminologySearchError = false;
        // Relevant filtering criteria
        this.applicableInformationDomains = [];
        this.applicableOrganizations$ = new BehaviorSubject([]);
        this.applicableStatuses$ = new BehaviorSubject([]);
        // Filtered vocabularies
        this.filteredTerminologies = [];
        // Other generic state
        this.loading = true;
        this.subscriptionsToClean = [];
        // Template wrappers
        this.getTerminologyTypeIconDef = getVocabularyTypeMaterialIcon;
        this.getInformationDomainIconSrc = getInformationDomainSvgIcon;
        // Comparators
        this.terminologyComparator = comparingLocalizable(this.languageService, function (terminology) { return terminology.label; });
        this.informationDomainComparator = comparingLocalizable(this.languageService, function (obj) { return obj.domain.label; });
        // Testing and debugging
        this.noRightMargin = false;
    }
    Object.defineProperty(VocabulariesComponent.prototype, "searchText", {
        get: function () {
            return this.searchText$.getValue();
        },
        set: function (value) {
            this.searchText$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabulariesComponent.prototype, "searchConcepts", {
        get: function () {
            return this.searchConcepts$.getValue();
        },
        set: function (value) {
            this.searchConcepts$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    VocabulariesComponent.prototype.ngOnInit = function () {
        var _this = this;
        var initialSearchText$ = this.searchText$.pipe(take(1));
        var debouncedSearchText$ = this.searchText$.pipe(skip(1), debounceTime(500));
        var searchText$ = concat(initialSearchText$, debouncedSearchText$);
        var searchConditions$ = combineLatest(searchText$, this.languageService.language$, this.searchConcepts$, this.userService.user$);
        this.subscriptionsToClean.push(searchConditions$.subscribe(function (_a) {
            var text = _a[0], language = _a[1], searchConcepts = _a[2], _user = _a[3];
            _this.elasticSearchService.terminologySearch(new TerminologySearchRequest(text, searchConcepts, language, 1000, 0))
                .subscribe(function (resp) {
                _this.terminologySearchError = false;
                if (resp.totalHitCount != resp.terminologies.length) {
                    console.error("Terminology search did not return all results. Got " + resp.terminologies.length + " (start: " + resp.resultStart + ", total hits: " + resp.totalHitCount + ")");
                }
                _this.terminologyResults$.next(resp);
            }, function (err) {
                if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500) {
                    _this.terminologySearchError = true;
                    _this.terminologyResults$.next({
                        totalHitCount: 0, resultStart: 0, terminologies: [], deepHits: {}
                    });
                }
                else {
                    console.error('Model search failed: ' + JSON.stringify(err));
                }
            });
        }));
        this.informationDomains$ = this.termedService.getGroupList();
        this.organizations$ = this.termedService.getOrganizationList().pipe(publishReplay(1), refCount());
        this.makeSubscriptions();
        this.noRightMargin = !!this.route.snapshot.queryParams['nrr'];
    };
    VocabulariesComponent.prototype.ngOnDestroy = function () {
        this.subscriptionsToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    VocabulariesComponent.prototype.isInformationDomainSelected = function (domain) {
        return this.selectedInformationDomain$.getValue() === domain;
    };
    VocabulariesComponent.prototype.toggleInformationDomainSelection = function (domain) {
        this.selectedInformationDomain$.next(this.isInformationDomainSelected(domain) ? null : domain);
    };
    VocabulariesComponent.prototype.canAddVocabulary = function () {
        return this.authorizationManager.canAddVocabulary();
    };
    VocabulariesComponent.prototype.addVocabulary = function () {
        this.router.navigate(['/newVocabulary']);
    };
    VocabulariesComponent.prototype.allLanguagesLabel = function (label) {
        var exp = /<\/?b>/g;
        var keys = Object.keys(label);
        if (keys.length) {
            return keys.map(function (key) { return label[key].replace(exp, '') + ' (' + key + ')'; }).join('\n');
        }
        return undefined;
    };
    VocabulariesComponent.prototype.makeSubscriptions = function () {
        var _this = this;
        var restrict = this.configurationService.restrictFilterOptions;
        this.subscriptionsToClean.push(this.languageService.language$.subscribe(function (_language) {
            // NOTE: Organization filter contains internal sorting. Statuses are in natural order.
            _this.filteredTerminologies.sort(_this.terminologyComparator);
            _this.applicableInformationDomains.sort(_this.informationDomainComparator);
        }));
        if (!restrict) {
            // If drop down filter options are not restricted then do not regenerate sets on selection changes.
            this.subscriptionsToClean.push(combineLatest(this.terminologyResults$, this.organizations$)
                .subscribe(function (_a) {
                var terminologyResults = _a[0], organizations = _a[1];
                var terminologies = terminologyResults.terminologies;
                var counts = terminologies.reduce(function (counts, tlogy) {
                    tlogy.contributors.forEach(function (org) { return counts.orgCounts[org.id] = counts.orgCounts[org.id] ? counts.orgCounts[org.id] + 1 : 1; });
                    counts.statCounts[tlogy.status] = counts.statCounts[tlogy.status] ? counts.statCounts[tlogy.status] + 1 : 1;
                    return counts;
                }, { orgCounts: {}, statCounts: {} });
                _this.applicableOrganizations$.next(organizations.filter(function (org) { return counts.orgCounts[org.id]; }));
                _this.applicableStatuses$.next(_this.statuses.filter(function (status) { return counts.statCounts[status]; }));
            }));
        }
        this.subscriptionsToClean.push(combineLatest(combineLatest(this.terminologyResults$, this.informationDomains$, this.organizations$), combineLatest(this.selectedInformationDomain$, this.selectedOrganization$, this.selectedStatus$))
            .subscribe(function (_a) {
            var _b = _a[0], terminologyResults = _b[0], domains = _b[1], organizations = _b[2], _c = _a[1], selectedDomain = _c[0], selectedOrganization = _c[1], selectedStatus = _c[2];
            var terminologies = terminologyResults.terminologies;
            var accumulated = terminologies.reduce(function (state, tlogy) {
                var domainMatch = informationDomainMatches(selectedDomain, tlogy);
                var orgMatch = organizationMatches(selectedOrganization, tlogy);
                var statusMatch = statusMatches(selectedStatus, tlogy);
                if (domainMatch && orgMatch && statusMatch) {
                    state.passedTerminologies.push(tlogy);
                }
                if (orgMatch && statusMatch) {
                    tlogy.informationDomains.forEach(function (domain) { return state.domainCounts[domain.id] = state.domainCounts[domain.id] ? state.domainCounts[domain.id] + 1 : 1; });
                }
                if (restrict && domainMatch) {
                    if (statusMatch) {
                        tlogy.contributors.forEach(function (org) { return state.orgCounts[org.id] = state.orgCounts[org.id] ? state.orgCounts[org.id] + 1 : 1; });
                    }
                    if (orgMatch) {
                        state.statusCounts[tlogy.status] = state.statusCounts[tlogy.status] ? state.statusCounts[tlogy.status] + 1 : 1;
                    }
                }
                return state;
            }, { passedTerminologies: [], domainCounts: {}, orgCounts: {}, statusCounts: {} });
            _this.filteredTerminologies = accumulated.passedTerminologies.sort(_this.terminologyComparator);
            _this.filteredDeepHits = {};
            if (terminologyResults.deepHits && Object.keys(terminologyResults.deepHits).length > 0) {
                var dhs_1 = terminologyResults.deepHits;
                _this.filteredTerminologies.forEach(function (tlogy) {
                    var hit = dhs_1[tlogy.id];
                    if (hit) {
                        _this.filteredDeepHits[tlogy.id] = hit;
                    }
                });
            }
            var currentlySelectedDomainId = selectedDomain ? selectedDomain.id : undefined;
            _this.applicableInformationDomains = domains
                .map(function (domain) { return ({ domain: domain, count: accumulated.domainCounts[domain.id] || 0 }); })
                .filter(function (obj) { return obj.count > 0 || obj.domain.id === currentlySelectedDomainId; })
                .sort(_this.informationDomainComparator);
            if (restrict) {
                var currentlySelectedOrgId_1 = selectedOrganization ? selectedOrganization.id : undefined;
                _this.applicableOrganizations$.next(organizations.filter(function (org) { return accumulated.orgCounts[org.id] || org.id === currentlySelectedOrgId_1; }));
                _this.applicableStatuses$.next(_this.statuses.filter(function (status) { return accumulated.statusCounts[status] || status === selectedStatus; }));
            }
            if (_this.loading) {
                _this.loading = false;
            }
        }));
    };
    return VocabulariesComponent;
}());
export { VocabulariesComponent };
function informationDomainMatches(informationDomain, terminology) {
    return !informationDomain || anyMatching(terminology.informationDomains, function (domain) { return domain.id === informationDomain.id; });
}
function organizationMatches(organization, terminology) {
    return !organization || anyMatching(terminology.contributors, function (contributor) { return contributor.id === organization.id; });
}
function statusMatches(status, terminology) {
    return !status || terminology.status === status;
}
