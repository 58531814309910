
    <div class="modal-header">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="select_link_target_cancel_link" (click)="cancel()"></i></a>
        <span translate>Enter link target</span>
      </h4>
    </div>
    <div class="modal-body">
      <form>
        <div class="row mb-2">
          <div class="col-md-12">
            <span translate>Enter the full HTTP or HTTPS address (URL) for the link:</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <input #urlInput
                   id="select_link_target_url_input"
                   type="url"
                   class="form-control"
                   placeholder="https://www.example.com"
                   [ngClass]="{'is-invalid': url.invalid && (url.dirty || url.touched)}"
                   [formControl]="url"
                   (keyup.enter)="confirm()"/>
            <app-error-messages *ngIf="url.dirty || url.touched" [control]="url"></app-error-messages>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" id="select_link_target_confirm_button" class="btn btn-action confirm"
              [disabled]="url.invalid || !url.dirty" (click)="confirm()" translate>Add link</button>
      <button type="button" id="select_link_target_cancel_button" class="btn btn-link cancel" (click)="cancel()" translate>Cancel</button>
    </div>
  