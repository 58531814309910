
    <ul *ngIf="!editing">
      <li *ngFor="let conceptLink of reference.value">
        <a [routerLink]="['/concepts', conceptLink.targetGraph, 'concept', conceptLink.targetId]"
           [id]="conceptLink.idIdentifier + '_' + id + '_concept_link_reference_link'"
           [ngbPopover]="popContent"
           [triggers]="'mouseenter:mouseleave'"
           [popoverTitle]="conceptLink.label | translateValue">
          {{conceptLink.label | translateValue}}
        </a>

        <ng-template #popContent>
          <app-concept-link-reference-popover [link]="conceptLink"></app-concept-link-reference-popover>
        </ng-template>
      </li>
    </ul>

    <div *ngIf="editing" [appDragSortable]="reference" [dragDisabled]="!canReorder()">
      <div *ngFor="let conceptLink of reference.value; let i = index"
           class="removable-text"
           [appDragSortableItem]="conceptLink"
           [index]="i">

        <a><i class="fa fa-times" [id]="conceptLink.idIdentifier + '_' + i + '_' + id + '_concept_link_remove_reference'" (click)="removeReference(conceptLink)"></i></a>
        <span [ngbPopover]="editingPopContent"
              [triggers]="'mouseenter:mouseleave'"
              #p="ngbPopover"
              (mousedown)="p.close()"
              [popoverTitle]="conceptLink.label | translateValue">
          {{conceptLink.label | translateValue}}
        </span>

        <ng-template #editingPopContent>
          <app-concept-link-reference-popover [link]="conceptLink"></app-concept-link-reference-popover>
        </ng-template>
      </div>
    </div>

    <button type="button"
            [id]="id + '_concept_link_reference_add_reference_button'"
            class="btn btn-sm btn-action"
            *ngIf="editing"
            (click)="addReference()">{{'Add concept' | translate}}
    </button>
  