import { EditableService } from 'app/services/editable.service';
var PropertyComponent = /** @class */ (function () {
    function PropertyComponent(editableService) {
        this.editableService = editableService;
        this.relatedConcepts = [];
    }
    Object.defineProperty(PropertyComponent.prototype, "show", {
        get: function () {
            return this.editableService.editing || !this.property.valueEmpty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyComponent.prototype, "label", {
        get: function () {
            return this.property.label;
        },
        enumerable: true,
        configurable: true
    });
    PropertyComponent.prototype.hasDescription = function () {
        return Object.values(this.property.description).length > 0;
    };
    Object.defineProperty(PropertyComponent.prototype, "description", {
        get: function () {
            return this.property.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PropertyComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    return PropertyComponent;
}());
export { PropertyComponent };
