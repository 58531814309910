
    
    <i [hidden]="!hasChildren() || expanded || loop" class="far fa-plus-square" [id]="concept.idIdentifier + '_expand_concept_hierarchy_node'" (click)="expand()"></i>
    <i [hidden]="!hasChildren() || collapsed || loop" class="far fa-minus-square" [id]="concept.idIdentifier + '_collapse_concept_hierarchy_node'" (click)="collapse()"></i>
    <i [hidden]="!loop" class="hierarchy-loop fa fa-retweet" [id]="concept.idIdentifier + '_concept_hierarchy_loop'"></i>
      
    <div [id]="concept.idIdentifier + '_concept_hierarchy_node'" class="text" [ngClass]="{'hierarchy-loop': loop}" [class.selection]="selected" (click)="navigate()">
      <span>{{concept.label | translateValue}}</span>
    </div>
    
    <ul *ngIf="expanded && children">
      <li *ngFor="let child of children | async">
        <app-concept-hierarchy-node [concept]="child" [parentIds]="path"></app-concept-hierarchy-node>
      </li>
    </ul>
  