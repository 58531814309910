/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/yti-common-ui/components/footer.component.ngfactory";
import * as i3 from "yti-common-ui/components/footer.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./navigation/navigation-bar.component.ngfactory";
import * as i6 from "./navigation/navigation-bar.component";
import * as i7 from "../services/language.service";
import * as i8 from "yti-common-ui/services/user.service";
import * as i9 from "yti-common-ui/components/login-modal.component";
import * as i10 from "../services/termed.service";
import * as i11 from "../services/configuration.service";
import * as i12 from "@angular/router";
import * as i13 from "../../../node_modules/yti-common-ui/components/breadcrumb.component.ngfactory";
import * as i14 from "yti-common-ui/components/breadcrumb.component";
import * as i15 from "@angular/common";
import * as i16 from "./app.component";
import * as i17 from "../services/location.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
function View_AppComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_AppComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-footer", [["id", "app_navigate_to_info"]], null, [[null, "informationClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("informationClick" === en)) {
        var pd_0 = (_co.navigateToInformation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FooterComponent_0, i2.RenderType_FooterComponent)), i1.ɵdid(1, 49152, null, 0, i3.FooterComponent, [i4.TranslateService], { title: [0, "title"], version: [1, "version"], showLicenseIcon: [2, "showLicenseIcon"] }, { informationClick: "informationClick" }), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("Terminologies")); var currVal_1 = _co.version; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_AppComponent_1)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-navigation-bar", [], null, null, null, i5.View_NavigationBarComponent_0, i5.RenderType_NavigationBarComponent)), i1.ɵdid(2, 49152, null, 0, i6.NavigationBarComponent, [i7.LanguageService, i8.UserService, i9.LoginModalService, i10.TermedService, i11.ConfigurationService, i12.Router], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "container-fluid"]], [[2, "without-footer", null]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-breadcrumb", [], null, null, null, i13.View_BreadcrumbComponent_0, i13.RenderType_BreadcrumbComponent)), i1.ɵdid(5, 49152, null, 0, i14.BreadcrumbComponent, [i12.Router], { location: [0, "location"], linkActive: [1, "linkActive"], refreshPath: [2, "refreshPath"] }, null), i1.ɵpad(6, 1), (_l()(), i1.ɵeld(7, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(8, 212992, null, 0, i12.RouterOutlet, [i12.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppComponent_2)), i1.ɵdid(10, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.location; var currVal_2 = true; var currVal_3 = _ck(_v, 6, 0, "re"); _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); _ck(_v, 8, 0); var currVal_4 = _co.showFooter; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showFooter; _ck(_v, 3, 0, currVal_0); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i16.AppComponent, [i17.LocationService, i12.Router], null, null)], null, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i16.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
