import { FormNode } from 'app/services/form-state';
import { EditableService } from 'app/services/editable.service';
var TermComponent = /** @class */ (function () {
    function TermComponent(editableService) {
        this.editableService = editableService;
    }
    Object.defineProperty(TermComponent.prototype, "showEmpty", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TermComponent.prototype, "properties", {
        get: function () {
            var _this = this;
            var hasContent = function (field) {
                return _this.filterLanguage ? field.hasContentForLanguage(_this.filterLanguage)
                    : !field.valueEmpty;
            };
            return this.term.properties.filter(function (f) { return _this.showEmpty || hasContent(f.value); });
        },
        enumerable: true,
        configurable: true
    });
    return TermComponent;
}());
export { TermComponent };
