import { Router } from '@angular/router';
import { LocationService } from 'app/services/location.service';
var versionInfo = require('!raw-loader!../../version.txt');
var AppComponent = /** @class */ (function () {
    function AppComponent(locationService, router) {
        var _this = this;
        this.locationService = locationService;
        this.router = router;
        locationService.showFooter.subscribe(function (showFooter) {
            _this.showFooter = showFooter;
        });
        this.version = versionInfo;
    }
    Object.defineProperty(AppComponent.prototype, "location", {
        get: function () {
            return this.locationService.location;
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.navigateToInformation = function () {
        this.router.navigate(['/information']);
    };
    return AppComponent;
}());
export { AppComponent };
