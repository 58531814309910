
    <div ngbDropdown [placement]="'bottom-right'">
      <button class="btn btn-language" id="filter_language_dropdown_button" ngbDropdownToggle>{{selectionName}}</button>
      <div ngbDropdownMenu aria-labelledby="filter_language_dropdown_button">
        <button class="dropdown-item"
                [class.active]="option.lang === selection"
                *ngFor="let option of options"
                [id]="getLangSelectionId(option.lang)"
                (click)="writeValue(option.lang)">
          {{option.name}}
        </button>
      </div>
    </div>
  