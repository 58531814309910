
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label [for]="idPrefix + '_prefix_input'" translate>Prefix</label>
          <app-required-symbol></app-required-symbol>
          <input type="text"
                 [id]="idPrefix + '_prefix_input'"
                 class="form-control"
                 autocomplete="off"
                 [ngClass]="{'is-invalid': !valid && !pending}"
                 [formControl]="control" />
          <app-error-messages [id]="idPrefix + '_prefix_input_error_messages'" [control]="parentControl"></app-error-messages>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label translate>Namespace</label>
          <p class="form-control-static">{{namespace}}{{control.value}}</p>
        </div>
      </div>
    
    </div>
  