import { FormControl, NgControl } from '@angular/forms';
var StatusInputComponent = /** @class */ (function () {
    function StatusInputComponent(ngControl) {
        var _this = this;
        this.ngControl = ngControl;
        this.select = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        if (ngControl) {
            ngControl.valueAccessor = this;
        }
        this.select.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
    }
    Object.defineProperty(StatusInputComponent.prototype, "valid", {
        get: function () {
            return !this.ngControl || this.ngControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    StatusInputComponent.prototype.writeValue = function (obj) {
        this.select.setValue(obj);
    };
    StatusInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    StatusInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return StatusInputComponent;
}());
export { StatusInputComponent };
