/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../form/property.component.ngfactory";
import * as i2 from "../form/property.component";
import * as i3 from "../../services/editable.service";
import * as i4 from "../form/reference.component.ngfactory";
import * as i5 from "../form/reference.component";
import * as i6 from "@angular/common";
import * as i7 from "../common/meta-information.component.ngfactory";
import * as i8 from "../common/meta-information.component";
import * as i9 from "./concept-form.component";
import * as i10 from "./search-concept-modal.component";
import * as i11 from "./select-concept-reference-modal.component";
import * as i12 from "../../services/configuration.service";
var styles_ConceptFormComponent = [];
var RenderType_ConceptFormComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConceptFormComponent, data: {} });
export { RenderType_ConceptFormComponent as RenderType_ConceptFormComponent };
function View_ConceptFormComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-property", [["class", "col-md-12"]], [[2, "col-xl-6", null]], null, null, i1.View_PropertyComponent_0, i1.RenderType_PropertyComponent)), i0.ɵdid(1, 49152, null, 0, i2.PropertyComponent, [i3.EditableService], { id: [0, "id"], property: [1, "property"], conceptSelector: [2, "conceptSelector"], relatedConcepts: [3, "relatedConcepts"], filterLanguage: [4, "filterLanguage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.idPrefix + "_") + _v.parent.context.$implicit.name); var currVal_2 = _v.parent.context.$implicit.value; var currVal_3 = _co.conceptSelector; var currVal_4 = _co.form.referencedConcepts; var currVal_5 = _co.filterLanguage; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMultiColumn(_v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_ConceptFormComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-reference", [["class", "col-md-12"]], [[2, "col-xl-6", null]], [[null, "conceptRemove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("conceptRemove" === en)) {
        var pd_0 = (_co.onConceptRemove($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_ReferenceComponent_0, i4.RenderType_ReferenceComponent)), i0.ɵdid(1, 49152, null, 0, i5.ReferenceComponent, [i3.EditableService], { id: [0, "id"], reference: [1, "reference"], unsaved: [2, "unsaved"], vocabulary: [3, "vocabulary"], concept: [4, "concept"], filterLanguage: [5, "filterLanguage"] }, { conceptRemove: "conceptRemove" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.idPrefix + "_") + _v.parent.context.$implicit.name); var currVal_2 = _v.parent.context.$implicit.value; var currVal_3 = !_co.concept.persistent; var currVal_4 = _co.vocabulary; var currVal_5 = _co.concept; var currVal_6 = _co.filterLanguage; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMultiColumn(_v.parent.context.$implicit); _ck(_v, 0, 0, currVal_0); }); }
function View_ConceptFormComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i6.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConceptFormComponent_2)), i0.ɵdid(3, 278528, null, 0, i6.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConceptFormComponent_3)), i0.ɵdid(5, 278528, null, 0, i6.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i6.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.value.fieldType; _ck(_v, 1, 0, currVal_0); var currVal_1 = "property"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "reference"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ConceptFormComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConceptFormComponent_1)), i0.ɵdid(2, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-meta-information", [], [[8, "hidden", 0]], null, null, i7.View_MetaInformationComponent_0, i7.RenderType_MetaInformationComponent)), i0.ɵdid(4, 49152, null, 0, i8.MetaInformationComponent, [], { node: [0, "node"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fields; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.concept; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.concept.persistent; _ck(_v, 3, 0, currVal_1); }); }
export function View_ConceptFormComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-concept-form", [], null, null, null, View_ConceptFormComponent_0, RenderType_ConceptFormComponent)), i0.ɵdid(1, 49152, null, 0, i9.ConceptFormComponent, [i3.EditableService, i10.SearchConceptModalService, i11.SelectConceptReferenceModalService, i12.ConfigurationService], null, null)], null, null); }
var ConceptFormComponentNgFactory = i0.ɵccf("app-concept-form", i9.ConceptFormComponent, View_ConceptFormComponent_Host_0, { vocabulary: "vocabulary", concept: "concept", form: "form", multiColumn: "multiColumn", filterLanguage: "filterLanguage" }, {}, []);
export { ConceptFormComponentNgFactory as ConceptFormComponentNgFactory };
