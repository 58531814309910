/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-confirmation-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "yti-common-ui/pipes/translate-value.pipe";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./delete-confirmation-modal.component";
import * as i6 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i7 from "../../services/meta-model.service";
var styles_DeleteConfirmationModalComponent = [i0.styles];
var RenderType_DeleteConfirmationModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteConfirmationModalComponent, data: {} });
export { RenderType_DeleteConfirmationModalComponent as RenderType_DeleteConfirmationModalComponent };
function View_DeleteConfirmationModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslateValuePipe, [i2.LOCALIZER])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.label)); _ck(_v, 1, 0, currVal_0); }); }
function View_DeleteConfirmationModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵpid(131072, i2.TranslateValuePipe, [i2.LOCALIZER]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteConfirmationModalComponent_3)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.nodes; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.meta.label, true)); _ck(_v, 1, 0, currVal_0); }); }
function View_DeleteConfirmationModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Following items are referring to the deleted item"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteConfirmationModalComponent_2)), i1.ɵdid(6, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.references; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_DeleteConfirmationModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header modal-header-warning"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "cancel_delete_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Confirm remove"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeleteConfirmationModalComponent_1)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(13, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Are you sure that you want to remove?"])), (_l()(), i1.ɵeld(15, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "button", [["class", "btn btn-secondary-action confirm"], ["id", "delete_confirmation_yes_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Yes"])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "button", [["class", "btn btn-link cancel"], ["id", "delete_confirmation_cancel_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = (_co.references.length > 0); _ck(_v, 11, 0, currVal_1); var currVal_2 = ""; _ck(_v, 13, 0, currVal_2); var currVal_3 = ""; _ck(_v, 17, 0, currVal_3); var currVal_4 = ""; _ck(_v, 20, 0, currVal_4); }, null); }
export function View_DeleteConfirmationModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-confirmation-modal", [], null, null, null, View_DeleteConfirmationModalComponent_0, RenderType_DeleteConfirmationModalComponent)), i1.ɵdid(1, 114688, null, 0, i5.DeleteConfirmationModalComponent, [i6.NgbActiveModal, i7.MetaModelService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteConfirmationModalComponentNgFactory = i1.ɵccf("app-delete-confirmation-modal", i5.DeleteConfirmationModalComponent, View_DeleteConfirmationModalComponent_Host_0, { node: "node" }, {}, []);
export { DeleteConfirmationModalComponentNgFactory as DeleteConfirmationModalComponentNgFactory };
