
    <table class="bar-container">
      <tr class="phase" *ngFor="let phase of phases" [ngClass]="{'success': phase.state === 'SUCCESS', 'failure': phase.state === 'FAILURE'}">
        <td [ngSwitch]="phase.state" class="icon">
          <i *ngSwitchCase="'NOT_STARTED'" class="material-icons">hourglass_empty</i>
          <!-- <i *ngSwitchCase="'STARTED'" class="material-icons"></i> -->
          <i *ngSwitchCase="'SUCCESS'" class="material-icons">check_circle_outline</i>
          <i *ngSwitchCase="'FAILURE'" class="material-icons">error_outline</i>
        </td>
        <td class="title">{{phase.titleKey | translate}}</td>
        <td [ngSwitch]="phase.state" class="bar">
          <ngb-progressbar *ngSwitchCase="'NOT_STARTED'" type="info" [value]="0"></ngb-progressbar>
          <ngb-progressbar *ngSwitchCase="'STARTED'" [type]="phase.progress.trackingError ? 'danger' : 'info'" [value]="phase.progress.definite ? phase.progress.current : 100"
                           [max]="phase.progress.safeTotal" [striped]="!phase.progress.definite"
                           [animated]="!phase.progress.definite"></ngb-progressbar>
          <ngb-progressbar *ngSwitchCase="'SUCCESS'" type="success" [value]="phase.progress.safeTotal"
                           [max]="phase.progress.safeTotal"></ngb-progressbar>
          <ngb-progressbar *ngSwitchCase="'FAILURE'" type="danger" [value]="phase.progress.safeTotal"
                           [max]="phase.progress.safeTotal"></ngb-progressbar>
        </td>
      </tr>
    </table>
  