import { AfterViewInit, ElementRef, EventEmitter, NgZone, OnDestroy } from '@angular/core';
import { ConceptNode } from 'app/entities/node';
import { asLocalizable } from 'yti-common-ui/utils/localization';
var PopoverPositionRefresher = /** @class */ (function () {
    function PopoverPositionRefresher(zone, element) {
        this.zone = zone;
        this.element = element;
    }
    PopoverPositionRefresher.prototype.start = function () {
        var _this = this;
        this.updateTop();
        this.zone.runOutsideAngular(function () {
            _this.intervalHandle = setInterval(function () { return _this.updateTop(); }, 100);
        });
    };
    PopoverPositionRefresher.prototype.stop = function () {
        clearInterval(this.intervalHandle);
        this.intervalHandle = null;
    };
    PopoverPositionRefresher.prototype.updateTop = function () {
        var element = this.element.nativeElement;
        element.style.top = '-' + (element.getBoundingClientRect().height + 2) + 'px';
    };
    return PopoverPositionRefresher;
}());
var SemanticTextInputLinkPopoverComponent = /** @class */ (function () {
    function SemanticTextInputLinkPopoverComponent(element, zone) {
        this.linkConcept = new EventEmitter();
        this.linkExternal = new EventEmitter();
        this.positionRefresher = new PopoverPositionRefresher(zone, element);
    }
    SemanticTextInputLinkPopoverComponent.prototype.ngAfterViewInit = function () {
        this.positionRefresher.start();
    };
    SemanticTextInputLinkPopoverComponent.prototype.ngOnDestroy = function () {
        this.positionRefresher.stop();
    };
    return SemanticTextInputLinkPopoverComponent;
}());
export { SemanticTextInputLinkPopoverComponent };
var SemanticTextInputUnlinkConceptPopoverComponent = /** @class */ (function () {
    function SemanticTextInputUnlinkConceptPopoverComponent(element, zone) {
        this.unlink = new EventEmitter();
        this.positionRefresher = new PopoverPositionRefresher(zone, element);
    }
    SemanticTextInputUnlinkConceptPopoverComponent.prototype.ngAfterViewInit = function () {
        this.positionRefresher.start();
    };
    SemanticTextInputUnlinkConceptPopoverComponent.prototype.ngOnDestroy = function () {
        this.positionRefresher.stop();
    };
    Object.defineProperty(SemanticTextInputUnlinkConceptPopoverComponent.prototype, "conceptDefinition", {
        get: function () {
            // FIXME: how to handle multiple definitions?
            return asLocalizable(this.concept.definition, true);
        },
        enumerable: true,
        configurable: true
    });
    return SemanticTextInputUnlinkConceptPopoverComponent;
}());
export { SemanticTextInputUnlinkConceptPopoverComponent };
var SemanticTextInputUnlinkExternalPopoverComponent = /** @class */ (function () {
    function SemanticTextInputUnlinkExternalPopoverComponent(element, zone) {
        this.unlink = new EventEmitter();
        this.positionRefresher = new PopoverPositionRefresher(zone, element);
    }
    SemanticTextInputUnlinkExternalPopoverComponent.prototype.ngAfterViewInit = function () {
        this.positionRefresher.start();
    };
    SemanticTextInputUnlinkExternalPopoverComponent.prototype.ngOnDestroy = function () {
        this.positionRefresher.stop();
    };
    return SemanticTextInputUnlinkExternalPopoverComponent;
}());
export { SemanticTextInputUnlinkExternalPopoverComponent };
