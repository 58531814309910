import { EventEmitter, OnInit } from '@angular/core';
var Progress = /** @class */ (function () {
    function Progress() {
        this.trackingError = false;
    }
    Object.defineProperty(Progress.prototype, "definite", {
        get: function () {
            return typeof this.current === 'number';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Progress.prototype, "safeTotal", {
        get: function () {
            return this.total || 100;
        },
        enumerable: true,
        configurable: true
    });
    Progress.prototype.updateFrom = function (progress) {
        this.current = progress.current;
        this.total = progress.total;
        this.label = progress.label;
        this.trackingError = false;
    };
    return Progress;
}());
export { Progress };
;
var Phase = /** @class */ (function () {
    function Phase(titleKey, start, poll, cancel) {
        this.titleKey = titleKey;
        this.start = start;
        this.poll = poll;
        this.cancel = cancel;
        this.state = 'NOT_STARTED';
        this.progress = new Progress();
    }
    return Phase;
}());
export { Phase };
;
var Result = /** @class */ (function () {
    function Result() {
    }
    return Result;
}());
export { Result };
;
var ProgressComponent = /** @class */ (function () {
    function ProgressComponent() {
        this.result = new EventEmitter();
        this.pollingError = new EventEmitter();
        this.currentPhase = -1;
    }
    ProgressComponent.prototype.ngOnInit = function () {
        this.next();
    };
    ProgressComponent.prototype.next = function () {
        var _this = this;
        this.currentPhase++;
        if (this.phases.length > this.currentPhase) {
            var phase_1 = this.phases[this.currentPhase];
            phase_1.state = 'STARTED';
            window.setTimeout(function () {
                phase_1.start(phase_1, _this.state).then(function (value) {
                    _this.cancelPoll();
                    phase_1.state = 'SUCCESS';
                    _this.state = value;
                    window.setTimeout(function () { return _this.next(); }, 0);
                }).catch(function (reason) {
                    _this.cancelPoll();
                    phase_1.state = 'FAILURE';
                    _this.result.emit({
                        lastPhase: phase_1,
                        success: false,
                        result: reason
                    });
                });
                if (phase_1.poll) {
                    var poller_1 = phase_1.poll;
                    window.setTimeout(function () { return _this.poll(poller_1, phase_1); }, 0);
                }
            }, 0);
        }
        else {
            this.result.emit({
                lastPhase: this.currentPhase >= 0 ? this.phases[this.currentPhase] : undefined,
                success: true,
                result: this.state
            });
        }
    };
    ProgressComponent.prototype.poll = function (poller, phase) {
        var _this = this;
        poller(phase, this.state).then(function (value) {
            if (phase.state === 'STARTED') {
                phase.progress.updateFrom(value);
                _this.pollTimeout = window.setTimeout(function () { return _this.poll(poller, phase); }, 500);
            }
        }).catch(function (reason) {
            if (phase.state === 'STARTED') {
                phase.progress.current = undefined;
                phase.progress.trackingError = true;
                _this.pollingError.emit(true);
            }
        });
    };
    ProgressComponent.prototype.cancelPoll = function () {
        if (this.pollTimeout) {
            window.clearTimeout(this.pollTimeout);
            this.pollTimeout = undefined;
        }
    };
    return ProgressComponent;
}());
export { ProgressComponent };
