/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./editable-buttons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/yti-common-ui/components/ajax-loading-indicator-small.component.ngfactory";
import * as i3 from "yti-common-ui/components/ajax-loading-indicator-small.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/common";
import * as i6 from "./editable-buttons.component";
import * as i7 from "../../services/editable.service";
import * as i8 from "../../services/authorization-manager.sevice";
var styles_EditableButtonsComponent = [i0.styles];
var RenderType_EditableButtonsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditableButtonsComponent, data: {} });
export { RenderType_EditableButtonsComponent as RenderType_EditableButtonsComponent };
function View_EditableButtonsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ajax-loading-indicator-small", [["class", "float-right"]], null, null, null, i2.View_AjaxLoadingIndicatorSmallComponent_0, i2.RenderType_AjaxLoadingIndicatorSmallComponent)), i1.ɵdid(1, 49152, null, 0, i3.AjaxLoadingIndicatorSmallComponent, [], null, null)], null, null); }
function View_EditableButtonsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn btn-link float-right cancel"], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelEditing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn btn-action float-right save"], ["type", "button"]], [[8, "id", 0], [8, "hidden", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveEdited() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(7, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Save"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "button", [["class", "btn btn-action float-right edit"], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startEditing() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "button", [["class", "btn btn-link float-right remove"], ["type", "button"]], [[8, "id", 0], [8, "disabled", 0], [8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fa fa-trash"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(16, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Remove"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableButtonsComponent_2)), i1.ɵdid(19, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ""; _ck(_v, 3, 0, currVal_3); var currVal_7 = ""; _ck(_v, 7, 0, currVal_7); var currVal_11 = ""; _ck(_v, 11, 0, currVal_11); var currVal_15 = ""; _ck(_v, 16, 0, currVal_15); var currVal_16 = _co.operationPending; _ck(_v, 19, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.idPrefix + "_editable_cancel_editing_button"); var currVal_1 = _co.operationPending; var currVal_2 = !_co.editing; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = (_co.idPrefix + "_editable_save_edited_button"); var currVal_5 = !_co.editing; var currVal_6 = (!_co.canSave() || _co.operationPending); _ck(_v, 5, 0, currVal_4, currVal_5, currVal_6); var currVal_8 = (_co.idPrefix + "_editable_start_editing_button"); var currVal_9 = _co.operationPending; var currVal_10 = _co.editing; _ck(_v, 9, 0, currVal_8, currVal_9, currVal_10); var currVal_12 = (_co.idPrefix + "_editable_remove_button"); var currVal_13 = _co.operationPending; var currVal_14 = (_co.editing || !_co.canRemove); _ck(_v, 13, 0, currVal_12, currVal_13, currVal_14); }); }
export function View_EditableButtonsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_EditableButtonsComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canEdit(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EditableButtonsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-editable-buttons", [], null, null, null, View_EditableButtonsComponent_0, RenderType_EditableButtonsComponent)), i1.ɵdid(1, 49152, null, 0, i6.EditableButtonsComponent, [i7.EditableService, i8.AuthorizationManager], null, null)], null, null); }
var EditableButtonsComponentNgFactory = i1.ɵccf("app-editable-buttons", i6.EditableButtonsComponent, View_EditableButtonsComponent_Host_0, { vocabulary: "vocabulary", form: "form", canRemove: "canRemove", idPrefix: "idPrefix" }, {}, []);
export { EditableButtonsComponentNgFactory as EditableButtonsComponentNgFactory };
