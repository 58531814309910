/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./semantic-text-input-popover.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./semantic-text-input-popover.component";
import * as i4 from "yti-common-ui/pipes/translate-value.pipe";
import * as i5 from "../semantic-text/semantic-text-plain.component.ngfactory";
import * as i6 from "../semantic-text/semantic-text-plain.component";
import * as i7 from "../../services/configuration.service";
import * as i8 from "@angular/common";
var styles_SemanticTextInputLinkPopoverComponent = [i0.styles];
var RenderType_SemanticTextInputLinkPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextInputLinkPopoverComponent, data: {} });
export { RenderType_SemanticTextInputLinkPopoverComponent as RenderType_SemanticTextInputLinkPopoverComponent };
export function View_SemanticTextInputLinkPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["popover", 1]], null, 14, "div", [["class", "popover"], ["role", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "h3", [["class", "popover-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "btn btn-sm btn-action"], ["translate", ""]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkConcept.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add concept reference"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "btn btn-sm btn-action"], ["translate", ""]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.linkExternal.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add link"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "popover-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(13, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Unlinked selected text"]))], function (_ck, _v) { var currVal_1 = ""; _ck(_v, 4, 0, currVal_1); var currVal_3 = ""; _ck(_v, 7, 0, currVal_3); var currVal_5 = ""; _ck(_v, 13, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "_link_popover_button"); _ck(_v, 3, 0, currVal_0); var currVal_2 = (_co.id + "_extlink_popover_button"); _ck(_v, 6, 0, currVal_2); var currVal_4 = _co.selectedText; _ck(_v, 10, 0, currVal_4); }); }
export function View_SemanticTextInputLinkPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-link-popover", [], null, null, null, View_SemanticTextInputLinkPopoverComponent_0, RenderType_SemanticTextInputLinkPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputLinkPopoverComponent, [i1.ElementRef, i1.NgZone], null, null)], null, null); }
var SemanticTextInputLinkPopoverComponentNgFactory = i1.ɵccf("app-semantic-text-input-link-popover", i3.SemanticTextInputLinkPopoverComponent, View_SemanticTextInputLinkPopoverComponent_Host_0, { id: "id", selectedText: "selectedText" }, { linkConcept: "linkConcept", linkExternal: "linkExternal" }, []);
export { SemanticTextInputLinkPopoverComponentNgFactory as SemanticTextInputLinkPopoverComponentNgFactory };
var styles_SemanticTextInputUnlinkConceptPopoverComponent = [i0.styles];
var RenderType_SemanticTextInputUnlinkConceptPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextInputUnlinkConceptPopoverComponent, data: {} });
export { RenderType_SemanticTextInputUnlinkConceptPopoverComponent as RenderType_SemanticTextInputUnlinkConceptPopoverComponent };
function View_SemanticTextInputUnlinkConceptPopoverComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Concept not in references"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_SemanticTextInputUnlinkConceptPopoverComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i4.TranslateValuePipe, [i4.LOCALIZER])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.concept.label)); _ck(_v, 1, 0, currVal_0); }); }
function View_SemanticTextInputUnlinkConceptPopoverComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["app-semantic-text-plain", ""], ["class", "popover-body"]], null, null, null, i5.View_SemanticTextPlainComponent_0, i5.RenderType_SemanticTextPlainComponent)), i1.ɵdid(1, 8962048, null, 0, i6.SemanticTextPlainComponent, [i7.ConfigurationService], { value: [0, "value"], format: [1, "format"] }, null), i1.ɵpid(131072, i4.TranslateValuePipe, [i4.LOCALIZER])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.conceptDefinition)); var currVal_1 = _co.concept.definitionSemanticTextFormat; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SemanticTextInputUnlinkConceptPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["popover", 1]], null, 11, "div", [["class", "popover"], ["role", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "h3", [["class", "popover-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputUnlinkConceptPopoverComponent_1)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputUnlinkConceptPopoverComponent_2)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["class", "btn btn-sm btn-action"], ["translate", ""]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unlink.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Unlink"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputUnlinkConceptPopoverComponent_3)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.concept; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.concept; _ck(_v, 5, 0, currVal_1); var currVal_3 = ""; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.concept; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.id + "_unlink_popover_button"); _ck(_v, 7, 0, currVal_2); }); }
export function View_SemanticTextInputUnlinkConceptPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-unlink-concept-popover", [], null, null, null, View_SemanticTextInputUnlinkConceptPopoverComponent_0, RenderType_SemanticTextInputUnlinkConceptPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputUnlinkConceptPopoverComponent, [i1.ElementRef, i1.NgZone], null, null)], null, null); }
var SemanticTextInputUnlinkConceptPopoverComponentNgFactory = i1.ɵccf("app-semantic-text-input-unlink-concept-popover", i3.SemanticTextInputUnlinkConceptPopoverComponent, View_SemanticTextInputUnlinkConceptPopoverComponent_Host_0, { id: "id", concept: "concept" }, { unlink: "unlink" }, []);
export { SemanticTextInputUnlinkConceptPopoverComponentNgFactory as SemanticTextInputUnlinkConceptPopoverComponentNgFactory };
var styles_SemanticTextInputUnlinkExternalPopoverComponent = [i0.styles];
var RenderType_SemanticTextInputUnlinkExternalPopoverComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextInputUnlinkExternalPopoverComponent, data: {} });
export { RenderType_SemanticTextInputUnlinkExternalPopoverComponent as RenderType_SemanticTextInputUnlinkExternalPopoverComponent };
export function View_SemanticTextInputUnlinkExternalPopoverComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["popover", 1]], null, 10, "div", [["class", "popover"], ["role", "tooltip"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "h3", [["class", "popover-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(3, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["External link"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [["class", "btn btn-sm btn-action"], ["translate", ""]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unlink.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Unlink"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "popover-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 3, 0, currVal_0); var currVal_2 = ""; _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.id + "_unlink_popover_button"); _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.target; _ck(_v, 10, 0, currVal_3); }); }
export function View_SemanticTextInputUnlinkExternalPopoverComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-unlink-ext-popover", [], null, null, null, View_SemanticTextInputUnlinkExternalPopoverComponent_0, RenderType_SemanticTextInputUnlinkExternalPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputUnlinkExternalPopoverComponent, [i1.ElementRef, i1.NgZone], null, null)], null, null); }
var SemanticTextInputUnlinkExternalPopoverComponentNgFactory = i1.ɵccf("app-semantic-text-input-unlink-ext-popover", i3.SemanticTextInputUnlinkExternalPopoverComponent, View_SemanticTextInputUnlinkExternalPopoverComponent_Host_0, { id: "id", target: "target" }, { unlink: "unlink" }, []);
export { SemanticTextInputUnlinkExternalPopoverComponentNgFactory as SemanticTextInputUnlinkExternalPopoverComponentNgFactory };
