import { OnInit } from '@angular/core';
import { ConceptLinkNode, VocabularyNode } from 'app/entities/node';
import { EditableService } from 'app/services/editable.service';
import { SearchConceptModalService } from './search-concept-modal.component';
import { MetaModelService } from 'app/services/meta-model.service';
import { TermedService } from 'app/services/termed.service';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { FormReferenceLiteral } from 'app/services/form-state';
import { flatMap, map } from 'rxjs/operators';
var ConceptLinkReferenceInputComponent = /** @class */ (function () {
    function ConceptLinkReferenceInputComponent(editableService, termedService, metaModelService, searchConceptModal) {
        this.editableService = editableService;
        this.termedService = termedService;
        this.metaModelService = metaModelService;
        this.searchConceptModal = searchConceptModal;
    }
    ConceptLinkReferenceInputComponent.prototype.ngOnInit = function () {
        this.metaModel = this.metaModelService.getMeta(this.reference.graphId);
    };
    Object.defineProperty(ConceptLinkReferenceInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    ConceptLinkReferenceInputComponent.prototype.removeReference = function (conceptLink) {
        this.reference.removeReference(conceptLink);
    };
    ConceptLinkReferenceInputComponent.prototype.addReference = function () {
        var _this = this;
        var graphId = this.reference.targetGraph;
        var restricts = this.reference.value.map(function (ref) { return ({ graphId: ref.targetGraph, conceptId: ref.targetId, reason: 'already added error' }); });
        this.searchConceptModal.openOtherThanVocabulary(this.vocabulary, '', restricts).then(function (concept) {
            _this.termedService.getVocabulary(concept.graphId)
                .pipe(flatMap(function (vocabulary) { return _this.createConceptLink(graphId, vocabulary, concept); }))
                .subscribe(function (conceptLink) { return _this.reference.addReference(conceptLink); });
        }, ignoreModalClose);
    };
    ConceptLinkReferenceInputComponent.prototype.createConceptLink = function (toGraphId, fromVocabulary, concept) {
        return this.metaModel.pipe(map(function (meta) { return meta.createConceptLink(toGraphId, fromVocabulary, concept); }));
    };
    ConceptLinkReferenceInputComponent.prototype.canReorder = function () {
        return this.editing && this.reference.value.length > 1;
    };
    return ConceptLinkReferenceInputComponent;
}());
export { ConceptLinkReferenceInputComponent };
var ConceptLinkReferencePopoverComponent = /** @class */ (function () {
    function ConceptLinkReferencePopoverComponent() {
    }
    return ConceptLinkReferencePopoverComponent;
}());
export { ConceptLinkReferencePopoverComponent };
