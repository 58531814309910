

    <ul *ngIf="!editing">
      <li *ngFor="let domain of reference.value">{{domain.label | translateValue:true}}</li>
    </ul>

    <div *ngIf="editing" [appDragSortable]="reference" [dragDisabled]="!canReorder()">
      <div *ngFor="let domain of reference.value; let i = index"
           class="removable-text"
           [appDragSortableItem]="domain"
           [index]="i">
        <a><i class="fa fa-times" [id]="id + '_' + domain.idIdentifier + '_remove_domain_reference_link'"
              (click)="removeReference(domain)"></i></a>
        <span>{{domain.label | translateValue:true}}</span>
      </div>
      <app-error-messages [id]="id + '_error_messages'" [control]="reference.control"></app-error-messages>
    </div>

    <button type="button"
            [id]="id + '_add_domain_button'"
            class="btn btn-sm btn-action mt-2"
            *ngIf="editing"
            (click)="addReference()" translate>Add information domain</button>
  