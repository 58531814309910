
    <div class="row">
      <div class="col-lg-12">

        <div class="selectable-actions">

          <button class="btn btn-action mb-3" id="collection_list_add_collection_button" (click)="addCollection()"
                  *ngIf="canAddCollection()">
            <span translate>Add new collection</span>
          </button>

          <div class="input-group input-group-lg input-group-search">
            <input #searchInput
                   id="collection_list_search_input"
                   [(ngModel)]="search"
                   type="text"
                   class="form-control"
                   [placeholder]="'Search collection' | translate"/>
          </div>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="selectable-collections">
          <ul [ngClass]="{'has-button': canAddCollection()}">
            <li *ngFor="let collection of searchResults | async; trackBy: collectionIdentity"
                [id]="collection.idIdentifier + '_collection_list_listitem'"
                (click)="navigate(collection)"
                [class.selection]="isSelected(collection)">
              <span [innerHTML]="collection.label | translateSearchValue: searchRegexp | highlight: highlightRegexp"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  