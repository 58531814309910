
    <ul *ngIf="!editing">
      <li *ngFor="let concept of reference.value">
        <a [routerLink]="['/concepts', concept.graphId, 'concept', concept.id]"
           [id]="concept.idIdentifier + '_' + id + '_concept_reference_concept_link'">{{concept.label | translateValue}}</a>
      </li>
    </ul>

    <div *ngIf="editing" [appDragSortable]="reference" [dragDisabled]="!canReorder()">
      <div *ngFor="let concept of reference.value; let i = index"
           class="removable-text"
           [appDragSortableItem]="concept" 
           [index]="i">
        <a><i class="fa fa-times" [id]="concept.idIdentifier + '_' + id + '_concept_reference_remove_reference_link'" (click)="removeReference(concept)"></i></a>
        <span> {{concept.label | translateValue}}</span>
      </div>
    </div>

    <button type="button"
            class="btn btn-sm btn-action"
            *ngIf="editing"
            (click)="addReference()" [id]="id + '_concept_reference_add_reference_button'" translate>Add concept</button>
  