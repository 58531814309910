import { ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { DomPath, DomSelection, formatTextContent, isInDocument, moveCursor, removeChildren } from 'app/utils/dom';
import { allMatching, first, firstMatching, insertBefore, last, nextOf, nextOfMatching, previousOf, previousOfMatching, remove } from 'yti-common-ui/utils/array';
import { wordAtOffset } from 'yti-common-ui/utils/string';
import { isDefined, requireDefined } from 'yti-common-ui/utils/object';
import { SemanticTextDocument, SemanticTextLink, SemanticTextLiteral, SemanticTextParagraph } from 'app/entities/semantic';
import { areNodesEqual, resolveSerializer } from 'app/utils/semantic';
import { UrlInputModalService } from './url-input-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
var Model = /** @class */ (function () {
    function Model(node) {
        this.node = node;
        this.content = [];
        this.linkableSelection = null;
        this.linkedSelection = null;
        removeChildren(node); // clear previous nodes
    }
    Object.defineProperty(Model.prototype, "selectionOffset", {
        get: function () {
            var selection = this.getSelection();
            if (selection) {
                return Model.pointToOffset(selection.start);
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Model.prototype, "firstParagraph", {
        get: function () {
            return first(this.content);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Model.prototype, "lastParagraph", {
        get: function () {
            return last(this.content);
        },
        enumerable: true,
        configurable: true
    });
    Model.ofSemanticTextNode = function (container, document) {
        var result = new Model(container);
        for (var _i = 0, _a = document.children; _i < _a.length; _i++) {
            var paragraph = _a[_i];
            result.appendParagraph(Paragraph.ofSemanticTextNode(result, paragraph));
        }
        result.ensureNonEmptyContent();
        return result;
    };
    Model.pointToOffset = function (point) {
        var offset = point.offset;
        for (var text = point.text.getPrecedingText(); text !== null; text = text.getPrecedingText()) {
            offset += text.length;
        }
        return offset;
    };
    Model.prototype.ensureNonEmptyContent = function () {
        if (this.content.length === 0) {
            var newParagraph = new Paragraph(this);
            this.appendParagraph(newParagraph);
            newParagraph.ensureNonEmptyContent();
        }
    };
    Model.prototype.hasParagraph = function (paragraph) {
        return this.content.indexOf(paragraph) !== -1;
    };
    Model.prototype.insertNewParagraph = function () {
        var selection = requireDefined(this.getSelection());
        var _a = selection.remove(), text = _a.text, offset = _a.offset;
        var paragraph = text.containingParagraph;
        // prevent two consecutive empty paragraphs which doesn't have equivalent markdown representation
        if (!paragraph.hasEmptyContent()) {
            var newPrependingParagraph = new Paragraph(this);
            this.insertParagraphBefore(newPrependingParagraph, text.containingParagraph);
            paragraph.splitTo(newPrependingParagraph, text, offset);
            paragraph.firstPoint.moveCursor();
        }
    };
    Model.prototype.insertTextToSelection = function (text, updateDom) {
        var selection = requireDefined(this.getSelection());
        var position = requireDefined(selection.remove());
        position.text.insertText(text, position.offset, updateDom).moveCursor();
    };
    Model.prototype.removeSelection = function () {
        requireDefined(this.getSelection()).remove().moveCursor();
    };
    Model.prototype.removeNextChar = function () {
        var selection = requireDefined(this.getSelection());
        if (selection.isRange()) {
            selection.remove().moveCursor();
        }
        else {
            var _a = selection.start, text = _a.text, offset = _a.offset;
            text.removeNextChar(offset).moveCursor();
        }
    };
    Model.prototype.removePreviousChar = function () {
        var selection = requireDefined(this.getSelection());
        if (selection.isRange()) {
            selection.remove().moveCursor();
        }
        else {
            var _a = selection.start, text = _a.text, offset = _a.offset;
            text.removePreviousChar(offset).moveCursor();
        }
    };
    Model.prototype.findTextForPath = function (indicesFromRoot) {
        var index = indicesFromRoot.shift();
        return this.content[index].findTextForPath(indicesFromRoot);
    };
    Model.prototype.getPrecedingText = function (paragraph) {
        var previous = previousOf(this.content, paragraph);
        if (previous) {
            return previous.lastText;
        }
        else {
            return null;
        }
    };
    Model.prototype.getFollowingText = function (paragraph) {
        var next = nextOf(this.content, paragraph);
        if (next) {
            return next.firstText;
        }
        else {
            return null;
        }
    };
    Model.prototype.removeContent = function (paragraph) {
        var canRemove = this.content.length > 1;
        if (canRemove) {
            this.node.removeChild(paragraph.node);
            remove(this.content, paragraph);
        }
        return canRemove;
    };
    Model.prototype.getSelection = function () {
        var domSelection = DomSelection.create(this.node);
        return domSelection ? Selection.ofDomSelection(this, domSelection) : null;
    };
    Model.prototype.link = function (target, category) {
        if (this.linkableSelection === null) {
            throw new Error('Illegal state');
        }
        var _a = this.linkableSelection, start = _a.start, end = _a.end, cursor = _a.cursor;
        var paragraph = this.linkableSelection.paragraph;
        var text = this.linkableSelection.text;
        var selectionAsLink = new Link(paragraph, this.linkableSelection.content, target, category);
        if (start > 0) {
            paragraph.insertContentBefore(new Text(paragraph, text.contentBeforeOffset(start)), text);
        }
        paragraph.insertContentBefore(selectionAsLink, text);
        if (end < text.length) {
            paragraph.insertContentBefore(new Text(paragraph, text.contentAfterOffset(end)), text);
        }
        text.remove();
        if (!this.isDetached()) {
            new Point(selectionAsLink.text, cursor - start).moveCursor();
            this.updateSelection();
        }
    };
    Model.prototype.isDetached = function () {
        return !isInDocument(this.node);
    };
    Model.prototype.unlink = function () {
        if (this.linkedSelection === null) {
            throw new Error('Illegal state');
        }
        var paragraph = this.linkedSelection.paragraph;
        var link = this.linkedSelection.link;
        var linkAsText = new Text(paragraph, link.content);
        paragraph.insertContentBefore(linkAsText, link);
        link.remove();
        paragraph.mergeConsecutiveTexts(new Point(linkAsText, this.linkedSelection.cursor));
        this.updateSelection();
    };
    Model.prototype.updateSelection = function () {
        var selection = this.getSelection();
        if (selection) {
            this.linkableSelection = selection.linkableSelection;
            this.linkedSelection = selection.linkedSelection;
        }
    };
    Model.prototype.removeLinkSelections = function () {
        this.linkableSelection = null;
        this.linkedSelection = null;
    };
    Model.prototype.toSemanticTextNode = function () {
        return new SemanticTextDocument(this.content.map(function (c) { return c.toSemanticTextNode(); }));
    };
    Model.prototype.moveCursorToOffset = function (offset) {
        this.offsetToPoint(offset).moveCursor();
    };
    Model.prototype.removeStartOfLine = function () {
        console.log('remove start of line, not implemented yet'); // TODO
    };
    Model.prototype.removeEndOfLine = function () {
        console.log('remove rest of line, not implemented yet'); // TODO
    };
    Model.prototype.removeNextWord = function () {
        console.log('remove next word, not implemented yet'); // TODO
    };
    Model.prototype.removePreviousWord = function () {
        console.log('remove previous word, not implemented yet'); // TODO
    };
    Model.prototype.copy = function () {
        return requireDefined(this.getSelection()).toPlainString();
    };
    Model.prototype.paste = function (text) {
        this.insertTextToSelection(text, true);
    };
    Model.prototype.cut = function () {
        var text = this.copy();
        requireDefined(this.getSelection()).remove().moveCursor();
        return text;
    };
    Model.prototype.appendParagraph = function (paragraph) {
        this.content.push(paragraph);
        this.node.appendChild(paragraph.node);
    };
    Model.prototype.insertParagraphBefore = function (newParagraph, ref) {
        insertBefore(this.content, newParagraph, ref);
        this.node.insertBefore(newParagraph.node, ref.node);
    };
    Model.prototype.offsetToPoint = function (offset) {
        var offsetWalked = 0;
        for (var text = this.firstParagraph.firstText; text !== null; text = text.getFollowingText()) {
            offsetWalked += text.length;
            if (offset <= offsetWalked) {
                return new Point(text, text.length - (offsetWalked - offset));
            }
        }
        return this.lastParagraph.lastText.lastPoint;
    };
    return Model;
}());
var Paragraph = /** @class */ (function () {
    function Paragraph(parent) {
        this.parent = parent;
        this.content = [];
        this.node = document.createElement('p');
    }
    Object.defineProperty(Paragraph.prototype, "firstPoint", {
        get: function () {
            return new Point(this.firstText, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "paragraph", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "lastContent", {
        get: function () {
            return last(this.content);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "lastText", {
        get: function () {
            return this.lastContent.text;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "firstContent", {
        get: function () {
            return first(this.content);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Paragraph.prototype, "firstText", {
        get: function () {
            return this.firstContent.text;
        },
        enumerable: true,
        configurable: true
    });
    Paragraph.ofSemanticTextNode = function (parent, paragraphNode) {
        var result = new Paragraph(parent);
        for (var _i = 0, _a = paragraphNode.children; _i < _a.length; _i++) {
            var child = _a[_i];
            result.appendContent(child.type === 'link' ? Link.ofSemanticTextNode(result, child)
                : Text.ofSemanticTextNode(result, child));
        }
        return result;
    };
    Paragraph.prototype.combineWith = function (paragraph) {
        var lastOfThis = last(this.content);
        var lastContentBeforeChanges = lastOfThis.text.content;
        var firstChild = true;
        for (var _i = 0, _a = paragraph.content; _i < _a.length; _i++) {
            var content = _a[_i];
            if (firstChild && lastOfThis instanceof Text && content instanceof Text) {
                if (!lastOfThis.hasEmptyContent()) {
                    lastOfThis.append(content.content);
                }
                else {
                    lastOfThis.content = content.content;
                }
            }
            else {
                this.appendContent(content.copyToParent(this));
            }
            firstChild = false;
        }
        paragraph.remove();
        return new Point(lastOfThis.text, lastContentBeforeChanges.trim() === '' ? 0 : lastContentBeforeChanges.length);
    };
    Paragraph.prototype.splitTo = function (prependingParagraph, fromText, fromOffset) {
        var contentToRemove = [];
        for (var _i = 0, _a = this.content; _i < _a.length; _i++) {
            var content = _a[_i];
            var isSplittingText = content.text === fromText;
            if (isSplittingText) {
                var beforeSplitPointContent = content.text.contentBeforeOffset(fromOffset);
                if (content instanceof Link) {
                    prependingParagraph.appendContent(new Link(prependingParagraph, beforeSplitPointContent, content.target, content.category));
                }
                else {
                    prependingParagraph.appendText(beforeSplitPointContent);
                }
                content.content = content.text.contentAfterOffset(fromOffset);
                break; // nothing to do after split point is handled
            }
            prependingParagraph.appendContent(content.copyToParent(prependingParagraph));
            contentToRemove.push(content);
        }
        for (var _b = 0, contentToRemove_1 = contentToRemove; _b < contentToRemove_1.length; _b++) {
            var content = contentToRemove_1[_b];
            content.remove();
        }
    };
    Paragraph.prototype.ensureNonEmptyContent = function () {
        if (this.content.length === 0) {
            this.appendContent(new Text(this));
        }
    };
    Paragraph.prototype.hasEmptyContent = function () {
        return allMatching(this.content, function (c) { return c.hasEmptyContent(); });
    };
    Paragraph.prototype.appendText = function (text) {
        if (this.content.length > 0 && this.lastContent instanceof Text) {
            this.lastContent.append(text);
        }
        else {
            this.appendContent(new Text(this, text));
        }
    };
    Paragraph.prototype.mergeConsecutiveTexts = function (cursor) {
        var cursorAfterMerging = cursor;
        if (this.content.length < 2) {
            // nothing to do
        }
        else {
            var i = 1;
            while (i < this.content.length) {
                var previous = this.content[i - 1];
                var current = this.content[i];
                if (previous instanceof Text && current instanceof Text) {
                    var previousLengthBeforeAppending = previous.length;
                    previous.append(current.content);
                    if (cursor.text === current) {
                        cursorAfterMerging = new Point(previous, previousLengthBeforeAppending + cursorAfterMerging.offset);
                    }
                    current.remove();
                }
                else {
                    i++;
                }
            }
        }
        moveCursor(cursorAfterMerging.text.node, cursorAfterMerging.offset);
    };
    Paragraph.prototype.appendContent = function (content) {
        this.content.push(content);
        this.node.appendChild(content.node);
    };
    Paragraph.prototype.insertContentBefore = function (content, ref) {
        insertBefore(this.content, content, ref);
        this.node.insertBefore(content.node, ref.node);
    };
    Paragraph.prototype.remove = function () {
        return this.parent.removeContent(this);
    };
    Paragraph.prototype.removeContent = function (content) {
        var previous = this.getPrecedingText(content.text);
        var next = this.getFollowingText(content.text);
        this.node.removeChild(content.node);
        remove(this.content, content);
        if (this.content.length === 0 && !this.parent.removeContent(this)) {
            this.ensureNonEmptyContent();
            return this.firstPoint;
        }
        return previous ? previous.lastPoint : next.firstPoint;
    };
    Paragraph.prototype.findTextForPath = function (indicesFromRoot) {
        if (indicesFromRoot.length === 0) {
            // at least chrome seems to return paragraph in selection
            return this.firstText;
        }
        var index = indicesFromRoot.shift();
        return this.content[index].findTextForPath(indicesFromRoot);
    };
    Paragraph.prototype.getPrecedingText = function (text) {
        var previous = previousOfMatching(this.content, function (c) { return c.text === text; });
        if (previous) {
            return previous.text;
        }
        else {
            return this.parent.getPrecedingText(this);
        }
    };
    Paragraph.prototype.getFollowingText = function (text) {
        var next = nextOfMatching(this.content, function (c) { return c.text === text; });
        if (next) {
            return next.text;
        }
        else {
            return this.parent.getFollowingText(this);
        }
    };
    Paragraph.prototype.toSemanticTextNode = function () {
        return new SemanticTextParagraph(this.content.map(function (c) { return c.toSemanticTextNode(); }));
    };
    return Paragraph;
}());
var Link = /** @class */ (function () {
    function Link(parent, text, target, category) {
        this.parent = parent;
        this.category = category;
        this.node = document.createElement('span');
        this.node.classList.add('link', category);
        this.text = new Text(this, text);
        this.target = target;
    }
    Object.defineProperty(Link.prototype, "text", {
        get: function () {
            return this._text;
        },
        set: function (value) {
            this._text = value;
            for (var _i = 0, _a = Array.from(this.node.childNodes.values()); _i < _a.length; _i++) {
                var child = _a[_i];
                this.node.removeChild(child);
            }
            this.node.appendChild(value.node);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "target", {
        get: function () {
            return this._target;
        },
        set: function (value) {
            this._target = value;
            this.node.dataset['target'] = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "content", {
        get: function () {
            return this.text.content;
        },
        set: function (value) {
            this.text.content = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Link.prototype, "paragraph", {
        get: function () {
            return this.parent;
        },
        enumerable: true,
        configurable: true
    });
    Link.ofSemanticTextNode = function (parent, link) {
        return new Link(parent, link.text, link.destination, link.category);
    };
    Link.prototype.copyToParent = function (parent) {
        return new Link(parent, this.content, this.target, this.category);
    };
    Link.prototype.hasEmptyContent = function () {
        return this.text.hasEmptyContent();
    };
    Link.prototype.remove = function () {
        return this.parent.removeContent(this);
    };
    Link.prototype.removeContent = function (text) {
        if (text !== this.text) {
            throw new Error('Illegal argument');
        }
        return this.remove();
    };
    Link.prototype.findTextForPath = function (indicesFromRoot) {
        var index = indicesFromRoot.shift();
        if (index !== 0 || indicesFromRoot.length !== 0) {
            throw new Error('Illegal state');
        }
        return this.text;
    };
    Link.prototype.getPrecedingText = function () {
        return this.parent.getPrecedingText(this.text);
    };
    Link.prototype.getFollowingText = function () {
        return this.parent.getFollowingText(this.text);
    };
    Link.prototype.toSemanticTextNode = function () {
        return new SemanticTextLink(this.content, this.target, this.category);
    };
    return Link;
}());
var Text = /** @class */ (function () {
    function Text(parent, content) {
        if (content === void 0) { content = ''; }
        this.parent = parent;
        this.node = document.createTextNode('');
        this.content = content;
    }
    Object.defineProperty(Text.prototype, "content", {
        get: function () {
            return this._content;
        },
        set: function (value) {
            this._content = value || ' ';
            this.node.textContent = formatTextContent(this.content);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Text.prototype, "text", {
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Text.prototype, "containingParagraph", {
        get: function () {
            return this.parent.paragraph;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Text.prototype, "length", {
        get: function () {
            return this.content.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Text.prototype, "firstPoint", {
        get: function () {
            return new Point(this, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Text.prototype, "lastPoint", {
        get: function () {
            return new Point(this, this.content.length);
        },
        enumerable: true,
        configurable: true
    });
    Text.ofSemanticTextNode = function (parent, text) {
        return new Text(parent, text.text);
    };
    Text.prototype.copyToParent = function (parent) {
        return new Text(parent, this.content);
    };
    Text.prototype.hasEmptyContent = function () {
        return this.content.trim() === '';
    };
    Text.prototype.contentBeforeOffset = function (offset) {
        return this.content.substring(0, offset);
    };
    Text.prototype.contentAfterOffset = function (offset) {
        return this.content.substring(offset, this.content.length);
    };
    Text.prototype.isInLink = function () {
        return this.parent instanceof Link;
    };
    Text.prototype.remove = function () {
        // FIXME: typescript won't type check without this no-op type guard
        return this.parent instanceof Paragraph ? this.parent.removeContent(this)
            : this.parent.removeContent(this);
    };
    Text.prototype.removeNextChar = function (offset) {
        if (offset >= this.length) {
            var next = this.getFollowingText();
            if (next) {
                if (next.containingParagraph !== this.containingParagraph) {
                    return this.containingParagraph.combineWith(next.containingParagraph);
                }
                else {
                    return next.removeFirstCharacter();
                }
            }
            else {
                return new Point(this, offset);
            }
        }
        else {
            return this.removeRange(offset, offset + 1);
        }
    };
    Text.prototype.removePreviousChar = function (offset) {
        if (offset <= 0) {
            var previous = this.getPrecedingText();
            if (previous) {
                if (previous.containingParagraph !== this.containingParagraph) {
                    return previous.containingParagraph.combineWith(this.containingParagraph);
                }
                else {
                    return previous.removeLastCharacter();
                }
            }
            else {
                return new Point(this, offset);
            }
        }
        else {
            return this.removeRange(offset - 1, offset);
        }
    };
    Text.prototype.removeAfter = function (offset) {
        return this.removeRange(offset, this.length);
    };
    Text.prototype.removeBefore = function (offset) {
        return this.removeRange(0, offset);
    };
    Text.prototype.removeLastCharacter = function () {
        if (this.content.length <= 1) {
            return this.remove();
        }
        else {
            return this.removeRange(this.content.length - 1, this.content.length);
        }
    };
    Text.prototype.removeFirstCharacter = function () {
        if (this.content.length <= 1) {
            return this.remove();
        }
        else {
            return this.removeRange(0, 1);
        }
    };
    Text.prototype.removeRange = function (start, end) {
        if (start < 0 || end > this.content.length) {
            throw new Error('remove range not in bounds, ' + start + ' .. ' + end + ' of [' + this.content + '] (' + this.content.length + ')');
        }
        if (start === 0 && end === this.content.length) {
            return this.remove();
        }
        else {
            this.content = this.contentBeforeOffset(start) + this.contentAfterOffset(end);
            return new Point(this, start);
        }
    };
    Text.prototype.insertText = function (text, offset, updateDom) {
        var actualOffset = updateDom ? offset : offset - text.length;
        var newContent = this.contentBeforeOffset(actualOffset) + text + this.contentAfterOffset(actualOffset);
        if (updateDom) {
            this.content = newContent;
        }
        else {
            this._content = newContent;
        }
        return new Point(this, actualOffset + text.length);
    };
    Text.prototype.append = function (text) {
        this.content = this.content + text;
        return this.lastPoint;
    };
    Text.prototype.findTextForPath = function (indicesFromRoot) {
        if (indicesFromRoot.length !== 0) {
            throw new Error('Illegal state');
        }
        return this;
    };
    Text.prototype.getPrecedingText = function () {
        if (this.parent instanceof Paragraph) {
            return this.parent.getPrecedingText(this);
        }
        else {
            return this.parent.getPrecedingText();
        }
    };
    Text.prototype.getFollowingText = function () {
        if (this.parent instanceof Paragraph) {
            return this.parent.getFollowingText(this);
        }
        else {
            return this.parent.getFollowingText();
        }
    };
    Text.prototype.toSemanticTextNode = function () {
        return new SemanticTextLiteral(this.content);
    };
    return Text;
}());
var Point = /** @class */ (function () {
    function Point(text, offset) {
        this.text = text;
        this.offset = offset;
    }
    Point.prototype.moveCursor = function () {
        moveCursor(this.text.node, this.offset);
    };
    return Point;
}());
var Selection = /** @class */ (function () {
    function Selection(model, start, end) {
        this.model = model;
        this.start = start;
        this.end = end;
        this.textBetween = [];
        if (this.start.text !== this.end.text) {
            for (var t = this.start.text.getFollowingText(); t !== this.end.text; t = t.getFollowingText()) {
                this.textBetween.push(t);
            }
        }
    }
    Object.defineProperty(Selection.prototype, "linkedSelection", {
        get: function () {
            if (this.isLink()) {
                return new LinkedSelection(this.end.text.parent, this.end.offset);
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Selection.prototype, "linkableSelection", {
        get: function () {
            if (this.isLinkable()) {
                if (this.isRange()) {
                    return new LinkableSelection(this.start.text, this.start.offset, this.end.offset, this.end.offset);
                }
                else {
                    var wordRange = wordAtOffset(this.start.text.content, this.start.offset);
                    if (wordRange) {
                        return new LinkableSelection(this.start.text, wordRange.start, wordRange.end, this.end.offset);
                    }
                    else {
                        return null;
                    }
                }
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Selection.ofDomSelection = function (model, domSelection) {
        function createPoint(domPoint) {
            if (domPoint.node === model.node) {
                return null;
            }
            var indicesFromRoot = domPoint.path.indicesFromRoot;
            var text = model.findTextForPath(indicesFromRoot);
            return text ? new Point(text, domPoint.offset) : null;
        }
        var startPoint = createPoint(domSelection.start);
        var endPoint = createPoint(domSelection.end);
        if (startPoint && endPoint) {
            return new Selection(model, startPoint, endPoint);
        }
        else {
            return null;
        }
    };
    Selection.prototype.isRange = function () {
        return this.start.text !== this.end.text || this.start.offset !== this.end.offset;
    };
    Selection.prototype.remove = function () {
        if (!this.isRange()) {
            return this.start;
        }
        if (this.start.text !== this.end.text) {
            for (var _i = 0, _a = this.textBetween; _i < _a.length; _i++) {
                var text = _a[_i];
                text.remove();
            }
            var startParagraph = this.start.text.containingParagraph;
            var endParagraph = this.end.text.containingParagraph;
            this.start.text.removeAfter(this.start.offset);
            var pointAfterRemoval = this.end.text.removeBefore(this.end.offset);
            if (startParagraph !== endParagraph && this.model.hasParagraph(startParagraph) && this.model.hasParagraph(endParagraph)) {
                return startParagraph.combineWith(endParagraph);
            }
            else {
                return pointAfterRemoval;
            }
        }
        else {
            return this.start.text.removeRange(this.start.offset, this.end.offset);
        }
    };
    Selection.prototype.toPlainString = function () {
        var start = this.start.text.content;
        var end = this.end.text.content;
        if (this.start.text === this.end.text) {
            return start.substring(this.start.offset, this.end.offset);
        }
        else {
            var result = start.substring(this.start.offset, start.length);
            for (var _i = 0, _a = this.textBetween; _i < _a.length; _i++) {
                var text = _a[_i];
                result += text.content;
            }
            result += end.substring(0, this.end.offset);
            return result;
        }
    };
    Selection.prototype.toString = function () {
        var _this = this;
        var createDomPath = function (point) { return requireDefined(DomPath.create(_this.model.node, point.text.node)); };
        return "From " + createDomPath(this.start).toString() + "(" + this.start.offset + ") " +
            ("to " + createDomPath(this.end).toString() + "(" + this.end.offset + ")");
    };
    Selection.prototype.isLinkable = function () {
        return this.start.text === this.end.text && !this.start.text.isInLink();
    };
    Selection.prototype.isLink = function () {
        return this.start.text === this.end.text && this.start.text.isInLink();
    };
    return Selection;
}());
var LinkableSelection = /** @class */ (function () {
    function LinkableSelection(text, start, end, cursor) {
        this.text = text;
        this.start = start;
        this.end = end;
        this.cursor = cursor;
    }
    Object.defineProperty(LinkableSelection.prototype, "content", {
        get: function () {
            return this.text.content.substring(this.start, this.end);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinkableSelection.prototype, "paragraph", {
        get: function () {
            return this.text.containingParagraph;
        },
        enumerable: true,
        configurable: true
    });
    return LinkableSelection;
}());
var LinkedSelection = /** @class */ (function () {
    function LinkedSelection(link, cursor) {
        this.link = link;
        this.cursor = cursor;
    }
    Object.defineProperty(LinkedSelection.prototype, "content", {
        get: function () {
            return this.link.content;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinkedSelection.prototype, "paragraph", {
        get: function () {
            return this.link.paragraph;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LinkedSelection.prototype, "target", {
        get: function () {
            return this.link.target;
        },
        enumerable: true,
        configurable: true
    });
    return LinkedSelection;
}());
var keyCodes = {
    backspace: 8,
    enter: 13,
    esc: 27,
    space: 32,
    del: 46,
    a: 65,
    b: 66,
    c: 67,
    d: 68,
    h: 72,
    i: 73,
    k: 75,
    u: 85,
    v: 86,
    x: 88,
    y: 89,
    z: 90
};
function isAlt(event, keyCode) {
    return !event.metaKey && !event.ctrlKey && event.altKey && (isDefined(keyCode) ? event.keyCode === keyCode : true);
}
function isCtrl(event, keyCode) {
    return !event.metaKey && event.ctrlKey && !event.altKey && (isDefined(keyCode) ? event.keyCode === keyCode : true);
}
function isMeta(event, keyCode) {
    return event.metaKey && !event.ctrlKey && !event.altKey && (isDefined(keyCode) ? event.keyCode === keyCode : true);
}
function isPlain(event, keyCode) {
    return !event.metaKey && !event.ctrlKey && !event.altKey && (isDefined(keyCode) ? event.keyCode === keyCode : true);
}
function isUndo(event) {
    return isCtrl(event, keyCodes.z) || isMeta(event, keyCodes.z);
}
function isRedo(event) {
    return (isMeta(event, keyCodes.z) && event.shiftKey) || isCtrl(event, keyCodes.y);
}
function isBoldCommand(event) {
    return isCtrl(event, keyCodes.b) || isMeta(event, keyCodes.b);
}
function isItalicCommand(event) {
    return isCtrl(event, keyCodes.i) || isMeta(event, keyCodes.i);
}
function isUnderlineCommand(event) {
    return isCtrl(event, keyCodes.u) || isMeta(event, keyCodes.u);
}
function isRemovePreviousChar(event) {
    return isPlain(event, keyCodes.backspace) || isCtrl(event, keyCodes.h);
}
function isRemoveNextChar(event) {
    return isPlain(event, keyCodes.del) || isCtrl(event, keyCodes.d);
}
function isRemovePreviousWord(event) {
    return isCtrl(event, keyCodes.backspace) || isAlt(event, keyCodes.backspace);
}
function isRemoveNextWord(event) {
    return isAlt(event, keyCodes.del);
}
function isRemoveStartOfLine(event) {
    return isMeta(event, keyCodes.backspace);
}
function isRemoveRestOfLine(event) {
    return isCtrl(event, keyCodes.k);
}
var SemanticTextInputComponent = /** @class */ (function () {
    function SemanticTextInputComponent(urlInputModalService, configurationService) {
        this.urlInputModalService = urlInputModalService;
        this.configurationService = configurationService;
        this.linkingInProgress = false;
        this.invalidData = false;
        this.undoStack = [];
        this.redoStack = [];
        this.undoDebounceTimeoutHandle = null;
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
    }
    Object.defineProperty(SemanticTextInputComponent.prototype, "linkableSelection", {
        get: function () {
            return requireDefined(this.model.linkableSelection);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SemanticTextInputComponent.prototype, "linkedSelection", {
        get: function () {
            return requireDefined(this.model.linkedSelection);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SemanticTextInputComponent.prototype, "linkedConcept", {
        get: function () {
            var _this = this;
            return firstMatching(this.relatedConcepts, function (concept) { return concept.isTargetOfLink(_this.linkedSelection.target); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SemanticTextInputComponent.prototype, "linkedExternalTarget", {
        get: function () {
            if (this.hasExternalLinkSelection()) {
                return this.model.linkedSelection.link.target;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    SemanticTextInputComponent.prototype.ngOnInit = function () {
        var _this = this;
        var element = this.editableElement.nativeElement;
        this.model = new Model(element);
        element.addEventListener('keydown', function (event) {
            if (isUnderlineCommand(event)) {
                console.log('underline command prevented');
                event.preventDefault();
            }
            else if (isItalicCommand(event)) {
                console.log('italic command prevented');
                event.preventDefault();
            }
            else if (isBoldCommand(event)) {
                console.log('bold command prevented');
                event.preventDefault();
            }
            else if (isRemoveStartOfLine(event)) {
                _this.reportChange(function () { return _this.model.removeStartOfLine(); });
                event.preventDefault();
            }
            else if (isRemoveRestOfLine(event)) {
                _this.reportChange(function () { return _this.model.removeEndOfLine(); });
                event.preventDefault();
            }
            else if (isRemovePreviousWord(event)) {
                _this.reportChange(function () { return _this.model.removePreviousWord(); });
                event.preventDefault();
            }
            else if (isRemoveNextWord(event)) {
                _this.reportChange(function () { return _this.model.removeNextWord(); });
                event.preventDefault();
            }
            else if (isRemoveNextChar(event)) {
                _this.reportChange(function () { return _this.model.removeNextChar(); });
                event.preventDefault();
            }
            else if (isRemovePreviousChar(event)) {
                _this.reportChange(function () { return _this.model.removePreviousChar(); });
                event.preventDefault();
            }
            else if (isRedo(event)) {
                _this.redo();
                event.preventDefault();
            }
            else if (isUndo(event)) {
                _this.undo();
                event.preventDefault();
            }
            else {
                // catch rest in key press handler which handles all text appending
            }
        });
        element.addEventListener('keypress', function (event) {
            if (event.keyCode === keyCodes.enter) {
                _this.reportChange(function () { return _this.model.insertNewParagraph(); });
                event.preventDefault();
            }
            else if (event.keyCode === keyCodes.space) {
                _this.reportChange(function () { return _this.model.insertTextToSelection(' ', true); });
                event.preventDefault();
            }
            else if (event.charCode === keyCodes.esc) {
                // nothing to do
            }
            else if (event.charCode) {
                _this.reportChange(function () { return _this.model.insertTextToSelection(event.key, true); });
                event.preventDefault();
            }
        });
        element.addEventListener('compositionstart', function (event) {
            _this.reportChange(function () { return _this.model.removeSelection(); });
        });
        element.addEventListener('compositionend', function (event) {
            _this.reportChange(function () { return _this.model.insertTextToSelection(event.data, false); });
        });
        element.addEventListener('keyup', function () {
            _this.model.updateSelection();
        });
        element.addEventListener('mouseup', function (event) {
            _this.model.updateSelection();
            event.preventDefault();
        });
        element.addEventListener('blur', function () {
            setTimeout(function () {
                if (!_this.linkingInProgress) {
                    _this.model.removeLinkSelections();
                }
            }, 200);
        });
        element.addEventListener('copy', function (event) {
            event.clipboardData.setData('text/plain', _this.model.copy());
            event.preventDefault();
        });
        element.addEventListener('paste', function (event) {
            _this.reportChange(function () { return _this.model.paste(event.clipboardData.getData('Text')); });
            event.preventDefault();
        });
        element.addEventListener('cut', function (event) {
            _this.reportChange(function () { return event.clipboardData.setData('text/plain', _this.model.cut()); });
            event.preventDefault();
        });
    };
    SemanticTextInputComponent.prototype.hasLinkableSelection = function () {
        if (!this.conceptSelector) {
            return false;
        }
        return this.model.linkableSelection !== null;
    };
    SemanticTextInputComponent.prototype.hasConceptLinkSelection = function () {
        return this.model.linkedSelection !== null && this.model.linkedSelection.link.category === 'internal';
    };
    SemanticTextInputComponent.prototype.hasExternalLinkSelection = function () {
        return this.model.linkedSelection !== null && this.model.linkedSelection.link.category === 'external';
    };
    SemanticTextInputComponent.prototype.focusEditor = function () {
        this.editableElement.nativeElement.focus();
    };
    SemanticTextInputComponent.prototype.linkInternal = function () {
        var _this = this;
        this.linkingInProgress = true;
        this.conceptSelector(this.linkableSelection.content).then(function (concept) {
            if (concept) {
                _this.reportChange(function () { return _this.model.link(requireDefined(concept.uri), 'internal'); });
            }
            _this.linkingInProgress = false;
            _this.focusEditor();
        });
    };
    SemanticTextInputComponent.prototype.linkExternal = function () {
        var _this = this;
        this.linkingInProgress = true;
        this.urlInputModalService.open(this.linkableSelection.content).then(function (url) {
            if (url) {
                _this.reportChange(function () { return _this.model.link(url, 'external'); });
            }
            _this.linkingInProgress = false;
            _this.focusEditor();
        }).catch(function (err) {
            _this.linkingInProgress = false;
            _this.focusEditor();
        });
    };
    SemanticTextInputComponent.prototype.unlink = function () {
        var _this = this;
        this.reportChange(function () { return _this.model.unlink(); });
        this.focusEditor();
    };
    SemanticTextInputComponent.prototype.undo = function () {
        if (this.undoStack.length > 1) {
            this.pushUndoIfChanged(false);
            // current state is at the top the stack
            this.redoStack.push(this.undoStack.pop());
            var _a = this.undoStack[this.undoStack.length - 1], semanticTextNode = _a.semanticTextNode, cursorOffset = _a.cursorOffset;
            this.resetModel(semanticTextNode, cursorOffset);
            this.propagateChange(resolveSerializer(this.format).serialize(semanticTextNode));
        }
    };
    SemanticTextInputComponent.prototype.redo = function () {
        if (this.undoDebounceTimeoutHandle) {
            this.pushUndoIfChanged(true);
        }
        if (this.redoStack.length > 0) {
            var historyItem = this.redoStack.pop();
            this.undoStack.push(historyItem);
            this.resetModel(historyItem.semanticTextNode, historyItem.cursorOffset);
            this.propagateChange(resolveSerializer(this.format).serialize(historyItem.semanticTextNode));
        }
    };
    SemanticTextInputComponent.prototype.writeValue = function (obj) {
        var value = obj || '';
        if (typeof value !== 'string') {
            throw new Error('Value must be a string');
        }
        this.undoStack = [];
        this.redoStack = [];
        var _a = resolveSerializer(this.format).deserialize(value, this.configurationService.namespaceRoot), document = _a.document, valid = _a.valid;
        this.resetModel(document);
        this.invalidData = !valid;
    };
    SemanticTextInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    SemanticTextInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    SemanticTextInputComponent.prototype.pushUndoIfChanged = function (resetRedo) {
        if (this.undoDebounceTimeoutHandle) {
            clearTimeout(this.undoDebounceTimeoutHandle);
            this.undoDebounceTimeoutHandle = null;
        }
        var historyItem = { semanticTextNode: this.model.toSemanticTextNode(), cursorOffset: this.model.selectionOffset };
        var topOfStack = this.undoStack.length > 0 ? this.undoStack[this.undoStack.length - 1] : null;
        if (!topOfStack || !areNodesEqual(topOfStack.semanticTextNode, historyItem.semanticTextNode)) {
            this.undoStack.push(historyItem);
            if (resetRedo) {
                this.redoStack = [];
            }
        }
    };
    SemanticTextInputComponent.prototype.undoDebounce = function () {
        var _this = this;
        var debounceTime = 500;
        if (this.undoDebounceTimeoutHandle) {
            clearTimeout(this.undoDebounceTimeoutHandle);
        }
        this.undoDebounceTimeoutHandle = setTimeout(function () { return _this.pushUndoIfChanged(true); }, debounceTime);
    };
    SemanticTextInputComponent.prototype.reportChange = function (modifyingAction) {
        if (!this.undoDebounceTimeoutHandle) {
            // do an initial push
            this.pushUndoIfChanged(true);
        }
        this.undoDebounce();
        modifyingAction();
        this.propagateChange(resolveSerializer(this.format).serialize(this.model.toSemanticTextNode()));
    };
    SemanticTextInputComponent.prototype.resetModel = function (semanticTextNode, cursorOffset) {
        var element = this.editableElement.nativeElement;
        this.model = Model.ofSemanticTextNode(element, semanticTextNode);
        if (cursorOffset) {
            this.model.moveCursorToOffset(cursorOffset);
        }
    };
    return SemanticTextInputComponent;
}());
export { SemanticTextInputComponent };
