import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditableService } from 'app/services/editable.service';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { DeleteConfirmationModalService } from 'app/components/common/delete-confirmation-modal.component';
import { requireDefined } from 'yti-common-ui/utils/object';
import { LanguageService } from 'app/services/language.service';
import { collectionIdPrefix } from 'app/utils/id-prefix';
var CollectionComponent = /** @class */ (function () {
    function CollectionComponent(route, conceptViewModel, deleteConfirmationModal, editableService, languageService) {
        var _this = this;
        this.route = route;
        this.conceptViewModel = conceptViewModel;
        this.editableService = editableService;
        this.languageService = languageService;
        this.subscriptionToClean = [];
        this.idPrefix = collectionIdPrefix;
        route.params.subscribe(function (params) { return conceptViewModel.initializeCollection(params['collectionId']); });
        editableService.onSave = function () { return _this.conceptViewModel.saveCollection(); };
        editableService.onCanceled = function () { return _this.conceptViewModel.resetCollection(); };
        editableService.onRemove = function () {
            return deleteConfirmationModal.open(requireDefined(_this.collection))
                .then(function () { return _this.conceptViewModel.removeCollection(); });
        };
        this.subscriptionToClean.push(this.conceptViewModel.resourceSelect$.subscribe(function (collection) {
            if (!collection.persistent && !editableService.editing) {
                editableService.edit();
            }
            else if (collection.persistent && editableService.editing) {
                editableService.cancel();
            }
        }));
    }
    Object.defineProperty(CollectionComponent.prototype, "showEmpty", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionComponent.prototype, "fields", {
        get: function () {
            var _this = this;
            var hasContent = function (field) {
                return _this.filterLanguage ? field.hasContentForLanguage(_this.filterLanguage)
                    : !field.valueEmpty;
            };
            return this.formNode.fields.filter(function (f) { return _this.showEmpty || hasContent(f.value); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionComponent.prototype, "formNode", {
        get: function () {
            return this.conceptViewModel.resourceForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionComponent.prototype, "unsaved", {
        get: function () {
            var collection = this.conceptViewModel.collection;
            return collection && !collection.persistent;
        },
        enumerable: true,
        configurable: true
    });
    CollectionComponent.prototype.ngOnDestroy = function () {
        for (var _i = 0, _a = this.subscriptionToClean; _i < _a.length; _i++) {
            var subscription = _a[_i];
            subscription.unsubscribe();
        }
    };
    Object.defineProperty(CollectionComponent.prototype, "collection", {
        get: function () {
            return this.conceptViewModel.collection;
        },
        enumerable: true,
        configurable: true
    });
    CollectionComponent.prototype.isEditing = function () {
        return this.editableService.editing;
    };
    CollectionComponent.prototype.cancelEditing = function () {
        this.editableService.cancel();
    };
    Object.defineProperty(CollectionComponent.prototype, "filterLanguage", {
        get: function () {
            return this.languageService.filterLanguage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionComponent.prototype, "vocabulary", {
        get: function () {
            return this.conceptViewModel.vocabulary;
        },
        enumerable: true,
        configurable: true
    });
    return CollectionComponent;
}());
export { CollectionComponent };
