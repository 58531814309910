    
    <ng-container>
      <ng-container *ngFor="let child of node.children" [ngSwitch]="child.type">
      
        <p *ngSwitchCase="'paragraph'" app-semantic-text-plain-element [node]="child" [ngClass] = '{"invalid-data": invalidData}'></p>
        <u *ngSwitchCase="'link'">{{child.text}}</u>
        <span *ngSwitchCase="'text'">{{child.text}}</span>
      
      </ng-container>
    </ng-container>
  