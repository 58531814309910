/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./select-concept-reference-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/yti-common-ui/components/dropdown.component.ngfactory";
import * as i4 from "yti-common-ui/components/dropdown.component";
import * as i5 from "@angular/forms";
import * as i6 from "./select-concept-reference-modal.component";
import * as i7 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i8 from "../../services/language.service";
var styles_SelectConceptReferenceModalComponent = [i0.styles];
var RenderType_SelectConceptReferenceModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectConceptReferenceModalComponent, data: {} });
export { RenderType_SelectConceptReferenceModalComponent as RenderType_SelectConceptReferenceModalComponent };
export function View_SelectConceptReferenceModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "select_concept_cancel_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(5, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Choose reference type"])), (_l()(), i1.ɵeld(7, 0, null, null, 15, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 13, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "p", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(11, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Selected concept is not yet formally referenced. Choose the reference type."])), (_l()(), i1.ɵeld(13, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "label", [["for", "status"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(15, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Reference type"])), (_l()(), i1.ɵeld(17, 0, null, null, 5, "app-dropdown", [["id", "select_concept_reference_dropdown"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.selection = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_DropdownComponent_0, i3.RenderType_DropdownComponent)), i1.ɵdid(18, 573440, null, 0, i4.DropdownComponent, [], { id: [0, "id"], options: [1, "options"] }, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DropdownComponent]), i1.ɵdid(20, 671744, null, 0, i5.NgModel, [[8, null], [8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i5.NgControl, null, [i5.NgModel]), i1.ɵdid(22, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵeld(23, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "button", [["class", "btn btn-action confirm"], ["id", "select_concept_confirm_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Select reference"])), (_l()(), i1.ɵeld(27, 0, null, null, 2, "button", [["class", "btn btn-link cancel"], ["id", "select_concept_cancel_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(28, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 5, 0, currVal_0); var currVal_1 = ""; _ck(_v, 11, 0, currVal_1); var currVal_2 = ""; _ck(_v, 15, 0, currVal_2); var currVal_10 = "select_concept_reference_dropdown"; var currVal_11 = _co.referenceOptions; _ck(_v, 18, 0, currVal_10, currVal_11); var currVal_12 = _co.selection; _ck(_v, 20, 0, currVal_12); var currVal_13 = ""; _ck(_v, 25, 0, currVal_13); var currVal_14 = ""; _ck(_v, 28, 0, currVal_14); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 22).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 22).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 22).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 22).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 22).ngClassValid; var currVal_8 = i1.ɵnov(_v, 22).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 22).ngClassPending; _ck(_v, 17, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }); }
export function View_SelectConceptReferenceModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-select-concept-reference-modal", [], null, null, null, View_SelectConceptReferenceModalComponent_0, RenderType_SelectConceptReferenceModalComponent)), i1.ɵdid(1, 114688, null, 0, i6.SelectConceptReferenceModalComponent, [i7.NgbActiveModal, i8.LanguageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectConceptReferenceModalComponentNgFactory = i1.ɵccf("app-select-concept-reference-modal", i6.SelectConceptReferenceModalComponent, View_SelectConceptReferenceModalComponent_Host_0, { formNode: "formNode" }, {}, []);
export { SelectConceptReferenceModalComponentNgFactory as SelectConceptReferenceModalComponentNgFactory };
