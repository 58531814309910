import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { assertNever } from 'yti-common-ui/utils/object';
import { allMatching, anyMatching, firstMatching, flatten, normalizeAsArray, moveElement } from 'yti-common-ui/utils/array';
import { Property } from 'app/entities/node';
import { validateMeta } from 'app/utils/validator';
import { requiredList, validateLanguage } from 'yti-common-ui/utils/validator';
import { comparingPrimitive } from 'yti-common-ui/utils/comparator';
import { removeMatchingLinks } from 'app/utils/semantic';
var FormNode = /** @class */ (function () {
    function FormNode(node, languagesProvider, metaModel) {
        this.node = node;
        this.languagesProvider = languagesProvider;
        this.control = new FormGroup({});
        this.fields = [];
        var createFormReference = function (name, reference) {
            if (reference.term) {
                return new FormReferenceTerm(reference, languagesProvider, metaModel);
            }
            else {
                return new FormReferenceLiteral(reference);
            }
        };
        var createFormProperty = function (property) {
            switch (property.meta.type.type) {
                case 'localizable':
                    var fixed = node.type === 'Term' && property.meta.id === 'prefLabel';
                    return new FormPropertyLocalizable(property, languagesProvider, fixed);
                case 'string':
                    switch (property.meta.type.cardinality) {
                        case 'single':
                            return new FormPropertyLiteral(property);
                        case 'multiple':
                            return new FormPropertyLiteralList(property);
                        default:
                            return assertNever(property.meta.type.cardinality);
                    }
                default:
                    return assertNever(property.meta.type);
            }
        };
        var fields = node.getAllProperties().concat(node.getAllReferences());
        fields.sort(comparingPrimitive(function (f) { return f.meta.index; })
            .andThen(comparingPrimitive(function (f) { return f instanceof Property; })));
        for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
            var field = fields_1[_i];
            var name_1 = field.meta.id;
            if (field instanceof Property) {
                var property = createFormProperty(field);
                this.control.addControl('property-' + name_1, property.control);
                this.fields.push({ value: property, name: name_1 });
            }
            else {
                var reference = createFormReference(name_1, field);
                this.control.addControl('reference-' + name_1, reference.control);
                this.fields.push({ value: reference, name: name_1 });
            }
        }
    }
    Object.defineProperty(FormNode.prototype, "properties", {
        get: function () {
            return this.fields
                .filter(function (f) { return f.value.fieldType === 'property'; })
                .map(function (f) { return f; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormNode.prototype, "references", {
        get: function () {
            return this.fields
                .filter(function (f) { return f.value.fieldType === 'reference'; })
                .map(function (f) { return f; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormNode.prototype, "prefLabelProperty", {
        get: function () {
            var property = firstMatching(this.properties, function (child) { return child.name === 'prefLabel'; });
            if (!property) {
                throw new Error('prefLabel not found in properties');
            }
            if (!(property.value instanceof FormPropertyLocalizable)) {
                throw new Error('prefLabel is not localizable');
            }
            return property.value.value;
        },
        enumerable: true,
        configurable: true
    });
    // TODO refactor term component to not use this method
    FormNode.prototype.hasStatus = function () {
        return this.node.hasStatus();
    };
    Object.defineProperty(FormNode.prototype, "status", {
        // TODO refactor term component to not use this method
        get: function () {
            if (!this.node.hasStatus()) {
                throw new Error('Node does not have status');
            }
            return this.node.status;
        },
        enumerable: true,
        configurable: true
    });
    FormNode.prototype.hasConceptReference = function (conceptId) {
        return anyMatching(this.referencedConcepts, function (concept) { return concept.id === conceptId; });
    };
    FormNode.prototype.hasRelatedConcepts = function () {
        return anyMatching(this.references, function (child) { return child.name === 'related'; });
    };
    Object.defineProperty(FormNode.prototype, "relatedConcepts", {
        get: function () {
            return firstMatching(this.references, function (child) { return child.name === 'related'; }).value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormNode.prototype, "referencedConcepts", {
        get: function () {
            return flatten(this.references
                .filter(function (ref) { return ref.value.targetType === 'Concept'; })
                .map(function (ref) { return ref.value.value; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormNode.prototype, "semanticProperties", {
        get: function () {
            return this.properties
                .map(function (p) { return p.value; })
                .filter(function (p) { return p.editor.type === 'semantic'; });
        },
        enumerable: true,
        configurable: true
    });
    FormNode.prototype.removeSemanticReferencesTo = function (concept, namespaceRoot) {
        for (var _i = 0, _a = this.semanticProperties; _i < _a.length; _i++) {
            var property = _a[_i];
            property.removeSemanticReferencesTo(concept, namespaceRoot);
        }
    };
    Object.defineProperty(FormNode.prototype, "hasNonEmptyPrefLabel", {
        get: function () {
            var property = firstMatching(this.properties, function (p) { return p.name === 'prefLabel'; });
            return !!property && !property.value.valueEmpty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormNode.prototype, "value", {
        get: function () {
            var result = this.node.clone();
            this.assignChanges(result);
            return result;
        },
        enumerable: true,
        configurable: true
    });
    FormNode.prototype.assignChanges = function (node) {
        for (var _i = 0, _a = this.properties; _i < _a.length; _i++) {
            var _b = _a[_i], name_2 = _b.name, value = _b.value;
            value.assignChanges(node.getProperty(name_2));
        }
        for (var _c = 0, _d = this.references; _c < _d.length; _c++) {
            var _e = _d[_c], name_3 = _e.name, value = _e.value;
            value.assignChanges(node.getReference(name_3));
        }
    };
    return FormNode;
}());
export { FormNode };
var FormReferenceLiteral = /** @class */ (function () {
    function FormReferenceLiteral(reference) {
        this.fieldType = 'reference';
        this.type = 'literal';
        this.meta = reference.meta;
        this.control = new FormControl(reference.values, this.required ? [requiredList] : []);
        this.targetMeta = reference.targetMeta;
    }
    Object.defineProperty(FormReferenceLiteral.prototype, "sortableValues", {
        get: function () {
            return this.value;
        },
        set: function (values) {
            this.control.setValue(values);
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceLiteral.prototype.moveItem = function (fromIndex, toIndex) {
        var copy = this.value.slice();
        moveElement(copy, fromIndex, toIndex);
        this.control.setValue(copy);
    };
    Object.defineProperty(FormReferenceLiteral.prototype, "label", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "description", {
        get: function () {
            return this.meta.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "required", {
        get: function () {
            return this.meta.required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "referenceType", {
        get: function () {
            return this.meta.referenceType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "targetType", {
        get: function () {
            return this.meta.targetType;
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceLiteral.prototype.addReference = function (target) {
        this.control.setValue(this.value.concat([target]));
    };
    FormReferenceLiteral.prototype.removeReference = function (target) {
        this.control.setValue(this.value.filter(function (v) { return v !== target; }));
    };
    Object.defineProperty(FormReferenceLiteral.prototype, "graphId", {
        get: function () {
            return this.meta.graphId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "value", {
        get: function () {
            return normalizeAsArray(this.control.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "targetGraph", {
        get: function () {
            return this.targetMeta.graphId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "term", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceLiteral.prototype, "valueEmpty", {
        get: function () {
            return this.value.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceLiteral.prototype.assignChanges = function (reference) {
        reference.values = this.value;
    };
    FormReferenceLiteral.prototype.hasContentForLanguage = function (language) {
        return !this.valueEmpty;
    };
    return FormReferenceLiteral;
}());
export { FormReferenceLiteral };
var FormReferenceTerm = /** @class */ (function () {
    function FormReferenceTerm(reference, languagesProvider, metaModel) {
        this.languagesProvider = languagesProvider;
        this.metaModel = metaModel;
        this.fieldType = 'reference';
        this.type = 'term';
        this.meta = reference.meta;
        this.targetMeta = reference.targetMeta;
        this.children = reference.values
            .filter(function (term) { return term.isValid(); })
            .map(function (term) { return ({
            formNode: new FormNode(term, languagesProvider, metaModel),
            language: term.language,
            id: term.id,
            idIdentifier: term.idIdentifier
        }); });
        var childControls = this.children.map(function (c) { return c.formNode.control; });
        this.control = new FormArray(childControls, this.required ? requiredList : null);
    }
    Object.defineProperty(FormReferenceTerm.prototype, "sortableValues", {
        get: function () {
            return this.children;
        },
        set: function (values) {
            var _this = this;
            values.forEach(function (c, i) { return _this.control.setControl(i, c.formNode.control); });
            this.children = values;
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceTerm.prototype.moveItem = function (fromIndex, toIndex) {
        var copy = this.children.slice();
        moveElement(copy, fromIndex, toIndex);
        this.sortableValues = copy;
    };
    Object.defineProperty(FormReferenceTerm.prototype, "addedLanguages", {
        get: function () {
            return Array.from(new Set(this.children.map(function (c) { return c.language; })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "label", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "description", {
        get: function () {
            return this.meta.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "referenceType", {
        get: function () {
            return this.meta.referenceType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "targetType", {
        get: function () {
            return this.meta.targetType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "required", {
        get: function () {
            return this.meta.required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "cardinality", {
        get: function () {
            return this.meta.cardinality;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "graphId", {
        get: function () {
            return this.meta.graphId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "value", {
        get: function () {
            return this.children.map(function (child) { return child.formNode.value; });
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceTerm.prototype.addTerm = function (metaModel, language) {
        var newTerm = this.metaModel.createEmptyTerm(this.graphId, language);
        var newChild = {
            formNode: new FormNode(newTerm, this.languagesProvider, this.metaModel),
            language: language,
            id: newTerm.id,
            idIdentifier: newTerm.idIdentifier
        };
        this.children.push(newChild);
        this.control.push(newChild.formNode.control);
    };
    FormReferenceTerm.prototype.remove = function (child) {
        var index = this.children.indexOf(child);
        this.children.splice(index, 1);
        this.control.removeAt(index);
    };
    Object.defineProperty(FormReferenceTerm.prototype, "term", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormReferenceTerm.prototype, "valueEmpty", {
        get: function () {
            return this.value.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    FormReferenceTerm.prototype.assignChanges = function (reference) {
        reference.values = this.value;
    };
    FormReferenceTerm.prototype.hasContentForLanguage = function (language) {
        var isNotEmpty = function (value) { return value.term; };
        return anyMatching(this.value, function (v) { return v.language === language && isNotEmpty(v); });
    };
    return FormReferenceTerm;
}());
export { FormReferenceTerm };
var FormPropertyLiteral = /** @class */ (function () {
    function FormPropertyLiteral(property) {
        this.fieldType = 'property';
        this.type = 'literal';
        this.meta = property.meta;
        this.control = this.createControl(property.literalValue);
    }
    Object.defineProperty(FormPropertyLiteral.prototype, "required", {
        get: function () {
            return this.meta.type.required;
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLiteral.prototype.createControl = function (initial) {
        var _this = this;
        var validators = [function (control) { return validateMeta(control, _this.meta); }];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (this.editor.type === 'language') {
            validators.push(validateLanguage);
        }
        return new FormControl(initial, validators);
    };
    Object.defineProperty(FormPropertyLiteral.prototype, "label", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteral.prototype, "description", {
        get: function () {
            return this.meta.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteral.prototype, "editor", {
        get: function () {
            return this.meta.type.editor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteral.prototype, "value", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteral.prototype, "multiColumn", {
        get: function () {
            return this.meta.multiColumn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteral.prototype, "valueEmpty", {
        get: function () {
            return this.value.trim() === '';
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLiteral.prototype.removeSemanticReferencesTo = function (concept, namespaceRoot) {
        if (this.editor.type === 'semantic') {
            var shouldRemoveDestination = function (dest) { return concept.isTargetOfLink(dest); };
            this.control.setValue(removeMatchingLinks(this.value, this.editor.format, shouldRemoveDestination, namespaceRoot));
        }
    };
    FormPropertyLiteral.prototype.assignChanges = function (property) {
        var regex = this.meta.regex;
        property.attributes = [{ lang: '', value: this.value, regex: regex }];
    };
    FormPropertyLiteral.prototype.hasContentForLanguage = function (language) {
        return !this.valueEmpty;
    };
    return FormPropertyLiteral;
}());
export { FormPropertyLiteral };
var FormPropertyLiteralList = /** @class */ (function () {
    function FormPropertyLiteralList(property) {
        var _this = this;
        this.fieldType = 'property';
        this.type = 'literal-list';
        this.meta = property.meta;
        var children = property.attributes.map(function (a) { return a.value; }).map(function (value) { return _this.createChildControl(value); });
        this.control = new FormArray(children, this.required ? requiredList : null);
    }
    FormPropertyLiteralList.prototype.createChildControl = function (initial) {
        var _this = this;
        var validators = [function (control) { return validateMeta(control, _this.meta); }];
        if (this.required) {
            validators.push(Validators.required);
        }
        if (this.editor.type === 'language') {
            validators.push(validateLanguage);
        }
        return new FormControl(initial, validators);
    };
    Object.defineProperty(FormPropertyLiteralList.prototype, "sortableValues", {
        get: function () {
            return this.children;
        },
        set: function (values) {
            var _this = this;
            values.forEach(function (c, i) { return _this.control.setControl(i, c); });
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLiteralList.prototype.moveItem = function (fromIndex, toIndex) {
        var copy = this.control.controls.slice();
        moveElement(copy, fromIndex, toIndex);
        this.sortableValues = copy;
    };
    Object.defineProperty(FormPropertyLiteralList.prototype, "children", {
        get: function () {
            return this.control.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "label", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "description", {
        get: function () {
            return this.meta.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "required", {
        get: function () {
            return this.meta.type.required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "editor", {
        get: function () {
            return this.meta.type.editor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "value", {
        get: function () {
            return this.children.map(function (control) { return control.value; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "valueAsString", {
        get: function () {
            return this.value.join(',');
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLiteralList.prototype.append = function (initial) {
        var control = this.createChildControl(initial);
        this.control.push(control);
    };
    FormPropertyLiteralList.prototype.remove = function (child) {
        this.control.removeAt(this.children.indexOf(child));
    };
    FormPropertyLiteralList.prototype.removeSemanticReferencesTo = function (concept, namespaceRoot) {
        if (this.editor.type === 'semantic') {
            var shouldRemoveDestination = function (dest) { return concept.isTargetOfLink(dest); };
            for (var _i = 0, _a = this.children; _i < _a.length; _i++) {
                var child = _a[_i];
                child.setValue(removeMatchingLinks(child.value, this.editor.format, shouldRemoveDestination, namespaceRoot));
            }
        }
    };
    Object.defineProperty(FormPropertyLiteralList.prototype, "multiColumn", {
        get: function () {
            return this.meta.multiColumn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLiteralList.prototype, "valueEmpty", {
        get: function () {
            return allMatching(this.value, function (v) { return v.trim() === ''; });
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLiteralList.prototype.assignChanges = function (property) {
        var regex = this.meta.regex;
        property.attributes = this.value.map(function (value) { return ({ lang: '', value: value, regex: regex }); });
    };
    FormPropertyLiteralList.prototype.hasContentForLanguage = function (language) {
        return !this.valueEmpty;
    };
    return FormPropertyLiteralList;
}());
export { FormPropertyLiteralList };
var FormPropertyLocalizable = /** @class */ (function () {
    function FormPropertyLocalizable(property, languagesProvider, fixed) {
        var _this = this;
        this.languagesProvider = languagesProvider;
        this.fixed = fixed;
        this.fieldType = 'property';
        this.type = 'localizable';
        this.meta = property.meta;
        this.children = property.attributes.map(function (attribute) { return ({
            lang: attribute.lang,
            control: _this.createChildControl(attribute.value)
        }); });
        var childControls = this.children.map(function (c) { return c.control; });
        this.control = new FormArray(childControls, this.required ? requiredList : null);
    }
    Object.defineProperty(FormPropertyLocalizable.prototype, "sortableValues", {
        get: function () {
            return this.children;
        },
        set: function (values) {
            var _this = this;
            values.forEach(function (c, i) { return _this.control.setControl(i, c.control); });
            this.children = values;
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLocalizable.prototype.moveItem = function (fromIndex, toIndex) {
        var copy = this.children.slice();
        moveElement(copy, fromIndex, toIndex);
        this.sortableValues = copy;
    };
    Object.defineProperty(FormPropertyLocalizable.prototype, "languages", {
        get: function () {
            return this.languagesProvider();
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLocalizable.prototype.createChildControl = function (initial) {
        var _this = this;
        var validators = [function (control) { return validateMeta(control, _this.meta); }];
        if (this.required) {
            validators.push(Validators.required);
        }
        return new FormControl(initial, validators);
    };
    Object.defineProperty(FormPropertyLocalizable.prototype, "addedLanguages", {
        get: function () {
            return Array.from(new Set(this.value.map(function (v) { return v.lang; })));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "label", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "description", {
        get: function () {
            return this.meta.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "required", {
        get: function () {
            return this.meta.type.required;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "editor", {
        get: function () {
            return this.meta.type.editor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "cardinality", {
        get: function () {
            return this.meta.type.cardinality;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "value", {
        get: function () {
            return this.children.map(function (_a) {
                var lang = _a.lang, control = _a.control;
                return ({ lang: lang, value: control.value });
            });
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLocalizable.prototype.append = function (lang, initial) {
        var control = this.createChildControl(initial);
        this.children.push({ lang: lang, control: control });
        this.control.push(control);
    };
    FormPropertyLocalizable.prototype.remove = function (child) {
        var index = this.children.indexOf(child);
        this.children.splice(index, 1);
        this.control.removeAt(index);
    };
    FormPropertyLocalizable.prototype.removeSemanticReferencesTo = function (concept, namespaceRoot) {
        if (this.editor.type === 'semantic') {
            var shouldRemoveDestination = function (destination) { return concept.isTargetOfLink(destination); };
            for (var _i = 0, _a = this.children; _i < _a.length; _i++) {
                var child = _a[_i];
                child.control.setValue(removeMatchingLinks(child.control.value, this.editor.format, shouldRemoveDestination, namespaceRoot));
            }
        }
    };
    Object.defineProperty(FormPropertyLocalizable.prototype, "multiColumn", {
        get: function () {
            return this.meta.multiColumn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FormPropertyLocalizable.prototype, "valueEmpty", {
        get: function () {
            return allMatching(this.value, function (v) { return v.value.trim() === ''; });
        },
        enumerable: true,
        configurable: true
    });
    FormPropertyLocalizable.prototype.assignChanges = function (property) {
        var regex = this.meta.regex;
        property.attributes = this.value.map(function (localization) { return ({ lang: localization.lang, value: localization.value, regex: regex }); });
    };
    FormPropertyLocalizable.prototype.hasContentForLanguage = function (language) {
        var isNotEmpty = function (value) { return value.trim() !== ''; };
        return anyMatching(this.value, function (v) { return v.lang === language && isNotEmpty(v.value); });
    };
    return FormPropertyLocalizable;
}());
export { FormPropertyLocalizable };
