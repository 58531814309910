var DomPoint = /** @class */ (function () {
    function DomPoint(node, offset, path) {
        this.node = node;
        this.offset = offset;
        this.path = path;
    }
    DomPoint.create = function (root, node, offset) {
        var path = DomPath.create(root, node);
        if (!path) {
            return null;
        }
        return new DomPoint(node, offset, path);
    };
    return DomPoint;
}());
export { DomPoint };
var DomSelection = /** @class */ (function () {
    function DomSelection(anchor, focus) {
        function compareDomPoints(a, b) {
            if (a.node === b.node) {
                return a.offset === b.offset ? 0 : a.offset > b.offset ? 1 : -1;
            }
            else {
                var maxLength = Math.max(a.path.length, b.path.length);
                for (var i = 0; i < maxLength; i++) {
                    if (i >= a.path.length) {
                        return 1;
                    }
                    else if (i >= b.path.length) {
                        return -1;
                    }
                    else {
                        var lhs = a.path.get(i).index;
                        var rhs = b.path.get(i).index;
                        if (lhs > rhs) {
                            return 1;
                        }
                        else if (lhs < rhs) {
                            return -1;
                        }
                    }
                }
                console.log('A', a.path.toString());
                console.log('B', b.path.toString());
                throw new Error('Algorithm broken');
            }
        }
        var points = [anchor, focus];
        points.sort(function (a, b) { return compareDomPoints(a, b); });
        this.start = points[0];
        this.end = points[1];
    }
    DomSelection.create = function (root) {
        var s = window.getSelection();
        if (s.anchorNode) {
            var anchor = DomPoint.create(root, s.anchorNode, s.anchorOffset);
            var focus_1 = DomPoint.create(root, s.focusNode, s.focusOffset);
            if (anchor && focus_1) {
                return new DomSelection(anchor, focus_1);
            }
        }
        return null;
    };
    return DomSelection;
}());
export { DomSelection };
var DomPath = /** @class */ (function () {
    function DomPath(path) {
        this.path = path;
    }
    Object.defineProperty(DomPath.prototype, "indicesFromRoot", {
        get: function () {
            return this.path.map(function (x) { return x.index; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DomPath.prototype, "length", {
        get: function () {
            return this.path.length;
        },
        enumerable: true,
        configurable: true
    });
    DomPath.create = function (root, node) {
        var path = [];
        var walk = node;
        while (walk !== root) {
            var index = indexInParent(walk);
            if (index === null) {
                return null;
            }
            path.unshift({ node: walk, index: index });
            if (!walk.parentNode) {
                return null;
            }
            walk = walk.parentNode;
        }
        return new DomPath(path);
    };
    DomPath.prototype.get = function (index) {
        return this.path[index];
    };
    DomPath.prototype.toString = function () {
        var result = '';
        var first = true;
        for (var _i = 0, _a = this.path; _i < _a.length; _i++) {
            var p = _a[_i];
            if (!first) {
                result += ' -> ';
            }
            result += p.node.nodeName + '(' + p.index + ')' + (p.node.nodeType === Node.TEXT_NODE ? " [" + p.node.textContent + "]" : '');
            first = false;
        }
        return result;
    };
    return DomPath;
}());
export { DomPath };
export function removeChildren(node) {
    while (node.hasChildNodes()) {
        node.removeChild(node.lastChild);
    }
}
function indexInParent(child) {
    if (!child.parentNode) {
        // TODO check why detached node can actually exist here
        return null;
    }
    var children = child.parentNode.childNodes;
    for (var i = 0; i < children.length; i++) {
        if (children[i] === child) {
            return i;
        }
    }
    throw new Error('Child not found in parent children');
}
export function moveCursor(textNode, offset) {
    if (textNode.nodeType !== Node.TEXT_NODE) {
        console.log(textNode);
        throw new Error('Not a text node');
    }
    var range = document.createRange();
    var selection = window.getSelection();
    range.setStart(textNode, offset);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
}
export var nbsp = '\u00A0';
export function formatTextContent(text) {
    var result = '';
    var spacesStartingPosition = null;
    for (var i = 0; i < text.length; i++) {
        var lookingAt = text.charAt(i);
        if (lookingAt === ' ') {
            if (spacesStartingPosition === null) {
                spacesStartingPosition = i;
            }
        }
        else {
            if (spacesStartingPosition !== null) {
                result += formatSpaces(i - spacesStartingPosition, spacesStartingPosition === 0);
                spacesStartingPosition = null;
            }
            result += lookingAt;
        }
    }
    if (spacesStartingPosition !== null) {
        result += formatSpaces(text.length - spacesStartingPosition, true);
    }
    return result;
}
function formatSpaces(amount, singleAsNbsp) {
    if (amount === 1) {
        return singleAsNbsp ? nbsp : ' ';
    }
    else {
        var result = '';
        for (var j = 1; j < amount; j++) {
            if (j % 2 === 0) {
                result += ' ';
            }
            else {
                result += nbsp;
            }
        }
        result += nbsp;
        return result;
    }
}
export function isInDocument(node) {
    while (node !== null) {
        if (node === document) {
            return true;
        }
        node = node.parentNode;
    }
    return false;
}
export function removeWhiteSpaceNodes(node) {
    var children = node.childNodes;
    var removeChildNodes = [];
    for (var i = 0; i < children.length; i++) {
        var child = children[i];
        switch (child.nodeType) {
            case Node.TEXT_NODE:
                if (!child.nodeValue.trim() && child.nodeValue.indexOf('\n') !== -1) {
                    removeChildNodes.push(child);
                }
                break;
            case Node.ELEMENT_NODE:
                removeWhiteSpaceNodes(child);
                break;
            default:
        }
    }
    for (var _i = 0, removeChildNodes_1 = removeChildNodes; _i < removeChildNodes_1.length; _i++) {
        var childNode = removeChildNodes_1[_i];
        node.removeChild(childNode);
    }
}
