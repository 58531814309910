
    
    <div *ngIf="!editing">
      <ng-container [ngSwitch]="property.editor.type">

        <div *ngSwitchCase="'semantic'"
             app-semantic-text-links
             [format]="property.editor.format"
             [value]="property.value">
        </div>

        <div *ngSwitchCase="'status'">
          {{property.value | translate}}
        </div>
        
        <div class="other-type-value" *ngSwitchDefault>
          {{property.value}}
        </div>

      </ng-container>
    </div>

    <div *ngIf="editing">

      <div class="form-group">
      
        <ng-container [ngSwitch]="property.editor.type">
          
          <input *ngSwitchCase="'input'" 
                 type="text"
                 class="form-control"
                 [ngClass]="{'is-invalid': valueInError()}"
                 [id]="id + '_input'"
                 autocomplete="off"
                 [formControl]="property.control" />
          
          <textarea *ngSwitchCase="'textarea'"
                    class="form-control"
                    [ngClass]="{'is-invalid': valueInError()}"
                    [id]="id + '_textarea'"
                    [formControl]="property.control"></textarea>

          <app-semantic-text-input *ngSwitchCase="'semantic'"
                                   [id]="id + '_semantic_text_input'"
                                   [format]="property.editor.format"
                                   [formControl]="property.control"></app-semantic-text-input>
          
          <app-status-input *ngSwitchCase="'status'"
                            [id]="id"
                            [formControl]="property.control"></app-status-input>

          <app-language-input *ngSwitchCase="'language'"
                              [id]="id + '_input'"
                              [formControl]="property.control"></app-language-input>

          <app-error-messages [id]="id + '_error_messages'" [control]="property.control"></app-error-messages>
          
        </ng-container>
        
      </div>
      
    </div>
  