import { SessionService } from 'app/services/session.service';
var leftWidth = 400; // should match variable in concepts.component.scss
var minSelectionWidth = 300;
var minVisualizationWidth = 361;
var minMaxIconWidth = 52;
var DividerComponent = /** @class */ (function () {
    function DividerComponent(sessionService) {
        this.sessionService = sessionService;
        this.setConstrainedSelectionWidth(this.sessionService.selectionWidth);
    }
    Object.defineProperty(DividerComponent.prototype, "selectionWidth", {
        get: function () {
            return this.sessionService.selectionWidth;
        },
        set: function (value) {
            this.sessionService.selectionWidth = value;
        },
        enumerable: true,
        configurable: true
    });
    DividerComponent.prototype.onResize = function () {
        this.setConstrainedSelectionWidth(this.selectionWidth);
    };
    DividerComponent.prototype.setConstrainedSelectionWidth = function (selectionWidth) {
        var maxSelectionWidth = DividerComponent.maxWidth - minVisualizationWidth;
        this.selectionWidth = Math.max(minSelectionWidth, Math.min(maxSelectionWidth, selectionWidth));
    };
    Object.defineProperty(DividerComponent, "maxWidth", {
        get: function () {
            return document.body.clientWidth - leftWidth - minMaxIconWidth;
        },
        enumerable: true,
        configurable: true
    });
    DividerComponent.prototype.moveDivider = function (mouseDown) {
        var _this = this;
        mouseDown.preventDefault();
        var offset = mouseDown.clientX - this.selectionWidth;
        var onMouseMove = function (event) {
            _this.setConstrainedSelectionWidth(event.clientX - offset);
        };
        var onMouseUp = function () {
            window.removeEventListener('mousemove', onMouseMove);
            window.removeEventListener('mouseup', onMouseUp);
        };
        window.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseup', onMouseUp);
    };
    return DividerComponent;
}());
export { DividerComponent };
