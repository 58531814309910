
    <div class="content-box">
      <app-ajax-loading-indicator *ngIf="!vocabulary"></app-ajax-loading-indicator>

      <div *ngIf="vocabulary">

        <form #form="ngForm" [formGroup]="formNode.control">

          <div class="row">
            <div class="col-12">
              <div class="top-actions">
                <app-editable-buttons [form]="form"
                                      [canRemove]="false"
                                      [idPrefix]="idPrefix"></app-editable-buttons>
              </div>
            </div>
          </div>

          <app-vocabulary-form [vocabulary]="vocabulary" [form]="formNode"></app-vocabulary-form>
          <app-prefix-input [formControl]="prefixFormControl" [idPrefix]="idPrefix"></app-prefix-input>
        </form>

      </div>
    </div>
  