import { AfterViewInit, ElementRef, Renderer } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { debounceTime, map, skip, take, tap } from 'rxjs/operators';
import { TermedService } from 'app/services/termed.service';
import { LanguageService } from 'app/services/language.service';
import { contains } from 'yti-common-ui/utils/array';
import { isDefined } from 'yti-common-ui/utils/object';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
var SearchOrganizationModalComponent = /** @class */ (function () {
    function SearchOrganizationModalComponent(modal, termedService, languageService, renderer) {
        var _this = this;
        this.modal = modal;
        this.languageService = languageService;
        this.renderer = renderer;
        this.search$ = new BehaviorSubject('');
        this.loading = false;
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        this.searchResults$ = combineLatest(termedService.getOrganizationList(), concat(initialSearch, debouncedSearch))
            .pipe(tap(function () { return _this.loading = false; }), map(function (_a) {
            var organizations = _a[0], search = _a[1];
            return organizations.filter(function (organization) {
                var label = languageService.translate(organization.label, true);
                var searchMatches = !search || label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
                var isNotRestricted = !contains(_this.restrictOrganizationIds, organization.id);
                var isAllowed = !isDefined(_this.allowOnlyOrganizationIds) || contains(_this.allowOnlyOrganizationIds, organization.id);
                return searchMatches && isNotRestricted && isAllowed;
            }).sort(comparingLocalizable(_this.languageService, function (org) { return org.label; }));
        }));
    }
    Object.defineProperty(SearchOrganizationModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SearchOrganizationModalComponent.prototype.select = function (organization) {
        this.modal.close(organization);
    };
    SearchOrganizationModalComponent.prototype.ngAfterViewInit = function () {
        this.renderer.invokeElementMethod(this.searchInput.nativeElement, 'focus');
    };
    SearchOrganizationModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    return SearchOrganizationModalComponent;
}());
export { SearchOrganizationModalComponent };
var SearchOrganizationModalService = /** @class */ (function () {
    function SearchOrganizationModalService(modalService) {
        this.modalService = modalService;
    }
    SearchOrganizationModalService.prototype.open = function (restrictOrganizationIds, allowOnlyOrganizationIds) {
        var modalRef = this.modalService.open(SearchOrganizationModalComponent, { size: 'sm' });
        var instance = modalRef.componentInstance;
        instance.restrictOrganizationIds = restrictOrganizationIds;
        instance.allowOnlyOrganizationIds = allowOnlyOrganizationIds;
        return modalRef.result;
    };
    return SearchOrganizationModalService;
}());
export { SearchOrganizationModalService };
