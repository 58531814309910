
    <app-semantic-text-input-link-popover *ngIf="hasLinkableSelection()"
                                          [id]="id"
                                          [selectedText]="linkableSelection.content"
                                          (linkConcept)="linkInternal()"
                                          (linkExternal)="linkExternal()">
    </app-semantic-text-input-link-popover>

    <app-semantic-text-input-unlink-concept-popover *ngIf="hasConceptLinkSelection()"
                                                    [id]="id"
                                                    [concept]="linkedConcept"
                                                    (unlink)="unlink()">
    </app-semantic-text-input-unlink-concept-popover>

    <app-semantic-text-input-unlink-ext-popover *ngIf="hasExternalLinkSelection()"
                                                [id]="id"
                                                [target]="linkedExternalTarget"
                                                (unlink)="unlink()">
    </app-semantic-text-input-unlink-ext-popover>

    <div #editable
         [id]="id"
         contenteditable="true"
         class="form-control"
         [ngClass]='{"invalid-data": invalidData}'
    ></div>
  