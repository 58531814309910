import { VocabularyNode } from 'app/entities/node';
import { EditableService } from 'app/services/editable.service';
import { FormReferenceLiteral } from 'app/services/form-state';
import { SearchOrganizationModalService } from './search-organization-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { AuthorizationManager } from 'app/services/authorization-manager.sevice';
import { LanguageService } from 'app/services/language.service';
var OrganizationInputComponent = /** @class */ (function () {
    function OrganizationInputComponent(editableService, searchOrganizationModal, authorizationManager, languageService) {
        this.editableService = editableService;
        this.searchOrganizationModal = searchOrganizationModal;
        this.authorizationManager = authorizationManager;
        this.languageService = languageService;
    }
    Object.defineProperty(OrganizationInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    OrganizationInputComponent.prototype.removeReference = function (organization) {
        this.reference.removeReference(organization);
    };
    OrganizationInputComponent.prototype.addReference = function () {
        var _this = this;
        var canEditOnlyOrganizations = this.authorizationManager.canEditOrganizationsIds();
        var allowOnlyOrganizationIds = canEditOnlyOrganizations === 'ALL' ? null : canEditOnlyOrganizations;
        var restrictOrganizationIds = this.reference.value.map(function (_a) {
            var id = _a.id;
            return id;
        });
        this.searchOrganizationModal.open(restrictOrganizationIds, allowOnlyOrganizationIds)
            .then(function (result) { return _this.reference.addReference(result); }, ignoreModalClose);
    };
    OrganizationInputComponent.prototype.canReorder = function () {
        return this.editing && this.reference.value.length > 1;
    };
    return OrganizationInputComponent;
}());
export { OrganizationInputComponent };
