/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./semantic-text-plain.component";
import * as i2 from "../../services/configuration.service";
import * as i3 from "./semantic-text-plain.component.scss.shim.ngstyle";
import * as i4 from "@angular/common";
var styles_SemanticTextPlainComponent = [];
var RenderType_SemanticTextPlainComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SemanticTextPlainComponent, data: {} });
export { RenderType_SemanticTextPlainComponent as RenderType_SemanticTextPlainComponent };
export function View_SemanticTextPlainComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { self: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["self", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["app-semantic-text-plain-element", ""]], null, null, null, View_SemanticTextPlainElementComponent_0, RenderType_SemanticTextPlainElementComponent)), i0.ɵdid(3, 49152, null, 0, i1.SemanticTextPlainElementComponent, [], { node: [0, "node"], invalidData: [1, "invalidData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document; var currVal_1 = _co.invalidData; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SemanticTextPlainComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-semantic-text-plain", ""]], null, null, null, View_SemanticTextPlainComponent_0, RenderType_SemanticTextPlainComponent)), i0.ɵdid(1, 8962048, null, 0, i1.SemanticTextPlainComponent, [i2.ConfigurationService], null, null)], null, null); }
var SemanticTextPlainComponentNgFactory = i0.ɵccf("[app-semantic-text-plain]", i1.SemanticTextPlainComponent, View_SemanticTextPlainComponent_Host_0, { value: "value", format: "format" }, {}, []);
export { SemanticTextPlainComponentNgFactory as SemanticTextPlainComponentNgFactory };
var styles_SemanticTextPlainElementComponent = [i3.styles];
var RenderType_SemanticTextPlainElementComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextPlainElementComponent, data: {} });
export { RenderType_SemanticTextPlainElementComponent as RenderType_SemanticTextPlainElementComponent };
function View_SemanticTextPlainElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["app-semantic-text-plain-element", ""]], null, null, null, View_SemanticTextPlainElementComponent_0, RenderType_SemanticTextPlainElementComponent)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "invalid-data": 0 }), i0.ɵdid(3, 49152, null, 0, i1.SemanticTextPlainElementComponent, [], { node: [0, "node"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.invalidData); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SemanticTextPlainElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "u", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_SemanticTextPlainElementComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_SemanticTextPlainElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextPlainElementComponent_2)), i0.ɵdid(3, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextPlainElementComponent_3)), i0.ɵdid(5, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextPlainElementComponent_4)), i0.ɵdid(7, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "paragraph"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "link"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "text"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_SemanticTextPlainElementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextPlainElementComponent_1)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.children; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SemanticTextPlainElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-semantic-text-plain-element", ""]], null, null, null, View_SemanticTextPlainElementComponent_0, RenderType_SemanticTextPlainElementComponent)), i0.ɵdid(1, 49152, null, 0, i1.SemanticTextPlainElementComponent, [], null, null)], null, null); }
var SemanticTextPlainElementComponentNgFactory = i0.ɵccf("[app-semantic-text-plain-element]", i1.SemanticTextPlainElementComponent, View_SemanticTextPlainElementComponent_Host_0, { node: "node", invalidData: "invalidData" }, {}, []);
export { SemanticTextPlainElementComponentNgFactory as SemanticTextPlainElementComponentNgFactory };
