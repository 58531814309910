import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ConceptViewModelService } from '../../services/concept.view.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { ConceptsComponent } from '../concept/concepts.component';
import { VocabularyComponent } from './vocabulary.component';
import { ConfirmationModalService } from 'yti-common-ui/components/confirmation-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { LanguageService } from '../../services/language.service';
import { requireDefined } from 'yti-common-ui/utils/object';
import { ImportVocabularyModalService } from './import-vocabulary-modal.component';
import { AuthorizationManager } from '../../services/authorization-manager.sevice';
import { MessagingService } from '../../services/messaging-service';
import { ErrorModalService } from 'yti-common-ui/components/error-modal.component';
import { ConfigurationService } from '../../services/configuration.service';
import { UserService } from 'yti-common-ui/services/user.service';
var VocabularyMainComponent = /** @class */ (function () {
    function VocabularyMainComponent(route, router, location, viewModel, confirmationModalService, importVocabularyModal, authorizationManager, languageService, messagingService, errorModalService, configurationService, userService) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.location = location;
        this.viewModel = viewModel;
        this.confirmationModalService = confirmationModalService;
        this.importVocabularyModal = importVocabularyModal;
        this.authorizationManager = authorizationManager;
        this.languageService = languageService;
        this.messagingService = messagingService;
        this.errorModalService = errorModalService;
        this.configurationService = configurationService;
        this.userService = userService;
        this.subscriptions = [];
        this.hasSubscription = undefined;
        this.terminologyUriRegexp = /^(https?:\/\/[^\/]+\/terminology\/[^\/]+\/)(?:[^\/]+\/?)?$/;
        this.subscriptions.push(this.router.events.subscribe(function (event) {
            if (_this.tabs && _this.tabs.activeId !== 'conceptsTab' && event instanceof NavigationEnd) {
                // NOTE: Currently all routes lead to conceptsTab
                _this.tabs.select('conceptsTab');
            }
        }));
        this.subscriptions.push(this.route.params.subscribe(function (params) {
            var conceptQuery = _this.route.snapshot.queryParams['q'];
            _this.graphId = params['graphId'];
            _this.viewModel.initializeVocabulary(_this.graphId, (conceptQuery && typeof conceptQuery === 'string') ? conceptQuery : undefined);
        }));
    }
    Object.defineProperty(VocabularyMainComponent.prototype, "vocabulary", {
        get: function () {
            return this.viewModel.vocabulary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "filterLanguage", {
        get: function () {
            return this.languageService.filterLanguage;
        },
        set: function (lang) {
            this.languageService.filterLanguage = lang;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "filterLanguages", {
        get: function () {
            return this.viewModel.languages;
        },
        enumerable: true,
        configurable: true
    });
    VocabularyMainComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(VocabularyMainComponent.prototype, "showMenu", {
        get: function () {
            return this.canSubscribe || this.canImport || this.canExport;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "canSubscribe", {
        get: function () {
            return this.configurationService.isMessagingEnabled && this.userService.isLoggedIn();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "canImport", {
        get: function () {
            if (!this.vocabulary) {
                return false;
            }
            return !this.isEditing() && this.authorizationManager.canEdit(this.vocabulary);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "canExport", {
        get: function () {
            if (!this.vocabulary) {
                return false;
            }
            return !this.isEditing();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "canAddSubscription", {
        get: function () {
            if (this.vocabulary && this.hasSubscription !== undefined) {
                return this.canSubscribe && !this.hasSubscription;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyMainComponent.prototype, "canRemoveSubscription", {
        get: function () {
            if (this.vocabulary && this.hasSubscription !== undefined) {
                return this.canSubscribe && this.hasSubscription;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    VocabularyMainComponent.prototype.isEditing = function () {
        return (this.conceptsComponent && this.conceptsComponent.isEditing()) || (this.terminologyComponent && this.terminologyComponent.isEditing());
    };
    VocabularyMainComponent.prototype.selectFile = function () {
        var _this = this;
        this.importVocabularyModal.open(requireDefined(this.vocabulary))
            .then(function () { return _this.viewModel.refreshConcepts(); }, ignoreModalClose);
    };
    VocabularyMainComponent.prototype.onTabChange = function (event) {
        var _this = this;
        if ((this.terminologyComponent && this.terminologyComponent.isEditing()) ||
            (this.conceptsComponent && this.conceptsComponent.isEditing())) {
            event.preventDefault();
            this.confirmationModalService.openEditInProgress().then(function () {
                (_this.conceptsComponent || _this.terminologyComponent).cancelEditing();
                _this.tabs.select(event.nextId);
            }, ignoreModalClose);
        }
    };
    VocabularyMainComponent.prototype.addSubscription = function () {
        var _this = this;
        if (this.vocabulary && this.vocabulary.uri) {
            var vocabularyUri_1 = this.getShortUri(this.vocabulary.uri);
            this.confirmationModalService.open('ADD EMAIL SUBSCRIPTION TO RESOURCE REGARDING CHANGES?', undefined, '')
                .then(function () {
                _this.messagingService.addSubscription(vocabularyUri_1, 'terminology').subscribe(function (success) {
                    if (success) {
                        _this.hasSubscription = true;
                    }
                    else {
                        _this.hasSubscription = false;
                        _this.errorModalService.open('Submit error', 'Adding subscription failed.', null);
                    }
                });
            }, ignoreModalClose);
        }
    };
    VocabularyMainComponent.prototype.removeSubscription = function () {
        var _this = this;
        if (this.vocabulary && this.vocabulary.uri) {
            var vocabularyUri_2 = this.getShortUri(this.vocabulary.uri);
            this.confirmationModalService.open('REMOVE EMAIL SUBSCRIPTION TO RESOURCE?', undefined, '')
                .then(function () {
                _this.messagingService.deleteSubscription(vocabularyUri_2).subscribe(function (success) {
                    if (success) {
                        _this.hasSubscription = false;
                    }
                    else {
                        _this.hasSubscription = true;
                        _this.errorModalService.open('Submit error', 'Subscription deletion failed.', null);
                    }
                });
            }, ignoreModalClose);
        }
    };
    Object.defineProperty(VocabularyMainComponent.prototype, "checkSubscription", {
        get: function () {
            if (this.hasSubscription === undefined) {
                if (!this.refreshingSubscription) {
                    this.refreshSubscription();
                }
                return false;
            }
            else {
                return this.hasSubscription;
            }
        },
        enumerable: true,
        configurable: true
    });
    VocabularyMainComponent.prototype.refreshSubscription = function () {
        var _this = this;
        console.log('refreshSubscription');
        this.refreshingSubscription = true;
        if (this.vocabulary && this.vocabulary.uri) {
            var vocabularyUri = this.getShortUri(this.vocabulary.uri);
            if (this.configurationService.isMessagingEnabled && this.userService.isLoggedIn()) {
                this.messagingService.getSubscription(vocabularyUri).subscribe(function (resource) {
                    if (resource) {
                        _this.hasSubscription = true;
                    }
                    else {
                        _this.hasSubscription = false;
                    }
                    _this.refreshingSubscription = false;
                });
            }
        }
    };
    VocabularyMainComponent.prototype.getShortUri = function (uri) {
        var match = this.terminologyUriRegexp.exec(uri);
        if (match) {
            return match[1];
        }
        console.error('Invalid terminology uri: ' + uri);
        return uri;
    };
    return VocabularyMainComponent;
}());
export { VocabularyMainComponent };
