
    <ng-container>
      <ng-container *ngFor="let child of node.children" [ngSwitch]="child.type">

        <p *ngSwitchCase="'paragraph'"
           app-semantic-text-links-element
           [ngClass] = '{"invalid-data": invalidData}'
           [node]="child"
           [format]="format"
           [relatedConcepts]="relatedConcepts"></p>

        <ng-container *ngSwitchCase="'link'">
          <a *ngIf="child.category === 'internal'"
             [class]="child.category"
             [routerLink]="link(child)"
             [popoverTitle]="conceptLabel(child) | translateValue"
             [ngbPopover]="popContent"
             triggers="mouseenter:mouseleave">{{child.text}}</a>
          <a *ngIf="child.category === 'external'"
             target="_blank"
             rel="noopener noreferrer"
             [class]="child.category"
             [href]="child.destination"
             [popoverTitle]="'External link' | translate"
             [ngbPopover]="popContentExt"
             triggers="mouseenter:mouseleave">{{child.text}}<i class="fas fa-external-link-alt"></i></a>
        </ng-container>

        <span *ngSwitchCase="'text'">{{child.text}}</span>

        <ng-template #popContent>
          <div app-semantic-text-plain
               [value]="conceptDefinition(child) | translateValue"
               [format]="format"></div>
        </ng-template>

        <ng-template #popContentExt>
          <div>{{child.destination}}</div>
        </ng-template>
      </ng-container>
    </ng-container>
  