/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-network.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./concept-network.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../services/language.service";
import * as i6 from "../../services/termed.service";
import * as i7 from "../../services/meta-model.service";
import * as i8 from "@angular/router";
import * as i9 from "../../services/concept.view.service";
import * as i10 from "../../services/configuration.service";
var styles_ConceptNetworkComponent = [i0.styles];
var RenderType_ConceptNetworkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConceptNetworkComponent, data: {} });
export { RenderType_ConceptNetworkComponent as RenderType_ConceptNetworkComponent };
function View_ConceptNetworkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link btn-lg maximize"], ["id", "maximize_concept_network_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.maximized = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-window-maximize"]], null, null, null, null, null))], null, null); }
function View_ConceptNetworkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-link btn-lg minimize"], ["id", "minimize_concept_network_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.maximized = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fa fa-window-minimize"]], null, null, null, null, null))], null, null); }
export function View_ConceptNetworkComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { networkCanvasRef: 0 }), i1.ɵqud(402653184, 2, { legendCanvasRef: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "component"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "canvas-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["networkCanvas", 1]], null, 0, "div", [["class", "network-canvas"], ["id", "concept_canvas_container"]], null, [[null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseleave" === en)) {
        var pd_0 = (_co.hidePopup() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, [[2, 0], ["legendCanvas", 1]], null, 0, "canvas", [["class", "legend"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptNetworkComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptNetworkComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.maximized; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.maximized; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ConceptNetworkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-network", [], [[2, "maximized", null]], null, null, View_ConceptNetworkComponent_0, RenderType_ConceptNetworkComponent)), i1.ɵdid(1, 245760, null, 0, i3.ConceptNetworkComponent, [i1.NgZone, i4.TranslateService, i5.LanguageService, i6.TermedService, i7.MetaModelService, i8.Router, i1.Renderer2, i9.ConceptViewModelService, i10.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).maximized; _ck(_v, 0, 0, currVal_0); }); }
var ConceptNetworkComponentNgFactory = i1.ɵccf("app-concept-network", i3.ConceptNetworkComponent, View_ConceptNetworkComponent_Host_0, {}, {}, []);
export { ConceptNetworkComponentNgFactory as ConceptNetworkComponentNgFactory };
