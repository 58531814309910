import { OnInit, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { LanguageService } from 'app/services/language.service';
import { TranslateService } from '@ngx-translate/core';
var OrganizationFilterDropdownComponent = /** @class */ (function () {
    function OrganizationFilterDropdownComponent(languageService, translateService) {
        this.languageService = languageService;
        this.translateService = translateService;
        this.subscriptionToClean = [];
    }
    OrganizationFilterDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptionToClean.push(combineLatest(this.organizations, this.languageService.language$)
            .subscribe(function (_a) {
            var orgs = _a[0];
            orgs.sort(comparingLocalizable(_this.languageService, function (org) { return org.label; }));
            _this.organizationOptions = [
                {
                    value: null,
                    name: function () { return _this.translateService.instant('All organizations'); },
                    idIdentifier: function () { return 'all_selected'; }
                }
            ].concat(orgs.map(function (org) { return ({
                value: org,
                name: function () { return _this.languageService.translate(org.label, true); },
                idIdentifier: function () { return org.getIdIdentifier(_this.languageService, true); }
            }); }));
        }));
    };
    OrganizationFilterDropdownComponent.prototype.ngOnDestroy = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    return OrganizationFilterDropdownComponent;
}());
export { OrganizationFilterDropdownComponent };
