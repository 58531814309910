
    <ul *ngIf="!editing">
      <li *ngFor="let organization of reference.value">{{organization.label | translateValue:true}}</li>
    </ul>

    <div *ngIf="editing" [appDragSortable]="reference" [dragDisabled]="!canReorder()">
      <div *ngFor="let organization of reference.value; let i = index"
           class="removable-text"
           [appDragSortableItem]="organization"
           [index]="i">
        <a><i class="fa fa-times"
              [id]="id + '_' + organization.getIdIdentifier(languageService) + '_remove_organization_reference_link'" (click)="removeReference(organization)"></i></a>
        <span>{{organization.label | translateValue:true}}</span>
      </div>
      <app-error-messages [id]="id + '_error_messages'" [control]="reference.control"></app-error-messages>
    </div>

    <button type="button"
            [id]="id + '_add_organization_button'"
            class="btn btn-sm btn-action mt-2"
            *ngIf="editing"
            (click)="addReference()" translate>Add organization</button>
  