
    <div *ngIf="vocabulary">
      <div>
        <form #form="ngForm" [formGroup]="formNode.control">

          <div class="top-actions">
            <app-editable-buttons [form]="form"
                                  [canRemove]="true"
                                  [vocabulary]="vocabulary"
                                  [idPrefix]="idPrefix"></app-editable-buttons>
          </div>

          <app-vocabulary-form [vocabulary]="vocabulary"
                               [form]="formNode"
                               [filterLanguage]="filterLanguage"
                               [namespace]="namespace"></app-vocabulary-form>

          <app-meta-information [node]="vocabulary"></app-meta-information>

        </form>
      </div>
    </div>
  