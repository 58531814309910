import { AfterViewInit, ElementRef, OnDestroy, Renderer } from '@angular/core';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { Router } from '@angular/router';
import { v4 as uuid } from 'uuid';
import { AuthorizationManager } from 'app/services/authorization-manager.sevice';
import { makePrefixPostfixHighlightRegexp, makePrefixPostfixSearchRegexp, splitSearchString } from 'yti-common-ui/utils/search';
var CollectionListComponent = /** @class */ (function () {
    function CollectionListComponent(conceptViewModel, authorizationManager, renderer, router) {
        var _this = this;
        this.conceptViewModel = conceptViewModel;
        this.authorizationManager = authorizationManager;
        this.renderer = renderer;
        this.router = router;
        this.subscriptionsToClean = [];
        this.model = conceptViewModel.collectionList;
        this.subscriptionsToClean.push(this.model.debouncedSearch$.subscribe(function (search) {
            var searchParts = splitSearchString(search);
            if (searchParts) {
                _this.searchRegexp = makePrefixPostfixSearchRegexp(searchParts);
                _this.highlightRegexp = makePrefixPostfixHighlightRegexp(searchParts);
            }
            else {
                _this.searchRegexp = undefined;
                _this.highlightRegexp = undefined;
            }
        }));
    }
    Object.defineProperty(CollectionListComponent.prototype, "search", {
        get: function () {
            return this.model.search$.getValue();
        },
        set: function (value) {
            this.model.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionListComponent.prototype, "searchResults", {
        get: function () {
            return this.model.searchResults;
        },
        enumerable: true,
        configurable: true
    });
    CollectionListComponent.prototype.ngAfterViewInit = function () {
        this.renderer.invokeElementMethod(this.searchInput.nativeElement, 'focus');
    };
    CollectionListComponent.prototype.ngOnDestroy = function () {
        this.subscriptionsToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    CollectionListComponent.prototype.collectionIdentity = function (index, item) {
        return item.id + item.lastModifiedDate.toISOString();
    };
    CollectionListComponent.prototype.canAddCollection = function () {
        if (!this.conceptViewModel.vocabulary) {
            return false;
        }
        return this.authorizationManager.canAddCollection(this.conceptViewModel.vocabulary);
    };
    CollectionListComponent.prototype.navigate = function (collection) {
        this.router.navigate(['/concepts', collection.graphId, 'collection', collection.id]);
    };
    CollectionListComponent.prototype.addCollection = function () {
        this.router.navigate(['/concepts', this.conceptViewModel.graphId, 'collection', uuid()]);
    };
    CollectionListComponent.prototype.isSelected = function (collection) {
        return this.conceptViewModel.resourceId === collection.id;
    };
    return CollectionListComponent;
}());
export { CollectionListComponent };
