
      <ng-container [ngSwitch]="phase">
          <div *ngSwitchCase="'FILE_SELECT'">
              <div class="modal-header">
                  <h4 class="modal-title strong">
                      <a><i id="close_modal_link" class="fa fa-times" (click)="close()"></i></a>
                      <span translate>Import concepts</span>
                  </h4>
              </div>

              <div class="modal-body">
                  <div class="d-inline-block">
                      <dl>
                          <dt>
                              <label translate>File format</label>
                          </dt>
                          <dd class="fileFormatDropdowns">
                              <div ngbDropdown>
                                  <button class="btn btn-dropdown" id="file_format_dropdown_button" ngbDropdownToggle>
                                      <span>{{format}}</span>
                                  </button>
                                  <div ngbDropdownMenu aria-labelledby="file_format_dropdown_button">
                                      <button id="ntrf_xml_format_dropdown_button"
                                              (click)="format = 'NTRF-XML'"
                                              class="dropdown-item"
                                              [class.active]="format === 'NTRF-XML'">
                                          NTRF-XML
                                      </button>
                                  </div>
                              </div>
                          </dd>
                      </dl>
                  </div>

                  <div class="form-group">
                      <dl>
                          <dt>
                              <label for="fileupload_input" translate>File to be uploaded</label>
                              <app-required-symbol></app-required-symbol>
                          </dt>
                          <dd>
                              <input name="file" id="fileupload_input" type="file" (change)="onChange($event)"/>
                          </dd>
                      </dl>
                  </div>
              </div>
              <div class="modal-footer">
                  <div>
                      <button id="upload_file_button"
                              [disabled]="!canSave()"
                              type="button"
                              class="btn btn-action"
                              (click)="uploadFile()"
                              translate>Import</button>
                      <button id="cancel_upload_button" type="button" class="btn btn-link" (click)="close()" translate>Cancel</button>
                  </div>
              </div>
          </div>
          <app-import-vocabulary-xml *ngSwitchCase="'XML'" [vocabulary]="vocabulary" [importFile]="file"></app-import-vocabulary-xml>
      </ng-container>
  