import { EventEmitter } from '@angular/core';
import { ConceptNode, VocabularyNode } from 'app/entities/node';
import { EditableService } from 'app/services/editable.service';
var ReferenceComponent = /** @class */ (function () {
    function ReferenceComponent(editableService) {
        this.editableService = editableService;
        this.conceptRemove = new EventEmitter();
    }
    Object.defineProperty(ReferenceComponent.prototype, "show", {
        get: function () {
            return this.editableService.editing || this.reference.value.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceComponent.prototype, "label", {
        get: function () {
            return this.reference.label;
        },
        enumerable: true,
        configurable: true
    });
    ReferenceComponent.prototype.hasDescription = function () {
        return Object.values(this.reference.description).length > 0;
    };
    Object.defineProperty(ReferenceComponent.prototype, "description", {
        get: function () {
            return this.reference.description;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReferenceComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    return ReferenceComponent;
}());
export { ReferenceComponent };
