import { AfterViewInit, ElementRef, OnInit, Renderer } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VocabularyNode } from 'app/entities/node';
import { BehaviorSubject, combineLatest, concat } from 'rxjs';
import { debounceTime, map, skip, take } from 'rxjs/operators';
import { TermedService } from 'app/services/termed.service';
import { ElasticSearchService } from 'app/services/elasticsearch.service';
import { FormNode } from 'app/services/form-state';
import { defaultLanguages } from 'app/utils/language';
import { firstMatching } from 'yti-common-ui/utils/array';
import { LanguageService } from 'app/services/language.service';
import { ModalService } from 'yti-common-ui/services/modal.service';
import { MetaModelService } from 'app/services/meta-model.service';
import { HttpErrorResponse } from '@angular/common/http';
var SearchConceptModalComponent = /** @class */ (function () {
    function SearchConceptModalComponent(modal, termedService, elasticSearchService, renderer, languageService, metaModelService) {
        this.modal = modal;
        this.termedService = termedService;
        this.elasticSearchService = elasticSearchService;
        this.renderer = renderer;
        this.languageService = languageService;
        this.metaModelService = metaModelService;
        this.searchResults$ = new BehaviorSubject([]);
        this.badSearchRequest$ = new BehaviorSubject({ error: false });
        this.selectedItem = null;
        this.selection = null;
        this.search$ = new BehaviorSubject('');
        this.onlyStatus$ = new BehaviorSubject(null);
        this.onlyVocabulary$ = new BehaviorSubject(null);
        this.loading = false;
        this.loaded = 0;
        this.canLoadMore = true;
    }
    Object.defineProperty(SearchConceptModalComponent.prototype, "restrictionReasonForSelection", {
        get: function () {
            var selection = this.selection;
            if (!selection) {
                return null;
            }
            var restriction = firstMatching(this.restricts, function (restrict) { return restrict.graphId === selection.graphId && restrict.conceptId === selection.id; });
            return restriction ? restriction.reason : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "searchResults", {
        get: function () {
            return this.searchResults$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "loadingSelection", {
        get: function () {
            return this.selectedItem && (!this.selection || this.selectedItem.id !== this.selection.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "onlyStatus", {
        get: function () {
            return this.onlyStatus$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "onlyVocabulary", {
        get: function () {
            return this.onlyVocabulary$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SearchConceptModalComponent.prototype, "filterLanguage", {
        get: function () {
            return this.languageService.filterLanguage;
        },
        set: function (lang) {
            this.languageService.filterLanguage = lang;
        },
        enumerable: true,
        configurable: true
    });
    SearchConceptModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.search = this.initialSearch;
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        var search = concat(initialSearch, debouncedSearch);
        if (this.allowVocabularyChange) {
            this.vocabularies = this.termedService.getVocabularyList()
                .pipe(map(function (vocabularies) { return vocabularies.filter(function (vocabulary) { return vocabulary.graphId !== _this.graphId; }); }));
        }
        combineLatest(search, this.onlyStatus$, this.onlyVocabulary$)
            .subscribe(function () { return _this.loadConcepts(true); });
    };
    SearchConceptModalComponent.prototype.hasStatus = function () {
        // TODO check from meta model if concept has status or not for this vocabulary
        return true;
    };
    SearchConceptModalComponent.prototype.cannotSelect = function () {
        return !this.selection || this.restrictionReasonForSelection !== null;
    };
    SearchConceptModalComponent.prototype.loadConcepts = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        var batchSize = 100;
        if (reset) {
            this.loaded = 0;
            this.canLoadMore = true;
        }
        if (this.canLoadMore) {
            this.loading = true;
            var appendResults = function (concepts) {
                if (_this.badSearchRequest$.getValue().error) {
                    _this.badSearchRequest$.next({ error: false });
                }
                if (concepts.length < batchSize) {
                    _this.canLoadMore = false;
                }
                _this.loaded += concepts.length;
                _this.searchResults$.next(reset ? concepts : _this.searchResults.concat(concepts));
                _this.loading = false;
            };
            var handleSearchError = function (err) {
                if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500) {
                    _this.badSearchRequest$.next({ error: true, message: err.message });
                    _this.searchResults$.next([]);
                }
                else {
                    console.error('Concept search failed: ' + JSON.stringify(err));
                }
            };
            if (this.onlyVocabulary) {
                this.elasticSearchService.getAllConceptsForVocabulary(this.onlyVocabulary.graphId, this.search, false, this.onlyStatus, this.loaded, batchSize)
                    .subscribe(appendResults, handleSearchError);
            }
            else {
                if (this.mode === 'include') {
                    this.elasticSearchService.getAllConceptsForVocabulary(this.graphId, this.search, false, this.onlyStatus, this.loaded, batchSize)
                        .subscribe(appendResults, handleSearchError);
                }
                else {
                    this.elasticSearchService.getAllConceptsNotInVocabulary(this.graphId, this.search, false, this.onlyStatus, this.loaded, batchSize)
                        .subscribe(appendResults, handleSearchError);
                }
            }
        }
    };
    SearchConceptModalComponent.prototype.conceptIdentity = function (index, item) {
        return item.id + item.modified.toISOString();
    };
    SearchConceptModalComponent.prototype.select = function (indexedConcept) {
        var _this = this;
        this.selectedItem = indexedConcept;
        var graphId = indexedConcept.vocabulary.id;
        combineLatest(this.termedService.getConcept(graphId, indexedConcept.id), this.metaModelService.getMeta(graphId))
            .subscribe(function (_a) {
            var concept = _a[0], metaModel = _a[1];
            _this.selection = concept;
            _this.formNode = _this.selection ? new FormNode(_this.selection, function () { return defaultLanguages; }, metaModel) : null;
        });
    };
    SearchConceptModalComponent.prototype.ngAfterViewInit = function () {
        this.renderer.invokeElementMethod(this.searchInput.nativeElement, 'focus');
    };
    SearchConceptModalComponent.prototype.cancel = function () {
        this.modal.dismiss('cancel');
    };
    SearchConceptModalComponent.prototype.confirm = function () {
        this.modal.close(this.selection);
    };
    return SearchConceptModalComponent;
}());
export { SearchConceptModalComponent };
var SearchConceptModalService = /** @class */ (function () {
    function SearchConceptModalService(modalService) {
        this.modalService = modalService;
    }
    SearchConceptModalService.prototype.openForVocabulary = function (vocabulary, initialSearch, restricts, allowVocabularyChange) {
        if (allowVocabularyChange === void 0) { allowVocabularyChange = false; }
        var modalRef = this.modalService.open(SearchConceptModalComponent, { size: 'lg' });
        var instance = modalRef.componentInstance;
        instance.graphId = vocabulary.graphId;
        instance.vocabulary = vocabulary;
        instance.filterLanguages = vocabulary.languages;
        instance.mode = 'include';
        instance.initialSearch = initialSearch;
        instance.restricts = restricts;
        instance.allowVocabularyChange = allowVocabularyChange;
        return modalRef.result;
    };
    SearchConceptModalService.prototype.openOtherThanVocabulary = function (vocabulary, initialSearch, restricts) {
        if (initialSearch === void 0) { initialSearch = ''; }
        var modalRef = this.modalService.open(SearchConceptModalComponent, { size: 'lg' });
        var instance = modalRef.componentInstance;
        instance.graphId = vocabulary.graphId;
        instance.vocabulary = vocabulary;
        instance.filterLanguages = vocabulary.languages;
        instance.mode = 'exclude';
        instance.initialSearch = initialSearch;
        instance.restricts = restricts;
        instance.allowVocabularyChange = true;
        return modalRef.result;
    };
    return SearchConceptModalService;
}());
export { SearchConceptModalService };
