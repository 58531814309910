
    <div class="component">

      <div class="canvas-container">
        <div #networkCanvas class="network-canvas" id="concept_canvas_container" (mouseleave)="hidePopup()"></div>
        <canvas class="legend" #legendCanvas></canvas>

        <button *ngIf="!maximized"
                id="maximize_concept_network_button"
                class="btn btn-link btn-lg maximize"
                (click)="maximized = true">
          <i class="fa fa-window-maximize"></i>
        </button>

        <button *ngIf="maximized"
                id="minimize_concept_network_button"
                class="btn btn-link btn-lg minimize"
                (click)="maximized = false">
          <i class="fa fa-window-minimize"></i>
        </button>
      </div>

    </div>
  