/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-vocabulary-xml.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "../../../../node_modules/yti-common-ui/components/clipboard.ngfactory";
import * as i4 from "yti-common-ui/components/clipboard";
import * as i5 from "ngx-clipboard";
import * as i6 from "@angular/common";
import * as i7 from "../progress.component.ngfactory";
import * as i8 from "../progress.component";
import * as i9 from "./import-vocabulary-xml.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i11 from "@angular/common/http";
var styles_ImportVocabularyXMLComponent = [i0.styles];
var RenderType_ImportVocabularyXMLComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportVocabularyXMLComponent, data: {} });
export { RenderType_ImportVocabularyXMLComponent as RenderType_ImportVocabularyXMLComponent };
function View_ImportVocabularyXMLComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "error-result"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, [" Error while monitoring processing. Close the modal and try refreshing page later. "]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ImportVocabularyXMLComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "ok-result"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(1, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(2, { created: 0 }), (_l()(), i1.ɵted(-1, null, ["Import success"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _ck(_v, 2, 0, (_co.finalResults.resultsCreated || "?")); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ImportVocabularyXMLComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "showMessagesToggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showMessages = !_co.showMessages) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, [". "])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-clipboard", [], null, null, null, i3.View_ClipboardComponent_0, i3.RenderType_ClipboardComponent)), i1.ɵdid(6, 49152, null, 0, i4.ClipboardComponent, [i5.ClipboardService, i2.TranslateService], { value: [0, "value"], description: [1, "description"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.generateMessageJSON(); var currVal_2 = "JSON"; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform((_co.showMessages ? "Hide messages" : "Show messages"))); _ck(_v, 2, 0, currVal_0); }); }
function View_ImportVocabularyXMLComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "ok-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"], translateParams: [1, "translateParams"] }, null), i1.ɵpod(3, { created: 0, errors: 1, warnings: 2 }), (_l()(), i1.ɵted(-1, null, ["Import success with errors"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _ck(_v, 3, 0, (_co.finalResults.resultsCreated || "?"), (_co.finalResults.resultsError || "0"), (_co.finalResults.resultsWarning || "0")); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.finalResults.statusMessage && _co.finalResults.statusMessage.length); _ck(_v, 6, 0, currVal_2); }, null); }
function View_ImportVocabularyXMLComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "error-result"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ":"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(4, 0, null, null, 1, "textarea", [["readonly", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform("Import failed")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.finalError; _ck(_v, 5, 0, currVal_1); }); }
function View_ImportVocabularyXMLComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "dd", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit.targetIdentifier; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.messageClass(_v.context.$implicit.level), ""); _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.message; _ck(_v, 4, 0, currVal_2); }); }
function View_ImportVocabularyXMLComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "xml-modal-result-messages mt-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_7)), i1.ɵdid(3, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.finalResults.statusMessage; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ImportVocabularyXMLComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 28, "div", [["class", "xml-modal-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "a", [], null, null, null, null, null)), i1.ɵdid(4, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(5, { disabled: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "cancel_import_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.canClose && _co.cancel()) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(8, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["XML import"])), (_l()(), i1.ɵeld(10, 0, null, null, 14, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "xml-modal-progress mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-progress", [], null, [[null, "result"], [null, "pollingError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("result" === en)) {
        var pd_0 = (_co.onResult($event) !== false);
        ad = (pd_0 && ad);
    } if (("pollingError" === en)) {
        var pd_1 = (_co.onPollingError($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_ProgressComponent_0, i7.RenderType_ProgressComponent)), i1.ɵdid(13, 114688, null, 0, i8.ProgressComponent, [], { phases: [0, "phases"] }, { result: "result", pollingError: "pollingError" }), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "xml-modal-result-summary"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_1)), i1.ɵdid(16, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_2)), i1.ɵdid(18, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_3)), i1.ɵdid(20, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_5)), i1.ɵdid(22, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyXMLComponent_6)), i1.ɵdid(24, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 2, "button", [["class", "btn btn-action confirm"], ["id", "import_yes_button"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(27, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, !_co.canClose); _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.progressPhases; _ck(_v, 13, 0, currVal_2); var currVal_3 = _co.monitoringError; _ck(_v, 16, 0, currVal_3); var currVal_4 = ((_co.finalResults && !_co.finalResults.resultsError) && !_co.finalResults.resultsWarning); _ck(_v, 18, 0, currVal_4); var currVal_5 = (_co.finalResults && (_co.finalResults.resultsError || _co.finalResults.resultsWarning)); _ck(_v, 20, 0, currVal_5); var currVal_6 = _co.finalError; _ck(_v, 22, 0, currVal_6); var currVal_7 = _co.showMessages; _ck(_v, 24, 0, currVal_7); var currVal_9 = ""; _ck(_v, 27, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = !_co.canClose; _ck(_v, 26, 0, currVal_8); }); }
export function View_ImportVocabularyXMLComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-import-vocabulary-xml", [], null, null, null, View_ImportVocabularyXMLComponent_0, RenderType_ImportVocabularyXMLComponent)), i1.ɵdid(1, 49152, null, 0, i9.ImportVocabularyXMLComponent, [i10.NgbActiveModal, i11.HttpClient], null, null)], null, null); }
var ImportVocabularyXMLComponentNgFactory = i1.ɵccf("app-import-vocabulary-xml", i9.ImportVocabularyXMLComponent, View_ImportVocabularyXMLComponent_Host_0, { vocabulary: "vocabulary", importFile: "importFile" }, {}, []);
export { ImportVocabularyXMLComponentNgFactory as ImportVocabularyXMLComponentNgFactory };
