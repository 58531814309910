
    <div class="row">

      <ng-container *ngFor="let field of fields" [ngSwitch]="field.value.fieldType">

        <app-property *ngSwitchCase="'property'"
                      class="col-md-12"
                      [class.col-xl-6]="isMultiColumn(field)"
                      [property]="field.value"
                      [id]="idPrefix + '_' + field.name"
                      [conceptSelector]="conceptSelector"
                      [relatedConcepts]="form.referencedConcepts"
                      [filterLanguage]="filterLanguage"></app-property>

        <app-reference *ngSwitchCase="'reference'"
                       class="col-md-12"
                       [class.col-xl-6]="isMultiColumn(field)"
                       [unsaved]="!concept.persistent"
                       (conceptRemove)="onConceptRemove($event)"
                       [reference]="field.value"
                       [concept]="concept"
                       [id]="idPrefix + '_' + field.name"
                       [filterLanguage]="filterLanguage"
                       [vocabulary]="vocabulary"></app-reference>

      </ng-container>

    </div>

    <app-meta-information [hidden]="!concept.persistent" [node]="concept"></app-meta-information>
  