import { UserService } from 'yti-common-ui/services/user.service';
var AuthorizationManager = /** @class */ (function () {
    function AuthorizationManager(userService) {
        this.userService = userService;
    }
    Object.defineProperty(AuthorizationManager.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    AuthorizationManager.prototype.canEdit = function (vocabulary) {
        if (this.user.superuser) {
            return true;
        }
        var organizationIds = vocabulary.contributors.map(function (org) { return org.id; });
        return this.user.isInRole(['ADMIN', 'TERMINOLOGY_EDITOR'], organizationIds);
    };
    AuthorizationManager.prototype.canAddCollection = function (vocabulary) {
        return this.canEdit(vocabulary);
    };
    AuthorizationManager.prototype.canAddConcept = function (vocabulary) {
        return this.canEdit(vocabulary);
    };
    AuthorizationManager.prototype.canAddVocabulary = function () {
        if (this.user.superuser) {
            return true;
        }
        return this.user.getOrganizations(['ADMIN', 'TERMINOLOGY_EDITOR']).size > 0;
    };
    AuthorizationManager.prototype.canEditOrganizationsIds = function () {
        if (this.user.superuser) {
            return 'ALL';
        }
        return Array.from(this.user.getOrganizations(['ADMIN', 'TERMINOLOGY_EDITOR']));
    };
    return AuthorizationManager;
}());
export { AuthorizationManager };
