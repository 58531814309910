
    <div class="component" *ngIf="collection">

      <div class="component-header">
        <h3>{{collection.label | translateValue}}</h3>
      </div>
      
      <form #form="ngForm" [formGroup]="formNode.control" class="component-content">

        <div class="top-actions">

          <app-status *ngIf="collection.hasStatus()"
                      [status]="collection.status"
                      class="float-left"></app-status>
          
            <app-editable-buttons [vocabulary]="vocabulary" 
                                  [form]="form"
                                  [canRemove]="true"
                                  [idPrefix]="idPrefix"></app-editable-buttons>
          
        </div>

        <div class="row">

          <ng-container *ngFor="let field of fields" [ngSwitch]="field.value.fieldType">

            <app-property *ngSwitchCase="'property'"
                          class="col-md-12"
                          [property]="field.value"
                          [id]="idPrefix + '_' + field.name"
                          [filterLanguage]="filterLanguage"></app-property>

            <app-reference *ngSwitchCase="'reference'"
                           class="col-md-12"
                           [reference]="field.value"
                           [id]="idPrefix + '_' + field.name"
                           [unsaved]="unsaved"
                           [filterLanguage]="filterLanguage"
                           [vocabulary]="vocabulary"></app-reference>

          </ng-container>

        </div>

        <app-meta-information [hidden]="!collection.persistent" [node]="collection"></app-meta-information>

      </form>

    </div>

    <app-ajax-loading-indicator *ngIf="!collection"></app-ajax-loading-indicator>
  