
    <div class="xml-modal-container">
      <div class="modal-header">
        <h4 class="modal-title">
          <a [ngClass]="{disabled: !canClose}"><i class="fa fa-times" id="cancel_import_link" (click)="canClose && cancel()"></i></a>
          <span translate>XML import</span>
        </h4>
      </div>
      <div class="modal-body">
        <div class="xml-modal-progress mb-3">
          <app-progress [phases]="progressPhases" (result)="onResult($event)" (pollingError)="onPollingError($event)"></app-progress>
        </div>
        <div class="xml-modal-result-summary">
          <div *ngIf="monitoringError" class="error-result" translate>
            Error while monitoring processing. Close the modal and try refreshing page later.
          </div>
          <span *ngIf="finalResults && !finalResults.resultsError && !finalResults.resultsWarning" class="ok-result" translate
                [translateParams]="{created: finalResults.resultsCreated || '?'}">Import success</span>
          <div *ngIf="finalResults && (finalResults.resultsError || finalResults.resultsWarning)" class="ok-result">
            <span translate
                  [translateParams]="{created: finalResults.resultsCreated || '?', errors: finalResults.resultsError || '0', warnings: finalResults.resultsWarning || '0'}">Import success with errors</span>
            <span *ngIf="finalResults.statusMessage && finalResults.statusMessage.length">
              <a class="showMessagesToggle"
                 (click)="showMessages = !showMessages">{{(showMessages ? 'Hide messages' : 'Show messages') | translate}}</a>.
              <app-clipboard [description]="'JSON'" [value]="generateMessageJSON()"></app-clipboard>
            </span>
          </div>
          <div *ngIf="finalError" class="error-result">
            <div>{{'Import failed' | translate}}:</div>
            <textarea readonly>{{finalError}}</textarea>
          </div>
        </div>
        <div class="xml-modal-result-messages mt-3" *ngIf="showMessages">
          <dl>
            <ng-container *ngFor="let message of finalResults.statusMessage">
              <dt>{{message.targetIdentifier}}</dt>
              <dd class="{{messageClass(message.level)}}">{{message.message}}</dd>
            </ng-container>
          </dl>
        </div>
      </div>

      <div class="modal-footer">
        <button type="button" id="import_yes_button" class="btn btn-action confirm" (click)="confirm()"
                [disabled]="!canClose" translate>Close</button>
      </div>
    </div>
  