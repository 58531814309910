import { Router } from '@angular/router';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { IndexedConcept } from 'app/services/elasticsearch.service';
var ConceptHierarchyNodeComponent = /** @class */ (function () {
    function ConceptHierarchyNodeComponent(conceptViewModel, router) {
        this.conceptViewModel = conceptViewModel;
        this.router = router;
        this.model = conceptViewModel.conceptHierarchy;
    }
    ConceptHierarchyNodeComponent.prototype.navigate = function () {
        this.router.navigate(['/concepts', this.concept.vocabulary.id, 'concept', this.concept.id]);
    };
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "selected", {
        get: function () {
            return this.conceptViewModel.resourceId === this.concept.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "expanded", {
        get: function () {
            return !this.loop && this.model.isExpanded(this.concept);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "collapsed", {
        get: function () {
            return !this.expanded;
        },
        enumerable: true,
        configurable: true
    });
    ConceptHierarchyNodeComponent.prototype.hasChildren = function () {
        return this.concept.hasNarrower;
    };
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "children", {
        get: function () {
            return this.model.getNarrowerConcepts(this.concept);
        },
        enumerable: true,
        configurable: true
    });
    ConceptHierarchyNodeComponent.prototype.collapse = function () {
        this.model.collapse(this.concept);
    };
    ConceptHierarchyNodeComponent.prototype.expand = function () {
        this.model.expand(this.concept);
    };
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "path", {
        get: function () {
            return this.parentIds.concat([this.concept.id]);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptHierarchyNodeComponent.prototype, "loop", {
        get: function () {
            return this.parentIds.indexOf(this.concept.id) >= 0;
        },
        enumerable: true,
        configurable: true
    });
    return ConceptHierarchyNodeComponent;
}());
export { ConceptHierarchyNodeComponent };
