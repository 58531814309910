
    <div class="modal-header modal-header-warning">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="cancel_remove_link_link" (click)="cancel()"></i></a>
        <span translate>Confirm remove</span>
      </h4>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <p *ngIf="translated.length !== 1" translate>The following references are about to be completely removed</p>
          <p *ngIf="translated.length === 1" translate>The following reference is about to be completely removed</p>
          <div>
            <table>
              <tr><th translate>Reference type</th><th translate>Target container</th><th translate>Target</th></tr>
              <tr *ngFor="let ref of translated">
                <td>{{ref.referenceLabel}}</td><td>{{ref.containerLabel?.label}}</td><td>{{ref.targetLabel}}</td>
              </tr>
            </table>
          </div>
          <p class="question" translate>Are you sure that you want to continue?</p>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" id="remove_link_confirmation_yes_button" class="btn btn-secondary-action confirm" (click)="confirm()" translate>Yes</button>
      <button type="button" id="remove_link_confirmation_cancel_button" class="btn btn-link cancel" (click)="cancel()" translate>Cancel</button>
    </div>
  