/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import-vocabulary-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i4 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/yti-common-ui/components/required-symbol.component.ngfactory";
import * as i7 from "yti-common-ui/components/required-symbol.component";
import * as i8 from "./import-vocabulary-xml.component.ngfactory";
import * as i9 from "./import-vocabulary-xml.component";
import * as i10 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
import * as i11 from "@angular/common/http";
import * as i12 from "../../services/editable.service";
import * as i13 from "yti-common-ui/components/error-modal.component";
import * as i14 from "yti-common-ui/services/user.service";
import * as i15 from "./import-vocabulary-modal.component";
import * as i16 from "@angular/router";
var styles_ImportVocabularyModalComponent = [i0.styles];
var RenderType_ImportVocabularyModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportVocabularyModalComponent, data: {} });
export { RenderType_ImportVocabularyModalComponent as RenderType_ImportVocabularyModalComponent };
function View_ImportVocabularyModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 46, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h4", [["class", "modal-title strong"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "close_modal_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Import concepts"])), (_l()(), i1.ɵeld(8, 0, null, null, 30, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 19, "div", [["class", "d-inline-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 18, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "label", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(13, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["File format"])), (_l()(), i1.ɵeld(15, 0, null, null, 13, "dd", [["class", "fileFormatDropdowns"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 12, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(17, 212992, null, 2, i3.NgbDropdown, [i1.ChangeDetectorRef, i4.NgbDropdownConfig, i5.DOCUMENT, i1.NgZone], null, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(20, 0, null, null, 4, "button", [["aria-haspopup", "true"], ["class", "btn btn-dropdown dropdown-toggle"], ["id", "file_format_dropdown_button"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵeld(23, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(24, null, ["", ""])), (_l()(), i1.ɵeld(25, 0, null, null, 3, "div", [["aria-labelledby", "file_format_dropdown_button"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(26, 16384, [[1, 4]], 0, i3.NgbDropdownMenu, [i3.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵeld(27, 0, null, null, 1, "button", [["class", "dropdown-item"], ["id", "ntrf_xml_format_dropdown_button"]], [[2, "active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.format = "NTRF-XML") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" NTRF-XML "])), (_l()(), i1.ɵeld(29, 0, null, null, 9, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 8, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 5, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "label", [["for", "fileupload_input"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(33, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["File to be uploaded"])), (_l()(), i1.ɵeld(35, 0, null, null, 1, "app-required-symbol", [], null, null, null, i6.View_RequiredSymbolComponent_0, i6.RenderType_RequiredSymbolComponent)), i1.ɵdid(36, 49152, null, 0, i7.RequiredSymbolComponent, [], null, null), (_l()(), i1.ɵeld(37, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 0, null, null, 0, "input", [["id", "fileupload_input"], ["name", "file"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 7, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(40, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(41, 0, null, null, 2, "button", [["class", "btn btn-action"], ["id", "upload_file_button"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.uploadFile() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(42, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Import"])), (_l()(), i1.ɵeld(44, 0, null, null, 2, "button", [["class", "btn btn-link"], ["id", "cancel_upload_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(45, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_1 = ""; _ck(_v, 13, 0, currVal_1); _ck(_v, 17, 0); var currVal_9 = ""; _ck(_v, 33, 0, currVal_9); var currVal_11 = ""; _ck(_v, 42, 0, currVal_11); var currVal_12 = ""; _ck(_v, 45, 0, currVal_12); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 17).isOpen(); _ck(_v, 16, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 21).dropdown.isOpen(); _ck(_v, 20, 0, currVal_3); var currVal_4 = _co.format; _ck(_v, 24, 0, currVal_4); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 26).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 26).placement; _ck(_v, 25, 0, currVal_5, currVal_6, currVal_7); var currVal_8 = (_co.format === "NTRF-XML"); _ck(_v, 27, 0, currVal_8); var currVal_10 = !_co.canSave(); _ck(_v, 41, 0, currVal_10); }); }
function View_ImportVocabularyModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-import-vocabulary-xml", [], null, null, null, i8.View_ImportVocabularyXMLComponent_0, i8.RenderType_ImportVocabularyXMLComponent)), i1.ɵdid(1, 49152, null, 0, i9.ImportVocabularyXMLComponent, [i10.NgbActiveModal, i11.HttpClient], { vocabulary: [0, "vocabulary"], importFile: [1, "importFile"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vocabulary; var currVal_1 = _co.file; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ImportVocabularyModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i5.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyModalComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImportVocabularyModalComponent_2)), i1.ɵdid(5, 278528, null, 0, i5.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i5.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.phase; _ck(_v, 1, 0, currVal_0); var currVal_1 = "FILE_SELECT"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "XML"; _ck(_v, 5, 0, currVal_2); }, null); }
export function View_ImportVocabularyModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-import-vocabulary-modal", [], null, null, null, View_ImportVocabularyModalComponent_0, RenderType_ImportVocabularyModalComponent)), i1.ɵprd(131584, null, i12.EditableService, i12.EditableService, [i13.ErrorModalService, i14.UserService]), i1.ɵdid(2, 49152, null, 0, i15.ImportVocabularyModalComponent, [i12.EditableService, i16.Router, i10.NgbActiveModal], null, null)], null, null); }
var ImportVocabularyModalComponentNgFactory = i1.ɵccf("app-import-vocabulary-modal", i15.ImportVocabularyModalComponent, View_ImportVocabularyModalComponent_Host_0, { vocabulary: "vocabulary" }, {}, []);
export { ImportVocabularyModalComponentNgFactory as ImportVocabularyModalComponentNgFactory };
