
    <div class="component" *ngIf="concept">

      <div class="component-header">
        <h3>{{concept.label | translateValue}}</h3>
      </div>
      <form #form="ngForm" [formGroup]="formNode.control" class="component-content">

        <div class="top-actions">

          <app-status *ngIf="concept.hasStatus()"
                      [status]="concept.status"
                      class="float-left"></app-status>

          <app-editable-buttons [form]="form"
                                [canRemove]="true"
                                [vocabulary]="vocabulary"
                                [idPrefix]="idPrefix"></app-editable-buttons>

        </div>

        <app-concept-form [form]="formNode" [concept]="concept" [multiColumn]="true" [filterLanguage]="filterLanguage"
                          [vocabulary]="vocabulary"></app-concept-form>
      </form>

    </div>

    <app-ajax-loading-indicator *ngIf="!concept"></app-ajax-loading-indicator>
  