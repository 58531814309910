import { FormControl, NgControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ietfLanguageTags } from 'yti-common-ui';
var LanguageInputComponent = /** @class */ (function () {
    function LanguageInputComponent(ngControl) {
        var _this = this;
        this.ngControl = ngControl;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        if (ngControl) {
            ngControl.valueAccessor = this;
        }
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
    }
    Object.defineProperty(LanguageInputComponent.prototype, "valid", {
        get: function () {
            return !this.ngControl || this.ngControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    LanguageInputComponent.prototype.languageProvider = function (text$) {
        return text$.pipe(map(function (value) { return ietfLanguageTags.filter(function (language) { return language.toLowerCase().indexOf(value.toLowerCase()) !== -1; }); }));
    };
    LanguageInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    LanguageInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    LanguageInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return LanguageInputComponent;
}());
export { LanguageInputComponent };
