import { HttpClient } from '@angular/common/http';
import { configApiUrl } from '../config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ConfigurationService = /** @class */ (function () {
    function ConfigurationService(http) {
        this.http = http;
    }
    Object.defineProperty(ConfigurationService.prototype, "configuration", {
        get: function () {
            if (this.configuration_) {
                return this.configuration_;
            }
            throw new Error("ConfigurationService used before initialization");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "environment", {
        get: function () {
            return this.configuration.env;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "codeListUrl", {
        get: function () {
            return this.configuration.codeListUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "dataModelUrl", {
        get: function () {
            return this.configuration.dataModelUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "commentsUrl", {
        get: function () {
            return this.configuration.commentsUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "groupManagementUrl", {
        get: function () {
            return this.configuration.groupmanagementUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showUnfinishedFeature", {
        get: function () {
            var env = (this.configuration.env || '').toLowerCase();
            return env === 'dev' || env === 'awsdev' || env === 'local';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "showAlmostReadyFeature", {
        get: function () {
            var env = (this.configuration.env || '').toLowerCase();
            return env === 'dev' || env === 'awsdev' || env === 'local' || env === 'test';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "isMessagingEnabled", {
        get: function () {
            return this.configuration.messagingEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "namespaceRoot", {
        get: function () {
            // TODO: Remove fallback when server is guaranteed to return value.
            return this.configuration.namespaceRoot || 'http://uri.suomi.fi/terminology/';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigurationService.prototype, "restrictFilterOptions", {
        get: function () {
            return this.configuration.restrictFilterOptions;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationService.prototype.getEnvironmentIdentifier = function (style) {
        if (this.environment !== 'prod') {
            var identifier = this.environment.toUpperCase();
            if (!style) {
                return identifier;
            }
            else if (style === 'prefix') {
                return identifier + ' - ';
            }
            else if (style === 'postfix') {
                return ' - ' + identifier;
            }
        }
        return '';
    };
    ConfigurationService.prototype.fetchConfiguration = function () {
        var _this = this;
        if (!this.configurationPromise) {
            this.configurationPromise = new Promise(function (resolve, refuse) {
                _this.http.get(configApiUrl)
                    .subscribe(function (configuration) {
                    _this.configuration_ = configuration;
                    resolve(configuration);
                }, function (error) {
                    refuse(error);
                });
            });
        }
        return this.configurationPromise;
    };
    ConfigurationService.prototype.getUriWithEnv = function (uri) {
        if (uri && this.environment !== 'prod') {
            return uri + '?env=' + this.environment;
        }
        return uri ? uri : null;
    };
    ConfigurationService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigurationService_Factory() { return new ConfigurationService(i0.inject(i1.HttpClient)); }, token: ConfigurationService, providedIn: "root" });
    return ConfigurationService;
}());
export { ConfigurationService };
