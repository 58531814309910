import { EventEmitter } from '@angular/core';
import { ConceptNode, VocabularyNode } from 'app/entities/node';
import { EditableService } from 'app/services/editable.service';
import { SearchConceptModalService } from './search-concept-modal.component';
import { ignoreModalClose } from 'yti-common-ui/utils/modal';
import { FormReferenceLiteral } from 'app/services/form-state';
import { isDefined, requireDefined } from 'yti-common-ui/utils/object';
var ConceptReferenceInputComponent = /** @class */ (function () {
    function ConceptReferenceInputComponent(editableService, searchConceptModal) {
        this.editableService = editableService;
        this.searchConceptModal = searchConceptModal;
        this.conceptRemove = new EventEmitter();
    }
    Object.defineProperty(ConceptReferenceInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    ConceptReferenceInputComponent.prototype.removeReference = function (concept) {
        this.reference.removeReference(concept);
        this.conceptRemove.emit(concept);
    };
    ConceptReferenceInputComponent.prototype.addReference = function () {
        var _this = this;
        var restricts = (isDefined(this.self) ? [{ graphId: this.self.graphId, conceptId: this.self.id, reason: 'self reference error' }] : []).concat(this.reference.value.map(function (_a) {
            var graphId = _a.graphId, id = _a.id;
            return ({ graphId: graphId, conceptId: id, reason: 'already added error' });
        }));
        this.searchConceptModal.openForVocabulary(requireDefined(this.vocabulary), '', restricts)
            .then(function (result) { return _this.reference.addReference(result); }, ignoreModalClose);
    };
    ConceptReferenceInputComponent.prototype.canReorder = function () {
        return this.editing && this.reference.value.length > 1;
    };
    return ConceptReferenceInputComponent;
}());
export { ConceptReferenceInputComponent };
