/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./concept-hierarchy.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./concept-hierarchy-node.component.ngfactory";
import * as i4 from "./concept-hierarchy-node.component";
import * as i5 from "../../services/concept.view.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "ngx-infinite-scroll";
import * as i9 from "./concept-hierarchy.component";
import * as i10 from "../../services/authorization-manager.sevice";
var styles_ConceptHierarchyComponent = [i0.styles];
var RenderType_ConceptHierarchyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConceptHierarchyComponent, data: {} });
export { RenderType_ConceptHierarchyComponent as RenderType_ConceptHierarchyComponent };
function View_ConceptHierarchyComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "btn btn-action btn-add-new"], ["id", "concept_add_concept_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addConcept() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add new concept"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ConceptHierarchyComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-concept-hierarchy-node", [], null, null, null, i3.View_ConceptHierarchyNodeComponent_0, i3.RenderType_ConceptHierarchyNodeComponent)), i1.ɵdid(2, 49152, null, 0, i4.ConceptHierarchyNodeComponent, [i5.ConceptViewModelService, i6.Router], { concept: [0, "concept"], parentIds: [1, "parentIds"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = i1.ɵEMPTY_ARRAY; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ConceptHierarchyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "selectable-actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptHierarchyComponent_1)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 8, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 7, "div", [["class", "col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "selectable-concepts"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "ul", [["infinite-scroll", ""]], null, [[null, "scrolled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolled" === en)) {
        var pd_0 = (_co.onScrollDown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 278528, null, 0, i7.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(10, { "has-button": 0 }), i1.ɵdid(11, 4866048, null, 0, i8.InfiniteScrollDirective, [i1.ElementRef, i1.NgZone], { infiniteScrollDistance: [0, "infiniteScrollDistance"], scrollWindow: [1, "scrollWindow"] }, { scrolled: "scrolled" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConceptHierarchyComponent_2)), i1.ɵdid(13, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.canAddConcept(); _ck(_v, 4, 0, currVal_0); var currVal_1 = _ck(_v, 10, 0, _co.canAddConcept()); _ck(_v, 9, 0, currVal_1); var currVal_2 = 2.5; var currVal_3 = false; _ck(_v, 11, 0, currVal_2, currVal_3); var currVal_4 = _co.model.topConcepts; var currVal_5 = _co.conceptIdentity; _ck(_v, 13, 0, currVal_4, currVal_5); }, null); }
export function View_ConceptHierarchyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-concept-hierarchy", [], null, null, null, View_ConceptHierarchyComponent_0, RenderType_ConceptHierarchyComponent)), i1.ɵdid(1, 49152, null, 0, i9.ConceptHierarchyComponent, [i6.Router, i5.ConceptViewModelService, i10.AuthorizationManager], null, null)], null, null); }
var ConceptHierarchyComponentNgFactory = i1.ɵccf("app-concept-hierarchy", i9.ConceptHierarchyComponent, View_ConceptHierarchyComponent_Host_0, {}, {}, []);
export { ConceptHierarchyComponentNgFactory as ConceptHierarchyComponentNgFactory };
