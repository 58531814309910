
    <div class="modal-header">
      <h4 class="modal-title">
        <a><i class="fa fa-times" id="search_concept_cancel_link" (click)="cancel()"></i></a>
        <span translate>Choose concept</span>
      </h4>
    </div>
    <div class="modal-body full-height">

      <div class="row mb-2">
        <div class="col-12">

          <div class="input-group input-group-lg input-group-search float-left">
            <input #searchInput
                   id="search_concept_search_input"
                   type="text"
                   class="form-control"
                   [ngClass]="{ 'is-invalid': (badSearchRequest$ | async).error }"
                   placeholder="{{'Search concept' | translate}}"
                   [(ngModel)]="search"/>
          </div>

          <app-filter-language [(ngModel)]="filterLanguage"
                               [ngModelOptions]="{standalone: true}"
                               [languages]="filterLanguages"
                               id="search_concept_filter_language"
                               class="float-right"></app-filter-language>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-12">

          <app-vocabulary-filter-dropdown *ngIf="allowVocabularyChange"
                                          [filterSubject]="onlyVocabulary$"
                                          id="search_concept_vocabulary_filter_dropdown"
                                          [vocabularies]="vocabularies"
                                          class="float-left"></app-vocabulary-filter-dropdown>

          <app-status-filter-dropdown *ngIf="hasStatus()"
                                      id="search_concept_status_filter_dropdown"
                                      [filterSubject]="onlyStatus$"
                                      [ngClass]="{'float-left': true, 'ml-2': allowVocabularyChange}"></app-status-filter-dropdown>

        </div>
      </div>

      <div class="row full-height">
        <div class="col-md-6">
          <div class="content-box">
            <div class="search-results"
                 id="search_concept_search_results"
                 infinite-scroll
                 [infiniteScrollDistance]="3"
                 [scrollWindow]="false"
                 (scrolled)="loadConcepts()">

              <div *ngFor="let concept of searchResults$ | async; trackBy: conceptIdentity; let last = last"
                   class="search-result"
                   [id]="concept.idIdentifier +'_search_result_concept'"
                   [class.active]="concept === selectedItem"
                   (click)="select(concept)">
                <div class="content" [class.last]="last">
                  <span class="title" [innerHTML]="concept.label | translateValue"></span>
                  <span class="body" [innerHTML]="concept.definitionWithoutSemantics | translateValue"></span>
                  <div class="origin">
                    <span class="float-left">{{concept.vocabulary.label | translateValue}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-6">
          <form>
            <app-concept-form *ngIf="selection && !loadingSelection"
                              [concept]="selection"
                              [form]="formNode"
                              [filterLanguage]="filterLanguage"
                              [vocabulary]="vocabulary"></app-concept-form>
          </form>

          <app-ajax-loading-indicator *ngIf="loadingSelection"></app-ajax-loading-indicator>
        </div>
      </div>
    </div>

    <div class="modal-footer">

      <button type="button"
              id="search_concept_confirm_button"
              class="btn btn-action confirm"
              (click)="confirm()"
              [disabled]="cannotSelect()">{{'Select concept' | translate}}
      </button>

      <button type="button"
              id="search_concept_cancel_button"
              class="btn btn-link cancel"
              (click)="cancel()">{{'Cancel' | translate}}
      </button>

      <div class="alert alert-danger modal-alert" role="alert" *ngIf="restrictionReasonForSelection">
        <span class="fa fa-exclamation-circle" aria-hidden="true"></span>
        <span>{{restrictionReasonForSelection | translate}}</span>
      </div>

    </div>
  