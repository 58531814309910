var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var InformationDomain = /** @class */ (function () {
    function InformationDomain() {
    }
    return InformationDomain;
}());
export { InformationDomain };
var Organization = /** @class */ (function () {
    function Organization() {
    }
    return Organization;
}());
export { Organization };
var TerminologySimple = /** @class */ (function () {
    function TerminologySimple() {
    }
    return TerminologySimple;
}());
export { TerminologySimple };
var Terminology = /** @class */ (function (_super) {
    __extends(Terminology, _super);
    function Terminology() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Terminology;
}(TerminologySimple));
export { Terminology };
var ConceptSimple = /** @class */ (function () {
    function ConceptSimple() {
    }
    return ConceptSimple;
}());
export { ConceptSimple };
var Concept = /** @class */ (function (_super) {
    __extends(Concept, _super);
    function Concept() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Concept;
}(ConceptSimple));
export { Concept };
var DeepSearchHitList = /** @class */ (function () {
    function DeepSearchHitList() {
    }
    return DeepSearchHitList;
}());
export { DeepSearchHitList };
var TerminologySearchRequest = /** @class */ (function () {
    function TerminologySearchRequest(query, searchConcepts, prefLang, pageSize, pageFrom) {
        this.query = query;
        this.searchConcepts = searchConcepts;
        this.prefLang = prefLang;
        this.pageSize = pageSize;
        this.pageFrom = pageFrom;
    }
    return TerminologySearchRequest;
}());
export { TerminologySearchRequest };
var TerminologySearchResponse = /** @class */ (function () {
    function TerminologySearchResponse() {
    }
    return TerminologySearchResponse;
}());
export { TerminologySearchResponse };
var ConceptSearchRequest = /** @class */ (function () {
    function ConceptSearchRequest() {
    }
    return ConceptSearchRequest;
}());
export { ConceptSearchRequest };
var ConceptSearchResponse = /** @class */ (function () {
    function ConceptSearchResponse() {
    }
    return ConceptSearchResponse;
}());
export { ConceptSearchResponse };
