
    <ng-template ngbModalContainer></ng-template>
    <app-navigation-bar></app-navigation-bar>
    <div class="container-fluid" [class.without-footer]="!showFooter">
      <app-breadcrumb [location]="location" [linkActive]="true" [refreshPath]="['re']"></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
    <app-footer [title]="'Terminologies' | translate"
                id="app_navigate_to_info"
                [showLicenseIcon]="true"
                [version]="version"
                (informationClick)="navigateToInformation()"
                *ngIf="showFooter"></app-footer>
  