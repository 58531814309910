/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./frontpage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./vocabulary/vocabularies.component.ngfactory";
import * as i3 from "./vocabulary/vocabularies.component";
import * as i4 from "../services/authorization-manager.sevice";
import * as i5 from "../services/configuration.service";
import * as i6 from "../services/language.service";
import * as i7 from "@ngx-translate/core";
import * as i8 from "../services/termed.service";
import * as i9 from "../services/elasticsearch.service";
import * as i10 from "yti-common-ui/services/user.service";
import * as i11 from "@angular/router";
import * as i12 from "./frontpage.component";
import * as i13 from "../services/location.service";
var styles_FrontpageComponent = [i0.styles];
var RenderType_FrontpageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FrontpageComponent, data: {} });
export { RenderType_FrontpageComponent as RenderType_FrontpageComponent };
export function View_FrontpageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-vocabularies", [], null, null, null, i2.View_VocabulariesComponent_0, i2.RenderType_VocabulariesComponent)), i1.ɵdid(1, 245760, null, 0, i3.VocabulariesComponent, [i4.AuthorizationManager, i5.ConfigurationService, i6.LanguageService, i7.TranslateService, i8.TermedService, i9.ElasticSearchService, i10.UserService, i11.Router, i11.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_FrontpageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-frontpage", [], null, null, null, View_FrontpageComponent_0, RenderType_FrontpageComponent)), i1.ɵdid(1, 49152, null, 0, i12.FrontpageComponent, [i13.LocationService], null, null)], null, null); }
var FrontpageComponentNgFactory = i1.ɵccf("app-frontpage", i12.FrontpageComponent, View_FrontpageComponent_Host_0, {}, {}, []);
export { FrontpageComponentNgFactory as FrontpageComponentNgFactory };
