/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filter-language.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown";
import * as i3 from "@ng-bootstrap/ng-bootstrap/dropdown/dropdown-config";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./filter-language.component";
import * as i7 from "@ngx-translate/core";
var styles_FilterLanguageComponent = [i0.styles];
var RenderType_FilterLanguageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterLanguageComponent, data: {} });
export { RenderType_FilterLanguageComponent as RenderType_FilterLanguageComponent };
function View_FilterLanguageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "dropdown-item"]], [[2, "active", null], [8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.writeValue(_v.context.$implicit.lang) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.lang === _co.selection); var currVal_1 = _co.getLangSelectionId(_v.context.$implicit.lang); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit.name; _ck(_v, 1, 0, currVal_2); }); }
export function View_FilterLanguageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(1, 212992, null, 2, i2.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i4.DOCUMENT, i1.NgZone], { placement: [0, "placement"] }, null), i1.ɵqud(335544320, 1, { _menu: 0 }), i1.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-language dropdown-toggle"], ["id", "filter_language_dropdown_button"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i2.NgbDropdownToggle, [i2.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[2, 4]], i2.NgbDropdownAnchor, null, [i2.NgbDropdownToggle]), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["aria-labelledby", "filter_language_dropdown_button"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i1.ɵdid(9, 16384, [[1, 4]], 0, i2.NgbDropdownMenu, [i2.NgbDropdown, i1.ElementRef, i1.Renderer2], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterLanguageComponent_1)), i1.ɵdid(11, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "bottom-right"; _ck(_v, 1, 0, currVal_1); var currVal_7 = _co.options; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.selectionName; _ck(_v, 7, 0, currVal_3); var currVal_4 = true; var currVal_5 = i1.ɵnov(_v, 9).dropdown.isOpen(); var currVal_6 = i1.ɵnov(_v, 9).placement; _ck(_v, 8, 0, currVal_4, currVal_5, currVal_6); }); }
export function View_FilterLanguageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-filter-language", [], null, null, null, View_FilterLanguageComponent_0, RenderType_FilterLanguageComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.FilterLanguageComponent]), i1.ɵdid(2, 245760, null, 0, i6.FilterLanguageComponent, [i7.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var FilterLanguageComponentNgFactory = i1.ɵccf("app-filter-language", i6.FilterLanguageComponent, View_FilterLanguageComponent_Host_0, { languages: "languages" }, {}, []);
export { FilterLanguageComponentNgFactory as FilterLanguageComponentNgFactory };
