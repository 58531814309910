import { EditableService } from 'app/services/editable.service';
import { FormPropertyLiteralList } from 'app/services/form-state';
var LiteralListInputComponent = /** @class */ (function () {
    function LiteralListInputComponent(editableService) {
        this.editableService = editableService;
    }
    LiteralListInputComponent.prototype.addNewValue = function () {
        this.property.append('');
    };
    LiteralListInputComponent.prototype.removeValue = function (control) {
        this.property.remove(control);
    };
    LiteralListInputComponent.prototype.canRemove = function () {
        return true;
    };
    Object.defineProperty(LiteralListInputComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    LiteralListInputComponent.prototype.canReorder = function () {
        return this.editing && this.property.children.length > 1;
    };
    return LiteralListInputComponent;
}());
export { LiteralListInputComponent };
