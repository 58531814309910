
    <div class="row">
      <div class="col-lg-12">

        <div class="selectable-actions">

          <button class="btn btn-action mb-3" id="concept_list_add_concept_button" *ngIf="canAddConcept()" (click)="addConcept()">
            <span translate>Add new concept</span>
          </button>

          <div class="input-group input-group-lg input-group-search">
            <input #searchInput
                   id="concept_list_search_concept_input"
                   [ngClass]="{ 'is-invalid': (model.badSearchRequest$ | async).error }"
                   [(ngModel)]="model.search"
                   type="text"
                   class="form-control"
                   [placeholder]="'Search concept' | translate"/>
            <app-ajax-loading-indicator-small *ngIf="model.loading"></app-ajax-loading-indicator-small>
          </div>

          <div class="btn btn-lg btn-filters"
               id="concept_list_filter_results"
               [ngbPopover]="filters"
               [triggers]="'manual'"
               [placement]="'right'"
               #p="ngbPopover"
               [popoverTitle]="'Filter results' | translate"
               (click)="p.toggle()">

            <div class="tooltip-overlay"
                 id="concept_list_filter_results_tooltip_overlay"
                 ngbTooltip="{{'Filter results' | translate}}"
                 #filterTooltip="ngbTooltip"
                 (click)="filterTooltip.close()"></div>

            <i class="fa fa-ellipsis-v"></i>
          </div>

          <ng-template #filters>

            <app-popover-close [popover]="p"></app-popover-close>

            <div class="filters">
              <div class="form-group">
                <label translate>Status</label>
                <app-status-filter-dropdown *ngIf="hasStatus()" id="concept_list_filter_dropdown"
                                            [filterSubject]="model.onlyStatus$"></app-status-filter-dropdown>
              </div>

              <div class="form-check">
                <label class="form-check-label">
                  <input class="form-check-input" id="concept_list_sort_by_time_checkbox" type="checkbox" [(ngModel)]="model.sortByTime"/>
                  {{'Order by modified date' | translate}}
                </label>
              </div>
            </div>
          </ng-template>

        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="selectable-concepts">
          <ul [ngClass]="{'has-button': canAddConcept()}"
              infinite-scroll
              id="concept_list_selectable_concepts_list"
              [infiniteScrollDistance]="2.5"
              [scrollWindow]="false"
              (scrolled)="onScrollDown()">
            <li *ngFor="let concept of model.searchResults; trackBy: conceptIdentity"
                (click)="navigate(concept)"
                [id]="concept.idIdentifier + '_concept_list_listitem'"
                [class.selection]="isSelected(concept)">
              <span [innerHTML]="concept.label | translateValue"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  