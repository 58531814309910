/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./url-input-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./error-messages.component.ngfactory";
import * as i3 from "./error-messages.component";
import * as i4 from "@ngx-translate/core";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/common";
import * as i7 from "./url-input-modal.component";
import * as i8 from "@ng-bootstrap/ng-bootstrap/modal/modal-ref";
var styles_UrlInputModalComponent = [i0.styles];
var RenderType_UrlInputModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UrlInputModalComponent, data: {} });
export { RenderType_UrlInputModalComponent as RenderType_UrlInputModalComponent };
function View_UrlInputModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-error-messages", [], null, null, null, i2.View_ErrorMessagesComponent_0, i2.RenderType_ErrorMessagesComponent)), i1.ɵdid(1, 49152, null, 0, i3.ErrorMessagesComponent, [], { control: [0, "control"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_UrlInputModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { urlInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "h4", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-times"], ["id", "select_link_target_cancel_link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(6, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Enter link target"])), (_l()(), i1.ɵeld(8, 0, null, null, 22, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 21, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 16384, null, 0, i5.ɵangular_packages_forms_forms_bg, [], null, null), i1.ɵdid(11, 4210688, null, 0, i5.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i5.ControlContainer, null, [i5.NgForm]), i1.ɵdid(13, 16384, null, 0, i5.NgControlStatusGroup, [[4, i5.ControlContainer]], null, null), (_l()(), i1.ɵeld(14, 0, null, null, 4, "div", [["class", "row mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i1.ɵdid(17, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Enter the full HTTP or HTTPS address (URL) for the link:"])), (_l()(), i1.ɵeld(19, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 10, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, [[1, 0], ["urlInput", 1]], null, 7, "input", [["class", "form-control"], ["id", "select_link_target_url_input"], ["placeholder", "https://www.example.com"], ["type", "url"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 24).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 24)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 24)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup.enter" === en)) {
        var pd_4 = (_co.confirm() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(22, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(23, { "is-invalid": 0 }), i1.ɵdid(24, 16384, null, 0, i5.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i5.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.DefaultValueAccessor]), i1.ɵdid(26, 540672, null, 0, i5.FormControlDirective, [[8, null], [8, null], [6, i5.NG_VALUE_ACCESSOR], [2, i5.ɵangular_packages_forms_forms_j]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i5.NgControl, null, [i5.FormControlDirective]), i1.ɵdid(28, 16384, null, 0, i5.NgControlStatus, [[4, i5.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_UrlInputModalComponent_1)), i1.ɵdid(30, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 6, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(32, 0, null, null, 2, "button", [["class", "btn btn-action confirm"], ["id", "select_link_target_confirm_button"], ["translate", ""], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(33, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Add link"])), (_l()(), i1.ɵeld(35, 0, null, null, 2, "button", [["class", "btn btn-link cancel"], ["id", "select_link_target_cancel_button"], ["translate", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(36, 8536064, null, 0, i4.TranslateDirective, [i4.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; _ck(_v, 6, 0, currVal_0); var currVal_8 = ""; _ck(_v, 17, 0, currVal_8); var currVal_16 = "form-control"; var currVal_17 = _ck(_v, 23, 0, (_co.url.invalid && (_co.url.dirty || _co.url.touched))); _ck(_v, 22, 0, currVal_16, currVal_17); var currVal_18 = _co.url; _ck(_v, 26, 0, currVal_18); var currVal_19 = (_co.url.dirty || _co.url.touched); _ck(_v, 30, 0, currVal_19); var currVal_21 = ""; _ck(_v, 33, 0, currVal_21); var currVal_22 = ""; _ck(_v, 36, 0, currVal_22); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵnov(_v, 13).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 13).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 13).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 13).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 13).ngClassValid; var currVal_6 = i1.ɵnov(_v, 13).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 13).ngClassPending; _ck(_v, 9, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 28).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 28).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 28).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 28).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 28).ngClassValid; var currVal_14 = i1.ɵnov(_v, 28).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 28).ngClassPending; _ck(_v, 21, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_20 = (_co.url.invalid || !_co.url.dirty); _ck(_v, 32, 0, currVal_20); }); }
export function View_UrlInputModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-url-input-modal", [], null, null, null, View_UrlInputModalComponent_0, RenderType_UrlInputModalComponent)), i1.ɵdid(1, 4243456, null, 0, i7.UrlInputModalComponent, [i8.NgbActiveModal], null, null)], null, null); }
var UrlInputModalComponentNgFactory = i1.ɵccf("app-url-input-modal", i7.UrlInputModalComponent, View_UrlInputModalComponent_Host_0, {}, {}, []);
export { UrlInputModalComponentNgFactory as UrlInputModalComponentNgFactory };
