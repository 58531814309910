
    <div class="yti-tool-front-page" *ngIf="!loading">

      <button class="btn btn-action float-right add-main-entity" id="add_vocabulary_button" *ngIf="canAddVocabulary()"
              (click)="addVocabulary()">
        <span translate>Add vocabulary</span>
      </button>

      <h4 class="tool-inner-title" translate>Terminologies</h4>
      <div class="tool-info"><p translate>ToolInfo</p>
      <p>
        <span translate>BetaLinkPrefix</span>
        <a href="https://sanastot.suomi.fi/" target="_blank" rel="noopener noreferrer" translate>BetaLink</a>
        <span>.</span>
      </p>
      </div>

      <div><span class="search-label" translate>Search vocabularies</span></div>
      <div class="row">
        <div class="col-md-6 mb-3">
          <div class="input-group input-group-lg input-group-search">
            <input class="form-control"
                   [ngClass]="{'is-invalid': terminologySearchError}"
                   id="vocabularies_search_input"
                   type="text"
                   [(ngModel)]="searchText"
                   placeholder="{{'Search term' | translate}}"/>
          </div>
        </div>
        <div class="col-md-6 mb-3 align-self-center d-flex align-items-center extend-search-selections">
          <span translate>Extend search</span>:
          <input class="ml-3" id="search_concepts_checkbox" type="checkbox" [(ngModel)]="searchConcepts"/>
          <label class="ml-1" for="search_concepts_checkbox" translate>to concepts</label>
        </div>
      </div>

      <div><span class="search-label" translate>Filter with information domain</span></div>
      <div class="row">
        <div class="col-md-4">
          <div class="information-domain-container">
            <div class="content-box">
              <div class="information-domain"
                   *ngFor="let domainAndCount of applicableInformationDomains"
                   [class.active]="isInformationDomainSelected(domainAndCount.domain)"
                   [id]="domainAndCount.domain.idIdentifier + '_domain_toggle'"
                   (click)="toggleInformationDomainSelection(domainAndCount.domain)">
                <img [src]="getInformationDomainIconSrc(domainAndCount.domain.getProperty('notation').literalValue)">
                <span class="name">{{domainAndCount.domain.label | translateValue:true}}</span>
                <span class="count">({{domainAndCount.count}})</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8">

          <div class="content-box result-list-container">

            <div class="row mb-4">
              <div class="col-md-12 result-list-filter-row">

                <span class="search-label search-label-inline with-info" translate>Filter results</span>

                <div class="result-list-filter-dropdowns">
                  <app-organization-filter-dropdown [filterSubject]="selectedOrganization$"
                                                    id="organization_filter_dropdown"
                                                    [organizations]="applicableOrganizations$"></app-organization-filter-dropdown>
                  <app-status-filter-dropdown [filterSubject]="selectedStatus$"
                                              id="status_filter_dropdown"
                                              [statuses]="applicableStatuses$"></app-status-filter-dropdown>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-md-12">
                <div>
                  {{filteredTerminologies.length}}
                  <span *ngIf="filteredTerminologies.length === 1" translate>result</span>
                  <span *ngIf="filteredTerminologies.length !== 1" translate>results</span>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="result-list-item" *ngFor="let terminology of filteredTerminologies"
                     [id]="terminology.uri + '_terminology_navigation'">
                  <span class="type">
                    <i
                      class="material-icons {{getTerminologyTypeIconDef('TerminologicalVocabulary').colorClass}}">{{getTerminologyTypeIconDef('TerminologicalVocabulary').name}}</i>{{'TerminologicalVocabularyType' | translate}}
                  </span>

                  <app-status class="status" [status]="terminology.status"></app-status>

                  <a class="name" [routerLink]="['/concepts', terminology.id]" [innerHTML]="terminology.label | translateValue:true"></a>

                  <div class="meta-information-row">
                    <ul class="organizations dot-separated-list">
                      <li class="organization" *ngFor="let contributor of terminology.contributors">
                        {{contributor.label | translateValue:true}}
                      </li>
                    </ul>

                    <span class="information-domains">
                      <span class="badge badge-light" *ngFor="let domain of terminology.informationDomains">
                        {{domain.label | translateValue:true}}
                      </span>
                    </span>
                  </div>

                  <div *ngIf="terminology.description | translateValue:true as descriptionText" class="description-component-container" [ngClass]="{'nrr': noRightMargin}">
                    <app-expandable-text [text]="descriptionText"></app-expandable-text>
                  </div>

                  <div *ngIf="filteredDeepHits[terminology.id] as deepHitLists" class="deep-results">
                    <div class="deep-results-title" translate>Search results</div>
                    <div *ngFor="let deepHitList of deepHitLists" class="deep-results-section">
                      <div class="deep-results-section-title" translate>Deep {{deepHitList.type}} hit</div>
                      <div class="deep-results-section-content">
                        <a *ngFor="let deepHit of deepHitList.topHits" class="deep-results-hit"
                           [routerLink]="['/concepts', terminology.id, 'concept', deepHit.id]" title="{{allLanguagesLabel(deepHit.label)}}"
                           [queryParams]="{'q': searchText}"
                           [innerHTML]="deepHit.label | translateValue:true"></a>
                        <a *ngIf="deepHitList.totalHitCount > deepHitList.topHits.length" class="deep-results-show-all"
                           [routerLink]="['/concepts', terminology.id]"
                           [queryParams]="{'q': searchText}">({{'See all results' | translate : {count: deepHitList.totalHitCount} }})</a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  