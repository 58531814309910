import { OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditableService } from 'app/services/editable.service';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { DeleteConfirmationModalService } from 'app/components/common/delete-confirmation-modal.component';
import { requireDefined } from 'yti-common-ui/utils/object';
import { LanguageService } from 'app/services/language.service';
import { conceptIdPrefix } from 'app/utils/id-prefix';
import { RemoveLinkConfirmationModalService } from './remove-link-confirmation-modal.component';
import { ConceptLinkNode, ConceptNode } from '../../entities/node';
var ConceptComponent = /** @class */ (function () {
    function ConceptComponent(route, conceptViewModel, deleteConfirmationModal, removeLinkConfirmationModal, editableService, languageService) {
        var _this = this;
        this.route = route;
        this.conceptViewModel = conceptViewModel;
        this.deleteConfirmationModal = deleteConfirmationModal;
        this.removeLinkConfirmationModal = removeLinkConfirmationModal;
        this.editableService = editableService;
        this.languageService = languageService;
        this.idPrefix = conceptIdPrefix;
        this.subscriptionToClean = [];
        this.subscriptionToClean.push(this.route.params.subscribe(function (params) {
            _this.conceptViewModel.initializeConcept(params['conceptId']);
        }));
        editableService.onSave = function () { return _this.conceptViewModel.saveConcept(_this.confirmLinkRemoval.bind(_this)); };
        editableService.onCanceled = function () { return _this.conceptViewModel.resetConcept(); };
        editableService.onRemove = function () {
            return deleteConfirmationModal.open(requireDefined(_this.concept))
                .then(function () { return _this.conceptViewModel.removeConcept(); });
        };
        this.subscriptionToClean.push(this.conceptViewModel.resourceSelect$.subscribe(function (concept) {
            if (!concept.persistent && !editableService.editing) {
                editableService.edit();
            }
            else if (concept.persistent && editableService.editing) {
                editableService.cancel();
            }
        }));
    }
    Object.defineProperty(ConceptComponent.prototype, "formNode", {
        get: function () {
            return this.conceptViewModel.resourceForm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptComponent.prototype, "concept", {
        get: function () {
            return this.conceptViewModel.concept;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptComponent.prototype, "filterLanguage", {
        get: function () {
            return this.languageService.filterLanguage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptComponent.prototype, "vocabulary", {
        get: function () {
            return this.conceptViewModel.vocabulary;
        },
        enumerable: true,
        configurable: true
    });
    ConceptComponent.prototype.ngOnDestroy = function () {
        for (var _i = 0, _a = this.subscriptionToClean; _i < _a.length; _i++) {
            var subscription = _a[_i];
            subscription.unsubscribe();
        }
    };
    ConceptComponent.prototype.isEditing = function () {
        return this.editableService.editing;
    };
    ConceptComponent.prototype.cancelEditing = function () {
        this.editableService.cancel();
    };
    ConceptComponent.prototype.confirmLinkRemoval = function (proposed, previous) {
        var _this = this;
        var proposedIds = {};
        proposed.getAllReferences()
            .filter(function (ref) { return ref.concept || ref.conceptLink; })
            .map(function (ref) { return ref.values; })
            .forEach(function (valArr) { return valArr.forEach(function (node) { return proposedIds[node.targetId || node.id] = true; }); });
        var missingRefs = [];
        previous.getAllReferences().filter(function (ref) { return ref.concept || ref.conceptLink; }).forEach(function (ref) {
            ref.values.forEach(function (node) {
                var id = node.targetId || node.id;
                if (id && !proposedIds[id]) {
                    var referenceLabel = ref.meta.label;
                    var containerLabel = void 0;
                    var targetLabel = void 0;
                    if (node instanceof ConceptNode) {
                        targetLabel = node.label;
                    }
                    else if (node instanceof ConceptLinkNode) {
                        targetLabel = node.label;
                        containerLabel = { titleLabel: node.vocabularyMetaLabel, label: node.vocabularyLabel };
                    }
                    else {
                        if (node.label) {
                            targetLabel = node.label;
                        }
                        else {
                            throw new Error('Invalid node type found under reference type "' + _this.languageService.translate(referenceLabel) + '"');
                        }
                    }
                    missingRefs.push({
                        referenceLabel: referenceLabel,
                        containerLabel: containerLabel,
                        targetLabel: targetLabel
                    });
                }
            });
        });
        if (missingRefs.length) {
            return this.removeLinkConfirmationModal.open(missingRefs).catch(function (reason) { return Promise.reject('cancel'); });
        }
        return Promise.resolve();
    };
    return ConceptComponent;
}());
export { ConceptComponent };
