import { EditableService } from 'app/services/editable.service';
import { NgForm } from '@angular/forms';
import { VocabularyNode } from 'app/entities/node';
import { AuthorizationManager } from 'app/services/authorization-manager.sevice';
var EditableButtonsComponent = /** @class */ (function () {
    function EditableButtonsComponent(editableService, authorizationManager) {
        this.editableService = editableService;
        this.authorizationManager = authorizationManager;
    }
    Object.defineProperty(EditableButtonsComponent.prototype, "operationPending", {
        get: function () {
            return this.saving || this.removing;
        },
        enumerable: true,
        configurable: true
    });
    EditableButtonsComponent.prototype.canEdit = function () {
        // when creating new vocabulary the vocabulary is not present
        return !this.vocabulary || this.authorizationManager.canEdit(this.vocabulary);
    };
    Object.defineProperty(EditableButtonsComponent.prototype, "editing", {
        get: function () {
            return this.editableService.editing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableButtonsComponent.prototype, "saving", {
        get: function () {
            return this.editableService.saving;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditableButtonsComponent.prototype, "removing", {
        get: function () {
            return this.editableService.removing;
        },
        enumerable: true,
        configurable: true
    });
    EditableButtonsComponent.prototype.startEditing = function () {
        this.editableService.edit();
    };
    EditableButtonsComponent.prototype.canSave = function () {
        return !this.form.invalid && !this.form.pending;
    };
    EditableButtonsComponent.prototype.cancelEditing = function () {
        this.editableService.cancel();
    };
    EditableButtonsComponent.prototype.saveEdited = function () {
        this.editableService.save();
    };
    EditableButtonsComponent.prototype.remove = function () {
        this.editableService.remove();
    };
    return EditableButtonsComponent;
}());
export { EditableButtonsComponent };
