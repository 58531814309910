
    <div #popover role="tooltip" class="popover">

      <h3 class="popover-header">
        <span translate>External link</span>
        <div class="actions">
          <span [id]="id + '_unlink_popover_button'" class="btn btn-sm btn-action" (click)="unlink.next()" translate>Unlink</span>
        </div>
      </h3>

      <div class="popover-body">{{target}}</div>
    </div>
  