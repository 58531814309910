var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
import { ElementRef, EventEmitter, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { requireDefined } from 'yti-common-ui/utils/object';
import { BehaviorSubject } from 'rxjs';
var DragSortableDirective = /** @class */ (function () {
    function DragSortableDirective(zone) {
        this.zone = zone;
        this.dragDisabled = false;
        this.positionChange = new EventEmitter();
        this.disabled$ = new BehaviorSubject(false);
        this.drag$ = new BehaviorSubject(null);
        this.dragValuesOriginal = null;
    }
    DragSortableDirective.prototype.ngOnChanges = function (changes) {
        var previouslyDisabled = this.disabled$.getValue();
        if ((this.dragDisabled && !previouslyDisabled) || !this.dragDisabled && previouslyDisabled) {
            this.disabled$.next(this.dragDisabled);
        }
    };
    DragSortableDirective.prototype.startDrag = function (dataTransfer, fromIndex, sourceWidth) {
        dataTransfer.setData('text', '');
        dataTransfer.dropEffect = 'move';
        dataTransfer.effectAllowed = 'move';
        this.drag = { fromIndex: fromIndex, droppable: true, cloneCreated: false, sourceWidth: sourceWidth };
        this.dragValuesOriginal = this.sortable.sortableValues.slice();
    };
    Object.defineProperty(DragSortableDirective.prototype, "drag", {
        get: function () {
            return this.drag$.getValue();
        },
        set: function (value) {
            this.drag$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    DragSortableDirective.prototype.cloneCreated = function () {
        if (this.drag) {
            this.drag.cloneCreated = true;
        }
    };
    DragSortableDirective.prototype.overDroppable = function (toIndex, targetWidth, mousePosition) {
        var _this = this;
        if (this.drag) {
            var sourceWidth = this.drag.sourceWidth;
            var toLeft = toIndex < this.drag.fromIndex;
            var stableDropRegion = toLeft ? mousePosition < sourceWidth : mousePosition > targetWidth - sourceWidth;
            if (stableDropRegion) {
                if (this.canDrop(toIndex)) {
                    var fromIndex_1 = this.drag.fromIndex;
                    this.zone.run(function () {
                        _this.drag = __assign({}, _this.drag, { fromIndex: toIndex, droppable: true });
                        _this.sortable.moveItem(fromIndex_1, toIndex);
                        _this.positionChange.emit({ fromIndex: fromIndex_1, toIndex: toIndex });
                    });
                }
                else {
                    this.drag = __assign({}, this.drag, { droppable: true });
                }
            }
        }
    };
    DragSortableDirective.prototype.notOverDroppable = function () {
        if (this.drag) {
            this.drag = __assign({}, this.drag, { droppable: false });
        }
    };
    DragSortableDirective.prototype.canDrop = function (index) {
        return this.drag ? this.drag.fromIndex !== index : false;
    };
    DragSortableDirective.prototype.drop = function () {
        var _this = this;
        if (this.drag && !this.drag.droppable) {
            var dragValuesOriginal_1 = this.dragValuesOriginal;
            this.zone.run(function () {
                _this.sortable.sortableValues = requireDefined(dragValuesOriginal_1);
            });
        }
        this.drag = null;
        this.dragValuesOriginal = null;
    };
    return DragSortableDirective;
}());
export { DragSortableDirective };
var DragSortableItemDirective = /** @class */ (function () {
    function DragSortableItemDirective(dragSortable, zone, elementRef) {
        var _this = this;
        this.dragSortable = dragSortable;
        this.zone = zone;
        this.subscriptionsToClean = [];
        this.dragStartHandler = function (event) {
            return _this.dragSortable.startDrag(event.dataTransfer, _this.index, _this.element.getBoundingClientRect().width);
        };
        this.dragEndHandler = function () {
            return _this.dragSortable.drop();
        };
        this.dragOverHandler = function (event) {
            if (_this.dragSortable.drag) {
                event.preventDefault();
                var mousePosition = event.clientX - _this.element.getBoundingClientRect().left;
                _this.dragSortable.overDroppable(_this.index, _this.element.getBoundingClientRect().width, mousePosition);
            }
        };
        this.dragLeaveHandler = function () {
            return _this.dragSortable.notOverDroppable();
        };
        this.dragEnterHandler = function () {
            return _this.dragSortable.cloneCreated();
        };
        this.dropHandler = function (event) {
            event.preventDefault();
            _this.dragSortable.drop();
        };
        this.element = elementRef.nativeElement;
    }
    DragSortableItemDirective.prototype.ngOnInit = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            _this.subscriptionsToClean.push(_this.dragSortable.disabled$.subscribe(function (disabled) {
                if (disabled) {
                    _this.disable();
                }
                else {
                    _this.enable();
                }
            }));
            _this.subscriptionsToClean.push(_this.dragSortable.drag$.subscribe(function (drag) {
                var dragReady = drag ? drag.cloneCreated : false;
                var dragged = dragReady && drag.fromIndex === _this.index;
                var droppable = dragReady && drag.droppable;
                if (dragged) {
                    _this.element.classList.add('dragged');
                }
                else {
                    _this.element.classList.remove('dragged');
                }
                if (droppable) {
                    _this.element.classList.add('droppable');
                }
                else {
                    _this.element.classList.remove('droppable');
                }
            }));
        });
    };
    DragSortableItemDirective.prototype.ngOnDestroy = function () {
        this.disable();
        this.subscriptionsToClean.forEach(function (s) { return s.unsubscribe(); });
        this.subscriptionsToClean = [];
    };
    DragSortableItemDirective.prototype.enable = function () {
        var _this = this;
        this.zone.runOutsideAngular(function () {
            _this.element.setAttribute('draggable', 'true');
            _this.element.addEventListener('dragstart', _this.dragStartHandler);
            _this.element.addEventListener('dragend', _this.dragEndHandler);
            _this.element.addEventListener('dragover', _this.dragOverHandler);
            _this.element.addEventListener('dragleave', _this.dragLeaveHandler);
            _this.element.addEventListener('dragenter', _this.dragEnterHandler);
            _this.element.addEventListener('drop', _this.dropHandler);
        });
    };
    DragSortableItemDirective.prototype.disable = function () {
        this.element.setAttribute('draggable', 'false');
        this.element.removeEventListener('dragstart', this.dragStartHandler);
        this.element.removeEventListener('dragend', this.dragEndHandler);
        this.element.removeEventListener('dragover', this.dragOverHandler);
        this.element.removeEventListener('dragleave', this.dragLeaveHandler);
        this.element.removeEventListener('dragenter', this.dragEnterHandler);
        this.element.removeEventListener('drop', this.dropHandler);
    };
    return DragSortableItemDirective;
}());
export { DragSortableItemDirective };
