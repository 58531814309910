import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ConceptViewModelService } from 'app/services/concept.view.service';
import { SessionService } from 'app/services/session.service';
import { ConceptNetworkComponent } from 'app/components/visualization/concept-network.component';
import { CollectionComponent } from 'app/components/collection/collection.component';
import { ConceptComponent } from './concept.component';
var ConceptsComponent = /** @class */ (function () {
    function ConceptsComponent(route, viewModel, sessionService, domSanitizer) {
        this.route = route;
        this.viewModel = viewModel;
        this.sessionService = sessionService;
        this.domSanitizer = domSanitizer;
        if (route.children.length > 0) {
            var childComponent = this.route.children[0].component;
            if (childComponent === CollectionComponent) {
                this.initialTabId = 'concepts_collection_tab';
            }
        }
    }
    Object.defineProperty(ConceptsComponent.prototype, "showDivider", {
        get: function () {
            return this.showSelection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptsComponent.prototype, "showSelection", {
        get: function () {
            return this.viewModel.selection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptsComponent.prototype, "showVisualization", {
        get: function () {
            return !this.conceptNetwork.isEmpty();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptsComponent.prototype, "selectionWidth", {
        get: function () {
            return this.sessionService.selectionWidth + 'px';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptsComponent.prototype, "visualizationWidth", {
        get: function () {
            return this.domSanitizer.bypassSecurityTrustStyle(this.showSelection ? "calc(100% - " + this.sessionService.selectionWidth + "px)" : '100%');
        },
        enumerable: true,
        configurable: true
    });
    ConceptsComponent.prototype.onOutletActivate = function ($event) {
        if ($event instanceof ConceptComponent) {
            this.childComponent = $event;
        }
        else if ($event instanceof CollectionComponent) {
            this.childComponent = $event;
        }
        else {
            this.childComponent = undefined;
        }
    };
    ConceptsComponent.prototype.isEditing = function () {
        if (this.childComponent) {
            return this.childComponent.isEditing();
        }
        return false;
    };
    ConceptsComponent.prototype.cancelEditing = function () {
        if (this.childComponent) {
            this.childComponent.cancelEditing();
        }
    };
    return ConceptsComponent;
}());
export { ConceptsComponent };
