/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./semantic-text-links.component";
import * as i2 from "../../services/configuration.service";
import * as i3 from "./semantic-text-links.component.scss.shim.ngstyle";
import * as i4 from "@angular/common";
import * as i5 from "@ng-bootstrap/ng-bootstrap/popover/popover";
import * as i6 from "@ng-bootstrap/ng-bootstrap/popover/popover-config";
import * as i7 from "yti-common-ui/pipes/translate-value.pipe";
import * as i8 from "@angular/router";
import * as i9 from "@ngx-translate/core";
import * as i10 from "./semantic-text-plain.component.ngfactory";
import * as i11 from "./semantic-text-plain.component";
var styles_SemanticTextLinksComponent = [];
var RenderType_SemanticTextLinksComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SemanticTextLinksComponent, data: {} });
export { RenderType_SemanticTextLinksComponent as RenderType_SemanticTextLinksComponent };
export function View_SemanticTextLinksComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { self: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["self", 1]], null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["app-semantic-text-links-element", ""]], null, null, null, View_SemanticTextLinksElementComponent_0, RenderType_SemanticTextLinksElementComponent)), i0.ɵdid(3, 49152, null, 0, i1.SemanticTextLinksElementComponent, [], { node: [0, "node"], relatedConcepts: [1, "relatedConcepts"], format: [2, "format"], invalidData: [3, "invalidData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.document; var currVal_1 = _co.relatedConcepts; var currVal_2 = _co.format; var currVal_3 = _co.invalidData; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SemanticTextLinksComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-semantic-text-links", ""]], null, null, null, View_SemanticTextLinksComponent_0, RenderType_SemanticTextLinksComponent)), i0.ɵdid(1, 8503296, null, 0, i1.SemanticTextLinksComponent, [i2.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SemanticTextLinksComponentNgFactory = i0.ɵccf("[app-semantic-text-links]", i1.SemanticTextLinksComponent, View_SemanticTextLinksComponent_Host_0, { value: "value", format: "format", relatedConcepts: "relatedConcepts" }, {}, []);
export { SemanticTextLinksComponentNgFactory as SemanticTextLinksComponentNgFactory };
var styles_SemanticTextLinksElementComponent = [i3.styles];
var RenderType_SemanticTextLinksElementComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextLinksElementComponent, data: {} });
export { RenderType_SemanticTextLinksElementComponent as RenderType_SemanticTextLinksElementComponent };
function View_SemanticTextLinksElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "p", [["app-semantic-text-links-element", ""]], null, null, null, View_SemanticTextLinksElementComponent_0, RenderType_SemanticTextLinksElementComponent)), i0.ɵdid(1, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(2, { "invalid-data": 0 }), i0.ɵdid(3, 49152, null, 0, i1.SemanticTextLinksElementComponent, [], { node: [0, "node"], relatedConcepts: [1, "relatedConcepts"], format: [2, "format"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.invalidData); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit; var currVal_2 = _co.relatedConcepts; var currVal_3 = _co.format; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SemanticTextLinksElementComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 4, "a", [["triggers", "mouseenter:mouseleave"]], [[8, "className", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i5.NgbPopover, [i0.ElementRef, i0.Renderer2, i0.Injector, i0.ComponentFactoryResolver, i0.ViewContainerRef, i6.NgbPopoverConfig, i0.NgZone], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], triggers: [2, "triggers"] }, null), i0.ɵpid(131072, i7.TranslateValuePipe, [i7.LOCALIZER]), i0.ɵdid(3, 671744, null, 0, i8.RouterLinkWithHref, [i8.Router, i8.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵnov(_v.parent.parent, 8); var currVal_4 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform(_co.conceptLabel(_v.parent.parent.context.$implicit))); var currVal_5 = "mouseenter:mouseleave"; _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.link(_v.parent.parent.context.$implicit); _ck(_v, 3, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.category; var currVal_1 = i0.ɵnov(_v, 3).target; var currVal_2 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_7 = _v.parent.parent.context.$implicit.text; _ck(_v, 4, 0, currVal_7); }); }
function View_SemanticTextLinksElementComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 4, "a", [["rel", "noopener noreferrer"], ["target", "_blank"], ["triggers", "mouseenter:mouseleave"]], [[8, "className", 0], [8, "href", 4]], null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i5.NgbPopover, [i0.ElementRef, i0.Renderer2, i0.Injector, i0.ComponentFactoryResolver, i0.ViewContainerRef, i6.NgbPopoverConfig, i0.NgZone], { ngbPopover: [0, "ngbPopover"], popoverTitle: [1, "popoverTitle"], triggers: [2, "triggers"] }, null), i0.ɵpid(131072, i9.TranslatePipe, [i9.TranslateService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "i", [["class", "fas fa-external-link-alt"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_2 = i0.ɵnov(_v.parent.parent, 9); var currVal_3 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform("External link")); var currVal_4 = "mouseenter:mouseleave"; _ck(_v, 1, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.category; var currVal_1 = _v.parent.parent.context.$implicit.destination; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.parent.context.$implicit.text; _ck(_v, 3, 0, currVal_5); }); }
function View_SemanticTextLinksElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_4)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_5)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.category === "internal"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.parent.context.$implicit.category === "external"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_SemanticTextLinksElementComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.text; _ck(_v, 1, 0, currVal_0); }); }
function View_SemanticTextLinksElementComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["app-semantic-text-plain", ""]], null, null, null, i10.View_SemanticTextPlainComponent_0, i10.RenderType_SemanticTextPlainComponent)), i0.ɵdid(1, 8962048, null, 0, i11.SemanticTextPlainComponent, [i2.ConfigurationService], { value: [0, "value"], format: [1, "format"] }, null), i0.ɵpid(131072, i7.TranslateValuePipe, [i7.LOCALIZER])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.conceptDefinition(_v.parent.context.$implicit))); var currVal_1 = _co.format; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SemanticTextLinksElementComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.destination; _ck(_v, 1, 0, currVal_0); }); }
function View_SemanticTextLinksElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_2)), i0.ɵdid(3, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_3)), i0.ɵdid(5, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_6)), i0.ɵdid(7, 278528, null, 0, i4.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵand(0, [["popContent", 2]], null, 0, null, View_SemanticTextLinksElementComponent_7)), (_l()(), i0.ɵand(0, [["popContentExt", 2]], null, 0, null, View_SemanticTextLinksElementComponent_8))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.type; _ck(_v, 1, 0, currVal_0); var currVal_1 = "paragraph"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "link"; _ck(_v, 5, 0, currVal_2); var currVal_3 = "text"; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_SemanticTextLinksElementComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SemanticTextLinksElementComponent_1)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.node.children; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SemanticTextLinksElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["app-semantic-text-links-element", ""]], null, null, null, View_SemanticTextLinksElementComponent_0, RenderType_SemanticTextLinksElementComponent)), i0.ɵdid(1, 49152, null, 0, i1.SemanticTextLinksElementComponent, [], null, null)], null, null); }
var SemanticTextLinksElementComponentNgFactory = i0.ɵccf("[app-semantic-text-links-element]", i1.SemanticTextLinksElementComponent, View_SemanticTextLinksElementComponent_Host_0, { node: "node", relatedConcepts: "relatedConcepts", format: "format", invalidData: "invalidData" }, {}, []);
export { SemanticTextLinksElementComponentNgFactory as SemanticTextLinksElementComponentNgFactory };
