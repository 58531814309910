var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { asLocalizable } from 'yti-common-ui/utils/localization';
import { requireDefined } from 'yti-common-ui/utils/object';
import { firstMatching, flatten, normalizeAsArray, remove, requireSingle, allMatching } from 'yti-common-ui/utils/array';
import * as moment from 'moment';
import { defaultLanguages } from 'app/utils/language';
import { stripSemanticMarkup } from 'app/utils/semantic';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
var defaultStatus = 'DRAFT';
var Property = /** @class */ (function () {
    function Property(attributes, meta) {
        this.attributes = attributes;
        this.meta = meta;
    }
    Property.prototype.getSingle = function () {
        return requireSingle(this.attributes);
    };
    Property.prototype.remove = function (attribute) {
        remove(this.attributes, attribute);
    };
    Property.prototype.asValues = function () {
        return this.attributes.map(function (attr) { return attr.value.trim(); }).filter(function (v) { return !!v; });
    };
    Property.prototype.asLocalizations = function () {
        return this.attributes;
    };
    Property.prototype.asLocalizationsWithoutSemantics = function (namespaceRoot) {
        var _this = this;
        if (this.meta.type.editor.type === 'semantic') {
            return this.asLocalizations().map(function (_a) {
                var lang = _a.lang, value = _a.value;
                return ({ lang: lang, value: stripSemanticMarkup(value, _this.meta.semanticTextFormat, namespaceRoot) });
            });
        }
        else {
            return this.asLocalizations();
        }
    };
    Property.prototype.asLocalizable = function (ignoreConflicts) {
        if (ignoreConflicts === void 0) { ignoreConflicts = false; }
        return asLocalizable(this.asLocalizations(), ignoreConflicts);
    };
    Property.prototype.asLocalizableWithoutSemantics = function (namespaceRoot, ignoreConflicts) {
        if (ignoreConflicts === void 0) { ignoreConflicts = false; }
        return asLocalizable(this.asLocalizationsWithoutSemantics(namespaceRoot), ignoreConflicts);
    };
    Property.prototype.setLocalizable = function (localizable) {
        this.setLocalizations(Object.entries(localizable).map(function (_a) {
            var lang = _a[0], value = _a[1];
            return ({ lang: lang, value: value });
        }));
    };
    Property.prototype.setLocalizations = function (localizations) {
        var _this = this;
        this.attributes = localizations.map(function (_a) {
            var lang = _a.lang, value = _a.value;
            return ({ lang: lang, value: value, regex: _this.meta.regex });
        });
    };
    Property.prototype.setValues = function (values) {
        var _this = this;
        this.attributes = values.map(function (value) { return ({ lang: '', value: value, regex: _this.meta.regex }); });
    };
    Property.prototype.isEmpty = function () {
        return this.attributes.length === 0 || allMatching(this.attributes, function (attribute) { return !attribute.value; });
    };
    Property.prototype.isLocalizable = function () {
        return this.meta.isLocalizable();
    };
    Property.prototype.isStatus = function () {
        return this.meta.isStatus();
    };
    Property.prototype.isLabel = function () {
        return this.meta.isLabel();
    };
    Property.prototype.asString = function () {
        return this.asValues().join(',');
    };
    Object.defineProperty(Property.prototype, "literalValue", {
        get: function () {
            if (this.attributes.length > 0) {
                return this.attributes[0].value;
            }
            else if (this.meta.id === 'status') {
                return defaultStatus;
            }
            else {
                return '';
            }
        },
        set: function (value) {
            this.attributes = [{ lang: '', value: value, regex: this.meta.regex }];
        },
        enumerable: true,
        configurable: true
    });
    return Property;
}());
export { Property };
var Reference = /** @class */ (function () {
    function Reference(nodes, meta, metaModel) {
        this.meta = meta;
        this.metaModel = metaModel;
        this.values = nodes.map(function (node) { return Node.create(node, metaModel, true); });
    }
    Reference.prototype.addNewReference = function () {
        var value = Node.create(this.targetMeta.createEmptyNode(), this.metaModel, false);
        this.values.push(value);
        return value;
    };
    Reference.prototype.getSingle = function () {
        return requireSingle(this.values);
    };
    Object.defineProperty(Reference.prototype, "targetMeta", {
        get: function () {
            return this.metaModel.getNodeMeta(this.meta.graphId, this.meta.targetType);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reference.prototype, "type", {
        get: function () {
            return this.meta.referenceType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reference.prototype, "term", {
        get: function () {
            return this.meta.term;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reference.prototype, "conceptLink", {
        get: function () {
            return this.meta.conceptLink;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reference.prototype, "concept", {
        get: function () {
            return this.meta.concept;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Reference.prototype, "inline", {
        get: function () {
            return this.term || this.conceptLink;
        },
        enumerable: true,
        configurable: true
    });
    Reference.prototype.toIdentifiers = function () {
        return this.values.map(function (node) { return node.identifier; });
    };
    Object.defineProperty(Reference.prototype, "empty", {
        get: function () {
            return this.values.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    return Reference;
}());
export { Reference };
var Referrer = /** @class */ (function () {
    function Referrer(referenceId, values) {
        this.referenceId = referenceId;
        this.values = values;
    }
    return Referrer;
}());
export { Referrer };
var Node = /** @class */ (function () {
    function Node(node, metaModel, persistent) {
        this.node = node;
        this.metaModel = metaModel;
        this.persistent = persistent;
        this.properties = {};
        this.references = {};
        this.referrers = {};
        this.meta = metaModel.getNodeMeta(this.graphId, this.type);
        for (var _i = 0, _a = this.meta.properties; _i < _a.length; _i++) {
            var propertyMeta = _a[_i];
            var property = normalizeAsArray((node.properties || {})[propertyMeta.id]);
            this.properties[propertyMeta.id] = new Property(property, propertyMeta);
        }
        for (var _b = 0, _c = this.meta.references; _b < _c.length; _b++) {
            var referenceMeta = _c[_b];
            var reference = normalizeAsArray((node.references || {})[referenceMeta.id]);
            this.references[referenceMeta.id] = new Reference(reference, referenceMeta, metaModel);
        }
        for (var _d = 0, _e = Object.entries(node.referrers || {}); _d < _e.length; _d++) {
            var _f = _e[_d], name_1 = _f[0], referrerNodes = _f[1];
            this.referrers[name_1] = new Referrer(name_1, normalizeAsArray(referrerNodes));
        }
    }
    Node.create = function (node, metaModel, persistent) {
        switch (node.type.id) {
            case 'Vocabulary':
            case 'TerminologicalVocabulary':
                return new VocabularyNode(node, metaModel, persistent);
            case 'Concept':
                return new ConceptNode(node, metaModel, persistent);
            case 'ConceptLink':
                return new ConceptLinkNode(node, metaModel, persistent);
            case 'Term':
                return new TermNode(node, metaModel, persistent);
            case 'Collection':
                return new CollectionNode(node, metaModel, persistent);
            case 'Group':
                return new GroupNode(node, metaModel, persistent);
            case 'Organization':
                return new OrganizationNode(node, metaModel, persistent);
            default:
                return new Node(node, metaModel, persistent);
        }
    };
    Node.prototype.toNodeWithoutReferencesAndReferrers = function () {
        var _this = this;
        var serializeProperties = function () {
            var result = {};
            for (var _i = 0, _a = Object.entries(_this.properties); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], property = _b[1];
                result[key] = property.attributes;
            }
            return result;
        };
        return {
            id: this.node.id,
            type: this.node.type,
            code: this.node.code,
            createdBy: this.node.createdBy,
            createdDate: this.node.createdDate,
            lastModifiedBy: this.node.lastModifiedBy,
            lastModifiedDate: this.node.lastModifiedDate,
            uri: this.node.uri,
            properties: serializeProperties()
        };
    };
    Node.prototype.toExternalNode = function () {
        var _this = this;
        var extractReferences = function () {
            var result = {};
            for (var _i = 0, _a = Object.entries(_this.references); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], reference = _b[1];
                result[key] = reference.values.map(function (node) { return node.toExternalNode(); });
            }
            return result;
        };
        return Object.assign(this.toNodeWithoutReferencesAndReferrers(), {
            referrers: this.node.referrers,
            references: extractReferences()
        });
    };
    Node.prototype.toInternalNode = function () {
        var _this = this;
        var extractReferences = function () {
            var result = {};
            for (var _i = 0, _a = Object.entries(_this.references); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], reference = _b[1];
                result[key] = reference.toIdentifiers();
            }
            return result;
        };
        var extractReferrers = function () {
            var result = {};
            for (var _i = 0, _a = Object.entries(_this.referrers); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], referrer = _b[1];
                result[key] = referrer.values.map(function (ref) { return ({
                    id: ref.id,
                    type: ref.type
                }); });
            }
            return result;
        };
        return Object.assign(this.toNodeWithoutReferencesAndReferrers(), {
            referrers: extractReferrers(),
            references: extractReferences()
        });
    };
    Node.prototype.clone = function () {
        var setPersistent = function (original, clone) {
            clone.persistent = original.persistent;
            for (var _i = 0, _a = Object.entries(original.references); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], reference = _b[1];
                var cloneReferenceValues = clone.references[key].values;
                var _loop_1 = function (refNode) {
                    setPersistent(refNode, requireDefined(cloneReferenceValues.find(function (clonedRefNode) { return refNode.id === clonedRefNode.id; })));
                };
                for (var _c = 0, _d = reference.values; _c < _d.length; _c++) {
                    var refNode = _d[_c];
                    _loop_1(refNode);
                }
            }
        };
        var cloned = Node.create(JSON.parse(JSON.stringify(this.toExternalNode())), this.metaModel, this.persistent);
        setPersistent(this, cloned);
        return cloned;
    };
    Object.defineProperty(Node.prototype, "identifier", {
        get: function () {
            return { id: this.id, type: this.node.type };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "idIdentifier", {
        get: function () {
            return this.uri ? this.uri : 'new_node';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "id", {
        get: function () {
            return this.node.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "code", {
        get: function () {
            return this.node.code;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "uri", {
        get: function () {
            return this.node.uri;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "graphId", {
        get: function () {
            return this.node.type.graph.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "type", {
        get: function () {
            return this.node.type.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "createdBy", {
        get: function () {
            return this.node.createdBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "createdDate", {
        get: function () {
            return moment(this.node.createdDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "lastModifiedBy", {
        get: function () {
            return this.node.lastModifiedBy;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "lastModifiedDate", {
        get: function () {
            return moment(this.node.lastModifiedDate);
        },
        set: function (date) {
            this.node.lastModifiedDate = date.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "concept", {
        get: function () {
            return this.meta.concept;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "term", {
        get: function () {
            return this.meta.term;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Node.prototype, "typeLabel", {
        get: function () {
            return this.meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Node.prototype.hasStatus = function () {
        return this.meta.hasProperty('status');
    };
    Object.defineProperty(Node.prototype, "status", {
        get: function () {
            var status = this.getProperty('status').literalValue;
            return status ? status : defaultStatus;
        },
        set: function (status) {
            this.getProperty('status').literalValue = status;
        },
        enumerable: true,
        configurable: true
    });
    Node.prototype.getAllProperties = function () {
        return Object.values(this.properties);
    };
    Node.prototype.getAllReferences = function () {
        return Object.values(this.references);
    };
    Node.prototype.getAllReferrers = function () {
        return Object.values(this.referrers);
    };
    Node.prototype.getNormalizedReferrer = function (referenceId) {
        return this.referrers[referenceId] || new Referrer(referenceId, []);
    };
    Node.prototype.getReference = function (referenceId) {
        return requireDefined(this.findReference(referenceId), 'Reference not found: ' + referenceId);
    };
    Node.prototype.findReference = function (referenceId) {
        return this.references[referenceId] || null;
    };
    Node.prototype.getProperty = function (propertyName) {
        return requireDefined(this.findProperty(propertyName), 'Property not found: ' + propertyName);
    };
    Node.prototype.findProperty = function (propertyName) {
        return this.properties[propertyName] || null;
    };
    return Node;
}());
export { Node };
var VocabularyNode = /** @class */ (function (_super) {
    __extends(VocabularyNode, _super);
    function VocabularyNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(VocabularyNode.prototype, "label", {
        get: function () {
            return asLocalizable(this.prefLabel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyNode.prototype, "description", {
        get: function () {
            // FIXME: How to handle multiple descriptions?
            return this.getProperty('description').asLocalizable(true);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyNode.prototype, "prefLabel", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizations();
        },
        set: function (value) {
            this.getProperty('prefLabel').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyNode.prototype, "priority", {
        get: function () {
            return this.getProperty('priority').literalValue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyNode.prototype, "contributors", {
        get: function () {
            return this.getReference('contributor').values;
        },
        enumerable: true,
        configurable: true
    });
    VocabularyNode.prototype.hasLanguage = function () {
        return this.meta.hasProperty('language');
    };
    Object.defineProperty(VocabularyNode.prototype, "languages", {
        get: function () {
            if (this.meta.hasProperty('language')) {
                var languages = this.getProperty('language').asValues();
                return languages.length > 0 ? languages : defaultLanguages;
            }
            else {
                return defaultLanguages;
            }
        },
        set: function (value) {
            this.getProperty('language').setValues(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VocabularyNode.prototype, "groups", {
        get: function () {
            return this.getReference('inGroup').values;
        },
        enumerable: true,
        configurable: true
    });
    VocabularyNode.prototype.hasGroup = function () {
        return !this.getReference('inGroup').empty;
    };
    return VocabularyNode;
}(Node));
export { VocabularyNode };
var ConceptNode = /** @class */ (function (_super) {
    __extends(ConceptNode, _super);
    function ConceptNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(ConceptNode.prototype, "label", {
        get: function () {
            return asLocalizable(this.prefLabel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "prefLabel", {
        get: function () {
            return this.getTermOrPropertyLabel('prefLabelXl', 'prefLabel');
        },
        set: function (localizations) {
            this.setTermOrPropertyLabel('prefLabelXl', 'prefLabel', localizations);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "altLabel", {
        get: function () {
            return this.getTermOrPropertyLabel('altLabelXl', 'altLabel');
        },
        set: function (value) {
            this.setTermOrPropertyLabel('altLabelXl', 'altLabel', value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "example", {
        get: function () {
            return this.getProperty('example').asLocalizations();
        },
        set: function (value) {
            this.getProperty('example').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "note", {
        get: function () {
            return this.getProperty('note').asLocalizations();
        },
        set: function (value) {
            this.getProperty('note').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.getTermOrPropertyLabel = function (termName, propertyName) {
        var labelProperty = this.findProperty(propertyName);
        var termReference = this.findReference(termName);
        if (labelProperty) {
            return labelProperty.asLocalizations();
        }
        else if (termReference) {
            return flatten(termReference.values.map(function (term) { return term.getProperty('prefLabel').asLocalizations(); }));
        }
        else {
            throw new Error('No label found');
        }
    };
    ConceptNode.prototype.setTermOrPropertyLabel = function (termName, propertyName, localizations) {
        var labelProperty = this.findProperty(propertyName);
        var termReference = this.findReference(termName);
        if (labelProperty) {
            labelProperty.setLocalizations(localizations);
        }
        else if (termReference) {
            var _loop_2 = function (lang, value) {
                var term = firstMatching(termReference.values, function (t) { return t.language === lang; }) || termReference.addNewReference();
                term.prefLabel = (_a = {}, _a[lang] = value, _a);
                var _a;
            };
            for (var _i = 0, localizations_1 = localizations; _i < localizations_1.length; _i++) {
                var _a = localizations_1[_i], lang = _a.lang, value = _a.value;
                _loop_2(lang, value);
            }
        }
    };
    Object.defineProperty(ConceptNode.prototype, "definition", {
        get: function () {
            return this.getProperty('definition').asLocalizations();
        },
        set: function (value) {
            this.getProperty('definition').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "definitionSemanticTextFormat", {
        get: function () {
            return this.getProperty('definition').meta.semanticTextFormat;
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.getDefinitionWithoutSemantics = function (namespaceRoot) {
        return this.getProperty('definition').asLocalizationsWithoutSemantics(namespaceRoot);
    };
    ConceptNode.prototype.isTargetOfLink = function (link) {
        if (link === this.uri) {
            return true;
        }
        // FIXME: This was:
        //  (isDefined(this.code) && (link.indexOf(this.code) !== -1))
        //  Which obviously fails if this.code === '4' and other.code === '42'. The following fix requires the URI to end with full code.
        //  But the whole purpose of this "might be related" is unclear. If this should match 'foo/<code>/bar' then the fixed version fails.
        var mightBeRelatedToThisFromImportedData = !!link && !!this.code && link.endsWith('/' + this.code);
        if (mightBeRelatedToThisFromImportedData) {
            console.warn('Accepting code-based concept match with URIs "' + this.uri + '", "' + link + '"');
        }
        return mightBeRelatedToThisFromImportedData;
    };
    ConceptNode.prototype.hasVocabulary = function () {
        return this.meta.hasReference('inScheme');
    };
    Object.defineProperty(ConceptNode.prototype, "vocabulary", {
        get: function () {
            return this.getReference('inScheme').getSingle();
        },
        set: function (vocabulary) {
            this.getReference('inScheme').values = [vocabulary];
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.hasRelatedConcepts = function () {
        return this.meta.hasReference('related');
    };
    Object.defineProperty(ConceptNode.prototype, "relatedConcepts", {
        get: function () {
            return this.getReference('related');
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.hasBroaderConcepts = function () {
        return this.meta.hasReference('broader');
    };
    Object.defineProperty(ConceptNode.prototype, "broaderConcepts", {
        get: function () {
            return this.getReference('broader');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "narrowerConcepts", {
        get: function () {
            return this.getNormalizedReferrer('broader');
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.hasIsPartOfConcepts = function () {
        return this.meta.hasReference('isPartOf');
    };
    Object.defineProperty(ConceptNode.prototype, "isPartOfConcepts", {
        get: function () {
            return this.getReference('isPartOf');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptNode.prototype, "partOfThisConcepts", {
        get: function () {
            return this.getNormalizedReferrer('isPartOf');
        },
        enumerable: true,
        configurable: true
    });
    ConceptNode.prototype.getConceptReferences = function () {
        return this.getAllReferences().filter(function (ref) { return ref.concept; });
    };
    return ConceptNode;
}(Node));
export { ConceptNode };
var TermNode = /** @class */ (function (_super) {
    __extends(TermNode, _super);
    function TermNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(TermNode.prototype, "language", {
        get: function () {
            var attribute = this.getProperty('prefLabel').getSingle();
            if (attribute.lang.trim() === '') {
                throw new Error('Cannot determine language');
            }
            return attribute.lang;
        },
        enumerable: true,
        configurable: true
    });
    TermNode.prototype.isValid = function () {
        var prefLabel = this.findProperty('prefLabel');
        return prefLabel && prefLabel.attributes.length === 1 && prefLabel.getSingle().lang !== '';
    };
    Object.defineProperty(TermNode.prototype, "prefLabel", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizable();
        },
        set: function (value) {
            this.getProperty('prefLabel').setLocalizable(value);
        },
        enumerable: true,
        configurable: true
    });
    return TermNode;
}(Node));
export { TermNode };
var ConceptLinkNode = /** @class */ (function (_super) {
    __extends(ConceptLinkNode, _super);
    function ConceptLinkNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(ConceptLinkNode.prototype, "label", {
        get: function () {
            return asLocalizable(this.prefLabel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptLinkNode.prototype, "prefLabel", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizations();
        },
        set: function (value) {
            this.getProperty('prefLabel').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptLinkNode.prototype, "vocabularyMetaLabel", {
        get: function () {
            return this.getProperty('vocabularyLabel').meta.label;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptLinkNode.prototype, "vocabularyLabel", {
        get: function () {
            return this.getProperty('vocabularyLabel').asLocalizable();
        },
        set: function (value) {
            this.getProperty('vocabularyLabel').setLocalizable(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptLinkNode.prototype, "targetGraph", {
        get: function () {
            return this.getProperty('targetGraph').literalValue;
        },
        set: function (value) {
            this.getProperty('targetGraph').literalValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptLinkNode.prototype, "targetId", {
        get: function () {
            return this.getProperty('targetId').literalValue;
        },
        set: function (value) {
            this.getProperty('targetId').literalValue = value;
        },
        enumerable: true,
        configurable: true
    });
    return ConceptLinkNode;
}(Node));
export { ConceptLinkNode };
var CollectionNode = /** @class */ (function (_super) {
    __extends(CollectionNode, _super);
    function CollectionNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(CollectionNode.prototype, "label", {
        get: function () {
            return asLocalizable(this.prefLabel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionNode.prototype, "prefLabel", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizations();
        },
        set: function (value) {
            this.getProperty('prefLabel').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CollectionNode.prototype, "definition", {
        get: function () {
            return this.getProperty('definition').asLocalizations();
        },
        set: function (value) {
            this.getProperty('definition').setLocalizations(value);
        },
        enumerable: true,
        configurable: true
    });
    CollectionNode.prototype.getDefinitionWithoutSemantics = function (namespaceRoot) {
        return this.getProperty('definition').asLocalizationsWithoutSemantics(namespaceRoot);
    };
    Object.defineProperty(CollectionNode.prototype, "memberConcepts", {
        get: function () {
            return this.getReference('member');
        },
        enumerable: true,
        configurable: true
    });
    CollectionNode.prototype.hasBroaderConcepts = function () {
        return this.meta.hasReference('broader');
    };
    Object.defineProperty(CollectionNode.prototype, "broaderConcepts", {
        get: function () {
            return this.getReference('broader');
        },
        enumerable: true,
        configurable: true
    });
    return CollectionNode;
}(Node));
export { CollectionNode };
var GroupNode = /** @class */ (function (_super) {
    __extends(GroupNode, _super);
    function GroupNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(GroupNode.prototype, "label", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizable();
        },
        enumerable: true,
        configurable: true
    });
    return GroupNode;
}(Node));
export { GroupNode };
var OrganizationNode = /** @class */ (function (_super) {
    __extends(OrganizationNode, _super);
    function OrganizationNode(node, metaModel, persistent) {
        return _super.call(this, node, metaModel, persistent) || this;
    }
    Object.defineProperty(OrganizationNode.prototype, "label", {
        get: function () {
            return this.getProperty('prefLabel').asLocalizable();
        },
        enumerable: true,
        configurable: true
    });
    OrganizationNode.prototype.getIdIdentifier = function (localizer, useUILanguage) {
        if (useUILanguage === void 0) { useUILanguage = false; }
        return labelNameToResourceIdIdentifier(localizer.translate(this.label, useUILanguage));
    };
    return OrganizationNode;
}(Node));
export { OrganizationNode };
