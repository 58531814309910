import { OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
var FilterLanguageComponent = /** @class */ (function () {
    function FilterLanguageComponent(translateService) {
        var _this = this;
        this.translateService = translateService;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        this.subscriptions = [];
        this.subscriptions.push(this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); }));
        this.subscriptions.push(translateService.onLangChange.subscribe(function () { return _this.updateOptions(); }));
    }
    FilterLanguageComponent.prototype.ngOnInit = function () {
        this.updateOptions();
    };
    FilterLanguageComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    FilterLanguageComponent.prototype.updateOptions = function () {
        var _this = this;
        this.options = [''].concat(this.languages).map(function (lang) { return ({
            lang: lang,
            name: _this.languageToOptionName(lang)
        }); });
    };
    Object.defineProperty(FilterLanguageComponent.prototype, "selection", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterLanguageComponent.prototype, "selectionName", {
        get: function () {
            return this.languageToSelectionName(this.selection);
        },
        enumerable: true,
        configurable: true
    });
    FilterLanguageComponent.prototype.getLangSelectionId = function (lang) {
        return lang ? lang + '_lang_selection_button' : 'all_lang_selection_button';
    };
    FilterLanguageComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    FilterLanguageComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    FilterLanguageComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    FilterLanguageComponent.prototype.languageToSelectionName = function (lang) {
        return lang ? lang.toUpperCase()
            : this.translateService.instant('Content language');
    };
    FilterLanguageComponent.prototype.languageToOptionName = function (lang) {
        return lang ? this.translateService.instant('Content in') + ' ' + lang.toUpperCase()
            : this.translateService.instant('Content in all languages');
    };
    return FilterLanguageComponent;
}());
export { FilterLanguageComponent };
