import { OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from './location.service';
import { TermedService } from './termed.service';
import { BehaviorSubject, combineLatest, concat, merge, NEVER, Subject, zip } from 'rxjs';
import { debounceTime, filter, flatMap, map, skip, switchMap, take } from 'rxjs/operators';
import { comparingLocalizable } from 'yti-common-ui/utils/comparator';
import { LanguageService } from './language.service';
import { MetaModelService } from './meta-model.service';
import { createEditAction, createNoSelection, createRemoveAction, createSelectAction, extractItem, isEdit, isRemove, isSelect } from './action';
import { ElasticSearchService } from './elasticsearch.service';
import { assertNever } from 'yti-common-ui/utils/object';
import { contains, removeMatching, replaceMatching } from 'yti-common-ui/utils/array';
import { FormNode } from './form-state';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { filterByPrefixPostfixSearch, splitSearchString } from 'yti-common-ui/utils/search';
function onlySelect(action) {
    return action.pipe(filter(isSelect), map(extractItem));
}
function onlyEdit(action) {
    return action.pipe(filter(isEdit), map(extractItem));
}
function onlyRemove(action) {
    return action.pipe(filter(isRemove), map(extractItem));
}
function updateOrRemoveItem(subject, id, newItem) {
    if (newItem) {
        return updateItem(subject, id, newItem);
    }
    else {
        return removeItem(subject, id);
    }
}
function updateItem(subject, id, newItem) {
    var previousCopy = subject.getValue().slice();
    if (replaceMatching(previousCopy, function (item) { return item.id === id; }, newItem)) {
        subject.next(previousCopy);
        return true;
    }
    else {
        return false;
    }
}
function removeItem(subject, id) {
    var previousCopy = subject.getValue().slice();
    if (removeMatching(previousCopy, function (item) { return item.id === id; })) {
        subject.next(previousCopy);
        return true;
    }
    else {
        return false;
    }
}
var ConceptListModel = /** @class */ (function () {
    function ConceptListModel(elasticSearchService, languageService) {
        var _this = this;
        this.elasticSearchService = elasticSearchService;
        this.search$ = new BehaviorSubject('');
        this.sortByTime$ = new BehaviorSubject(false);
        this.onlyStatus$ = new BehaviorSubject(null);
        this.searchResults$ = new BehaviorSubject([]);
        this.badSearchRequest$ = new BehaviorSubject({ error: false });
        this.loading = false;
        this.initializing$ = new Subject();
        this.loaded = 0;
        this.canLoadMore = true;
        this.subscriptionToClean = [];
        var initialSearch = this.search$.pipe(take(1));
        var debouncedSearch = this.search$.pipe(skip(1), debounceTime(500));
        var search = concat(initialSearch, debouncedSearch);
        var conditionChange = combineLatest(search, this.sortByTime$, this.onlyStatus$, languageService.translateLanguage$);
        this.subscriptionToClean.push(this.initializing$.pipe(switchMap(function (initializing) { return initializing ? NEVER : conditionChange; }))
            .subscribe(function () { return _this.loadConcepts(true); }));
    }
    Object.defineProperty(ConceptListModel.prototype, "searchResults", {
        get: function () {
            return this.searchResults$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptListModel.prototype, "search", {
        get: function () {
            return this.search$.getValue();
        },
        set: function (value) {
            this.search$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptListModel.prototype, "sortByTime", {
        get: function () {
            return this.sortByTime$.getValue();
        },
        set: function (value) {
            this.sortByTime$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptListModel.prototype, "onlyStatus", {
        get: function () {
            return this.onlyStatus$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    ConceptListModel.prototype.loadConcepts = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        var batchSize = 100;
        if (reset) {
            this.loaded = 0;
            this.canLoadMore = true;
        }
        if (this.canLoadMore) {
            this.loading = true;
            this.elasticSearchService.getAllConceptsForVocabulary(this.graphId, this.search, this.sortByTime, this.onlyStatus, this.loaded, batchSize)
                .subscribe(function (concepts) {
                _this.clearBadSearchRequest();
                if (concepts.length < batchSize) {
                    _this.canLoadMore = false;
                }
                _this.loaded += concepts.length;
                _this.searchResults$.next(reset ? concepts : _this.searchResults.concat(concepts));
                _this.loading = false;
            }, this.setBadSearchRequest.bind(this));
        }
    };
    ConceptListModel.prototype.refresh = function (conceptId, remove) {
        var _this = this;
        if (remove) {
            removeItem(this.searchResults$, conceptId);
        }
        else {
            this.elasticSearchService.findSingleConceptForVocabulary(this.graphId, conceptId, this.search, this.sortByTime, this.onlyStatus)
                .subscribe(function (indexedConcept) {
                _this.clearBadSearchRequest();
                if (!updateOrRemoveItem(_this.searchResults$, conceptId, indexedConcept)) {
                    _this.loadConcepts(true);
                }
            }, this.setBadSearchRequest.bind(this));
        }
    };
    ConceptListModel.prototype.initializeGraph = function (graphId, initialSearch) {
        this.initializing$.next(true);
        this.graphId = graphId;
        this.search$.next(initialSearch ? initialSearch : '');
        this.sortByTime$.next(false);
        this.onlyStatus$.next(null);
        this.initializing$.next(false);
    };
    ConceptListModel.prototype.clean = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    ConceptListModel.prototype.setBadSearchRequest = function (err) {
        this.loading = false;
        if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500) {
            this.badSearchRequest$.next({ error: true, message: err.message });
            this.searchResults$.next([]);
        }
        else {
            console.error('Concept search failed: ' + JSON.stringify(err));
        }
    };
    ConceptListModel.prototype.clearBadSearchRequest = function () {
        if (this.badSearchRequest$.getValue().error) {
            this.badSearchRequest$.next({ error: false });
        }
    };
    return ConceptListModel;
}());
export { ConceptListModel };
var ConceptHierarchyModel = /** @class */ (function () {
    function ConceptHierarchyModel(elasticSearchService, languageService) {
        var _this = this;
        this.elasticSearchService = elasticSearchService;
        this.topConcepts$ = new BehaviorSubject([]);
        this.nodes = new Map();
        this.loading = false;
        this.initializing$ = new Subject();
        this.loaded = 0;
        this.canLoadMore = true;
        this.subscriptionToClean = [];
        var conditionChange = combineLatest(languageService.translateLanguage$);
        this.subscriptionToClean.push(this.initializing$.pipe(switchMap(function (initializing) { return initializing ? NEVER : conditionChange; }))
            .subscribe(function () { return _this.loadConcepts(true); }));
    }
    Object.defineProperty(ConceptHierarchyModel.prototype, "topConcepts", {
        get: function () {
            return this.topConcepts$.getValue();
        },
        enumerable: true,
        configurable: true
    });
    ConceptHierarchyModel.prototype.initializeGraph = function (graphId) {
        this.initializing$.next(true);
        this.graphId = graphId;
        this.loading = true;
        this.initializing$.next(false);
    };
    ConceptHierarchyModel.prototype.loadConcepts = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        var batchSize = 100;
        if (reset) {
            this.loaded = 0;
            this.canLoadMore = true;
            this.nodes.clear();
        }
        if (this.canLoadMore) {
            this.loading = true;
            this.elasticSearchService.getTopConceptsForVocabulary(this.graphId, this.loaded, batchSize)
                .subscribe(function (concepts) {
                if (concepts.length < batchSize) {
                    _this.canLoadMore = false;
                }
                _this.loaded += concepts.length;
                _this.topConcepts$.next(reset ? concepts : _this.topConcepts.concat(concepts));
                _this.loading = false;
            }, function (err) { return console.error("Loading top concepts failed: " + JSON.stringify(err)); });
        }
    };
    ConceptHierarchyModel.prototype.refresh = function (conceptId, remove) {
        var _this = this;
        if (remove) {
            removeItem(this.topConcepts$, conceptId);
            for (var _i = 0, _a = Array.from(this.nodes.values()); _i < _a.length; _i++) {
                var narrowerConcepts = _a[_i].narrowerConcepts;
                removeItem(narrowerConcepts, conceptId);
            }
        }
        else {
            this.elasticSearchService.findSingleConceptForVocabulary(this.graphId, conceptId, '', false, null)
                .subscribe(function (indexedConcept) {
                var updated = false;
                updated = updated || updateOrRemoveItem(_this.topConcepts$, conceptId, indexedConcept);
                for (var _i = 0, _a = Array.from(_this.nodes.values()); _i < _a.length; _i++) {
                    var narrowerConcepts = _a[_i].narrowerConcepts;
                    updated = updated || updateOrRemoveItem(narrowerConcepts, conceptId, indexedConcept);
                }
                if (!updated) {
                    _this.loadConcepts(true);
                }
            }, function (err) { return console.error("Refreshing a concept failed: " + JSON.stringify(err)); });
        }
    };
    ConceptHierarchyModel.prototype.getNarrowerConcepts = function (concept) {
        return this.nodes.get(concept.id).narrowerConcepts;
    };
    ConceptHierarchyModel.prototype.collapse = function (concept) {
        this.nodes.get(concept.id).expanded = false;
    };
    ConceptHierarchyModel.prototype.expand = function (concept) {
        if (!this.nodes.has(concept.id)) {
            var subject_1 = new BehaviorSubject([]);
            this.nodes.set(concept.id, { expanded: true, narrowerConcepts: subject_1 });
            this.elasticSearchService.getNarrowerConcepts(concept.vocabulary.id, concept.id)
                .subscribe(function (concepts) { return subject_1.next(concepts); }, function (err) { return console.error("Getting narrower concepts failed: " + JSON.stringify(err)); });
        }
        else {
            this.nodes.get(concept.id).expanded = true;
        }
    };
    ConceptHierarchyModel.prototype.isExpanded = function (concept) {
        var node = this.nodes.get(concept.id);
        return !!node && node.expanded;
    };
    ConceptHierarchyModel.prototype.clean = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    return ConceptHierarchyModel;
}());
export { ConceptHierarchyModel };
var CollectionListModel = /** @class */ (function () {
    function CollectionListModel(termedService, languageService) {
        this.termedService = termedService;
        this.languageService = languageService;
        this.search$ = new BehaviorSubject('');
        this.allCollections$ = new BehaviorSubject([]);
        this.loading = false;
        var initialSearch$ = this.search$.pipe(take(1));
        var debouncedSearch$ = this.search$.pipe(skip(1), debounceTime(500));
        this.debouncedSearch$ = concat(initialSearch$, debouncedSearch$);
        this.searchResults = combineLatest(this.allCollections$, this.debouncedSearch$, languageService.translateLanguage$).pipe(map(function (_a) {
            var collections = _a[0], search = _a[1];
            var searchParts = splitSearchString(search);
            if (searchParts) {
                return filterByPrefixPostfixSearch(collections, searchParts, function (c) { return c.label; });
            }
            return collections;
        }));
    }
    CollectionListModel.prototype.initializeGraph = function (graphId) {
        var _this = this;
        this.graphId = graphId;
        this.loading = true;
        this.termedService.getCollectionList(graphId).subscribe(function (collections) {
            var sortedCollections = collections.sort(comparingLocalizable(_this.languageService, function (collection) { return collection.label; }));
            _this.allCollections$.next(sortedCollections);
            _this.loading = false;
        });
    };
    CollectionListModel.prototype.refresh = function (collectionId, remove) {
        var _this = this;
        if (remove) {
            removeItem(this.allCollections$, collectionId);
        }
        else {
            this.termedService.getCollection(this.graphId, collectionId)
                .subscribe(function (collection) {
                if (!updateItem(_this.allCollections$, collectionId, collection)) {
                    _this.initializeGraph(_this.graphId);
                }
            });
        }
    };
    CollectionListModel.prototype.clean = function () {
        // nop for now
    };
    return CollectionListModel;
}());
export { CollectionListModel };
var ConceptViewModelService = /** @class */ (function () {
    function ConceptViewModelService(router, termedService, elasticSearchService, metaModelService, locationService, languageService, translateService) {
        var _this = this;
        this.router = router;
        this.termedService = termedService;
        this.elasticSearchService = elasticSearchService;
        this.metaModelService = metaModelService;
        this.languageService = languageService;
        this.translateService = translateService;
        this.vocabularyAction$ = new BehaviorSubject(createNoSelection());
        this.vocabularySelect$ = onlySelect(this.vocabularyAction$);
        this.vocabularyEdit$ = onlyEdit(this.vocabularyAction$);
        this.vocabularyRemove$ = onlyRemove(this.vocabularyAction$);
        this.resourceAction$ = new BehaviorSubject(createNoSelection());
        this.resourceSelect$ = onlySelect(this.resourceAction$);
        this.resourceEdit$ = onlyEdit(this.resourceAction$);
        this.ressourceRemove$ = onlyRemove(this.resourceAction$);
        this.resourceId = null; // separate id besides selection item id is needed because selection is async loaded
        this.conceptList = new ConceptListModel(this.elasticSearchService, this.languageService);
        this.conceptHierarchy = new ConceptHierarchyModel(this.elasticSearchService, this.languageService);
        this.collectionList = new CollectionListModel(this.termedService, this.languageService);
        this.loadingVocabulary = true;
        this.loadingResource = true;
        combineLatest(this.vocabularyAction$, this.resourceAction$)
            .subscribe(function (_a) {
            var vocabularyAction = _a[0], resourceAction = _a[1];
            if (isSelect(vocabularyAction) || isEdit(vocabularyAction)) {
                if (isSelect(resourceAction) || isEdit(resourceAction)) {
                    switch (resourceAction.item.type) {
                        case 'Concept':
                            locationService.atConcept(vocabularyAction.item, resourceAction.item);
                            break;
                        case 'Collection':
                            locationService.atCollection(vocabularyAction.item, resourceAction.item);
                            break;
                        default:
                            assertNever(resourceAction.item);
                    }
                }
                else {
                    locationService.atVocabulary(vocabularyAction.item);
                }
            }
        });
        this.resourceAction$.subscribe(function (action) {
            switch (action.type) {
                case 'edit':
                case 'remove':
                    var remove = action.type === 'remove';
                    if (action.item.type === 'Concept') {
                        _this.conceptList.refresh(action.item.id, remove);
                        _this.conceptHierarchy.refresh(action.item.id, remove);
                    }
                    else {
                        _this.collectionList.refresh(action.item.id, remove);
                    }
            }
        });
        merge(this.vocabularySelect$, this.vocabularyEdit$).subscribe(function (vocabulary) {
            if (languageService.filterLanguage && !contains(vocabulary.languages, languageService.filterLanguage)) {
                languageService.filterLanguage = '';
            }
        });
    }
    Object.defineProperty(ConceptViewModelService.prototype, "languages", {
        get: function () {
            return this.vocabulary ? this.vocabulary.languages : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptViewModelService.prototype, "vocabulary", {
        get: function () {
            var action = this.vocabularyAction$.getValue();
            if (action.type === 'noselect' || action.type === 'remove') {
                return null;
            }
            return action.item;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptViewModelService.prototype, "concept", {
        get: function () {
            var action = this.resourceAction$.getValue();
            if (action.type === 'noselect' || action.type === 'remove' || action.item.type !== 'Concept') {
                return null;
            }
            return action.item;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptViewModelService.prototype, "collection", {
        get: function () {
            var action = this.resourceAction$.getValue();
            if (action.type === 'noselect' || action.type === 'remove' || action.item.type !== 'Collection') {
                return null;
            }
            return action.item;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConceptViewModelService.prototype, "selection", {
        get: function () {
            return this.concept || this.collection;
        },
        enumerable: true,
        configurable: true
    });
    ConceptViewModelService.prototype.initializeVocabulary = function (graphId, initialConceptSearch) {
        var _this = this;
        this.graphId = graphId;
        this.metaModel = this.metaModelService.getMeta(graphId);
        this.loadingVocabulary = true;
        this.conceptList.initializeGraph(graphId, initialConceptSearch);
        this.conceptHierarchy.initializeGraph(graphId);
        this.collectionList.initializeGraph(graphId);
        combineLatest(this.termedService.getVocabulary(graphId), this.metaModel)
            .subscribe(function (_a) {
            var vocabulary = _a[0], metaModel = _a[1];
            _this.vocabularyAction$.next(createSelectAction(vocabulary));
            _this.vocabularyForm = new FormNode(vocabulary, function () { return vocabulary.languages; }, metaModel);
            _this.loadingVocabulary = false;
        });
        this.termedService.getGraphNamespace(graphId).subscribe(function (graphNamespace) { return _this.prefixAndNamespace = graphNamespace; });
    };
    ConceptViewModelService.prototype.initializeConcept = function (conceptId) {
        var _this = this;
        var init = function (concept) {
            _this.metaModel.subscribe(function (metaModel) {
                _this.resourceAction$.next(createSelectAction(concept));
                _this.resourceForm = new FormNode(concept, function () { return _this.languages; }, metaModel);
                _this.loadingResource = false;
            });
        };
        this.loadingResource = true;
        this.resourceId = conceptId;
        this.termedService.findConcept(this.graphId, conceptId).subscribe(function (concept) {
            if (concept) {
                init(concept);
            }
            else {
                // XXX: Vocabulary might not be initialized yet
                // more robust waiting mechanism instead of duplicate fetch would be preferred
                _this.termedService.getVocabulary(_this.graphId).subscribe(function (vocabulary) {
                    _this.createEmptyConcept(vocabulary, conceptId).subscribe(init);
                });
            }
        });
    };
    ConceptViewModelService.prototype.initializeCollection = function (collectionId) {
        var _this = this;
        var init = function (collection) {
            _this.metaModel.subscribe(function (metaModel) {
                _this.resourceAction$.next(createSelectAction(collection));
                _this.resourceForm = new FormNode(collection, function () { return _this.languages; }, metaModel);
                _this.loadingResource = false;
            });
        };
        this.loadingResource = true;
        this.resourceId = collectionId;
        this.termedService.findCollection(this.graphId, collectionId).subscribe(function (collection) {
            if (collection) {
                init(collection);
            }
            else {
                // XXX: Vocabulary might not be initialized yet
                // more robust waiting mechanism instead of duplicate fetch would be preferred
                _this.termedService.getVocabulary(_this.graphId).subscribe(function (vocabulary) {
                    _this.createEmptyCollection(vocabulary, collectionId).subscribe(init);
                });
            }
        });
    };
    ConceptViewModelService.prototype.initializeNoSelection = function (selectFirstConcept) {
        var _this = this;
        this.resourceId = null;
        this.resourceAction$.next(createNoSelection());
        this.resourceForm = null;
        if (selectFirstConcept) {
            var searchResults$ = this.conceptList.searchResults$.asObservable();
            // XXX: when loading first search result is empty array initialization
            if (this.conceptList.loading) {
                searchResults$ = searchResults$.pipe(skip(1));
            }
            searchResults$.pipe(take(1)).subscribe(function (searchResults) {
                if (searchResults.length > 0) {
                    var firstConcept = searchResults[0];
                    _this.router.navigate(['/concepts', firstConcept.vocabulary.id, 'concept', firstConcept.id], { skipLocationChange: true });
                }
            });
        }
    };
    ConceptViewModelService.prototype.saveConcept = function (confirmation) {
        var _this = this;
        if (!this.concept || !this.resourceForm) {
            throw new Error('Cannot save when there is no concept');
        }
        var that = this;
        var concept = this.concept.clone();
        this.resourceForm.assignChanges(concept);
        var save = function () { return new Promise(function (resolve, reject) {
            _this.metaModel.subscribe(function (metaModel) {
                _this.termedService.updateNode(concept, _this.concept)
                    .pipe(flatMap(function () { return _this.termedService.getConcept(_this.graphId, concept.id); }))
                    .subscribe({
                    next: function (persistentConcept) {
                        that.resourceAction$.next(createEditAction(persistentConcept.clone()));
                        that.resourceForm = new FormNode(persistentConcept, function () { return that.languages; }, metaModel);
                        resolve();
                    },
                    error: function (err) {
                        reject(err);
                    }
                });
            });
        }); };
        if (confirmation) {
            return confirmation(concept, this.concept).then(save);
        }
        else {
            return save();
        }
    };
    ConceptViewModelService.prototype.removeConcept = function () {
        var _this = this;
        if (!this.concept) {
            throw new Error('Cannot remove when there is no concept');
        }
        var that = this;
        var concept = this.concept;
        return new Promise(function (resolve, reject) {
            _this.termedService.removeNode(concept).subscribe({
                next: function () {
                    that.resourceAction$.next(createRemoveAction(concept));
                    that.router.navigate(['/concepts', that.graphId]);
                    resolve();
                },
                error: function (err) {
                    reject(err);
                }
            });
        });
    };
    ConceptViewModelService.prototype.resetConcept = function () {
        var _this = this;
        if (!this.concept) {
            throw new Error('Cannot reset when there is no concept');
        }
        if (!this.concept.persistent) {
            this.router.navigate(['/concepts', this.graphId]);
        }
        else {
            var concept_1 = this.concept;
            this.metaModel.subscribe(function (metaModel) {
                _this.resourceForm = new FormNode(concept_1, function () { return _this.languages; }, metaModel);
            });
        }
    };
    ConceptViewModelService.prototype.saveCollection = function () {
        var _this = this;
        if (!this.collection || !this.resourceForm) {
            throw new Error('Cannot save when there is no collection');
        }
        var that = this;
        var collection = this.collection.clone();
        this.resourceForm.assignChanges(collection);
        return new Promise(function (resolve, reject) {
            _this.metaModel.subscribe(function (metaModel) {
                _this.termedService.updateNode(collection, _this.collection)
                    .pipe(flatMap(function () { return _this.termedService.getCollection(_this.graphId, collection.id); }))
                    .subscribe({
                    next: function (persistentCollection) {
                        that.resourceAction$.next(createEditAction(persistentCollection.clone()));
                        that.resourceForm = new FormNode(persistentCollection, function () { return that.languages; }, metaModel);
                        resolve();
                    },
                    error: function (err) {
                        reject(err);
                    }
                });
            });
        });
    };
    ConceptViewModelService.prototype.removeCollection = function () {
        var _this = this;
        if (!this.collection) {
            throw new Error('Cannot remove when there is no collection');
        }
        var that = this;
        var collection = this.collection;
        return new Promise(function (resolve, reject) {
            _this.termedService.removeNode(collection).subscribe({
                next: function () {
                    that.resourceAction$.next(createRemoveAction(collection));
                    that.router.navigate(['/concepts', that.graphId]);
                    resolve();
                },
                error: function (err) {
                    reject(err);
                }
            });
        });
    };
    ConceptViewModelService.prototype.resetCollection = function () {
        var _this = this;
        if (!this.collection) {
            throw new Error('Cannot reset when there is no collection');
        }
        if (!this.collection.persistent) {
            this.router.navigate(['/concepts', this.graphId]);
        }
        else {
            var collection_1 = this.collection;
            this.metaModel.subscribe(function (metaModel) {
                _this.resourceForm = new FormNode(collection_1, function () { return _this.languages; }, metaModel);
            });
        }
    };
    ConceptViewModelService.prototype.saveVocabulary = function () {
        var _this = this;
        if (!this.vocabulary) {
            throw new Error('Cannot save when there is no vocabulary');
        }
        var that = this;
        var vocabulary = this.vocabulary.clone();
        this.vocabularyForm.assignChanges(vocabulary);
        return new Promise(function (resolve, reject) {
            _this.metaModel.subscribe(function (metaModel) {
                _this.termedService.updateNode(vocabulary, _this.vocabulary)
                    .pipe(flatMap(function () { return _this.termedService.getVocabulary(_this.graphId); }))
                    .subscribe({
                    next: function (persistentVocabulary) {
                        that.vocabularyAction$.next(createEditAction(persistentVocabulary.clone()));
                        that.vocabularyForm = new FormNode(persistentVocabulary, function () { return that.languages; }, metaModel);
                        resolve();
                    },
                    error: function (err) {
                        reject(err);
                    }
                });
            });
        });
    };
    ConceptViewModelService.prototype.removeVocabulary = function () {
        var _this = this;
        if (!this.vocabulary) {
            throw new Error('Cannot remove when there is no vocabulary');
        }
        var that = this;
        var vocabulary = this.vocabulary;
        return new Promise(function (resolve, reject) {
            _this.termedService.removeVocabulary(vocabulary.graphId).subscribe({
                next: function () {
                    that.router.navigate(['/']);
                    resolve();
                },
                error: function (err) {
                    reject(err);
                }
            });
        });
    };
    ConceptViewModelService.prototype.resetVocabulary = function () {
        var _this = this;
        if (!this.vocabulary) {
            throw new Error('Cannot reset when there is no vocabulary');
        }
        var vocabulary = this.vocabulary;
        this.metaModel.subscribe(function (metaModel) {
            _this.vocabularyForm = new FormNode(vocabulary, function () { return _this.languages; }, metaModel);
        });
    };
    ConceptViewModelService.prototype.createEmptyConcept = function (vocabulary, nodeId) {
        var _this = this;
        var label$ = this.translateService.get('New concept');
        return zip(label$, this.metaModel).pipe(map(function (_a) {
            var newConceptLabel = _a[0], meta = _a[1];
            var newConcept = meta.createEmptyConcept(vocabulary, nodeId);
            newConcept.prefLabel = [{ lang: _this.languageService.language, value: newConceptLabel }];
            return newConcept;
        }));
    };
    ConceptViewModelService.prototype.createEmptyCollection = function (vocabulary, nodeId) {
        var _this = this;
        var label$ = this.translateService.get('New collection');
        return zip(label$, this.metaModel).pipe(map(function (_a) {
            var newCollectionLabel = _a[0], meta = _a[1];
            var newCollection = meta.createEmptyCollection(vocabulary, nodeId);
            newCollection.prefLabel = [{ lang: _this.languageService.language, value: newCollectionLabel }];
            return newCollection;
        }));
    };
    ConceptViewModelService.prototype.refreshConcepts = function () {
        this.conceptList.loadConcepts(true);
        this.conceptHierarchy.loadConcepts(true);
    };
    ConceptViewModelService.prototype.ngOnDestroy = function () {
        this.conceptList.clean();
        this.conceptHierarchy.clean();
        this.collectionList.clean();
    };
    return ConceptViewModelService;
}());
export { ConceptViewModelService };
