import { FormControl, NgControl } from '@angular/forms';
import { TermedService } from 'app/services/termed.service';
var PrefixInputComponent = /** @class */ (function () {
    function PrefixInputComponent(parentControl, termedService) {
        var _this = this;
        this.parentControl = parentControl;
        this.control = new FormControl();
        this.propagateChange = function () { };
        this.propagateTouched = function () { };
        termedService.getNamespaceRoot().subscribe(function (namespace) { return _this.namespace = namespace; });
        if (parentControl) {
            parentControl.valueAccessor = this;
        }
        this.control.valueChanges.subscribe(function (x) { return _this.propagateChange(x); });
    }
    Object.defineProperty(PrefixInputComponent.prototype, "valid", {
        get: function () {
            return !this.parentControl || this.parentControl.valid;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PrefixInputComponent.prototype, "pending", {
        get: function () {
            return !this.parentControl || this.parentControl.pending;
        },
        enumerable: true,
        configurable: true
    });
    PrefixInputComponent.prototype.writeValue = function (obj) {
        this.control.setValue(obj);
    };
    PrefixInputComponent.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    PrefixInputComponent.prototype.registerOnTouched = function (fn) {
        this.propagateTouched = fn;
    };
    return PrefixInputComponent;
}());
export { PrefixInputComponent };
