import { OnDestroy } from '@angular/core';
import { UserService } from 'yti-common-ui/services/user.service';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { index } from 'yti-common-ui/utils/array';
import { TermedService } from '../../services/termed.service';
import { comparingLocalizable } from '../../utils/comparator';
import { combineSets, hasAny } from 'yti-common-ui/utils/set';
import { labelNameToResourceIdIdentifier } from 'yti-common-ui/utils/resource';
import { LocationService } from '../../services/location.service';
import { LanguageService } from '../../services/language.service';
import { TranslateService } from '@ngx-translate/core';
var UserDetailsInformationComponent = /** @class */ (function () {
    function UserDetailsInformationComponent(router, userService, termedService, locationService, languageService, translateService) {
        var _this = this;
        this.router = router;
        this.userService = userService;
        this.termedService = termedService;
        this.locationService = locationService;
        this.languageService = languageService;
        this.translateService = translateService;
        this.subscriptionToClean = [];
        this.selectedOrganization = null;
        this.requestsInOrganizations = new Map();
        this.subscriptionToClean.push(this.userService.loggedIn$.subscribe(function (loggedIn) {
            if (!loggedIn) {
                router.navigate(['/']);
            }
        }));
        userService.updateLoggedInUser();
        locationService.atUserDetails();
        this.subscriptionToClean.push(combineLatest(termedService.getOrganizationList(), languageService.language$)
            .subscribe(function (_a) {
            var organizationNodes = _a[0];
            organizationNodes.sort(comparingLocalizable(languageService, function (org) { return org.label; }));
            _this.allOrganizations = organizationNodes;
            _this.allOrganizationsById = index(organizationNodes, function (org) { return org.id; });
        }));
        this.refreshRequests();
    }
    UserDetailsInformationComponent.prototype.ngOnDestroy = function () {
        this.subscriptionToClean.forEach(function (s) { return s.unsubscribe(); });
    };
    Object.defineProperty(UserDetailsInformationComponent.prototype, "user", {
        get: function () {
            return this.userService.user;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "loading", {
        get: function () {
            return !this.allOrganizations || !this.requestsInOrganizations;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "userOrganizations", {
        get: function () {
            var _this = this;
            var organizationIds = new Set(Array.from(this.user.rolesInOrganizations.keys()).concat(Array.from(this.requestsInOrganizations.keys())));
            var result = Array.from(organizationIds.values()).map(function (organizationId) {
                return {
                    organization: _this.allOrganizationsById.get(organizationId),
                    roles: Array.from(_this.user.getRoles(organizationId)),
                    requests: Array.from(_this.requestsInOrganizations.get(organizationId) || [])
                };
            });
            result.sort(comparingLocalizable(this.languageService, function (org) { return org.organization ? org.organization.label : {}; }));
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserDetailsInformationComponent.prototype, "organizationOptions", {
        get: function () {
            var _this = this;
            var hasExistingRoleOrRequest = function (org) {
                var rolesOrRequests = combineSets([
                    _this.user.getRoles(org.id),
                    _this.requestsInOrganizations.get(org.id) || new Set()
                ]);
                return hasAny(rolesOrRequests, ['TERMINOLOGY_EDITOR', 'ADMIN']);
            };
            var requestableOrganizations = this.allOrganizations.filter(function (organization) { return !hasExistingRoleOrRequest(organization); });
            return [null].concat(requestableOrganizations).map(function (org) {
                return {
                    value: org,
                    name: function () { return org ? _this.languageService.translate(org.label, true)
                        : _this.translateService.instant('Choose organization'); },
                    idIdentifier: function () { return org ? labelNameToResourceIdIdentifier(_this.languageService.translate(org.label, true)) : 'all_selected'; }
                };
            });
        },
        enumerable: true,
        configurable: true
    });
    UserDetailsInformationComponent.prototype.sendRequest = function () {
        var _this = this;
        if (!this.selectedOrganization) {
            throw new Error('No organization selected for request');
        }
        this.termedService.sendRequest(this.selectedOrganization.id)
            .subscribe(function () { return _this.refreshRequests(); });
    };
    UserDetailsInformationComponent.prototype.refreshRequests = function () {
        var _this = this;
        this.selectedOrganization = null;
        this.termedService.getUserRequests().subscribe(function (userRequests) {
            _this.requestsInOrganizations.clear();
            for (var _i = 0, userRequests_1 = userRequests; _i < userRequests_1.length; _i++) {
                var userRequest = userRequests_1[_i];
                _this.requestsInOrganizations.set(userRequest.organizationId, new Set(userRequest.role));
            }
        });
    };
    return UserDetailsInformationComponent;
}());
export { UserDetailsInformationComponent };
