/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./semantic-text-input.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./semantic-text-input-popover.component.ngfactory";
import * as i3 from "./semantic-text-input-popover.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/forms";
import * as i6 from "./semantic-text-input.component";
import * as i7 from "./url-input-modal.component";
import * as i8 from "../../services/configuration.service";
var styles_SemanticTextInputComponent = [i0.styles];
var RenderType_SemanticTextInputComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SemanticTextInputComponent, data: {} });
export { RenderType_SemanticTextInputComponent as RenderType_SemanticTextInputComponent };
function View_SemanticTextInputComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-link-popover", [], null, [[null, "linkConcept"], [null, "linkExternal"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("linkConcept" === en)) {
        var pd_0 = (_co.linkInternal() !== false);
        ad = (pd_0 && ad);
    } if (("linkExternal" === en)) {
        var pd_1 = (_co.linkExternal() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SemanticTextInputLinkPopoverComponent_0, i2.RenderType_SemanticTextInputLinkPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputLinkPopoverComponent, [i1.ElementRef, i1.NgZone], { id: [0, "id"], selectedText: [1, "selectedText"] }, { linkConcept: "linkConcept", linkExternal: "linkExternal" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.linkableSelection.content; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SemanticTextInputComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-unlink-concept-popover", [], null, [[null, "unlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("unlink" === en)) {
        var pd_0 = (_co.unlink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SemanticTextInputUnlinkConceptPopoverComponent_0, i2.RenderType_SemanticTextInputUnlinkConceptPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputUnlinkConceptPopoverComponent, [i1.ElementRef, i1.NgZone], { id: [0, "id"], concept: [1, "concept"] }, { unlink: "unlink" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.linkedConcept; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SemanticTextInputComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-semantic-text-input-unlink-ext-popover", [], null, [[null, "unlink"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("unlink" === en)) {
        var pd_0 = (_co.unlink() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SemanticTextInputUnlinkExternalPopoverComponent_0, i2.RenderType_SemanticTextInputUnlinkExternalPopoverComponent)), i1.ɵdid(1, 4374528, null, 0, i3.SemanticTextInputUnlinkExternalPopoverComponent, [i1.ElementRef, i1.NgZone], { id: [0, "id"], target: [1, "target"] }, { unlink: "unlink" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.id; var currVal_1 = _co.linkedExternalTarget; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_SemanticTextInputComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { editableElement: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SemanticTextInputComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, [[1, 0], ["editable", 1]], null, 2, "div", [["class", "form-control"], ["contenteditable", "true"]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(8, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(9, { "invalid-data": 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hasLinkableSelection(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hasConceptLinkSelection(); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.hasExternalLinkSelection(); _ck(_v, 6, 0, currVal_2); var currVal_4 = "form-control"; var currVal_5 = _ck(_v, 9, 0, _co.invalidData); _ck(_v, 8, 0, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.id; _ck(_v, 7, 0, currVal_3); }); }
export function View_SemanticTextInputComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-semantic-text-input", [], null, null, null, View_SemanticTextInputComponent_0, RenderType_SemanticTextInputComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.SemanticTextInputComponent]), i1.ɵdid(2, 114688, null, 0, i6.SemanticTextInputComponent, [i7.UrlInputModalService, i8.ConfigurationService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SemanticTextInputComponentNgFactory = i1.ɵccf("app-semantic-text-input", i6.SemanticTextInputComponent, View_SemanticTextInputComponent_Host_0, { id: "id", conceptSelector: "conceptSelector", relatedConcepts: "relatedConcepts", format: "format" }, {}, []);
export { SemanticTextInputComponentNgFactory as SemanticTextInputComponentNgFactory };
